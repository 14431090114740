import { RSAA } from 'redux-api-middleware';

export const FETCH_COLLECTION_GET_DONATION_SEARCH_REQUEST = 'FETCH_COLLECTION_GET_DONATION_SEARCH_REQUEST';
export const FETCH_COLLECTION_GET_DONATION_SEARCH_SUCCESS = 'FETCH_COLLECTION_GET_DONATION_SEARCH_SUCCESS';
export const FETCH_COLLECTION_GET_DONATION_SEARCH_FAILURE = 'FETCH_COLLECTION_GET_DONATION_SEARCH_FAILURE';

export const fetchCollectionGetDonationSearch = searchTerm => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-collection-get-donation-search/${searchTerm}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COLLECTION_GET_DONATION_SEARCH_REQUEST,
      {
        type: FETCH_COLLECTION_GET_DONATION_SEARCH_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          if (!json.data[0]) {
            return { entities: { donationsSearched: { data: ['No results found'] } } };
          }
          return { entities: { donationsSearched: json } };
        },
      },
      {
        type: FETCH_COLLECTION_GET_DONATION_SEARCH_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const CollectionGetDonationSearchhMiddleware = () => next => action => {
  next(action);
  switch (action.type) {
    case FETCH_COLLECTION_GET_DONATION_SEARCH_REQUEST:
      break;
    case FETCH_COLLECTION_GET_DONATION_SEARCH_SUCCESS:
      console.log('fetch all donations by search success');
      break;
    case FETCH_COLLECTION_GET_DONATION_SEARCH_FAILURE:
      console.log('fetch all donations by search failure');
      break;
    default:
      break;
  }
};
