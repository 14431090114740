import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    marginTop: theme.spacing(8),
    overflowX: 'hidden',
  },
  modalRow1: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
  },
  buttonCloseModal: {
    float: 'right',
    fontSize: '16px',
    position: 'absolute',
    right: '5px',
    top: '5px',
  },
  paper: {
    backgroundColor: '#fafafa',
  },
}));

export default useStyles;
