import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import useStyles from './RankList.styles';
import { withCommas } from '../../utils';

function RankList({ list, usePopUp }) {
  const classes = useStyles();
  // eslint-disable-next-line consistent-return
  const showRows = () => {
    if (list && list[0]) {
      return list.map((item, rank) => (
        <div key={`${item.title}-Group`}>
          <ListItem key={`${item.title}-Item`} className={classes.listItem}>
            {rank + 1}.{' '}
            {!usePopUp ? (
              <Link component={RouterLink} className={classes.listLink} to={{ pathname: item.url }}>
                {item.title}
              </Link>
            ) : (
              item.showLink()
            )}
            <ListItemText
              disableTypography
              className={classes.listStats}
              primary={
                <Typography style={{ fontSize: '.9rem', fontWeight: '500' }}>
                  {item.stat && withCommas(item.stat)}
                </Typography>
              }
              secondary={
                <Typography style={{ color: '#747480', fontSize: '.85rem', fontWeight: '500' }}>
                  {item.subStat && withCommas(item.subStat)}
                </Typography>
              }
            />
          </ListItem>
          {rank + 1 !== list.length ? (
            <Divider
              key={`${item.title}-divider`}
              className={classes.divider}
              variant="inset"
              component="li"
            />
          ) : (
            ''
          )}
        </div>
      ));
    }
    return 'No data available for selected period of time';
  };

  return <List className={classes.rankContainer}> {showRows()} </List>;
}

RankList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  usePopUp: PropTypes.bool,
};

RankList.defaultProps = {
  usePopUp: false,
};

export default RankList;
