import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import flatMap from 'lodash/flatMap';
import RankList from '../../../components/RankList/RankList';
import { TableModal } from '../../../components/Table/TableLink';
import useStyles from '../Quality.styles';
import Loader from '../../../components/Loader/Loader';
import { fetchComplianceGetTopReasons } from '../../../redux/overview';

function TopReasons({ handleReasonsRowClick, timeFrame }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const loading = useSelector(state => get(state, 'loading.FETCH_COMPLIANCE_GET_TOP_REASONS', false));

  useEffect(() => {
    dispatch(fetchComplianceGetTopReasons(timeFrame));
  }, [timeFrame]);

  const topReasonsLists = useSelector(state =>
    flatMap(get(state, 'entities.topReasons', []), data => {
      const populate = (arr, type, reason, stat, statUnit, substat, thirdStat) => {
        const array = [];
        if (arr)
          arr.forEach(item => {
            // If reason is Destroy, remove "Destroy - " from 'Destroyed Reasons'
            let reasonUpdated = item[reason];
            let reasonDescription = '';
            // Set default displayed top reason title equal to the reason
            let topReasonDisplayedTitle = reasonUpdated;
            if (reason === 'destroyed_reason' && reasonUpdated.substring(0, 10) === 'Destroy - ') {
              reasonUpdated = reasonUpdated.replace('Destroy - ', '');
              topReasonDisplayedTitle = reasonUpdated;
              // If reason is Lookback, display both lookback report type + description
            } else if (reason === 'lookback_reason') {
              reasonUpdated = item.lookback_reason;
              reasonDescription = item.lookback_reason_description;
              // For Lookback items, display the lookback report type + description (instead of the reason)
              topReasonDisplayedTitle = `${item.lookback_report_type} - ${item.lookback_reason_description}`;
            }

            array.push({
              title: reasonUpdated,
              stat: `${item[stat]} ${statUnit}`,
              showLink: () =>
                TableModal(topReasonDisplayedTitle, () =>
                  handleReasonsRowClick(type, reasonUpdated, reasonDescription, {
                    donations: `${item[thirdStat]}`,
                    volume: `${item[stat]}`,
                    est_igg: `${item[substat]}`,
                  })
                ),
            });
          });
        return array;
      };
      if (data[0]) {
        return [
          {
            'Lookback Reasons': populate(
              data[0].submitted,
              'lookback',
              'lookback_reason',
              'volume',
              'L',
              'est_igg',
              'donations'
            ),
          },
          {
            'Destroyed Reasons': populate(
              data[1].destroyed,
              'destroyed',
              'destroyed_reason',
              'volume',
              'L',
              'est_igg',
              'donations'
            ),
          },
        ];
      }
      return {};
    })
  );

  const showTopFiveReasonsLists = () => {
    if (topReasonsLists[0]) {
      return topReasonsLists.map(list => {
        const key = Object.keys(list)[0];
        return (
          <Grid item xs={3} key={key} align="left">
            <Typography className={classes.rankListTitle} variant="h6">
              {key}
            </Typography>
            <RankList list={list[key]} usePopUp />
          </Grid>
        );
      });
    }
    return null;
  };

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.accordionDescription}>
          <Typography className={classes.title} variant="subtitle2">
            The top lookback reasons for BioLife donations. Data displayed is for the time interval selected
            above.
          </Typography>
        </div>
      </Grid>
      <Grid container spacing={3} align="left">
        {loading ? <Loader /> : showTopFiveReasonsLists()}
      </Grid>
    </>
  );
}

TopReasons.propTypes = {
  timeFrame: PropTypes.string.isRequired,
  handleReasonsRowClick: PropTypes.func.isRequired,
};
export default TopReasons;
