const symbols = [
  '<',
  '>',
  '"',
  "'",
  '%',
  ':',
  ';',
  ')',
  '(',
  '&',
  '+',
  '|',
  '!',
  '?',
  '/',
  '\\',
  '-',
  '_',
  '*',
  '@',
  '+',
  '=',
  ',',
];
const words = [
  'char',
  'load_file',
  'select',
  'declare',
  'delete',
  'desc',
  'distinct',
  'exec',
  'handler',
  'having',
  'or',
  'union',
  'update',
  'replace',
  'procedure',
  'print',
  'truncate',
  'order by',
  'insert',
  'like',
  'limit',
  'handler',
  'having',
  'as',
  'asc',
  'bfilename',
];

const hasSymbol = input => {
  let has = false;
  symbols.forEach(e => {
    if (input.includes(e)) {
      has = true;
    }
  });
  return has;
};

const isWord = input => {
  let has = false;
  const word = input.toLowerCase();
  words.forEach(e => {
    if (word === e) {
      has = true;
    }
  });
  return has;
};

const isInputValid = input => {
  if (isWord(input)) return false;
  const res = input.split(' ');
  let valid = true;
  res.forEach(e => {
    if (hasSymbol(e) || isWord(e) || e === '0' || input.startsWith('0x', 0)) valid = false;
  });
  return valid;
};

export default isInputValid;
