import React from 'react';
import { Switch, useHistory } from 'react-router-dom';
import './App.css';
import Container from '@material-ui/core/Container';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import oktaConfig from './oktaConfig';
import Header from './components/Header/Header';
import Inventory from './pages/Inventory/Inventory';
import Collection from './pages/Collection/Collection';
import Transit from './pages/Transit/Transit';
import Quality from './pages/Quality/Quality';
import Manufacturing from './pages/Manufacturing/Manufacturing';
import CollectionLocation from './pages/CollectionLocation/CollectionLocation';
import ManufacturingLocation from './pages/ManufacturingLocation/ManufacturingLocation';
import ManufacturingLocationPool from './pages/ManufacturingLocationPool/ManufacturingLocationPool';
import Reconciliation from './pages/Reconciliation/Reconciliation';
import Donation from './pages/Donation/Donation';
import Layout from './Layout';
import NoAuth from './components/NoAuth/NoAuth';
import NotFound from './pages/Error/NotFound';
import ErrorBoundary from './error';

const oktaAuth = new OktaAuth(oktaConfig.oidc);

function App() {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Layout>
        <LoginCallback errorComponent={NoAuth} />
        <Header />
        <ErrorBoundary>
          <Container style={{ paddingTop: '150px' }}>
            <Switch>
              <SecureRoute exact path="/" component={Inventory} />
              <SecureRoute exact path="/collection" component={Collection} />
              <SecureRoute path="/collection/:id/donation/:donation/" component={Donation} />
              <SecureRoute path="/collection/:id/:location" component={CollectionLocation} />
              <SecureRoute exact path="/transit" component={Transit} />
              <SecureRoute exact path="/quality" component={Quality} />
              <SecureRoute exact path="/manufacturing" component={Manufacturing} />
              <SecureRoute path="/manufacturing/location/:id/:location" component={ManufacturingLocation} />
              <SecureRoute path="/manufacturing/teardown/:lotid" component={ManufacturingLocationPool} />
              <SecureRoute exact path="/reconciliation" component={Reconciliation} />
              <SecureRoute component={NotFound} />
            </Switch>
          </Container>
        </ErrorBoundary>
      </Layout>
    </Security>
  );
}

export default App;
