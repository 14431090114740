import { RSAA } from 'redux-api-middleware';

export const FETCH_COLLECTION_GET_LOCATION_BOXED_DONATIONS_REQUEST =
  'FETCH_COLLECTION_GET_LOCATION_BOXED_DONATIONS_REQUEST';
export const FETCH_COLLECTION_GET_LOCATION_BOXED_DONATIONS_SUCCESS =
  'FETCH_COLLECTION_GET_LOCATION_BOXED_DONATIONS_SUCCESS';
export const FETCH_COLLECTION_GET_LOCATION_BOXED_DONATIONS_FAILURE =
  'FETCH_COLLECTION_GET_LOCATION_BOXED_DONATIONS_FAILURE';

export const FETCH_COLLECTION_GET_BOX_DONATIONS_REQUEST = 'FETCH_COLLECTION_GET_BOX_DONATIONS_REQUEST';
export const FETCH_COLLECTION_GET_BOX_DONATIONS_SUCCESS = 'FETCH_COLLECTION_GET_BOX_DONATIONS_SUCCESS';
export const FETCH_COLLECTION_GET_BOX_DONATIONS_FAILURE = 'FETCH_COLLECTION_GET_BOX_DONATIONS_FAILURE';

export const fetchCollectionGetLocationBoxedDonations = id => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-collection-get-location-boxed-donations/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COLLECTION_GET_LOCATION_BOXED_DONATIONS_REQUEST,
      {
        type: FETCH_COLLECTION_GET_LOCATION_BOXED_DONATIONS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          if (!json.data[0]) {
            return { entities: { data: { boxed: ['no entries'] } } };
          }
          return { entities: { boxed: json } };
        },
      },
      {
        type: FETCH_COLLECTION_GET_LOCATION_BOXED_DONATIONS_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

const getUrlExtension = (locationid, locationtype, openedFrom) => {
  if (openedFrom === 'location') {
    return `&locationid=${locationid}&locationtype=${locationtype}`;
  }
  return '';
};

export const fetchCollectionGetBoxDonations = (boxNumber, locationid, locationtype, openedFrom) => ({
  [RSAA]: {
    endpoint: `${
      process.env.REACT_APP_API_URL
    }/plasmachain-collection-get-box-donations?boxid=${boxNumber}${getUrlExtension(
      locationid,
      locationtype,
      openedFrom
    )}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COLLECTION_GET_BOX_DONATIONS_REQUEST,
      {
        type: FETCH_COLLECTION_GET_BOX_DONATIONS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          if (!json.data[0]) {
            return { entities: { donationsInBox: { data: ['no entries'] } } };
          }
          return { entities: { donationsInBox: json } };
        },
      },
      {
        type: FETCH_COLLECTION_GET_BOX_DONATIONS_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const CollectionGetLocationBoxedDonationsMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COLLECTION_GET_LOCATION_BOXED_DONATIONS_SUCCESS:
      console.log('fetch boxed donations success');
      break;
    case FETCH_COLLECTION_GET_LOCATION_BOXED_DONATIONS_FAILURE:
      console.log('fetch boxed donations failure');
      break;
    default:
      break;
  }
};

export const CollectionGetBoxDonationsMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COLLECTION_GET_BOX_DONATIONS_SUCCESS:
      console.log('fetch donations in box success');
      break;
    case FETCH_COLLECTION_GET_BOX_DONATIONS_FAILURE:
      console.log('fetch donations in box failure');
      break;
    default:
      break;
  }
};
