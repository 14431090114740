/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import map from 'lodash/map';
import get from 'lodash/get';
import TabPanel from '../../components/TabPanel/TabPanel';
import ReconciliationTable from '../../components/ReconciliationTable/ReconciliationTable';
import TableSearch from '../../components/TableSearch/TableSearch';
import useStyles from './Reconciliation.styles';
import ViewInDevBanner from '../../components/ViewInDevBanner/ViewInDevBanner';
import CustomRangeFilter from '../../components/CustomRangeFilter/CustomRangeFilter';
// import CustomDropdownFilter from '../../components/CustomDropdownFilter/CustomDropdownFilter';
import {
  customAlignLeft,
  sortNum,
  sortQuantities,
  sortDate,
  formatDate,
  formatQuantities,
  formatListOfNumbersNumDecimalCheck,
  checkAndFormatIfDate,
  filterDateOptionsLogic,
  customFilterListOptionsRender,
  customFilterListOptionsUpdate,
  customLightGray,
  customDarkGray,
  customRightAlignLightGray,
  customRightAlignDarkGray,
  customRightAlignWhite,
  customLeftAlignDarkGray,
  customLeftAlignWhite,
  returnRowContainsStatus,
  returnRowIsBlank,
  getFilterArray,
  findFilter,
} from '../../utils';
import { quantityRelatedColumn, reconBOLRelatedColumn } from '../../components/Table/Columns';
import { fetchShipmentReconciliation, fetchReconciliationGetBolNumber } from '../../redux/reconciliation';

function Reconciliation() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState('bol');
  const [timeframe, setTimeframe] = useState('all');

  const [qtySortOptions, setQtySortOptions] = useState({ name: 'reconciliation_status', direction: 'desc' });
  const [rowsPerQtyPage, setRowsPerQtyPage] = useState(50);
  const [qtyPage, setQtyPage] = useState(0);
  const [qtyFilterOptions, setQtyFilterOptions] = useState({});
  const [qtySearchText, setQtySearchText] = useState('');
  const loadingQty = useSelector(state => get(state, 'loading.FETCH_SHIPMENT_RECONCILIATION', true));
  const [qtyFiltersRetrieved, setQtyFiltersRetrieved] = useState(false);
  const [qtyFilterData, setQtyFilterData] = useState({});
  const [qtyRetrieved, setQtyRetrieved] = useState(false);

  const [bolSortOptions, setBolSortOptions] = useState({ name: 'dis_bol', direction: 'asc' });
  const [rowsPerBolPage, setRowsPerBolPage] = useState(50);
  const [bolPage, setBolPage] = useState(0);
  const [bolFilterOptions, setBolFilterOptions] = useState({});
  const [bolSearchText, setBolSearchText] = useState('');
  const loadingBols = useSelector(state => get(state, 'loading.FETCH_RECONCILIATION_GET_BOL_NUMBER', true));
  const [bolFiltersRetrieved, setBolFiltersRetrieved] = useState(false);
  const [bolFilterData, setBolFilterData] = useState({});
  const [bolRetrieved, setBolRetrieved] = useState(false);

  const qtyData = useSelector(state => {
    if (!get(state, 'entities.reconBols.data', {})[0]) {
      return [];
    }
    return map(get(state, 'entities.reconBols.data', {})[0].list, qty => ({
      total_queried_count: Number(qty.total_count),
      bol: qty.bol ? qty.bol : '-',
      dis_ship_date: qty.dis_ship_date ? formatDate(qty.dis_ship_date) : '-',
      item_number: qty.item_number ? formatListOfNumbersNumDecimalCheck(qty.item_number, 0, '', true) : '-',
      tpl_carrier_name: qty.tpl_carrier_name ? qty.tpl_carrier_name : '-',
      dis_ship_quantity: qty.dis_ship_quantity ? formatQuantities(qty.dis_ship_quantity) : '-',
      biolife_out_quantity: qty.biolife_out_quantity ? formatQuantities(qty.biolife_out_quantity) : '-',
      tpl_in_quantity: qty.tpl_in_quantity ? formatQuantities(qty.tpl_in_quantity) : '-',
      tpl_out_quantity: qty.tpl_out_quantity ? formatQuantities(qty.tpl_out_quantity) : '-',
      manufacturing_in_quantity: qty.manufacturing_in_quantity
        ? formatQuantities(qty.manufacturing_in_quantity)
        : '-',
      reconciliation_status:
        !qty.reconciliation_status && qty.reconciliation_status !== 0
          ? 'N/A'
          : formatQuantities(qty.reconciliation_status),
      biolife_out_date: qty.biolife_out_date ? formatDate(qty.biolife_out_date) : '-',
      tpl_in_date: qty.tpl_in_date ? formatDate(qty.tpl_in_date) : '-',
      tpl_out_date: qty.tpl_out_date ? formatDate(qty.tpl_out_date) : '-',
      manufacturing_in_date: qty.manufacturing_in_date ? formatDate(qty.manufacturing_in_date) : '-',
      tpl_center_code: qty.tpl_center_code ? qty.tpl_center_code : '-',
      manufacturing_warehouse: qty.manufacturing_warehouse ? qty.manufacturing_warehouse : '-',
      journey_status: qty.journey_status ? qty.journey_status : '-',
    }));
  });

  const qtyFilters = useSelector(state => {
    if (qtyFiltersRetrieved) {
      return qtyFilterData;
    }
    if (!get(state, 'entities.reconBols.data', {})[1]) {
      return [];
    }
    const allFilters = get(state, 'entities.reconBols.data', {})[1].filters;
    if (allFilters !== undefined) {
      const filtersObject = {
        min_status: findFilter(allFilters, 'reconciliation_status', 'min'),
        max_status: findFilter(allFilters, 'reconciliation_status', 'max'),
        mfg_centers: findFilter(allFilters, 'manufacturing_warehouse'),
        tpl_center_codes: findFilter(allFilters, 'tpl_center_code'),
        min_dis_ship_date: formatDate(findFilter(allFilters, 'dis_ship_date', 'min')),
        max_dis_ship_date: formatDate(findFilter(allFilters, 'dis_ship_date', 'max')),
        min_tpl_in_date: formatDate(findFilter(allFilters, 'tpl_in_date', 'min')),
        max_tpl_in_date: formatDate(findFilter(allFilters, 'tpl_in_date', 'max')),
        min_tpl_out_date: formatDate(findFilter(allFilters, 'tpl_out_date', 'min')),
        max_tpl_out_date: formatDate(findFilter(allFilters, 'tpl_out_date', 'max')),
        min_bio_out_date: formatDate(findFilter(allFilters, 'biolife_out_date', 'min')),
        max_bio_out_date: formatDate(findFilter(allFilters, 'biolife_out_date', 'max')),
        min_tpl_carrier: findFilter(allFilters, 'tpl_carrier_name', 'min'),
        max_tpl_carrier: findFilter(allFilters, 'tpl_carrier_name', 'max'),
        min_dis_ship_quantity: findFilter(allFilters, 'dis_ship_quantity', 'min'),
        max_dis_ship_quantity: findFilter(allFilters, 'dis_ship_quantity', 'max'),
        min_biolife_out_quantity: findFilter(allFilters, 'biolife_out_quantity', 'min'),
        max_biolife_out_quantity: findFilter(allFilters, 'biolife_out_quantity', 'max'),
        min_tpl_in_quantity: findFilter(allFilters, 'tpl_in_quantity', 'min'),
        max_tpl_in_quantity: findFilter(allFilters, 'tpl_in_quantity', 'max'),
        min_tpl_out_quantity: findFilter(allFilters, 'tpl_out_quantity', 'min'),
        max_tpl_out_quantity: findFilter(allFilters, 'tpl_out_quantity', 'max'),
        min_manufacturing_in_quantity: findFilter(allFilters, 'manufacturing_in_quantity', 'min'),
        max_manufacturing_in_quantity: findFilter(allFilters, 'manufacturing_in_quantity', 'max'),
        min_mfg_ov_date: formatDate(findFilter(allFilters, 'mfg_ov_date', 'min')),
        max_mfg_ov_date: formatDate(findFilter(allFilters, 'mfg_ov_date', 'max')),
        journey_status: findFilter(allFilters, 'ri_ov_journey_status'),
      };
      setQtyFilterData(filtersObject);
      setQtyFiltersRetrieved(true);
      return filtersObject;
    }
    return [];
  });

  const filterSortDates = (text, minMax, alignment) => ({
    sort: true,
    sortCompare: order => sortDate(order),
    filter: true,
    customBodyRender: alignment,
    filterType: 'custom',
    customFilterListOptions: {
      render: v => customFilterListOptionsRender(v, text),
      update: (filterList, filterPos, index) => customFilterListOptionsUpdate(filterList, filterPos, index),
    },
    setCellHeaderProps: () => ({
      className: classes.rightAlignedHeader,
    }),
    filterOptions: {
      names: [],
      logic(val, filters) {
        return filters.length ? filterDateOptionsLogic(val, filters) : false;
      },
      display: (filterList, onChange, index, column) => (
        <CustomRangeFilter
          filterList={filterList}
          onChange={onChange}
          index={index}
          column={column}
          label={text}
          type="date"
          list={minMax}
        />
      ),
    },
  });

  const qtyColumns = [
    {
      name: 'bol',
      label: 'ODS - BOL Number',
      options: {
        sort: true,
        sortCompare: order => sortNum(order),
        viewColumns: false,
        filter: false,
        setCellHeaderProps: () => ({
          className: classes.leftAlignedNoMarginHeader,
        }),
        customBodyRender: customLeftAlignWhite,
      },
    },
    {
      name: 'dis_ship_date',
      label: 'ODS - BioLife Ship-Out Date (MM/DD/YYYY)',
      options: filterSortDates(
        'ODS Ship Date',
        {
          min_date: qtyFilters.min_dis_ship_date ? qtyFilters.min_dis_ship_date : '',
          max_date: qtyFilters.max_dis_ship_date ? qtyFilters.max_dis_ship_date : '',
        },
        customRightAlignWhite
      ),
    },
    {
      name: 'item_number',
      label: 'ODS/JDE - Item Numbers',
      options: {
        setCellHeaderProps: () => ({
          className: classes.leftAlignedHeader,
        }),
        customBodyRender: customLeftAlignWhite,
        filter: false,
        sort: true,
      },
    },
    quantityRelatedColumn(
      'dis_ship_quantity',
      'ODS - BioLife Ship-Out Quantity (L)',
      customRightAlignLightGray,
      { className: classes.rightAlignedHeader },
      {
        logic: (disShipQuantity, filters) => returnRowIsBlank(disShipQuantity, filters),
        names: [qtyFilters.min_dis_ship_quantity, qtyFilters.max_dis_ship_quantity],
      }
    ),
    quantityRelatedColumn(
      'biolife_out_quantity',
      'JDE - BioLife Ship-Out Quantity (L)',
      customRightAlignLightGray,
      {
        className: classes.rightAlignedHeader,
      },
      {
        logic: (biolifeOutQuantity, filters) => returnRowIsBlank(biolifeOutQuantity, filters),
        names: [qtyFilters.min_biolife_out_quantity, qtyFilters.max_biolife_out_quantity],
      }
    ),
    quantityRelatedColumn(
      'tpl_in_quantity',
      'JDE - 3PL Ship-In Quantity (L)',
      customRightAlignLightGray,
      {
        className: classes.rightAlignedHeader,
      },
      {
        logic: (tplInQuantity, filters) => returnRowIsBlank(tplInQuantity, filters),
        names: [qtyFilters.min_tpl_in_quantity, qtyFilters.max_tpl_in_quantity],
      }
    ),
    quantityRelatedColumn(
      'tpl_out_quantity',
      'JDE - 3PL Ship-Out Quantity (L)',
      customRightAlignLightGray,
      {
        className: classes.rightAlignedHeader,
      },
      {
        logic: (tplOutQuantity, filters) => returnRowIsBlank(tplOutQuantity, filters),
        names: [qtyFilters.min_tpl_out_quantity, qtyFilters.max_tpl_out_quantity],
      }
    ),
    quantityRelatedColumn(
      'manufacturing_in_quantity',
      'JDE - MFG Warehouse Ship-In Quantity (L)',
      customRightAlignLightGray,
      {
        className: classes.rightAlignedHeader,
      },
      {
        logic: (manufacturingInQuantity, filters) => returnRowIsBlank(manufacturingInQuantity, filters),
        names: [qtyFilters.min_manufacturing_in_quantity, qtyFilters.max_manufacturing_in_quantity],
      }
    ),
    {
      name: 'journey_status',
      label: 'RI -> OV Journey Status',
      options: {
        setCellHeaderProps: () => ({
          className: classes.leftAlignHeader,
        }),
        customBodyRender: customRightAlignDarkGray,
        filter: true,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Journey Status: ${v}`,
        },
        filterOptions: {
          logic: (status, filters) => returnRowContainsStatus(status, filters),
          names: qtyFilters.journey_status,
        },
        sort: true,
      },
    },
    {
      name: 'reconciliation_status',
      label: 'Reconciliation Status (L)',
      options: {
        filter: true,
        filterType: 'dropdown',
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
        customBodyRender: customRightAlignDarkGray,
        customFilterListOptions: {
          render: v => `Status: ${v}`, // .replace(/[0-9]|\./g, '')}`,
        },
        filterOptions: {
          logic: (status, filters) => returnRowIsBlank(status, filters),
          names: [qtyFilters.min_status, qtyFilters.max_status],
          // display: (filterList, onChange, index, column) => (
          //   <CustomDropdownFilter
          //     filterList={filterList}
          //     onChange={onChange}
          //     index={index}
          //     column={column}
          //     label="Reconciliation Status"
          //     list={['All', qtyFilters.min_status, qtyFilters.max_status]}
          //   />
          // ),
        },
        sort: true,
        sortCompare: sortQuantities,
      },
    },
    {
      name: 'tpl_carrier_name',
      label: '3PL Carrier Name',
      options: {
        customBodyRender: customLeftAlignDarkGray,
        setCellHeaderProps: () => ({
          className: classes.leftAlignedHeader,
        }),
        filter: true,
        filterType: 'dropdown',
        customFilterListOptions: {
          render: v => `Carrier: ${v}`, // .replace(/[0-9]|\./g, '')}`,
        },
        filterOptions: {
          logic: (carriers, filters) => returnRowContainsStatus(carriers, filters),
          names: [qtyFilters.min_tpl_carrier, qtyFilters.max_tpl_carrier],
          // display: (filterList, onChange, index, column) => (
          //   <CustomDropdownFilter
          //     filterList={filterList}
          //     onChange={onChange}
          //     index={index}
          //     column={column}
          //     label="3PL Carrier Name"
          //     list={['All', qtyFilters.min_tpl_carrier, qtyFilters.max_tpl_carrier]}
          //   />
          // ),
        },
        sort: true,
      },
    },
    {
      name: 'tpl_center_code',
      label: 'JDE Donation Center Code',
      options: {
        filter: true,
        filterType: 'multiselect',
        setCellHeaderProps: () => ({
          className: classes.leftAlignedHeader,
        }),
        customBodyRender: customLeftAlignDarkGray,
        customFilterListOptions: {
          render: v => `Center Code: ${v.replace(/[0-9]|\./g, '')}`,
        },
        filterOptions: {
          logic: (centers, filters) => returnRowContainsStatus(centers, filters),
          names: qtyFilters.tpl_center_codes,
        },
        sort: true,
      },
    },
    {
      name: 'manufacturing_warehouse',
      label: 'Consignee',
      options: {
        filter: true,
        customBodyRender: customLeftAlignDarkGray,
        setCellHeaderProps: () => ({
          className: classes.leftAlignedHeader,
        }),
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Receiving MFG Warehouse: ${v.replace(/[0-9]|\./g, '')}`,
        },
        filterOptions: {
          logic: (centers, filters) => returnRowContainsStatus(centers, filters),
          names: qtyFilters.mfg_centers,
        },
        sort: true,
      },
    },
    {
      name: 'biolife_out_date',
      label: 'JDE - BioLife Ship-Out Date (MM/DD/YYYY)',
      options: filterSortDates(
        'BioLife Out Date',
        {
          min_date: qtyFilters.min_bio_out_date ? qtyFilters.min_bio_out_date : '',
          max_date: qtyFilters.max_bio_out_date ? qtyFilters.max_bio_out_date : '',
        },
        customRightAlignLightGray
      ),
    },
    {
      name: 'tpl_in_date',
      label: 'JDE - 3PL Ship-In Date (MM/DD/YYYY)',
      options: filterSortDates(
        '3PL Out Date',
        {
          min_date: qtyFilters.min_tpl_in_date ? qtyFilters.min_tpl_in_date : '',
          max_date: qtyFilters.max_tpl_in_date ? qtyFilters.max_tpl_in_date : '',
        },
        customRightAlignLightGray
      ),
    },
    {
      name: 'tpl_out_date',
      label: 'JDE - 3PL Ship-Out Date (MM/DD/YYYY)',
      options: filterSortDates(
        '3PL Out Date',
        {
          min_date: qtyFilters.min_tpl_out_date ? qtyFilters.min_tpl_out_date : '',
          max_date: qtyFilters.max_tpl_out_date ? qtyFilters.max_tpl_out_date : '',
        },
        customRightAlignLightGray
      ),
    },
    {
      name: 'manufacturing_in_date',
      label: 'JDE - MFG Warehouse Ship-In Date (MM/DD/YYYY)',
      options: filterSortDates(
        'Warehouse Ship-In Date',
        {
          min_date: qtyFilters.min_mfg_ov_date ? qtyFilters.min_mfg_ov_date : '',
          max_date: qtyFilters.max_mfg_ov_date ? qtyFilters.max_mfg_ov_date : '',
        },
        customRightAlignLightGray
      ),
    },
  ];

  // INITIAL USE EFFECT FOR BOTH QUERIES
  useEffect(() => {
    if (!qtyRetrieved) {
      dispatch(
        fetchShipmentReconciliation(
          300,
          timeframe,
          qtySortOptions.name,
          qtySortOptions.direction,
          qtyFiltersRetrieved,
          checkAndFormatIfDate(qtySearchText)
        )
      );
    }
    setQtyRetrieved(true);
    if (!bolRetrieved) {
      dispatch(
        fetchReconciliationGetBolNumber(
          300,
          timeframe,
          bolSortOptions.name,
          bolSortOptions.direction,
          bolFiltersRetrieved,
          checkAndFormatIfDate(bolSearchText)
        )
      );
    }
    setBolRetrieved(true);
  }, [dispatch]);

  const qtyLimit = (qtyPage + 3) * rowsPerQtyPage;
  const qtyDateColumns = [
    'manufacturing_in_date',
    'tpl_in_date',
    'tpl_out_date',
    'biolife_out_date',
    'dis_ship_date',
  ];
  const qtyFilterQuery = getFilterArray(qtyColumns, qtyDateColumns, qtyFilterOptions);

  // QUANTITIES CHANGES: SORT, FILTERS AND SEARCH
  useEffect(() => {
    if (qtyRetrieved) {
      if (selectedTab === 'transqty') {
        dispatch(
          fetchShipmentReconciliation(
            qtyLimit,
            timeframe,
            qtySortOptions.name,
            qtySortOptions.direction,
            qtyFiltersRetrieved,
            checkAndFormatIfDate(qtySearchText === null ? '' : qtySearchText),
            qtyFilterQuery.join('')
          )
        );
        setQtyPage(0);
      }
    }
  }, [timeframe, qtySortOptions, qtyFilterOptions, qtySearchText, dispatch]);

  // QUANTITIES CHANGES: ROWS PER PAGE
  useEffect(() => {
    if (qtyRetrieved) {
      setQtyPage(0);
    }
  }, [rowsPerQtyPage]);

  // QUANTITY CHANGES: PAGE CHANGE
  const previousQtyPage = useRef(qtyPage);
  useEffect(() => {
    if (qtyRetrieved && selectedTab === 'transqty') {
      if (previousQtyPage.current !== qtyPage) {
        if (qtyPage > previousQtyPage.current) {
          previousQtyPage.current = qtyPage;
          dispatch(
            fetchShipmentReconciliation(
              qtyLimit,
              timeframe,
              qtySortOptions.name,
              qtySortOptions.direction,
              qtyFiltersRetrieved,
              checkAndFormatIfDate(qtySearchText === null ? '' : qtySearchText),
              qtyFilterQuery.join('')
            )
          );
        } else if (qtyPage < previousQtyPage.current) {
          previousQtyPage.current = qtyPage;
        }
      }
    }
  }, [qtyPage]);

  // BOL NUMBERS SECTION

  const bolData = useSelector(state => {
    if (!get(state, 'entities.reconBolNumbers.data', {})[0]) {
      return [];
    }
    return map(get(state, 'entities.reconBolNumbers.data', {})[0].list, bols => ({
      total_queried_count: Number(bols.total_count),
      reconciliation_status: bols.reconciliation_status ? bols.reconciliation_status : ' - ',
      dis_bol: bols.dis_bol ? bols.dis_bol : ' - ',
      dis_item: bols.dis_item ? bols.dis_item : ' - ',
      dis_center: bols.dis_center ? bols.dis_center : ' - ',
      dis_ship_date: bols.dis_ship_date ? formatDate(bols.dis_ship_date) : ' - ',
      mfg_ov_bol: bols.mfg_ov_bol ? bols.mfg_ov_bol : ' - ',
      mfg_ov_item: bols.mfg_ov_item ? bols.mfg_ov_item : ' - ',
      tpl_bol: bols.tpl_bol ? bols.tpl_bol : ' - ',
      tpl_item: bols.tpl_item ? bols.tpl_item : ' - ',
      tpl_center: bols.tpl_center ? bols.tpl_center : ' - ',
      tpl_carrier: bols.tpl_carrier ? bols.tpl_carrier : ' - ',
      number_missmatch_bol: bols.number_missmatch_bol ? bols.number_missmatch_bol : ' - ',
    }));
  });

  const reconBolsFilters = useSelector(state => {
    if (bolFiltersRetrieved) {
      return bolFilterData;
    }
    if (!get(state, 'entities.reconBolNumbers.data', {})[1]) {
      return [];
    }
    const allFilters = get(state, 'entities.reconBolNumbers.data', {})[1].filters;
    if (allFilters !== undefined) {
      const filtersObject = {
        min_jde_bol: findFilter(allFilters, 'jde_bol', 'min'),
        max_jde_bol: findFilter(allFilters, 'jde_bol', 'max'),
        tpl_centers: findFilter(allFilters, 'tpl_centers'),
        min_tpl_bol: findFilter(allFilters, 'tpl_bol', 'min'),
        max_tpl_bol: findFilter(allFilters, 'tpl_bol', 'max'),
        min_status: findFilter(allFilters, 'reconciliation_status', 'min'),
        max_status: findFilter(allFilters, 'reconciliation_status', 'max'),
        min_ods_bol: findFilter(allFilters, 'ods_bol', 'min'),
        max_ods_bol: findFilter(allFilters, 'ods_bol', 'max'),
        min_tpl_carrier: findFilter(allFilters, 'tpl_carrier', 'min'),
        max_tpl_carrier: findFilter(allFilters, 'tpl_carrier', 'max'),
        min_dis_ship_date: formatDate(findFilter(allFilters, 'dis_ship_date', 'min')),
        max_dis_ship_date: formatDate(findFilter(allFilters, 'dis_ship_date', 'max')),
      };
      setBolFilterData(filtersObject);
      setBolFiltersRetrieved(true);
      return filtersObject;
    }
    return [];
  });

  const bolColumns = [
    reconBOLRelatedColumn(
      'reconciliation_status',
      'Reconciliation Status (Y/N)',
      customAlignLeft,
      {
        className: classes.leftAlignedNoMarginHeader,
      },
      {
        logic: (status, filters) => returnRowContainsStatus(status, filters),
        names: [reconBolsFilters.min_status, reconBolsFilters.max_status],
      }
    ),
    reconBOLRelatedColumn(
      'dis_bol',
      'ODS BOL #',
      customLightGray,
      {
        className: classes.leftAlignedHeader,
      },
      {
        logic: (odsBol, filters) => returnRowIsBlank(odsBol, filters),
        names: [reconBolsFilters.min_ods_bol, reconBolsFilters.max_ods_bol],
      }
    ),
    {
      name: 'dis_ship_date',
      label: 'ODS - BioLife Ship-Out Date',
      options: filterSortDates(
        'ODS - BioLife Ship-Out Date',
        {
          min_date: reconBolsFilters.min_dis_ship_date ? reconBolsFilters.min_dis_ship_date : '',
          max_date: reconBolsFilters.max_dis_ship_date ? reconBolsFilters.max_dis_ship_date : '',
        },
        customRightAlignLightGray
      ),
    },
    reconBOLRelatedColumn('dis_item', 'ODS Item #', customLightGray, {
      className: classes.leftAlignedHeader,
    }),
    reconBOLRelatedColumn('dis_center', 'ODS Donation Center Code', customLightGray, {
      className: classes.leftAlignedHeader,
    }),
    reconBOLRelatedColumn(
      'mfg_ov_bol',
      'JDE BOL #',
      customDarkGray,
      {
        className: classes.leftAlignedHeader,
      },
      {
        logic: (jdeBol, filters) => returnRowIsBlank(jdeBol, filters),
        names: [reconBolsFilters.min_jde_bol, reconBolsFilters.max_jde_bol],
      }
    ),
    reconBOLRelatedColumn('mfg_ov_item', 'JDE Item #', customDarkGray, {
      className: classes.leftAlignedHeader,
    }),
    reconBOLRelatedColumn(
      'tpl_center',
      'JDE Donation Center Code',
      customDarkGray,
      {
        className: classes.leftAlignedHeader,
      },
      {
        logic: (center, filters) => returnRowContainsStatus(center, filters),
        names: reconBolsFilters.tpl_centers,
      }
    ),
    reconBOLRelatedColumn(
      'tpl_bol',
      '3PL BOL #',
      customLightGray,
      {
        className: classes.leftAlignedHeader,
      },
      {
        logic: (tplBol, filters) => returnRowIsBlank(tplBol, filters),
        names: [reconBolsFilters.min_tpl_bol, reconBolsFilters.max_tpl_bol],
      }
    ),
    reconBOLRelatedColumn('tpl_item', '3PL Item #', customLightGray, {
      className: classes.leftAlignedHeader,
    }),
    reconBOLRelatedColumn(
      'tpl_carrier',
      '3PL Carrier Name',
      customLightGray,
      {
        className: classes.leftAlignedHeader,
      },
      {
        logic: (status, filters) => returnRowContainsStatus(status, filters),
        names: [reconBolsFilters.min_tpl_carrier, reconBolsFilters.max_tpl_carrier],
      }
    ),
  ];

  const bolLimit = (bolPage + 3) * rowsPerBolPage;
  const bolFilterQuery = getFilterArray(bolColumns, [], bolFilterOptions);

  // BOL CHANGES: SORT, FILTERS AND SEARCH
  useEffect(() => {
    if (bolRetrieved) {
      if (selectedTab === 'bol') {
        dispatch(
          fetchReconciliationGetBolNumber(
            bolLimit,
            timeframe,
            bolSortOptions.name,
            bolSortOptions.direction,
            bolFiltersRetrieved,
            checkAndFormatIfDate(bolSearchText === null ? '' : bolSearchText),
            bolFilterQuery.join('')
          )
        );
        setBolPage(0);
      }
    }
  }, [bolSortOptions, bolFilterOptions, bolSearchText, dispatch]);

  // BOL CHANGES: ROWS PER PAGE
  useEffect(() => {
    if (bolRetrieved) {
      setBolPage(0);
    }
  }, [rowsPerBolPage]);

  // BOL CHANGES: PAGE CHANGE
  const previousBolPage = useRef(bolPage);
  useEffect(() => {
    if (bolRetrieved && selectedTab === 'bol') {
      if (previousBolPage.current !== bolPage) {
        if (bolPage > previousBolPage.current) {
          previousBolPage.current = bolPage;
          dispatch(
            fetchReconciliationGetBolNumber(
              bolLimit,
              timeframe,
              bolSortOptions.name,
              bolSortOptions.direction,
              bolFiltersRetrieved,
              checkAndFormatIfDate(bolSearchText === null ? '' : bolSearchText),
              bolFilterQuery.join('')
            )
          );
        } else if (bolPage < previousBolPage.current) {
          previousBolPage.current = bolPage;
        }
      }
    }
  }, [bolPage]);

  const handleChange = e => {
    if (timeframe !== e.target.value) {
      setTimeframe(e.target.value);
    }
  };

  const handleTabChange = (e, value) => {
    setSelectedTab(value);
  };

  return (
    <Grid
      className={classes.manufacturing}
      container
      direction="row"
      spacing={3}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <ViewInDevBanner />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="overline" className={classes.overviewSubtitle}>
          RECONCILIATION
        </Typography>
        <Typography className={classes.title} variant="h4" noWrap>
          Reconciliation
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          spacing={3}
          justify="flex-end"
          alignItems="right"
          className={classes.transQtyTableContainer}
        >
          <Grid item xs={3} className={classes.timeframeDropdown}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label" className={classes.timeframeDropdownLabel}>
                Timeframe
              </InputLabel>
              <Select
                labelId="timeframe-filter"
                id="timeframe-filter"
                value={timeframe}
                label="DIS Ship Date"
                variant="outlined"
                onChange={handleChange}
              >
                <MenuItem value="all">All Time</MenuItem>
                <MenuItem value="30">Last 30 Days</MenuItem>
                <MenuItem value="90">Last 90 Days</MenuItem>
                <MenuItem value="365">Last 1 Year</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Tabs
          classes={{
            root: classes.navBar,
            flexContainer: classes.flexContainer,
            indicator: classes.sectionIndicator,
          }}
          value={selectedTab}
          onChange={handleTabChange}
        >
          <Tab className={classes.tabLink} value="bol" label="BOL Number" />
          <Tab className={classes.tabLink} value="transqty" label="Transactional Quantity" />
        </Tabs>
        <TabPanel value={selectedTab} index="bol">
          <ReconciliationTable
            columns={bolColumns}
            additionalClasses={classes.reconTable}
            data={bolData}
            title="BOL Number"
            sortOptions={bolSortOptions}
            setSortOptions={setBolSortOptions}
            isLoading={loadingBols}
            rowsPerPage={rowsPerBolPage}
            setRowsPerPage={setRowsPerBolPage}
            page={bolPage}
            setPage={setBolPage}
            setFilterOptions={setBolFilterOptions}
            tableBodyMaxHeight="600px"
            fixedHeader
            bolParmeters={{
              bolTimeFrame: timeframe,
              bolSortOptions,
              bolFiltersRetrieved,
              bolSearchText,
            }}
            bolFilterQuery={bolFilterQuery}
            reconciliationTab="BOL"
            options={{
              onSearchChange: text => setBolSearchText(text),
              customSearchRender: (_searchText, handleSearch, onHide) => (
                // handleSearch is mui datatables default function
                // we utilize it to handle searches that don't require the api
                // takes a parameter which is the search query
                <TableSearch
                  options={{ searchPlaceholder: bolSearchText }}
                  setCustomSearchText={setBolSearchText}
                  onSearch={handleSearch}
                  onHide={() => {
                    onHide();
                    setBolSearchText('');
                  }}
                />
              ),
            }}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index="transqty">
          <ReconciliationTable
            columns={qtyColumns}
            additionalClasses={classes.reconTable}
            data={qtyData}
            title="Transactional Quantity"
            sortOptions={qtySortOptions}
            setSortOptions={setQtySortOptions}
            isLoading={loadingQty}
            rowsPerPage={rowsPerQtyPage}
            setRowsPerPage={setRowsPerQtyPage}
            page={qtyPage}
            setPage={setQtyPage}
            setFilterOptions={setQtyFilterOptions}
            qtyParameters={{
              qtyTimeframe: timeframe,
              qtySortOptions,
              qtyFiltersRetrieved,
              qtySearchText,
            }}
            qtyFilterQuery={qtyFilterQuery}
            reconciliationTab="QTY"
            options={{
              onSearchChange: text => setQtySearchText(text),
              customSearchRender: (_searchText, handleSearch, onHide) => (
                // handleSearch is mui datatables default function
                // we utilize it to handle searches that don't require the api
                // takes a parameter which is the search query
                <TableSearch
                  options={{ searchPlaceholder: qtySearchText }}
                  setCustomSearchText={setQtySearchText}
                  onSearch={handleSearch}
                  onHide={() => {
                    onHide();
                    setQtySearchText('');
                  }}
                />
              ),
            }}
          />
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default Reconciliation;
