import { RSAA } from 'redux-api-middleware';

export const FETCH_COLLECTION_GET_LOCATION_DONATIONS_REQUEST =
  'FETCH_COLLECTION_GET_LOCATION_DONATIONS_REQUEST';
export const FETCH_COLLECTION_GET_LOCATION_DONATIONS_SUCCESS =
  'FETCH_COLLECTION_GET_LOCATION_DONATIONS_SUCCESS';
export const FETCH_COLLECTION_GET_LOCATION_DONATIONS_FAILURE =
  'FETCH_COLLECTION_GET_LOCATION_DONATIONS_FAILURE';

export const FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_REQUEST =
  'FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_REQUEST';
export const FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_SUCCESS =
  'FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_SUCCESS';
export const FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_FAILURE =
  'FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_FAILURE';

export const FETCH_MANUFACTURING_GET_LOCATION_RECEIVED_DONATIONS_REQUEST =
  'FETCH_MANUFACTURING_GET_LOCATION_RECEIVED_DONATIONS_REQUEST';
export const FETCH_MANUFACTURING_GET_LOCATION_RECEIVED_DONATIONS_SUCCESS =
  'FETCH_MANUFACTURING_GET_LOCATION_RECEIVED_DONATIONS_SUCCESS';
export const FETCH_MANUFACTURING_GET_LOCATION_RECEIVED_DONATIONS_FAILURE =
  'FETCH_MANUFACTURING_GET_LOCATION_RECEIVED_DONATIONS_FAILURE';

export const FETCH_MANUFACTURING_GET_LOCATION_SORTED_DONATIONS_REQUEST =
  'FETCH_MANUFACTURING_GET_LOCATION_SORTED_DONATIONS_REQUEST';
export const FETCH_MANUFACTURING_GET_LOCATION_SORTED_DONATIONS_SUCCESS =
  'FETCH_MANUFACTURING_GET_LOCATION_SORTED_DONATIONS_SUCCESS';
export const FETCH_MANUFACTURING_GET_LOCATION_SORTED_DONATIONS_FAILURE =
  'FETCH_MANUFACTURING_GET_LOCATION_SORTED_DONATIONS_FAILURE';

export const FETCH_MANUFACTURING_GET_LOCATION_RELEASED_DONATIONS_REQUEST =
  'FETCH_MANUFACTURING_GET_LOCATION_RELEASED_DONATIONS_REQUEST';
export const FETCH_MANUFACTURING_GET_LOCATION_RELEASED_DONATIONS_SUCCESS =
  'FETCH_MANUFACTURING_GET_LOCATION_RELEASED_DONATIONS_SUCCESS';
export const FETCH_MANUFACTURING_GET_LOCATION_RELEASED_DONATIONS_FAILURE =
  'FETCH_MANUFACTURING_GET_LOCATION_RELEASED_DONATIONS_FAILURE';

export const FETCH_MANUFACTURING_GET_LOCATION_TEARDOWN_DONATIONS_REQUEST =
  'FETCH_MANUFACTURING_GET_LOCATION_TEARDOWN_DONATIONS_REQUEST';
export const FETCH_MANUFACTURING_GET_LOCATION_TEARDOWN_DONATIONS_SUCCESS =
  'FETCH_MANUFACTURING_GET_LOCATION_TEARDOWN_DONATIONS_SUCCESS';
export const FETCH_MANUFACTURING_GET_LOCATION_TEARDOWN_DONATIONS_FAILURE =
  'FETCH_MANUFACTURING_GET_LOCATION_TEARDOWN_DONATIONS_FAILURE';

export const FETCH_COLLECTION_GET_LOCATION_DONATIONS_DATES_REQUEST =
  'FETCH_COLLECTION_GET_LOCATION_DONATIONS_DATES_REQUEST';
export const FETCH_COLLECTION_GET_LOCATION_DONATIONS_DATES_SUCCESS =
  'FETCH_COLLECTION_GET_LOCATION_DONATIONS_DATES_SUCCESS';
export const FETCH_COLLECTION_GET_LOCATION_DONATIONS_DATES_FAILURE =
  'FETCH_COLLECTION_GET_LOCATION_DONATIONS_DATES_FAILURE';

export const FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_DATES_REQUEST =
  'FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_DATES_REQUEST';
export const FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_DATES_SUCCESS =
  'FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_DATES_SUCCESS';
export const FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_DATES_FAILURE =
  'FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_DATES_FAILURE';

export const fetchCollectionGetLocationDonations = (
  idLocation,
  limit,
  offset,
  sortBy,
  orderBy,
  search,
  filterQuery = ''
) => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-collection-get-location-donations/${idLocation}?limit=${limit}&offset=${offset}&sort_by=${sortBy}&order_by=${orderBy}&search=${search}${filterQuery}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COLLECTION_GET_LOCATION_DONATIONS_REQUEST,
      {
        type: FETCH_COLLECTION_GET_LOCATION_DONATIONS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { donations: json } };
        },
      },
      {
        type: FETCH_COLLECTION_GET_LOCATION_DONATIONS_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchManufacturingGetLocationDonations = (
  plantName,
  limit,
  sortBy,
  orderBy,
  search,
  filterQuery = ''
) => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-manufacturing-get-location-donations/${plantName}?limit=${limit}&sort_by=${sortBy}&order_by=${orderBy}&search=${search}${filterQuery}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_REQUEST,
      {
        type: FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { donations: json } };
        },
      },
      {
        type: FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchManufacturingGetLocationReceivedDonations = (
  plantName,
  limit,
  sortBy,
  orderBy,
  search,
  filterQuery = ''
) => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-manufacturing-get-location-received-donations/${plantName}?limit=${limit}&sort_by=${sortBy}&order_by=${orderBy}&search=${search}${filterQuery}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_MANUFACTURING_GET_LOCATION_RECEIVED_DONATIONS_REQUEST,
      {
        type: FETCH_MANUFACTURING_GET_LOCATION_RECEIVED_DONATIONS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { received: json } };
        },
      },
      {
        type: FETCH_MANUFACTURING_GET_LOCATION_RECEIVED_DONATIONS_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchManufacturingGetLocationSortedDonations = (
  plantName,
  limit,
  sortBy,
  orderBy,
  search,
  filterQuery = ''
) => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-manufacturing-get-location-sorted-donations/${plantName}?limit=${limit}&sort_by=${sortBy}&order_by=${orderBy}&search=${search}${filterQuery}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_MANUFACTURING_GET_LOCATION_SORTED_DONATIONS_REQUEST,
      {
        type: FETCH_MANUFACTURING_GET_LOCATION_SORTED_DONATIONS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { sorted: json } };
        },
      },
      {
        type: FETCH_MANUFACTURING_GET_LOCATION_SORTED_DONATIONS_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchManufacturingGetLocationReleasedDonations = (
  plantName,
  limit,
  sortBy,
  orderBy,
  search,
  filterQuery = ''
) => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-manufacturing-get-location-released-donations/${plantName}?limit=${limit}&sort_by=${sortBy}&order_by=${orderBy}&search=${search}${filterQuery}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_MANUFACTURING_GET_LOCATION_RELEASED_DONATIONS_REQUEST,
      {
        type: FETCH_MANUFACTURING_GET_LOCATION_RELEASED_DONATIONS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { released: json } };
        },
      },
      {
        type: FETCH_MANUFACTURING_GET_LOCATION_TEARDOWN_DONATIONS_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchManufacturingGetLocationTeardownDonations = plantName => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-manufacturing-get-location-teardown-donations/${plantName}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_MANUFACTURING_GET_LOCATION_TEARDOWN_DONATIONS_REQUEST,
      {
        type: FETCH_MANUFACTURING_GET_LOCATION_TEARDOWN_DONATIONS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { teardown: json } };
        },
      },
      {
        type: FETCH_MANUFACTURING_GET_LOCATION_TEARDOWN_DONATIONS_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchCollectionGetLocationDonationsDates = id => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-collection-get-location-donations-dates/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COLLECTION_GET_LOCATION_DONATIONS_DATES_REQUEST,
      {
        type: FETCH_COLLECTION_GET_LOCATION_DONATIONS_DATES_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { dates: json } };
        },
      },
      {
        type: FETCH_COLLECTION_GET_LOCATION_DONATIONS_DATES_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchManufacturingGetLocationDonationsDates = id => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-manufacturing-get-location-donations-dates/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_DATES_REQUEST,
      {
        type: FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_DATES_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { dates: json } };
        },
      },
      {
        type: FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_DATES_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const CollectionGetLocationDonationsMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COLLECTION_GET_LOCATION_DONATIONS_SUCCESS:
      console.log('fetch all donations by location success');
      break;
    case FETCH_COLLECTION_GET_LOCATION_DONATIONS_FAILURE:
      console.log('fetch all donations by location failure');
      break;
    default:
      break;
  }
};

export const ManufacturingGetLocationDonationsMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_SUCCESS:
      console.log('fetch all manufacturing donations by location success');
      break;
    case FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_FAILURE:
      console.log('fetch all manufacturing donations by location failure');
      break;
    default:
      break;
  }
};

export const ManufacturingGetLocationSortedDonationsMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_MANUFACTURING_GET_LOCATION_SORTED_DONATIONS_SUCCESS:
      console.log('fetch sorted manufacturing donations by location success');
      break;
    case FETCH_MANUFACTURING_GET_LOCATION_SORTED_DONATIONS_FAILURE:
      console.log('fetch sorted manufacturing donations by location failure');
      break;
    default:
      break;
  }
};

export const ManufacturingGetLocationReleasedDonationsMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_MANUFACTURING_GET_LOCATION_RELEASED_DONATIONS_SUCCESS:
      console.log('fetch released manufacturing donations by location success');
      break;
    case FETCH_MANUFACTURING_GET_LOCATION_RELEASED_DONATIONS_FAILURE:
      console.log('fetch released manufacturing donations by location failure');
      break;
    default:
      break;
  }
};

export const ManufacturingGetLocationReceivedDonationsMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_MANUFACTURING_GET_LOCATION_RECEIVED_DONATIONS_SUCCESS:
      console.log('fetch received manufacturing donations by location success');
      break;
    case FETCH_MANUFACTURING_GET_LOCATION_RECEIVED_DONATIONS_FAILURE:
      console.log('fetch received manufacturing donations by location failure');
      break;
    default:
      break;
  }
};

export const ManufacturingGetLocationTeardownDonationsMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_MANUFACTURING_GET_LOCATION_TEARDOWN_DONATIONS_SUCCESS:
      console.log('fetch all manufacturing teardown donations by location success');
      break;
    case FETCH_MANUFACTURING_GET_LOCATION_TEARDOWN_DONATIONS_FAILURE:
      console.log('fetch all manufacturing teardown donations by location failure');
      break;
    default:
      break;
  }
};

export const CollectionGetLocationDonationsDatesMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COLLECTION_GET_LOCATION_DONATIONS_DATES_SUCCESS:
      console.log('fetch all donations dates by location success');
      break;
    case FETCH_COLLECTION_GET_LOCATION_DONATIONS_DATES_FAILURE:
      console.log('fetch all donations dates by location failure');
      break;
    default:
      break;
  }
};

export const ManufacturingGetLocationDonationsDatesMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_DATES_SUCCESS:
      console.log('fetch all manufacturing donations dates by location success');
      break;
    case FETCH_MANUFACTURING_GET_LOCATION_DONATIONS_DATES_FAILURE:
      console.log('fetch all manufacturing donations dates by location failure');
      break;
    default:
      break;
  }
};
