import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import get from 'lodash/get';
import map from 'lodash/map';
import Loader from '../Loader/Loader';
import DataFieldHint from '../DataFieldHint/DataFieldHint';
import {
  fetchCollectionGetLocationProcessesOverview,
  fetchCollectionGetLocationQuarantineOverview,
} from '../../redux/overview';
import { numDecimalsCheck, getCountConversion, withCommas } from '../../utils';
import useStyles from './LocationOverview.styles';
import tooltipHints from '../../tooltipHints';

const { center: centerHints } = tooltipHints;

const {
  collected: collectedHint,
  boxed: boxedHint,
  boxed_with_bol: boxedWithBOLHint,
  quarantine: quarantineHint,
  lookback_submitted: lookbackSubmittedHint,
  lookback_confirmed: lookbackConfirmedHint,
  destroyed: destroyedHint,
  total_volume: totalVolumeHint,
} = centerHints;

const getPrimaryHint = (title, center) => {
  let hint = '';
  switch (title.toUpperCase()) {
    case 'TOTAL VOLUME (L)':
      hint = totalVolumeHint.replace('[center name]', center);
      break;
    case 'COLLECTED (L)':
      hint = collectedHint.replace('[center name]', center);
      break;
    case 'BOXED (L)':
      hint = boxedHint.replace('[center name]', center);
      break;
    case 'BOXED WITH BOL (L)':
      hint = boxedWithBOLHint.replace('[center name]', center);
      break;
    case 'QUARANTINE (L)':
      hint = quarantineHint.replace('[center name]', center);
      break;
    case 'LOOKBACK SUBMITTED (L)':
      hint = lookbackSubmittedHint.replace('[center name]', center);
      break;
    case 'LOOKBACK NOTIFICATION RECEIVED (L)':
      hint = lookbackConfirmedHint.replace('[center name]', center);
      break;
    case 'DESTROYED (L)':
      hint = destroyedHint.replace('[center name]', center);
      break;
    default:
      hint = 'field information';
  }

  return hint;
};

function LocationOverview({ id, center }) {
  const classes = useStyles();
  const loading = useSelector(state =>
    get(state, 'loading.FETCH_COLLECTION_GET_LOCATION_PROCESSES_OVERVIEW', true)
  );
  const quarantineLoading = useSelector(state =>
    get(state, 'loading.FETCH_COLLECTION_GET_LOCATION_QUARANTINE_OVERVIEW', true)
  );
  const [quarantineRetrieved, setQuarantineRetrieved] = useState(false);
  const dispatch = useDispatch();

  const emptyData = {
    'Plasma in Quarantine & Lookback': [
      {
        title: 'No data to show',
        detail: '0 L',
        detail_two: '0 g est. gamma',
        secondaryDetails: [
          {
            label: 'Donations',
            detail: '0',
          },
          {
            label: 'Est. gamma(g)',
            detail: '0',
          },
        ],
      },
    ],
  };

  const overviewData = useSelector(state =>
    map(get(state, 'entities.overviewProcesses.overview', {}), overview => ({
      'View by Processes': [
        {
          title: 'Total Volume (L)',
          detail: numDecimalsCheck(overview.volume, 3),
          detail_two: numDecimalsCheck(overview.total_igg, 2, 'g est. gamma'),
          secondaryDetails: [
            {
              label: 'Donations',
              detail: overview.total_donations,
            },
            {
              label: 'Est. gamma(g)',
              detail: overview.total_igg,
            },
          ],
        },
        { title: 'Divider' },
        {
          title: 'Collected (L)',
          detail: numDecimalsCheck(overview.collected, 3),
          secondaryDetails: [
            {
              label: 'Donations',
              detail: overview.collected_donations,
            },
            {
              label: 'Est. gamma(g)',
              detail: overview.collected_igg,
            },
          ],
        },
        {
          title: 'Boxed (L)',
          detail: numDecimalsCheck(overview.boxed, 3),
          secondaryDetails: [
            {
              label: 'Donations',
              detail: overview.boxed_donations,
            },
            {
              label: 'Boxes',
              detail: overview.boxes,
            },
            {
              label: 'Est. gamma(g)',
              detail: overview.boxed_igg,
            },
          ],
        },
        {
          title: 'Boxed with BOL (L)',
          detail: numDecimalsCheck(overview.bol, 3),
          secondaryDetails: [
            {
              label: 'Donations',
              detail: overview.bol_donations,
            },
            {
              label: 'BOLs',
              detail: overview.bols,
            },
            {
              label: 'Est. gamma(g)',
              detail: overview.bol_igg,
            },
          ],
        },
      ],
    }))
  );

  const overviewDetails = useSelector(state =>
    map(get(state, 'entities.overviewQuarantine.overview', {}), overview => ({
      'Plasma in Quarantine & Lookback': [
        {
          title: 'Quarantine (L)',
          detail: numDecimalsCheck(overview.quarantine, 3),
          secondaryDetails: [
            {
              label: 'Donations',
              detail: 'overview.quarantine_donations',
            },
            {
              label: 'Est. gamma(g)',
              detail: 'overview.quarantine_igg',
            },
          ],
          detail_two: numDecimalsCheck(overview.quarantine_igg, 2, 'g est. gamma'),
        },
        {
          title: 'Lookback Submitted (L)',
          detail: numDecimalsCheck(overview.lookback_submitted, 3),
          secondaryDetails: [
            {
              label: 'Donations',
              detail: overview.submitted_donations,
            },
            {
              label: 'Est. gamma(g)',
              detail: overview.lookback_submitted_igg,
            },
          ],
        },
        {
          title: 'Lookback Notification Received (L)',
          detail: numDecimalsCheck(overview.lookback_confirmed, 3),
          secondaryDetails: [
            {
              label: 'Donations',
              detail: overview.confirmed_donations,
            },
            {
              label: 'Est. gamma(g)',
              detail: overview.lookback_confirmed_igg,
            },
          ],
        },
        {
          title: 'Destroyed (L)',
          detail: numDecimalsCheck(overview.destroyed, 3),
          secondaryDetails: [
            {
              label: 'Donations',
              detail: overview.destroyed_donations,
            },
            {
              label: 'Est. gamma(g)',
              detail: overview.destroyed_igg,
            },
          ],
        },
      ],
    }))
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchCollectionGetLocationProcessesOverview(id));
    }
  }, [id, dispatch]);

  const overviews = { ...overviewData[0], ...emptyData };

  const overviewsComplete = { ...overviewData[0], ...overviewDetails[0] };

  const getQuarantineDetails = () => {
    if (!quarantineRetrieved) {
      setQuarantineRetrieved(true);
      dispatch(fetchCollectionGetLocationQuarantineOverview(id));
    }
  };

  const getWidth = label => {
    switch (label) {
      case 'Donations':
        return 75;
      case 'Est. gamma(g)':
        return 87;
      case 'Boxes':
        return 70;
      case 'BOLs':
        return 65;
      default:
        return 90;
    }
  };

  const getSecondaryDetails = items =>
    items.map(item => (
      <div style={{ flex: `0 0 ${getWidth(item.label)}px` }} className={classes.secondaryItem}>
        <Typography className={classes.secondaryItemLabel}>{item.label}</Typography>
        <Tooltip title={withCommas(item.detail)} className={classes.toolTip} placement="bottom" arrow>
          <Typography variant="h6" className={classes.secondaryItemDetail}>
            {getCountConversion(Number(item.detail))}
          </Typography>
        </Tooltip>
      </div>
    ));

  const getItemClassName = (item, showCounts, classList) => {
    if (showCounts) {
      switch (item.title) {
        case 'Total Volume (L)':
        case 'Collected (L)':
        case 'Quarantine (L)':
        case 'Lookback Notification Received (L)':
        case 'Destroyed (L)':
        case 'Lookback Submitted (L)':
          return classList.listSecondaryItem;
        case 'Boxed (L)':
        case 'Boxed with BOL (L)':
          return classList.listSecondaryItemLong;
        default:
          return classList.listItem;
      }
    }
    if (item.title === 'Gamma Protein Estimate (pre-donation)') {
      return classList.listItemLong;
    }
    return classList.listItem;
  };

  const getRowItems = items =>
    items.map(item => {
      if (item.title.toUpperCase() === 'DIVIDER') {
        return <Divider key="divider" orientation="vertical" flexItem />;
      }
      return (
        <ListItem className={getItemClassName(item, true, classes)} key={`row-item-${item.title}`}>
          <ListItemText
            disableTypography
            primary={
              <DataFieldHint title={getPrimaryHint(item.title.toUpperCase(), center)} center={center}>
                <Typography variant="overline" className={classes.listItemTitle}>
                  {item.title.toUpperCase()}
                </Typography>
              </DataFieldHint>
            }
            secondary={
              <>
                <Typography variant="h5" className={classes.listItemDetail}>
                  {item.detail}
                </Typography>
                <List className={classes.horizontalAccordionList}>
                  {getSecondaryDetails(item.secondaryDetails)}
                </List>
              </>
            }
          />
        </ListItem>
      );
    });

  const getRowDetails = key => {
    if (quarantineRetrieved) {
      return getRowItems(overviewsComplete[key]);
    }
    return getRowItems(overviews[key]);
  };

  const outputRows = () => {
    if (loading) {
      return (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      );
    }
    if (Object.keys(overviews).length > 1) {
      return Object.keys(overviews).map((key, index) => {
        if (index >= 1) {
          return (
            <AccordionDetails key={key}>
              <Grid item xs={12} className={classes.summaryDetailsOverview}>
                <Typography variant="overline" className={classes.overviewRowTitle}>
                  {key.toUpperCase()}
                </Typography>
                <List className={classes.horizontalAccordionList}>
                  {quarantineLoading ? (
                    <div className={classes.loaderContainer}>
                      <Loader />
                    </div>
                  ) : (
                    getRowDetails(key)
                  )}
                </List>
              </Grid>
            </AccordionDetails>
          );
        }
        return (
          <AccordionSummary
            key={key}
            className={classes.summary}
            expandIcon={<ExpandMoreIcon className={classes.icon} />}
          >
            <Grid item xs={12} className={classes.summaryAccOverview}>
              <Typography variant="overline" className={classes.overviewRowTitle}>
                {key.toUpperCase()}
              </Typography>
              <List className={classes.horizontalAccordionList}>{getRowItems(overviews[key], classes)}</List>
            </Grid>
          </AccordionSummary>
        );
      });
    }
    return Object.keys(overviews).map((key, index) => (
      <Grid item xs={12} key={`visible-rows-${index === 0 ? 'one' : 'two'}`}>
        <Typography variant="overline" className={classes.overviewRowTitle}>
          {key.toUpperCase()}
        </Typography>
        <List className={classes.horizontalList}>{getRowItems(overviews[key], classes)}</List>
      </Grid>
    ));
  };

  return (
    <div key="double-summary" className={classes.summaryOverviewContainer} data-testid="summary overview">
      <Accordion
        onChange={(e, expanded) => {
          if (expanded) {
            getQuarantineDetails();
          }
        }}
        className={classes.accordion}
      >
        {outputRows(overviews, classes, loading, quarantineLoading)}
      </Accordion>
    </div>
  );
}

LocationOverview.propTypes = {
  id: PropTypes.string.isRequired,
  center: PropTypes.string.isRequired,
};

export default LocationOverview;
