const cutoffDate = 'August 1, 2021';
const tooltipHints = {
  pbn: 'Plasma Bleed Number',
  pdn: 'Plasma Donor Number',
  collected:
    'Includes all units collected at a BioLife center that have not yet been boxed. Includes units that are both eligible and ineligible for boxing.',
  tested: 'Includes all units that are going through testing.',
  boxed:
    'Plasma boxed at a BioLife center that has not yet been assigned a BOL. Depending on whether or not the center is using same-day boxing, these units may or may not yet be tested.',
  boxed_with_bol: `Plasma boxed at a BioLife center and assigned a BOL. Currently includes both shipped and unshipped units (after ${cutoffDate}).`,
  shipped: 'Includes all units that have left the center, but have not yet been received by the warehouse.',
  received: 'Plasma received at a Takeda warehouse that has not yet been sorted',
  sorted:
    'Plasma sorted at a Takeda warehouse into a disposition lot that has not yet been released from the warehouse',
  released_at_mfg:
    'Plasma in a disposition lot at a Takeda warehouse that has been released from the warehouse, but has not yet started the teardown process',
  warehouse: {
    received: 'Plasma received at [warehouse name] Takeda warehouse that has not yet been sorted',
    sorted:
      'Plasma sorted at [warehouse name] Takeda warehouse into a disposition lot that has not yet been released from the warehouse',
    released_at_mfg:
      'Plasma in a disposition lot at [warehouse name] Takeda warehouse that has been released from the warehouse, but has not yet started the teardown process',
    total_donation_volume: 'All plasma currently at [warehouse name] Takeda warehouse',
    teardown_pools: 'Teardown pools created originating from the [warehouse name] Takeda warehouse',
  },
  volume: 'Volume of plasma donation',
  center: {
    collected:
      'Plasma collected at [center name] BioLife center that have not yet been boxed. Includes units that are both eligible and ineligible for boxing.',
    boxed:
      'Plasma boxed at [center name] BioLife center that has not yet been assigned a BOL. Depending on whether or not the center is using same-day boxing, these units may or may not yet be tested.',
    boxed_with_bol: `Plasma boxed at [center name] BioLife center and assigned a BOL. Currently includes both shipped and unshipped units (after ${cutoffDate}}).`,
    total_volume: `All plasma collected, boxed, and shipped from [center name] BioLife center (after ${cutoffDate})`,
    quarantine: `Donations at [center name] with bleed date occurring less than 60 days ago (after ${cutoffDate})`,
    lookback_confirmed: `Donations at [center name] with lookback notification receipt confirmation according to LNS/APDS (after ${cutoffDate})`,
    lookback_submitted: `Donations at [center name] for which a lookback has been submitted (after ${cutoffDate})`,
    destroyed: `Donations at [center name] that have been destroyed (after ${cutoffDate})`,
  },
  teardown: 'Includes all units currently going through the teardown process',
  fractionation: 'Includes all units currently going through the fractionation process',
  purification: 'purification',
  filling: 'Includes all units currently going through the filling process',
  inspection:
    'Includes all units currently going through, but that have not yet completed, the inspection process',
  final_product: 'Includes all units that have fully completed all steps of the manufacturing process`',
  plasma_by_phases: {
    biolife_donation_centers:
      'Includes all units currently sitting at BioLife donation centers. Includes all units with these statuses: collected, boxed, boxed with BOL (Currently includes both shipped and unshipped units; Updated September 2021). This total may or may not have completed “tested” status yet.',
    rx_crossroads:
      'Includes all units currently either in transit or sitting at an Rx Crossroads warehouse. Includes units with statuses: shipped, received, sorted, released.',
    manufacturing:
      'Includes all units currently at manufacturing plants, including these statuses: teardown, fractionation, purification, filling, inspection, final product.',
  },
  total_volume: `All plasma collected, boxed, and shipped from BioLife centers (after ${cutoffDate})`,
  total_plasma_volume: 'All plasma currently in the Takeda supply chain',
  lot_id: 'Unique identifier of manufacturing lot',
  description: 'Description of manufacturing lot',
  quantity: 'Quantity within manufacturing lot',
  location: 'Location of manufacturing lot',
  quarantine: `Donations with bleed date occurring less than 60 days ago (after ${cutoffDate})`,
  total_gamma_protein_estimate:
    'Total estimated gamma protein in the Takeda supply chain. Gamma level is estimated using a variety of data points including the donor’s number of previous donations, other donor attributes, the donation date, and donor location.',
  est_gamma:
    'This estimate was calculated using a formula developed by Takeda data scientists. Gamma level is estimated using a variety of data points including the donor’s number of previous donations, other donor attributes (e.g. total protein), the donation date, and donor location. Actual Gamma data and/or more precise may be available in PlasmaChain at a later date.',
  earliest_bleed_date: 'The donation date of the unit of plasma inside a given box that was collected first.',
  latest_bleed_date:
    'The donation date of the unit of plasma inside a given box that was collected most recently',
  transition_velocity:
    'Amount of time between current status and previous status (i.e. current status timestamp - previous status timestamp)',
  total_time_aging:
    'Amount of time the donation has had the first status (i.e. now - collected status timestamp). Can also be thought of as number of days lapsed from bleed date to current date.',
  token_id:
    'PlasmaChain runs on blockchain technology, which means that each unit of donated plasma is assigned a token when it enters the system. The token ID is the unique numerical label for each token. Most PlasmaChain end users will not need to reference this ID number.',
  lookback_submitted: `Donations for which a lookback has been submitted (after ${cutoffDate})`,
  lookback_confirmed: `Donations with lookback notification received according to LNS/APDS. (after ${cutoffDate})`,
  destroyed: `Donations that have been destroyed (after ${cutoffDate})`,
  gamma_protein_estimate_pre_donation:
    'Estimated gamma protein in plasma donation. Gamma level is estimated using a variety of data points including the donor’s number of previous donations, other donor attributes, the donation date, and donor location.',
  master_file_approved:
    'Plasma donation was collected at a BioLife center that has gone through all the necessary approvals in order for its collected plasma to be used in EU manufacturing plants.',
  flavor:
    'Plasma donation type. Please refer to the PlasmaChain Glossary for a full list and description of Plasma flavors.',
  aging: 'Age of plasma donation',
};

export default tooltipHints;
