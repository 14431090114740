import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import Table from '../../Table/Table';
import useStyles from './InputModalDetails.styles';
import Loader from '../../Loader/Loader';
import { numDecimalsCheck } from '../../../utils';
import {
  iggColumn,
  volumeColumn,
  agingColumn,
  statusColumn,
  tokenColumn,
  pbnColumn,
} from '../../Table/Columns';
import { TableLink } from '../../Table/TableLink';

function ModalDetails({ selectedId, donationsData, totalsData }) {
  const classes = useStyles();
  const loading = useSelector(state => get(state, 'loading.loading', false));

  const overviewData =
    totalsData !== undefined
      ? [
          {
            title: 'Donations',
            detail: totalsData.donations ? numDecimalsCheck(totalsData.donations, 2, 'units') : '-',
          },
          {
            title: 'Volume',
            detail: totalsData.volume ? numDecimalsCheck(totalsData.volume, 0, 'L') : '-',
          },
          {
            title: 'Gamma Protein Estimate (pre-donation)',
            detail: totalsData.gamma ? numDecimalsCheck(totalsData.gamma, 0, 'g') : '-',
          },
        ]
      : [
          {
            title: 'Donations',
            detail: '-',
          },
          {
            title: 'Volume',
            detail: '-',
          },
          {
            title: 'Gamma Protein Estimate (pre-donation)',
            detail: '-',
          },
        ];

  const getOverviewItems = () => {
    if (overviewData) {
      return overviewData.map(data => (
        <ListItem className={classes.listItem} key={`row-item-${data.title}`}>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="overline" className={classes.listItemTitle}>
                {data.title}
              </Typography>
            }
            secondary={
              <Typography variant="subtitle2" className={classes.listItemDetail}>
                {data.detail}
              </Typography>
            }
          />
        </ListItem>
      ));
    }
    return '';
  };

  const donationsColumns = [
    pbnColumn(value => TableLink(value, { pathname: `/` })),
    statusColumn(),
    tokenColumn({
      className: classes.leftAlignedHeader,
    }),
    volumeColumn({
      className: classes.rightAlignedHeader,
    }),
    iggColumn({
      className: classes.rightAlignedHeader,
    }),
    {
      name: 'masterfile',
      label: 'Master File Approved',
      options: {
        filter: true,
        sort: true,
      },
    },
    agingColumn({
      className: classes.rightAlignedHeader,
    }),
  ];

  // eslint-disable-next-line consistent-return
  const showTable = () => {
    if (loading) {
      return <Loader />;
    }
    if (donationsData && donationsData.length > 0) {
      return <Table columns={donationsColumns} data={donationsData} />;
    }
    return (
      <Typography variant="overline" className={classes.boxSubtitle}>
        No donations available
      </Typography>
    );
  };

  return (
    <Grid className={classes.boxDetailsContainer}>
      <Typography variant="overline" className={classes.boxSubtitle}>
        INPUT BIN
      </Typography>
      <Typography className={classes.boxTitle} variant="h4" noWrap>
        {selectedId}
      </Typography>
      <div className={classes.boxOverviewContainer}>
        <Grid className={classes.boxOverview}>
          <Grid item xs={12}>
            <List className={classes.horizontalList}>{getOverviewItems()}</List>
          </Grid>
        </Grid>
      </div>
      <Grid item xs={12} className={classes.modalDetailsTable}>
        {showTable()}
      </Grid>
    </Grid>
  );
}

ModalDetails.propTypes = {
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  overviewData: PropTypes.shape({}),
  donationsData: PropTypes.arrayOf.isRequired,
  totalsData: PropTypes.shape({ donations: 0, volume: 0, gamma: 0 }).isRequired,
};

ModalDetails.defaultProps = {
  overviewData: {},
};

export default ModalDetails;
