import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import './index.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import App from './App';
import theme from './theme';
import store, { history } from './redux/store';
import ErrorBoundary from './error';

const initialStore = {};

ReactDOM.render(
  <Provider store={store(initialStore)}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary>
          <App history={history} />
        </ErrorBoundary>
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
