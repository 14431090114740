import React from 'react';
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FiberManualRecordRoudedIcon from '@material-ui/icons/FiberManualRecordRounded';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import get from 'lodash/get';
import Loader from '../../Loader/Loader';
import { formatDate } from '../../../utils';
import useStyles from './EventLog.styles';

function EventLog({ logs, setSelectedEvent }) {
  const { donation } = useParams();
  const classes = useStyles();
  const loading = useSelector(state => get(state, 'loading.FETCH_COLLECTION_GET_DONATION_DETAILS', true));
  const location = useLocation();

  return (
    <div className={classes.root}>
      {loading || !logs[0] ? (
        <Loader />
      ) : (
        <>
          {logs.map(item => (
            <div className={classes.row} key={item.eventName}>
              {!item.date && (
                <div className={classes.rootLeft}>
                  <FiberManualRecordRoudedIcon className={classes.grayCircle} />
                  {item.eventName === 'Bol' ? 'Boxed with BOL' : item.eventName}
                </div>
              )}
              {item.date && (
                <Button
                  className={classes.buttonRow}
                  onClick={() => {
                    setSelectedEvent(item.eventName);
                  }}
                >
                  <div className={classes.rootLeft}>
                    <CheckCircleIcon className={classes.greenCheck} />
                    {item.eventName === 'Bol' ? 'Boxed with BOL' : item.eventName}
                  </div>
                  <div className={classes.rootRight}>
                    <Typography className={classes.date} variant="h4" component="h1" align="center">
                      {item.eventName === 'Tested' ? '-' : formatDate(item.date)}
                    </Typography>
                  </div>
                </Button>
              )}
            </div>
          ))}
          <div>
            <Link
              component={RouterLink}
              to={{
                pathname: `/manufacturing/teardown/${donation}`,
                state: { prevPath: location.pathname },
              }}
            >
              <Button
                className={classes.buttonMFG}
                disabled={!logs.some(item => item.eventName === 'Teardown')}
                variant="contained"
              >
                Trace PBN through MFG
              </Button>
            </Link>
            <div className={classes.buttonRow}>
              <InfoIcon color="action" fontSize="small" />
              <Typography className={classes.infoRow} variant="h3" component="h1" align="center">
                &apos;Trace PBN through MFG&apos; provides you with information from teardown pool to finished
                product.
              </Typography>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

EventLog.propTypes = {
  setSelectedEvent: PropTypes.func,
  logs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

EventLog.defaultProps = {
  setSelectedEvent: PropTypes.func,
};

export default EventLog;
