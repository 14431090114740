import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '45%',
    paddingRight: '20px',
    borderRight: 'solid',
    borderRightWidth: '1px',
    borderRightColor: '#e0e0e0',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderBottom: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: '#e0e0e0',
    height: '60px',
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'left',
    width: '100%',
    height: '60px',
    marginTop: '2%',
  },
  buttonMFG: {
    color: 'white',
    width: '40%',
    marginTop: '5%',
    background: 'black',
    '&:hover': {
      background: '#303030',
    },
  },
  infoRow: {
    fontSize: '14px',
    color: '#9a9a9a',
    paddingRight: '12px',
  },
  rootLeft: {
    display: 'flex',
    alignItems: 'center',
    width: '60%',
    marginBottom: theme.spacing(1.2),
    marginTop: theme.spacing(1.2),
    fontWeight: '700',
  },
  rootRight: {
    display: 'flex',
    alignItems: 'center',
    width: '40%',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    fontWeight: '700',
  },
  greenCheck: {
    color: '#558b2f',
    fontSize: '18px',
    marginRight: theme.spacing(1),
  },
  grayCircle: {
    color: '#e0e0e0',
    fontSize: '18px',
    marginRight: theme.spacing(1),
  },
  date: {
    fontSize: '16px',
    color: '#9e9e9e',
    paddingRight: '12px',
  },
}));

export default useStyles;
