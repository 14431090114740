import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useStyles from './LogNavigation.styles';
import EventLog from '../EventLog/EventLog';
import EventDetails from '../EventDetails/EventDetails';
import BlockchainDetails from '../BlockchainDetails/BlockchainDetails';
import TabPanel from '../../TabPanel/TabPanel';

function LogNavigation(events) {
  const classes = useStyles();
  const [value, setValue] = useState('event');
  const [selectedEvent, setSelectedEvent] = useState('Collected');
  const eventNames = ['Collected', 'Boxed', 'Bol', 'Shipped', 'Received', 'Sorted', 'Released', 'Teardown'];

  // eslint-disable-next-line react/destructuring-assignment
  const eventList = events && events.events && events.events[0] ? events.events[0] : {};

  const returnEvents = event => {
    if (events && eventList) return eventList[event.toLowerCase()];
    return null;
  };

  const getPreviousEventsFields = event => {
    const eventSummary = {};
    eventNames.some(eventName => {
      if (eventName === event || event === 'Tested') {
        Object.assign(eventSummary, returnEvents(event));
      } else {
        Object.assign(eventSummary, returnEvents(eventName));
      }
      return eventName === event;
    });

    return eventSummary;
  };

  const capitalize = s => s.replace(/(^\w|\s\w)/g, m => m.toUpperCase());

  const getEventLogs = () => {
    if (eventList) {
      const logs = [];
      const event = Object.keys(eventList);
      event.forEach(name => {
        logs.push({
          eventName: capitalize(name),
          date: eventList[name].status_timestamp,
        });
      });
      return logs;
    }
    return [];
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.bar} position="static">
        <Tabs className={classes.tabs} value={value} onChange={handleChange}>
          <Tab className={classes.tab} value="event" label="Event Log" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index="event">
        <div className={classes.containerEventLog}>
          <EventLog logs={getEventLogs()} setSelectedEvent={e => setSelectedEvent(e)} />
          <div className={classes.containerDetails}>
            <EventDetails event={selectedEvent} fields={getPreviousEventsFields(selectedEvent)} />
            <BlockchainDetails event={selectedEvent} fields={eventList[selectedEvent.toLowerCase()]} />
          </div>
        </div>
      </TabPanel>
    </div>
  );
}

LogNavigation.propTypes = {};

export default LogNavigation;
