import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: '700',
    paddingBottom: theme.spacing(3),
  },
  messageContainer: {
    backgroundColor: '#f1f1f1',
    width: '100vw',
    minWidth: '100vw',
    minHeight: '100px',
    paddingBottom: theme.spacing(5),
  },
  message: {
    maxWidth: '1280px',
    width: '500px',
    margin: `0 auto ${theme.spacing(1)}px`,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(5),
    position: 'relative',
  },
  messageTitle: {
    fontWeight: '700',
    paddingBottom: theme.spacing(3),
  },
  messageText: {
    fontSize: '16px',
  },
  link: {
    color: '#1777CF',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default useStyles;
