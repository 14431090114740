import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import Loader from '../Loader/Loader';

function Table({
  title,
  columns,
  data,
  csvData,
  selectableRows,
  sortOptions,
  setSortOptions,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  setFilterOptions,
  isLoading,
  options,
  additionalClasses,
  tableBodyHeight,
  tableBodyMaxHeight,
  fixedHeader,
}) {
  const [filterOpen, setFilterOpen] = useState(true);

  const getCount = () => {
    if (data[0] && data[0].total_queried_count) {
      return data[0].total_queried_count || data.length;
    }
    if (data[0] && data[0].total_count) {
      return data[0].total_count;
    }
    if (data.length && data.length > 300) {
      return data.length;
    }
    return null; // this lets the package handle the count.
  };

  return (
    <MUIDataTable
      className={additionalClasses}
      title={title}
      columns={columns}
      data={data}
      serverSide
      options={{
        selectableRows, // toggle checkboxes in rows
        rowsPerPageOptions: [10, 15, 50, 100],
        rowsPerPage,
        tableBodyHeight,
        tableBodyMaxHeight,
        fixedHeader,
        sortFilterList: false,
        page,
        textLabels: {
          body: {
            noMatch: isLoading ? <Loader /> : 'Sorry, there is no matching data to display',
          },
        },
        sortOrder: sortOptions,
        count: getCount(),
        onTableChange: (action, tableState) => {
          if (action === 'changePage') {
            setPage(tableState.page);
          }
          if (action === 'changeRowsPerPage') {
            setRowsPerPage(tableState.rowsPerPage);
          }
          if (action === 'onFilterDialogClose') {
            setFilterOptions(tableState.filterList);
            setFilterOpen(false);
          }
          if (action === 'onFilterDialogOpen') {
            setFilterOpen(true);
          }
          if (action === 'sort') {
            setSortOptions(tableState.sortOrder);
          }
          if (action === 'filterChange' && !filterOpen) {
            setFilterOptions(tableState.filterList);
          }
        },
        customSearchRender: options.customSearchRender,
        onSearchChange: options.onSearchChange,
        onDownload: (buildHead, buildBody, tableColumns, tableData) =>
          `\uFEFF${buildHead(tableColumns)}${buildBody(csvData.length > 0 ? csvData : tableData)}`,
      }}
    />
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      total_queried_count: PropTypes.number,
      total_count: PropTypes.number,
    })
  ),
  csvData: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      data: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
  title: PropTypes.string,
  additionalClasses: PropTypes.string,
  selectableRows: PropTypes.string,
  sortOptions: PropTypes.shape({}),
  setSortOptions: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  setPage: PropTypes.func,
  setFilterOptions: PropTypes.func,
  isLoading: PropTypes.bool,
  tableBodyHeight: PropTypes.string,
  tableBodyMaxHeight: PropTypes.string,
  fixedHeader: PropTypes.bool,
  options: PropTypes.shape({
    onSearchChange: PropTypes.func,
    customSearchRender: PropTypes.func,
  }),
};

Table.defaultProps = {
  title: '',
  additionalClasses: '',
  selectableRows: 'none',
  data: [{ total_queried_count: 0 }],
  csvData: [],
  sortOptions: {},
  setSortOptions: () => {},
  rowsPerPage: 50,
  setRowsPerPage: () => {},
  page: 0,
  setPage: () => {},
  setFilterOptions: () => {},
  isLoading: false,
  tableBodyHeight: 'auto',
  tableBodyMaxHeight: '600px',
  fixedHeader: true,
  options: {
    onSearchChange: () => {},
    customSearchRender: () => {},
  },
};

export default Table;
