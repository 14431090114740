import React from 'react';

function FolderXIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      style={{ enableBackground: 'new 0 0 512 512', height: '30px', width: 'auto' }}
      xmlSpace="preserve"
    >
      <path d="M76.8 89.6h91.8l69.3 69.3c4.8 4.8 11.4 7.5 18.1 7.5h179.2c14.1 0 25.6 11.5 25.6 25.6v204.8c0 14.1-11.5 25.6-25.6 25.6H76.8c-14.1 0-25.6-11.5-25.6-25.6V115.2c0-14.1 11.5-25.6 25.6-25.6 14.1 0 25.6-11.5 25.6-25.6 0-14.1-11.5-25.6-25.6-25.6C34.4 38.4 0 72.8 0 115.2v281.6c0 42.4 34.4 76.8 76.8 76.8h358.4c42.4 0 76.8-34.4 76.8-76.8V192c0-42.4-34.4-76.8-76.8-76.8H266.6l-69.3-69.3c-4.8-4.8-11.4-7.5-18.1-7.5H76.8c-14.1 0-25.6 11.5-25.6 25.6 0 14.1 11.5 25.6 25.6 25.6z" />
      <path d="m186.7 261.3 102.4 102.4c10 10 26.2 10 36.2 0 10-10 10-26.2 0-36.2L222.9 225.1c-10-10-26.2-10-36.2 0-10 10-10 26.2 0 36.2zm102.4-36.2L186.7 327.5c-10 10-10 26.2 0 36.2 10 10 26.2 10 36.2 0l102.4-102.4c10-10 10-26.2 0-36.2-10-10-26.2-10-36.2 0z" />
    </svg>
  );
}

export default FolderXIcon;
