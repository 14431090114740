import { RSAA } from 'redux-api-middleware';

export const FETCH_SHIPMENT_RECONCILIATION_REQUEST = 'FETCH_SHIPMENT_RECONCILIATION_REQUEST';
export const FETCH_SHIPMENT_RECONCILIATION_SUCCESS = 'FETCH_SHIPMENT_RECONCILIATION_SUCCESS';
export const FETCH_SHIPMENT_RECONCILIATION_FAILURE = 'FETCH_SHIPMENT_RECONCILIATION_FAILURE';

export const FETCH_RECONCILIATION_GET_BOL_NUMBER_REQUEST = 'FETCH_RECONCILIATION_GET_BOL_NUMBER_REQUEST';
export const FETCH_RECONCILIATION_GET_BOL_NUMBER_SUCCESS = 'FETCH_RECONCILIATION_GET_BOL_NUMBER_SUCCESS';
export const FETCH_RECONCILIATION_GET_BOL_NUMBER_FAILURE = 'FETCH_RECONCILIATION_GET_BOL_NUMBER_FAILURE';

export const fetchReconciliationGetBolNumber = (
  limit,
  timeframe,
  sortBy,
  orderBy,
  filtersRetrieved,
  search,
  filterQuery = ''
) => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-reconciliation-get-bol-number?limit=${limit}&timeframe=${timeframe}&sort_by=${sortBy}&order_by=${orderBy}&filtersRetrieved=${filtersRetrieved}&search=${search}${filterQuery}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_RECONCILIATION_GET_BOL_NUMBER_REQUEST,
      {
        type: FETCH_RECONCILIATION_GET_BOL_NUMBER_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          const payload = limit === 'max' ? { reconBolNumbersMax: json } : { reconBolNumbers: json };
          return { entities: payload };
        },
      },
      {
        type: FETCH_RECONCILIATION_GET_BOL_NUMBER_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchShipmentReconciliation = (
  limit,
  timeframe,
  sortBy,
  orderBy,
  filtersRetrieved,
  search,
  filterQuery = ''
) => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-reconciliation-get-shipment?limit=${limit}&timeframe=${timeframe}&sort_by=${sortBy}&order_by=${orderBy}&filtersRetrieved=${filtersRetrieved}&search=${search}${filterQuery}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_SHIPMENT_RECONCILIATION_REQUEST,
      {
        type: FETCH_SHIPMENT_RECONCILIATION_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          const payload = limit === 'max' ? { reconBolsMax: json } : { reconBols: json };
          return { entities: payload };
        },
      },
      {
        type: FETCH_SHIPMENT_RECONCILIATION_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const ReconciliationGetBolNumberMiddleware = () => next => action => {
  next(action);
  switch (action.type) {
    case FETCH_RECONCILIATION_GET_BOL_NUMBER_SUCCESS:
      console.log('fetch shipment reconciliation bols success');
      break;
    case FETCH_RECONCILIATION_GET_BOL_NUMBER_FAILURE:
      console.log('fetch shipment reconciliation bols failure');
      break;
    default:
      break;
  }
};

export const ShipmentReconciliationMiddleware = () => next => action => {
  next(action);
  switch (action.type) {
    case FETCH_SHIPMENT_RECONCILIATION_SUCCESS:
      console.log('fetch shipment reconciliation bols success');
      break;
    case FETCH_SHIPMENT_RECONCILIATION_FAILURE:
      console.log('fetch shipment reconciliation bols failure');
      break;
    default:
      break;
  }
};
