import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputPanel: {
    width: '49%',
    borderRight: 'solid #c3c3c3 1px',
    paddingRight: theme.spacing(3),
  },
  panelTitle: {
    fontSize: '20px',
    fontWeight: '500',
    marginLeft: '15px',
    marginBottom: '20px',
  },
  outputPanel: {
    paddingLeft: theme.spacing(3),
    width: '49%',
  },
  listItem: {
    borderBottom: 'solid #c3c3c3 1px',
    minHeight: '80px',
  },
  listItemDetail: {
    fontWeight: '400',
  },
  tab: {
    width: '100px',
    minWidth: '100px',
    fontWeight: '900',
  },
}));

export default useStyles;
