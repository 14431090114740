import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: '20px',
    width: '100%',
  },
  field: {
    fontSize: '14px',
    color: '#9e9e9e',
    marginRight: '5px',
  },
  value: {
    fontSize: '16px',
  },
  title: {
    fontSize: '18px',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  fieldValue: {
    height: '50px',
    marginTop: '10px',
  },
  accordion: {
    boxShadow: 'none',
    borderBottom: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: '#e0e0e0',
  },
  accordionSummary: {
    maxHeight: '60px',
  },
  /* accordionDetails: {
    
  }, */
  successContainer: {
    display: 'flex',
    backgroundColor: '#ecf7ed',
    borderRadius: '25px',
    width: '110px',
  },
  pendingContainer: {
    display: 'flex',
    backgroundColor: '#fff8e1',
    borderRadius: '25px',
    width: '110px',
  },
  failedContainer: {
    display: 'flex',
    backgroundColor: '#fce9ef',
    borderRadius: '25px',
    width: '95px',
  },
  statusIcon: {
    marginRight: '8px',
  },
  success: {
    color: 'green',
  },
  pending: {
    color: '#ffb300',
  },
  failed: {
    color: 'red',
  },
  toolTip: {
    maxWidth: '50px',
    marginTop: '2px',
  },
  iconInfo: {
    fontSize: '16px',
    color: '#9e9e9e',
  },
  fieldTooltip: {
    display: 'flex',
  },
}));

export default useStyles;
