import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 700,
  },
  overviewSubtitle: {
    color: theme.palette.text.secondary,
    letterSpacing: '1.25px',
  },
  rankListTitle: {
    paddingBottom: theme.spacing(3),
    textAlign: 'left',
  },
  totals: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  totalVolume: {
    marginRight: theme.spacing(4),
  },
  listItemTitle: {
    letterSpacing: '1.25px',
  },
  listItemDetail: {
    color: theme.palette.text.primary,
    fontWeight: '700',
  },
  listItemDetailTwo: {
    fontWeight: '400',
    color: theme.palette.text.secondary,
  },
  phasesTitle: {
    fontSize: '18px',
    fontWeight: '500',
  },
  panelsContainer: {
    display: 'flex',
    marginTop: '15px',
    marginBottom: theme.spacing(3),
  },
  panel: {
    border: 'solid',
    borderWidth: '1px',
    borderColor: '#e0e0e0',
    borderRadius: '5px',
    padding: '12px',
    marginRight: '20px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.1)',
  },
  inventoryTitles: {
    display: 'flex',
  },
  viewDate: {
    width: '100%',
    display: 'flex',
    marginTop: '15px',
    height: '30px',
    alignItems: 'center',
    justifyContent: 'flex-End',
  },
  dateBox: {
    marginLeft: '25px',
    display: 'flex',
    border: 'solid',
    borderWidth: '1px',
    borderColor: '#202020',
    borderRadius: '5px',
    padding: '8px 14px 8px 14px',
  },
  eventIcon: {
    color: 'red',
    fontSize: '20px',
    marginLeft: '5px',
  },
}));

export default useStyles;
