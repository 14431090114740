import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { useOktaAuth } from '@okta/okta-react';
import { ReactComponent as TakedaSvg } from './takedalogo.svg';
// component
import Navigation from '../Navigation/Navigation';
import Modal from '../Modal/Modal';
import ProfileCard from '../ProfileCard/ProfileCard';
import Search from '../Search/Search';
// styles
import useStyles from './Header.styles';

function Header() {
  const classes = useStyles();
  const [openSearch, setOpenSearch] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const { authState } = useOktaAuth();

  const neutralizeBack = callback => {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, '', window.location.href);
      callback();
    };
  };

  const revivalBack = () => {
    window.onpopstate = () => undefined;
    window.history.back();
    setOpenSearch(false);
  };

  const handleOpenSearch = () => {
    neutralizeBack(revivalBack);
    if (authState.accessToken) {
      setOpenSearch(true);
    }
  };

  const handleCloseSearch = () => {
    revivalBack();
    setOpenSearch(false);
    if (authState.accessToken) {
      setOpenSearch(false);
    }
  };
  const handleCloseOnly = () => {
    setOpenSearch(false);
    if (authState.accessToken) {
      setOpenSearch(false);
    }
  };
  const handleOpenProfile = () => {
    if (authState.accessToken) {
      setOpenProfile(!openProfile);
    }
  };

  if (!authState || !authState.isAuthenticated) {
    return null;
  }

  if (authState.isAuthenticated) {
    sessionStorage.setItem('OktaToken', `${authState.accessToken.accessToken}`);
  }

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <Link component={RouterLink} to="/">
          <TakedaSvg alt="logo" className={classes.logo} />
        </Link>
        <Typography className={classes.title} variant="h5" noWrap>
          Plasmachain
        </Typography>
        <div className={classes.grow} />
        <div className={classes.sectionIcons}>
          <IconButton aria-label="search" color="inherit" onClick={handleOpenSearch}>
            <SearchIcon />
          </IconButton>
          <IconButton aria-label="faqs" color="inherit">
            <HelpOutlineOutlinedIcon />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="account of current user"
            color="inherit"
            onClick={handleOpenProfile}
          >
            <AccountCircle />
          </IconButton>
        </div>
      </Toolbar>
      {authState.accessToken && (
        <>
          <Navigation />
          <Modal open={openSearch} handleClose={handleCloseSearch}>
            <Search handleClose={handleCloseOnly} />
          </Modal>
          <ProfileCard open={openProfile} handleClose={handleOpenProfile} />
        </>
      )}
    </AppBar>
  );
}

Header.propTypes = {};

export default Header;
