import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.primary.darkest,
  },
  toolBar: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  logo: {
    height: '35px',
    width: 'auto',
    marginTop: theme.spacing(0.5),
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    fontWeight: 700,
    paddingLeft: theme.spacing(1.5),
  },
  sectionIcons: {
    float: 'right',
    padding: theme.spacing(0.5),
  },
  closeSpan: {
    float: 'right',
  },
  margin: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));

export default useStyles;
