import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import map from 'lodash/map';
import get from 'lodash/get';
import { saveAs } from 'file-saver';
import Loader from '../Loader/Loader';
import { fetchShipmentReconciliation, fetchReconciliationGetBolNumber } from '../../redux/reconciliation';
import { checkAndFormatIfDate, formatDate, formatToXLST } from '../../utils';

function ReconciliationTable({
  title,
  columns,
  data,
  selectableRows,
  sortOptions,
  setSortOptions,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  setFilterOptions,
  isLoading,
  options,
  additionalClasses,
  tableBodyHeight,
  tableBodyMaxHeight,
  fixedHeader,
  bolParmeters,
  bolFilterQuery,
  qtyParameters,
  reconciliationTab,
  qtyFilterQuery,
}) {
  const dispatch = useDispatch();

  const [filterOpen, setFilterOpen] = useState(true);
  const [bolDataRequested, setBolDataRequested] = useState(false);
  const [qtyDataRequested, setQtyDataRequested] = useState(false);
  const { bolTimeframe, bolSortOptions, bolSearchText, bolFiltersRetrieved } = bolParmeters;
  const { qtyTimeframe, qtySortOptions, qtySearchText, qtyFiltersRetrieved } = qtyParameters;
  const downloadButtonTextModifier = reconciliationTab === 'BOL' ? 'BOL Number' : 'Transactional Quantity';

  const getCount = () => {
    if (data[0] && data[0].total_queried_count) {
      return data[0].total_queried_count || data.length;
    }
    if (data[0] && data[0].total_count) {
      return data[0].total_count;
    }
    if (data.length && data.length > 300) {
      return data.length;
    }
    return null; // this lets the package handle the count.
  };

  const reconBolNumbersMax = useSelector(state => {
    if (!get(state, 'entities.reconBolNumbersMax.data', {})[0]) {
      return [];
    }

    return map(get(state, 'entities.reconBolNumbersMax.data', {})[0].list, (bols, key) => ({
      index: key,
      data: [
        bols.reconciliation_status ? bols.reconciliation_status : ' - ',
        bols.dis_bol ? bols.dis_bol : ' - ',
        bols.dis_ship_date ? formatDate(bols.dis_ship_date) : ' - ',
        bols.dis_item ? bols.dis_item : ' - ',
        bols.dis_center ? bols.dis_center : ' - ',
        bols.mfg_ov_bol ? bols.mfg_ov_bol : ' - ',
        bols.mfg_ov_item ? bols.mfg_ov_item : ' - ',
        bols.tpl_center ? bols.tpl_center : ' - ',
        bols.tpl_bol ? bols.tpl_bol : ' - ',
        bols.tpl_item ? bols.tpl_item : ' - ',
        bols.tpl_carrier ? bols.tpl_carrier : ' - ',
      ],
    }));
  });

  const reconBolsMax = useSelector(state => {
    if (!get(state, 'entities.reconBolsMax.data', {})[0]) {
      return [];
    }
    return map(get(state, 'entities.reconBolsMax.data', {})[0].list, (qty, key) => ({
      index: key,
      data: [
        qty.bol ? qty.bol : ' - ',
        qty.dis_ship_date ? formatDate(qty.dis_ship_date) : ' - ',
        qty.item_number ? qty.item_number : ' - ',
        qty.dis_ship_quantity ? qty.dis_ship_quantity : ' - ',
        qty.biolife_out_quantity ? Number(qty.biolife_out_quantity) : ' - ',
        qty.tpl_in_quantity ? qty.tpl_in_quantity : ' - ',
        qty.tpl_out_quantity ? Number(qty.tpl_out_quantity) : ' - ',
        qty.manufacturing_in_quantity ? qty.manufacturing_in_quantity : ' - ',
        qty.journey_status ? qty.journey_status : '-',
        qty.reconciliation_status ? Number(qty.reconciliation_status) : ' - ',
        qty.tpl_carrier_name ? qty.tpl_carrier_name : ' - ',
        qty.tpl_center_code ? qty.tpl_center_code : ' - ',
        qty.manufacturing_warehouse ? qty.manufacturing_warehouse : ' - ',
        qty.biolife_out_date ? formatDate(qty.biolife_out_date) : ' - ',
        qty.tpl_in_date ? formatDate(qty.tpl_in_date) : ' - ',
        qty.tpl_out_date ? formatDate(qty.tpl_out_date) : ' - ',
        qty.manufacturing_in_date ? formatDate(qty.manufacturing_in_date) : ' - ',
      ],
    }));
  });

  return (
    <MUIDataTable
      className={additionalClasses}
      title={title}
      columns={columns}
      data={data}
      serverSide
      options={{
        selectableRows, // toggle checkboxes in rows
        rowsPerPageOptions: [10, 15, 50, 100],
        rowsPerPage,
        tableBodyHeight,
        tableBodyMaxHeight,
        fixedHeader,
        sortFilterList: false,
        page,
        textLabels: {
          body: {
            noMatch: isLoading ? <Loader /> : 'Sorry, there is no matching data to display',
          },
          toolbar: {
            downloadCsv: `Download ${downloadButtonTextModifier} Spreadsheet (.xlsx)`,
          },
        },
        sortOrder: sortOptions,
        count: getCount(),
        onTableChange: (action, tableState) => {
          if (action === 'changePage') {
            setPage(tableState.page);
          }
          if (action === 'changeRowsPerPage') {
            setRowsPerPage(tableState.rowsPerPage);
          }
          if (action === 'onFilterDialogClose') {
            setFilterOptions(tableState.filterList);
            setFilterOpen(false);
          }
          if (action === 'onFilterDialogOpen') {
            setFilterOpen(true);
          }
          if (action === 'sort') {
            setSortOptions(tableState.sortOrder);
          }
          if (action === 'filterChange' && !filterOpen) {
            setFilterOptions(tableState.filterList);
          }
        },
        customSearchRender: options.customSearchRender,
        onSearchChange: options.onSearchChange,
        onDownload: (buildHead, buildBody, tableColumns) => {
          let filtrInterval = null;
          let spreadsheetData = [];
          const DATA_CHECK_ITNERVAL = 1500;
          if (reconciliationTab === 'BOL' && bolDataRequested === false) {
            dispatch(
              fetchReconciliationGetBolNumber(
                'max',
                bolTimeframe,
                bolSortOptions.name,
                bolSortOptions.direction,
                bolFiltersRetrieved,
                checkAndFormatIfDate(bolSearchText === null ? '' : bolSearchText),
                // eslint-disable-next-line react/prop-types
                bolFilterQuery.join('')
              )
            );
            setBolDataRequested(true);
          } else if (reconciliationTab === 'QTY' && qtyDataRequested === false) {
            dispatch(
              fetchShipmentReconciliation(
                'max',
                qtyTimeframe,
                qtySortOptions.name,
                qtySortOptions.direction,
                qtyFiltersRetrieved,
                checkAndFormatIfDate(qtySearchText === null ? '' : qtySearchText),
                // eslint-disable-next-line react/prop-types
                qtyFilterQuery.join('')
              )
            );
            setQtyDataRequested(true);
          }

          spreadsheetData = reconciliationTab === 'BOL' ? reconBolNumbersMax : reconBolsMax;

          if (spreadsheetData.length > 0) {
            setBolDataRequested(false);
            setQtyDataRequested(false);
            if (reconciliationTab === 'BOL') {
              // clear store after successfull download for filters
              dispatch({
                type: 'FETCH_RECONCILIATION_GET_BOL_NUMBER_SUCCESS',
                payload: { entities: { reconBolNumbersMax: null } },
              });
            } else if (reconciliationTab === 'QTY') {
              dispatch({
                type: 'FETCH_SHIPMENT_RECONCILIATION_SUCCESS',
                payload: { entities: { reconBolsMax: null } },
              });
            }

            const xlstData = formatToXLST(tableColumns, spreadsheetData);
            saveAs(xlstData, 'reconciliation-data.xlsx');
            return false;
            // return buildHead(tableColumns) + buildBody(spreadsheetData);
          }
          if (filtrInterval) clearInterval(filtrInterval);
          filtrInterval = setInterval(() => {
            document
              .querySelectorAll(
                `[data-testid="Download${downloadButtonTextModifier.replace(
                  ' ',
                  ''
                )}Spreadsheet(.xlsx)-iconButton"]`
              )[0]
              .click();
            clearInterval(filtrInterval);
          }, DATA_CHECK_ITNERVAL);
          return false;
        },
      }}
    />
  );
}

ReconciliationTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      total_queried_count: PropTypes.number,
      total_count: PropTypes.number,
    })
  ),
  title: PropTypes.string,
  additionalClasses: PropTypes.string,
  selectableRows: PropTypes.string,
  sortOptions: PropTypes.shape({}),
  setSortOptions: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  setPage: PropTypes.func,
  setFilterOptions: PropTypes.func,
  isLoading: PropTypes.bool,
  tableBodyHeight: PropTypes.string,
  tableBodyMaxHeight: PropTypes.string,
  fixedHeader: PropTypes.bool,
  bolParmeters: PropTypes.shape({
    bolTimeframe: PropTypes.string,
    bolSortOptions: PropTypes.shape({
      name: PropTypes.string,
      direction: PropTypes.string,
    }),
    bolFiltersRetrieved: PropTypes.bool,
    bolSearchText: PropTypes.string,
  }),
  bolFilterQuery: PropTypes.shape({}),
  qtyParameters: PropTypes.shape({
    qtyTimeframe: PropTypes.string,
    qtySearchText: PropTypes.string,
    qtyFiltersRetrieved: PropTypes.bool,
    qtySortOptions: PropTypes.shape({
      name: PropTypes.string,
      direction: PropTypes.string,
    }),
  }),
  qtyFilterQuery: PropTypes.shape({}),
  reconciliationTab: PropTypes.string.isRequired,
  options: PropTypes.shape({
    onSearchChange: PropTypes.func,
    customSearchRender: PropTypes.func,
  }),
};

ReconciliationTable.defaultProps = {
  title: '',
  additionalClasses: '',
  selectableRows: 'none',
  data: [{ total_queried_count: 0 }],
  sortOptions: {},
  setSortOptions: () => {},
  rowsPerPage: 50,
  setRowsPerPage: () => {},
  page: 0,
  setPage: () => {},
  setFilterOptions: () => {},
  isLoading: false,
  tableBodyHeight: 'auto',
  tableBodyMaxHeight: '600px',
  fixedHeader: true,
  qtyParameters: {
    qtyTimeframe: 'all',
    qtySortOptions: { name: 'reconciliation_status', direction: 'desc' },
    qtyFiltersRetrieved: false,
    qtySearchText: '',
  },
  bolParmeters: {
    bolTimeframe: 'all',
    bolSortOptions: { name: 'reconciliation_status', direction: 'desc' },
    bolFiltersRetrieved: false,
    bolSearchText: '',
  },
  bolFilterQuery: [],
  qtyFilterQuery: [],
  options: {
    onSearchChange: () => {},
    customSearchRender: () => {},
  },
};

export default ReconciliationTable;
