import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  headerInfo: {
    maxWidth: '1280px',
    margin: `0 auto ${theme.spacing(1)}px`,
    paddingRight: theme.spacing(1),
    position: 'relative',
    marginTop: theme.spacing(2),
  },
  placeTitle: {
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  pbn: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0',
    marginTop: theme.spacing(1),
  },
  pdn: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  pbnTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  donorStatus: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.light,
    fontWeight: 'bold',
  },
  grayCheck: {
    color: theme.palette.primary.light,
    fontSize: '18px',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(3),
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    padding: '10px',
  },
}));

export default useStyles;
