/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import map from 'lodash/map';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment';
import useStyles from './ManufacturingLocation.styles';
import SummaryOverview from '../../components/SummaryOverview/SummaryOverview';
import TabPanel from '../../components/TabPanel/TabPanel';
import Table from '../../components/Table/Table';
import TableSearch from '../../components/TableSearch/TableSearch';
import ViewInDevBanner from '../../components/ViewInDevBanner/ViewInDevBanner';
import { fetchManufacturingGetLocationPhaseOverview } from '../../redux/manufacturing';
import {
  fetchManufacturingGetLocationDonations,
  fetchManufacturingGetLocationDonationsDates,
  fetchManufacturingGetLocationReceivedDonations,
  fetchManufacturingGetLocationSortedDonations,
  fetchManufacturingGetLocationReleasedDonations,
  fetchManufacturingGetLocationTeardownDonations,
} from '../../redux/donations';
import {
  formatDate,
  formatStatus,
  customAlignRight,
  customDateAlignRight,
  filterDateOptionsLogic,
  customFilterListOptionsRender,
  customFilterListOptionsUpdate,
  numDecimalsCheck,
  returnRowContainsStatus,
  sortNum,
  withCommas,
  checkAndFormatIfDate,
  formatLocationName,
} from '../../utils';
import Loader from '../../components/Loader/Loader';
import CustomRangeFilter from '../../components/CustomRangeFilter/CustomRangeFilter';
import {
  iggColumn,
  volumeColumn,
  agingColumn,
  totalVelocityColumn,
  statusTimeColumn,
  transitionColumn,
  itemNumberColumn,
  pdnColumn,
  tokenColumn,
  pbnColumn,
} from '../../components/Table/Columns';
import { TableLink } from '../../components/Table/TableLink';

function ManufacturingLocation() {
  const { id, location } = useParams();
  const [sortOptions, setSortOptions] = useState({ name: 'aging', direction: 'asc' });
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [filterOptions, setFilterOptions] = useState({});
  const [selectedTab, setSelectedTab] = useState('donations');
  const urlLocation = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const SPACED_DATE_FORMAT = 'MM/DD/YYYY';

  const locationDonationsLoading = useSelector(state => {
    const loadingArray = [
      'FETCH_MANUFACTURING_GET_LOCATION_DONATIONS',
      'FETCH_MANUFACTURING_GET_LOCATION_RECEIVED_DONATIONS',
      'FETCH_MANUFACTURING_GET_LOCATION_SORTED_DONATIONS',
      'FETCH_MANUFACTURING_GET_LOCATION_RELEASED_DONATIONS',
      'FETCH_MANUFACTURING_GET_LOCATION_TEARDOWN_DONATIONS',
    ];

    const even = element => state.loading[element] === true;

    return Object.keys(state.loading).length !== 0 ? loadingArray.some(even) : true;
  });

  const overviewLoading = useSelector(state =>
    get(state, 'loading.FETCH_MANUFACTURING_GET_LOCATION_PHASE_OVERVIEW', true)
  );

  const getHyphenatedDate = dateString => moment(dateString, 'MM/DD/YYYY').format(SPACED_DATE_FORMAT);

  useEffect(() => {
    if (id) {
      dispatch(fetchManufacturingGetLocationPhaseOverview(id));
    }
  }, [id]);

  const handleChange = (e, value) => {
    const dispatchParams = [
      formatLocationName(location),
      300,
      sortOptions.name,
      sortOptions.direction,
      checkAndFormatIfDate(searchText),
    ];

    if (id && value === 'received') {
      dispatch(fetchManufacturingGetLocationReceivedDonations(...dispatchParams));
    }
    if (id && value === 'sorted') {
      dispatch(fetchManufacturingGetLocationSortedDonations(...dispatchParams));
    }
    if (id && value === 'released') {
      dispatch(fetchManufacturingGetLocationReleasedDonations(...dispatchParams));
    }
    if (id && value === 'teardown') {
      dispatch(fetchManufacturingGetLocationTeardownDonations(formatLocationName(location)));
    }
    setSelectedTab(value);
  };

  const overviewData = useSelector(state => {
    const details = (title, detail, detailTwo) => ({
      title,
      detail: !parseInt(detail, 10) ? '-' : numDecimalsCheck(detail, 3, 'L'),
      detail_two: !parseInt(detailTwo, 10)
        ? 'Data not available for this vein-to-vein lifecycle status'
        : numDecimalsCheck(detailTwo, 2, 'g est. gamma'),
    });
    return map(get(state, 'entities.overview.overview', {}), overview => ({
      'View by Processes': [
        details('Total Donation Volume', overview.volume_manufacturing, overview.total_igg_manufacturing),
        { title: 'Divider' },
        details('Received', overview.received_volume, overview.received_igg),
        details('Sorted', overview.sorted_volume, overview.sorted_volume),
        details('Released at MFG', overview.released_volume, overview.released_igg),
        { title: 'Divider' },
        {
          title: 'Teardown Pools',
          detail: !parseInt(overview.total_pools, 10) ? '-' : `${overview.total_pools} Pools`,
        },
      ],
    }));
  });

  const overviews = {
    ...overviewData[0],
    // ...overviewDetails[0], Remove comment when details required for main
  };

  const allData = useSelector(state =>
    map(get(state, 'entities.donations.data', {}), donation => ({
      total_count: Number(donation.total_count),
      total_queried_count: Number(donation.total_queried_count),
      pbn: donation.pbn || '-',
      pdn: donation.pdn || '-',
      status: formatStatus(donation.status),
      token_id: donation.token || '-',
      volume: numDecimalsCheck(donation.volume, 3),
      est_igg: numDecimalsCheck(donation.est_igg, 2),
      flavor: donation.flavor || '-',
      item_number: donation.item_number || '-',
      origin: donation.origin || '-',
      masterfile: donation.masterfile || '-',
      box_number: donation.box_number || '-',
      bol_number: donation.bol_number || '-',
      carrier: donation.carrier || '-',
      vin: donation.vin || '-',
      bleed_date: moment(formatDate(donation.bleed_date), 'MM-DD-YYYY').toDate().getTime(),
      aging: withCommas(donation.aging),
      received_date: moment(formatDate(donation.received_date), 'MM-DD-YYYY').toDate().getTime(),
      status_time: withCommas(donation.status_time),
      transition_velocity: withCommas(donation.transition_velocity),
      total_velocity: withCommas(donation.total_velocity),
    }))
  );

  const aliases = useSelector(state =>
    reduce(
      get(state, 'entities.donations.data', []),
      (acc, alias) => {
        if (!acc.includes(alias.origin)) {
          acc.push(alias.origin);
        }
        return acc;
      },
      []
    )
  );

  const bleedDates = useSelector(state =>
    map(get(state, 'entities.dates.data', {}), bleedDate => ({
      min_date: formatDate(bleedDate.min_date),
      max_date: formatDate(bleedDate.max_date),
    }))
  );

  const mapDonationData = (entity, state, phaseDate) =>
    map(get(state, entity, {}), donation => ({
      total_count: Number(donation.total_count),
      total_queried_count: Number(donation.total_queried_count),
      pbn: donation.pbn || '-',
      pdn: donation.pdn || '-',
      status: formatStatus(donation.status),
      token_id: donation.token || '-',
      volume: numDecimalsCheck(donation.volume, 3),
      est_igg: numDecimalsCheck(donation.est_igg, 2),
      flavor: donation.flavor || '-',
      item_number: donation.item_number || '-',
      origin: donation.origin || '-',
      masterfile: donation.masterfile || '-',
      box_number: donation.box_number || '-',
      bol_number: donation.bol_number || '-',
      carrier: donation.carrier || '-',
      vin: donation.vin || '-',
      bleed_date: moment(formatDate(donation.bleed_date), 'MM-DD-YYYY').toDate().getTime(),
      aging: withCommas(donation.aging),
      received_date: moment(formatDate(donation[phaseDate]), 'MM-DD-YYYY').toDate().getTime(),
      status_time: withCommas(donation.status_time),
      transition_velocity: withCommas(donation.transition_velocity),
      total_velocity: withCommas(donation.total_velocity),
    }));

  const sortedData = useSelector(state => mapDonationData('entities.sorted.data', state, 'received_date'));
  const releasedData = useSelector(state =>
    mapDonationData('entities.released.data', state, 'released_date')
  );
  const receivedData = useSelector(state =>
    mapDonationData('entities.received.data', state, 'received_date')
  );

  const teardownData = useSelector(state =>
    map(get(state, 'entities.teardown.data', {}), donation => ({
      teardown_lot_number: donation.teardown_lot_number || '-',
      donations: donation.donations || '-',
      volume: numDecimalsCheck(donation.volume, 3),
      est_igg: donation.est_igg || '-',
      teardown_date: getHyphenatedDate(formatDate(donation.teardown_date)),
      teardown_date_number: moment(formatDate(donation.teardown_date), 'MM-DD-YYYY').toDate().getTime(),
    }))
  );

  const getReceivedDateLabel = concat => {
    let label = `Received Date ${concat}`;
    if (selectedTab === 'sorted') {
      label = `Sorted Date ${concat}`;
    }
    if (selectedTab === 'released') {
      label = `Released Date ${concat}`;
    }
    return label;
  };

  const allColumns = [
    pbnColumn(value => TableLink(value, `/collection/${id}/donation/${value}/`)),
    pdnColumn,
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Status: ${v.replace(/[0-9]|\./g, '')}`,
        },
        filterOptions: {
          logic: (status, filters) => returnRowContainsStatus(status, filters),
          names: ['Received', 'Sorted', 'Released', 'Teardown'],
        },
        sort: true,
      },
    },
    tokenColumn({
      className: classes.leftAlignedHeader,
    }),
    volumeColumn({
      className: classes.rightAlignedHeader,
    }),
    iggColumn({
      className: classes.rightAlignedHeader,
    }),
    {
      name: 'flavor',
      label: 'Flavor',
      options: {
        filter: true,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Flavor: ${v}`,
        },
        filterOptions: {
          logic: (flavor, filters) => returnRowContainsStatus(flavor, filters),
          names: ['Source', 'Void', 'No Take', 'Physical', 'Source-Designated', 'Low Volume', 'Salvaged'],
        },
        sort: true,
      },
    },
    itemNumberColumn({
      className: classes.leftAlignedHeader,
    }),
    {
      name: 'origin',
      label: 'Origin',
      options: {
        filter: true,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Origin: ${v}`,
        },
        filterOptions: {
          logic: (status, filters) => returnRowContainsStatus(status, filters),
          names: aliases,
        },
        sort: true,
      },
    },
    {
      name: 'masterfile',
      label: 'Master File Approved',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'box_number',
      label: 'Box Number',
      options: {
        sort: true,
        sortCompare: order => sortNum(order),
        customBodyRender: customAlignRight,
        viewColumns: false,
      },
    },
    {
      name: 'bol_number',
      label: 'BOL Number',
      options: {
        sort: true,
        sortCompare: order => sortNum(order),
        customBodyRender: customAlignRight,
        viewColumns: false,
      },
    },
    {
      name: 'carrier',
      label: 'Carrier Number',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
      },
    },
    {
      name: 'vin',
      label: 'VIN',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
      },
    },
    {
      name: 'bleed_date',
      label: 'Bleed Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customDateAlignRight,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => customFilterListOptionsRender(v, 'Bleed Date'),
          update: (filterList, filterPos, index) =>
            customFilterListOptionsUpdate(filterList, filterPos, index),
        },
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
        filterOptions: {
          names: [],
          logic(val, filters) {
            return filters.length ? filterDateOptionsLogic(val, filters) : false;
          },
          display: (filterList, onChange, index, column) => (
            <CustomRangeFilter
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
              label="Bleed Date"
              type="date"
              list={bleedDates[0]}
            />
          ),
        },
      },
    },
    agingColumn(),
    {
      name: 'received_date',
      label: getReceivedDateLabel('(MM/DD/YYYY)'),
      options: {
        filter: true,
        customBodyRender: customDateAlignRight,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => customFilterListOptionsRender(v, getReceivedDateLabel('')),
          update: (filterList, filterPos, index) =>
            customFilterListOptionsUpdate(filterList, filterPos, index),
        },
        filterOptions: {
          names: [],
          logic(val, filters) {
            return filters.length ? filterDateOptionsLogic(val, filters) : false;
          },
          display: (filterList, onChange, index, column) => (
            <CustomRangeFilter
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
              label={getReceivedDateLabel('')}
              type="date"
              list={bleedDates[0]}
            />
          ),
        },
      },
    },
    statusTimeColumn(),
    transitionColumn(),
    totalVelocityColumn(),
  ];

  const teardownColumns = [
    {
      name: 'teardown_lot_number',
      label: 'Teardown Lot',
      options: {
        filter: false,
        sort: true,
        sortCompare: order => sortNum(order),
        viewColumns: false,
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: 0,
            background: 'white',
            zIndex: 101,
          },
        }),
        customBodyRender: value =>
          TableLink(value, {
            pathname: `/manufacturing/teardown/${value}`,
            state: { prevPath: urlLocation.pathname },
          }),
      },
    },
    {
      name: 'donations',
      label: 'Donations',
      options: {
        filter: false,
        sort: true,
      },
    },
    volumeColumn(),
    iggColumn(),
    {
      name: 'teardown_date_number',
      label: 'Teardown Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => moment(new Date(value)).utc().format(SPACED_DATE_FORMAT),
      },
    },
  ];

  useEffect(() => {
    if (location) {
      dispatch(
        fetchManufacturingGetLocationDonations(
          formatLocationName(location),
          300,
          sortOptions.name,
          sortOptions.direction,
          checkAndFormatIfDate(searchText)
        )
      );
      dispatch(fetchManufacturingGetLocationDonationsDates(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    /* If the total donations are greater than the current shown
       Fetch the donations each time the user changes table status */
    const limit = (page + 3) * rowsPerPage;
    const donationCount = allData && allData[0] ? allData[0].total_count : 300;
    const receivedCount = receivedData && receivedData[0] ? receivedData[0].total_count : 300;
    const sortedCount = sortedData && sortedData[0] ? sortedData[0].total_count : 300;
    const releasedCount = releasedData && releasedData[0] ? releasedData[0].total_count : 300;

    /* if filters are added, parse and add to query */
    const filterQuery = [];
    const keys = Object.keys(filterOptions);

    keys.forEach((key, index) => {
      const filters = filterOptions[key];
      if (filters && filters.length) {
        // If it's a range, format in range type
        if (
          allColumns[index] &&
          allColumns[index].options &&
          allColumns[index].options.filterType === 'custom'
        ) {
          const min = filters[0] === '' ? 'min' : filters[0];
          const max = filters[1] === '' || !filters[1] ? 'max' : filters[1];
          filterQuery.push(`&${allColumns[index].name}-range=${min}-${max}`);
          // Else just format with status = filter
        } else if (allColumns[index].name === 'bleed_date') {
          filterQuery.push(`&${allColumns[index].name}=${checkAndFormatIfDate(filters[0])}`);
        } else {
          const parsedFilters = filters.map(word => (word === 'Boxed with BOL' ? 'boxed_bol' : word));
          filterQuery.push(`&${allColumns[index].name}=${parsedFilters}`);
        }
      }
    });
    if (location && donationCount > 300 && selectedTab === 'donations') {
      dispatch(
        fetchManufacturingGetLocationDonations(
          formatLocationName(location),
          limit,
          sortOptions.name,
          sortOptions.direction,
          checkAndFormatIfDate(searchText === null ? '' : searchText),
          filterQuery.join('')
        )
      );
    } else if (location && receivedCount > 300 && selectedTab === 'received') {
      dispatch(
        fetchManufacturingGetLocationReceivedDonations(
          formatLocationName(location),
          limit,
          sortOptions.name,
          sortOptions.direction,
          checkAndFormatIfDate(searchText === null ? '' : searchText),
          filterQuery.join('')
        )
      );
    } else if (location && sortedCount > 300 && selectedTab === 'sorted') {
      dispatch(
        fetchManufacturingGetLocationSortedDonations(
          formatLocationName(location),
          limit,
          sortOptions.name,
          sortOptions.direction,
          checkAndFormatIfDate(searchText === null ? '' : searchText),
          filterQuery.join('')
        )
      );
    } else if (location && releasedCount > 300 && selectedTab === 'released') {
      dispatch(
        fetchManufacturingGetLocationReleasedDonations(
          formatLocationName(location),
          limit,
          sortOptions.name,
          sortOptions.direction,
          checkAndFormatIfDate(searchText === null ? '' : searchText),
          filterQuery.join('')
        )
      );
    }
  }, [id, page, rowsPerPage, sortOptions, filterOptions, searchText, dispatch]);

  const tablePanels = (type, data) => (
    <TabPanel index={type} value={selectedTab}>
      {locationDonationsLoading ? (
        <Loader />
      ) : (
        <Table
          isLoading={locationDonationsLoading}
          columns={allColumns}
          title={`Plasma Donations List: ${type[0].toUpperCase()}${type.slice(1).toLowerCase()} Donations`}
          data={data}
          sortOptions={sortOptions}
        />
      )}
    </TabPanel>
  );

  return (
    <Grid
      className={classes.location}
      container
      direction="row"
      spacing={3}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <ViewInDevBanner />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.title} variant="h4" noWrap>
          {location ? formatLocationName(location) : ''}
        </Typography>
      </Grid>
      <div className={classes.overview}>
        <SummaryOverview
          data={overviews}
          loading={overviewLoading}
          warehouse={location ? formatLocationName(location) : ''}
        />
      </div>
      <Grid item xs={12}>
        <Tabs
          classes={{
            root: classes.navBar,
            flexContainer: classes.flexContainer,
            indicator: classes.sectionIndicator,
          }}
          value={selectedTab}
          onChange={handleChange}
        >
          <Tab className={classes.tabLink} value="donations" label="All Donations" />
          <Tab className={classes.tabLink} value="received" label="Received Donations" />
          <Tab className={classes.tabLink} value="sorted" label="Sorted Donations" />
          <Tab className={classes.tabLink} value="released" label="Released Donations" />
          <Tab className={classes.tabLink} value="teardown" label="Teardown Lots" />
        </Tabs>
        <TabPanel index="donations" value={selectedTab}>
          <Table
            columns={allColumns}
            title="Plasma Donations List: All Donations"
            data={allData}
            sortOptions={sortOptions}
            setSortOptions={setSortOptions}
            isLoading={locationDonationsLoading}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            setFilterOptions={setFilterOptions}
            options={{
              onSearchChange: text => setSearchText(text),
              customSearchRender: (_searchText, handleSearch, onHide) => (
                // handleSearch is mui datatables default function
                // we utilize it to handle searches that don't require the api
                // takes a parameter which is the search query
                <TableSearch
                  options={{ searchPlaceholder: searchText }}
                  setCustomSearchText={setSearchText}
                  onSearch={handleSearch}
                  onHide={() => {
                    onHide();
                    setSearchText('');
                  }}
                />
              ),
            }}
          />
        </TabPanel>
        {tablePanels('received', receivedData)}
        {tablePanels('sorted', sortedData)}
        {tablePanels('released', releasedData)}
        <TabPanel index="teardown" value={selectedTab}>
          {locationDonationsLoading ? (
            <Loader />
          ) : (
            <Table
              isLoading={locationDonationsLoading}
              columns={teardownColumns}
              title="Plasma Donations List: Teardown Pools"
              data={teardownData}
              sortOptions={sortOptions}
            />
          )}
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default ManufacturingLocation;
