import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import map from 'lodash/map';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import QualityModalDetails from '../../components/QualityModalDetails/QualityModalDetails';
import Modal from '../../components/Modal/Modal';
import TopBioCenters from './QualitySections/TopBioCenters';
import TopReasons from './QualitySections/TopReasons';
import CrossDonations from './QualitySections/CrossDonations';
import SummaryOverview from '../../components/SummaryOverview/SummaryOverview';
import { fetchComplianceGetLookbackOverview } from '../../redux/overview';
import { numDecimalsCheck, useBackForwardCommands } from '../../utils';
import useStyles from './Quality.styles';
import ViewInDevBanner from '../../components/ViewInDevBanner/ViewInDevBanner';
import AllLookbackDestroyedUnits from './QualitySections/AllLookbackDestroyedUnits';

function Quality() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  useBackForwardCommands(history);
  const loading = useSelector(state => get(state, 'loading.FETCH_COMPLIANCE_GET_LOOKBACK_OVERVIEW', false));

  // Properties to pass into QualityModalsDetails component
  const [openModal, setOpenModal] = useState(false);
  const [reasonOrLoc, setReasonOrLoc] = useState('');
  // If location
  const [selectedLocId, setSelectedLocId] = useState('');
  // If reason
  const [modalOverview, setModalOverview] = useState('');
  const [selectedAttribute, setSelectedAttribute] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [modalDescription, setSetModalDescription] = useState('');
  const [timeFrame, setTimeFrame] = useState('today');
  const [expandLookback, setExpandLookback] = useState(false);
  const [expandCrossDonations, setExpandCrossDonations] = useState(false);
  const [expandTopReasons, setExpandTopReasons] = useState(false);
  const [expandTopBioCenters, setExpandTopBioCenters] = useState(false);

  useEffect(() => {
    dispatch(fetchComplianceGetLookbackOverview(timeFrame));
  }, [timeFrame]);

  const handleLocationClick = (rowId, type, location) => {
    setSelectedLocId(rowId);
    setSelectedAttribute(type);
    setSelectedValue(location);
    setReasonOrLoc('location');
    setOpenModal(true);
  };

  const handleReasonsRowClick = (type, reason, description, overviewObject) => {
    setReasonOrLoc('reason');
    setSelectedAttribute(type);
    setSelectedValue(reason);
    setSetModalDescription(description);
    setModalOverview(overviewObject);
    setOpenModal(true);
  };

  const revivalBack = () => {
    window.onpopstate = () => undefined;
    window.history.back();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedLocId('');
    setSelectedAttribute('');
    setSelectedValue('');
    setReasonOrLoc('');
    setModalOverview('');
    revivalBack();
  };

  const overviewData = useSelector(state =>
    map(get(state, 'entities.overview.overview', {}), view => {
      const overview = [
        {
          title: 'Quarantine (L)',
          detail: numDecimalsCheck(view.quarantine, 3),
          secondaryDetails: [
            {
              label: 'Donations',
              detail: view.quarantine_donations,
            },
            {
              label: 'Est. gamma(g)',
              detail: view.quarantine_igg,
            },
          ],
        },
        {
          title: 'Lookback Submitted (L)',
          detail: numDecimalsCheck(view.lookback_submitted, 3),
          secondaryDetails: [
            {
              label: 'Donations',
              detail: view.submitted_donations,
            },
            {
              label: 'Est. gamma(g)',
              detail: view.submitted_igg,
            },
          ],
        },
        {
          title: 'Destroyed (L)',
          detail: numDecimalsCheck(view.destroyed, 3),
          secondaryDetails: [
            {
              label: 'Donations',
              detail: view.destroyed_donations,
            },
            {
              label: 'Est. gamma(g)',
              detail: view.destroyed_igg,
            },
          ],
        },
      ];
      overview.splice(1, 0, {
        title: 'Lookback Notification Received (L)',
        detail: numDecimalsCheck(view.lookback_confirmed, 3, 'L'),
        secondaryDetails: [
          {
            label: 'Donations',
            detail: view.confirmed_donations,
          },
          {
            label: 'Est. gamma(g)',
            detail: view.confirmed_igg,
          },
        ],
      });

      return { overview };
    })
  );

  const toggleLookback = () => {
    setExpandLookback(!expandLookback);
  };

  const toggleCrossDonation = () => {
    setExpandCrossDonations(!expandCrossDonations);
  };

  const toggleTopReasons = () => {
    setExpandTopReasons(!expandTopReasons);
  };

  const toggleTopBioCenters = () => {
    setExpandTopBioCenters(!expandTopBioCenters);
  };

  return (
    <Grid
      className={classes.quality}
      container
      direction="row"
      spacing={3}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <ViewInDevBanner />
      </Grid>
      <Grid item xs={12} sm={10}>
        <Typography className={classes.title} variant="h4" noWrap>
          Plasma in Quarantine and Lookback
        </Typography>
        <Typography variant="h6" className={classes.subtitle}>
          as of{' '}
          {Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric' }).format(
            new Date()
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={2}>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="timeframe-dropdown"
            id="timeframe-dropdown"
            className={classes.timeFrameDropdown}
            value={timeFrame}
            onChange={e => setTimeFrame(e.target.value)}
          >
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="30days">30 days</MenuItem>
            <MenuItem value="90days">90 days</MenuItem>
            <MenuItem value="365days">1 year</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <SummaryOverview data={overviewData[0] ? overviewData[0] : {}} showCounts loading={loading} />
      <Grid item xs={12}>
        <Accordion className={classes.accordion} expanded={expandLookback}>
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon />}
            IconButtonProps={{
              onClick: toggleLookback,
            }}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={toggleLookback}
          >
            <Typography className={classes.titleAccordion} variant="h6" noWrap>
              LOOKBACK DONATIONS & DESTROYED DONATIONS
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandLookback && <AllLookbackDestroyedUnits timeFrame={timeFrame} />}
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion className={classes.accordion} expanded={expandCrossDonations}>
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon />}
            IconButtonProps={{
              onClick: toggleCrossDonation,
            }}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={toggleCrossDonation}
          >
            <Typography className={classes.titleAccordion} variant="h6" noWrap>
              PTR/PDI & CROSS-DONATIONS LIST
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandCrossDonations && <CrossDonations timeFrame={timeFrame} />}
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion className={classes.accordion} expanded={expandTopReasons}>
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon />}
            IconButtonProps={{
              onClick: toggleTopReasons,
            }}
            aria-controls="panel2a-content"
            id="panel2a-header"
            onClick={toggleTopReasons}
          >
            <Typography className={classes.titleAccordion} variant="h6" noWrap>
              TOP LOOKBACK REASONS & DESTROYED REASONS
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.topLists}>
            {expandTopReasons && (
              <TopReasons handleReasonsRowClick={handleReasonsRowClick} timeFrame={timeFrame} />
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion className={classes.accordion} expanded={expandTopBioCenters}>
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon />}
            IconButtonProps={{
              onClick: toggleTopBioCenters,
            }}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={toggleTopBioCenters}
          >
            <Typography className={classes.titleAccordion} variant="h6" noWrap>
              TOP BIOLIFE CENTERS - QUARANTINE, LOOKBACKS, AND DESTROYED DONATIONS
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.topLists}>
            {expandTopBioCenters && (
              <TopBioCenters handleLocationClick={handleLocationClick} timeFrame={timeFrame} />
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>
      {reasonOrLoc && (
        <Modal open={openModal} handleClose={handleCloseModal}>
          <QualityModalDetails
            reasonOrLoc={reasonOrLoc}
            selectedLocId={selectedLocId}
            modalOverview={modalOverview}
            selectedAttribute={selectedAttribute}
            selectedValue={selectedValue}
            reasonDescription={modalDescription}
            timeFrame={timeFrame}
            handleClose={handleCloseModal}
          />
        </Modal>
      )}
    </Grid>
  );
}

export default Quality;
