import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal as MUIModal } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import { useOktaAuth } from '@okta/okta-react';
import useStyles from './ProfileCard.styles';

function ProfileCard({ open, handleClose }) {
  const classes = useStyles();
  const { authState, oktaAuth } = useOktaAuth();
  const [user, setUser] = useState({});

  useEffect(() => {
    if (!authState.isAuthenticated) {
      setUser(null);
    } else {
      oktaAuth.getUser().then(info => {
        setUser(info);
      });
    }
  }, [authState, oktaAuth]);

  const logout = async () => {
    sessionStorage.removeItem('OktaToken');
    oktaAuth.tokenManager.clear();
    await oktaAuth.signOut('/');
  };

  return (
    <MUIModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Card>
        <CardContent className={classes.content}>
          <div>
            <AccountCircle className={classes.icon} />
          </div>
          <div>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {user ? user.name : ''}
            </Typography>
            <Typography variant="body2" component="p">
              {user ? user.email : ''}
            </Typography>
          </div>
        </CardContent>
        <CardActions>
          <Button className={classes.button} onClick={logout}>
            Sign Out
          </Button>
        </CardActions>
      </Card>
    </MUIModal>
  );
}

ProfileCard.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ProfileCard;
