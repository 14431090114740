const descriptions = [
  {
    label: 'Event',
    description:
      'PlasmaChain tokens currently have three different blockchain event types: "Mint" refers to the creation of ERC-721 tokens, "Burn" refers to the destruction of ERC-721 tokens, and "Metadata Update" refers to the addition or change of specific attributes in the token json on-chain schema.',
  },
  {
    label: 'Status',
    description: 'The status of the transaction',
  },
  {
    label: 'Block',
    description:
      'The number of the block in which the transaction was recorded. Block confirmation indicate how many blocks since the transaction is mined.',
  },
  {
    label: 'Transaction Hash',
    description:
      'A TxHash or transaction hash is a unique 66 characters identifier that is generated whenever a transaction is executed',
  },
  {
    label: 'Contract Address',
    description:
      'Unique contract public key that is used to invoke a solution smart contract on the blockchain network',
  },
  {
    label: 'Timestamp',
    description: 'The date and time at which the transaction is mined',
  },
  {
    label: 'From',
    description: 'The sending party of the transaction (could be from a contract address).',
  },
  {
    label: 'To',
    description: 'The receiving party of the transaction (could be a contract address).',
  },
  {
    label: 'Token ID',
    description: 'Unique token on-chain identifier leveraged for traceability and composability features.',
  },
  {
    label: 'Transaction Fee',
    description: 'The amount paid to the miner for processing the transaction.',
  },
  {
    label: 'Gas Price',
    description:
      'Cost per unit of gas specified for the transaction, in Ether and Gwei. The higher the gas price the higher chance of getting included in a block.',
  },
  {
    label: 'Gas Limit',
    description:
      'Maximum amount of gas provided for the transaction. For normal Eth transfer, the value is 21,000. For contract this value is higher and bound by block gas limit.',
  },
  {
    label: 'Gas Used by Transaction',
    description: 'The exact units of gas that was used for the transaction.',
  },
  {
    label: 'Nonce',
    description:
      'Sequential running number for an address, beginning with 0 for the first transaction. For example, if the nonce of a transaction is 10, it would be the 11th transaction sent from the sender’s address.',
  },
  {
    label: 'Input Data',
    description: 'Additional information that is required for the transaction.',
  },
  {
    label: 'Metadata',
    description: 'List of fields that were updated.',
  },
  {
    label: 'Method',
    description: 'Type of transaction event.',
  },
];

const getDescription = label => {
  let result = 'Missing description';
  descriptions.forEach(element => {
    if (element.label === label) {
      result = element.description;
    }
  });
  return result;
};

export default getDescription;
