import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import { useOktaAuth } from '@okta/okta-react';
import useStyles from './NoAuth.styles';

function NoAuth() {
  const classes = useStyles();
  const { oktaAuth } = useOktaAuth();

  const logout = async () => {
    oktaAuth.tokenManager.clear();
    await oktaAuth.signOut('/');
  };

  return (
    <div className={classes.root}>
      <div className={classes.box}>
        <ChatBubbleOutlineIcon className={classes.icon} />
        <Typography className={classes.text1}>PlasmaChain access denied</Typography>
        <Typography className={classes.text2}>
          Please submit ticket via{' '}
          <a href="https://myaccess.onetakeda.com/" rel="noreferrer" target="_blank">
            My Access
          </a>
          <br />
          <Button variant="contained" color="primary" onClick={logout}>
            Sign Out
          </Button>
        </Typography>
      </div>
    </div>
  );
}

export default NoAuth;
