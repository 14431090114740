import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import useStyles from './InputOutput.styles';
import InputModalDetails from '../InputModalDetails/InputModalDetails';
import ModalTeardown from '../InputModalDetails/ModalTeardown/ModalTeardown';
import { TableModal } from '../../Table/TableLink';

function InputOutput({ selectedLot, lotData, activeStep, handleStep, lotDonations, lotTotals }) {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [openModalAs, setModalOpenAs] = useState('');
  const [selectedId, setSelectedId] = useState('');

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedId('');
  };

  const handleRowClick = (value, type) => {
    if (type === 'input' && activeStep === 0) {
      setOpenModal(true);
      setModalOpenAs('input bin');
      setSelectedId(value);
    } else if (type === 'input') {
      handleStep(activeStep - 1, value);
    } else if (type === 'output') {
      handleStep(activeStep + 1, value);
    }
  };

  const getInputDonations = lotInfo => {
    if (lotInfo) {
      return (
        <ListItem className={classes.listItem}>
          <ListItemText>{TableModal(lotInfo.lot, () => handleRowClick(lotInfo.lot, 'input'))}</ListItemText>
          <ListItemText edge="end" style={{ textAlign: 'right' }}>
            <Typography variant="subtitle2" style={{ fontWeight: '400' }}>
              {`${selectedLot.quantity_declared || '0.00'} Donations`}
            </Typography>
          </ListItemText>
        </ListItem>
      );
    }
    return null;
  };

  const getInputLots = selectedInputs => {
    if (selectedInputs) {
      return selectedInputs.map(input => (
        <ListItem className={classes.listItem}>
          <ListItemText>{TableModal(input.lot, () => handleRowClick(input.lot, 'input'))}</ListItemText>
          <ListItemText edge="end" style={{ textAlign: 'right' }}>
            <Typography variant="subtitle2" style={{ fontWeight: '400' }}>
              {`${input.quantity_declared || '0.00'} Donations`}
            </Typography>
          </ListItemText>
        </ListItem>
      ));
    }
    return null;
  };

  const getNoOutputsPanel = () => (
    <ListItem className={classes.listItem}>
      <ListItemText
        disableTypography
        primary={
          <Typography variant="subtitle2" className={classes.listItemTitle} style={{ fontWeight: '400' }}>
            No further output lots
          </Typography>
        }
      />
    </ListItem>
  );

  const getOutputLots = lotInfo =>
    lotInfo.map(lot =>
      lot.child.map(output => (
        <ListItem className={classes.listItem}>
          <ListItemText
            disableTypography
            primary={TableModal(
              <Typography variant="subtitle2" className={classes.listItemTitle} style={{ fontWeight: '400' }}>
                {output.lot}
              </Typography>,
              () => handleRowClick(output.lot, 'output')
            )}
            secondary={
              <Typography variant="subtitle2" className={classes.listItemDetail}>
                {output.description && output.description.replaceAll(',', ', ')}
              </Typography>
            }
          />
          <ListItemText edge="end" style={{ textAlign: 'right' }}>
            <Typography variant="subtitle2" style={{ fontWeight: '400' }}>
              Lot
            </Typography>
          </ListItemText>
        </ListItem>
      ))
    );

  const isObject = inData => {
    if (inData) {
      if (inData[0] === undefined) {
        return false;
      }
      const datatype = typeof inData;
      return datatype === 'object' && lotData[0].child;
    }
    return false;
  };

  return (
    <Grid className={classes.panels} container direction="row" spacing={3}>
      <Grid item xs={6} className={classes.inputPanel}>
        <Typography className={classes.panelTitle}>Inputs</Typography>
        <List style={{ paddingTop: '0px' }}>
          {activeStep === 0 ? getInputDonations(lotData[0]) : getInputLots(lotData)}
        </List>
      </Grid>
      <Grid item xs={6} className={classes.outputPanel}>
        <Typography className={classes.panelTitle}>Outputs</Typography>
        <Typography sx={{ mt: 2, mb: 1 }}>
          {isObject(lotData) ? getOutputLots(lotData) : getNoOutputsPanel()}
        </Typography>
      </Grid>
      {selectedId && (
        <ModalTeardown open={openModal} handleClose={handleCloseModal}>
          <InputModalDetails
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            openedAs={openModalAs}
            handleClose={handleCloseModal}
            donationsData={lotDonations}
            totalsData={lotTotals}
          />
        </ModalTeardown>
      )}
    </Grid>
  );
}

InputOutput.propTypes = {
  activeStep: PropTypes.number.isRequired,
  selectedLot: PropTypes.string.isRequired,
  lotData: PropTypes.shape({ lot: '' }).isRequired,
  handleStep: PropTypes.func.isRequired,
  lotDonations: PropTypes.arrayOf.isRequired,
  lotTotals: PropTypes.shape({}).isRequired,
};

export default InputOutput;
