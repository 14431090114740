import { RSAA } from 'redux-api-middleware';

export const FETCH_TRANSIT_GET_SHIPPED_BOL_REQUEST = 'FETCH_TRANSIT_GET_SHIPPED_BOL_REQUEST';
export const FETCH_TRANSIT_GET_SHIPPED_BOL_SUCCESS = 'FETCH_TRANSIT_GET_SHIPPED_BOL_SUCCESS';
export const FETCH_TRANSIT_GET_SHIPPED_BOL_FAILURE = 'FETCH_TRANSIT_GET_SHIPPED_BOL_FAILURE';

export const FETCH_TRANSIT_GET_SHIPPED_BOXES_REQUEST = 'FETCH_TRANSIT_GET_SHIPPED_BOXES_REQUEST';
export const FETCH_TRANSIT_GET_SHIPPED_BOXES_SUCCESS = 'FETCH_TRANSIT_GET_SHIPPED_BOXES_SUCCESS';
export const FETCH_TRANSIT_GET_SHIPPED_BOXES_FAILURE = 'FETCH_TRANSIT_GET_SHIPPED_BOXES_FAILURE';

export const FETCH_TRANSIT_GET_SHIPPED_BOL_CARRIER_CONSIGNEE_REQUEST =
  'FETCH_TRANSIT_GET_SHIPPED_BOL_CARRIER_CONSIGNEE_REQUEST';
export const FETCH_TRANSIT_GET_SHIPPED_BOL_CARRIER_CONSIGNEE_SUCCESS =
  'FETCH_TRANSIT_GET_SHIPPED_BOL_CARRIER_CONSIGNEE_SUCCESS';
export const FETCH_TRANSIT_GET_SHIPPED_BOL_CARRIER_CONSIGNEE_FAILURE =
  'FETCH_TRANSIT_GET_SHIPPED_BOL_CARRIER_CONSIGNEE_FAILURE';

export const fetchTransitGetShippedBOL = (limit, sortBy, orderBy) => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-transit-get-shipped-BOL?limit=${limit}&sort_by=${sortBy}&order_by=${orderBy}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_TRANSIT_GET_SHIPPED_BOL_REQUEST,
      {
        type: FETCH_TRANSIT_GET_SHIPPED_BOL_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          if (!json.data[0]) {
            return { entities: { data: { shipped: ['no entries'] } } };
          }
          return { entities: { shipped: json } };
        },
      },
      {
        type: FETCH_TRANSIT_GET_SHIPPED_BOL_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchTransitGetShippedBoxes = (limit, sortBy, orderBy) => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-transit-get-shipped-boxes?limit=${limit}&sort_by=${sortBy}&order_by=${orderBy}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_TRANSIT_GET_SHIPPED_BOXES_REQUEST,
      {
        type: FETCH_TRANSIT_GET_SHIPPED_BOXES_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          if (!json.data[0]) {
            return { entities: { data: { shippedBoxes: ['no entries'] } } };
          }
          return { entities: { shippedBoxes: json } };
        },
      },
      {
        type: FETCH_TRANSIT_GET_SHIPPED_BOXES_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchTransitGetShippedBOLsCarrierConsignee = () => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-transit-get-shipped-bols-carrier-consignee`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_TRANSIT_GET_SHIPPED_BOL_CARRIER_CONSIGNEE_REQUEST,
      {
        type: FETCH_TRANSIT_GET_SHIPPED_BOL_CARRIER_CONSIGNEE_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          if (!json.data[0]) {
            return { entities: { data: { shipped: ['no entries'] } } };
          }
          return { entities: { carriersConsignees: json } };
        },
      },
      {
        type: FETCH_TRANSIT_GET_SHIPPED_BOL_CARRIER_CONSIGNEE_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const TransitGetShippedBOLMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_TRANSIT_GET_SHIPPED_BOL_SUCCESS:
      console.log('fetch transit shipped BOL success');
      break;
    case FETCH_TRANSIT_GET_SHIPPED_BOL_FAILURE:
      console.log('fetch transit shipped BOL failure');
      break;
    default:
      break;
  }
};

export const TransitGetShippedBoxesMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_TRANSIT_GET_SHIPPED_BOXES_SUCCESS:
      console.log('fetch transit shipped boxes success');
      break;
    case FETCH_TRANSIT_GET_SHIPPED_BOXES_FAILURE:
      console.log('fetch transit shipped boxes failure');
      break;
    default:
      break;
  }
};

export const TransitGetShippedBOLsCarrierConsigneeMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_TRANSIT_GET_SHIPPED_BOL_CARRIER_CONSIGNEE_SUCCESS:
      console.log('fetch transit shipped BOL carriers and consignees success');
      break;
    case FETCH_TRANSIT_GET_SHIPPED_BOL_CARRIER_CONSIGNEE_FAILURE:
      console.log('fetch transit shipped BOL carriers and consignees failure');
      break;
    default:
      break;
  }
};
