import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: '20px',
    width: '100%',
  },
  field: {
    fontSize: '14px',
    color: '#9e9e9e',
  },
  value: {
    fontSize: '16px',
  },
  title: {
    fontSize: '18px',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  fieldValue: {
    height: '50px',
    marginTop: '10px',
  },
  accordion: {
    boxShadow: 'none',
    borderBottom: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: '#e0e0e0',
  },
  accordionSummary: {
    maxHeight: '60px',
  },
  rightAlignedHeader: {
    '& span': {
      '& button': {
        textAlign: 'right',
      },
    },
  },
}));

export default useStyles;
