import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 700,
    paddingBottom: theme.spacing(3),
  },
  overview: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  overviewSubtitle: {
    color: theme.palette.text.secondary,
    letterSpacing: '1.25px',
  },
  rightTableHeader: {
    '& > span': {
      justifyContent: 'flex-end',
    },
  },
  shortRightTableHeader: {
    width: '230px !important',
    '& > span': {
      justifyContent: 'flex-end',
      '& > *': {
        textAlign: 'right',
      },
    },
  },
  rightAlignedHeader: {
    '& span': {
      '& button': {
        textAlign: 'right',
      },
    },
  },
}));

export default useStyles;
