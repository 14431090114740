import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

function DataFieldHint({ children, title, placement }) {
  return (
    <>
      {children}
      <Tooltip title={title} placement={placement} arrow>
        <InfoOutlinedIcon className="infoicon" />
      </Tooltip>
    </>
  );
}

DataFieldHint.propTypes = {
  children: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  placement: PropTypes.string,
};

DataFieldHint.defaultProps = {
  children: {},
  placement: 'top',
};

export default DataFieldHint;
