import React, { useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';
import RankList from '../../components/RankList/RankList';
import useStyles from './Inventory.styles';
import Loader from '../../components/Loader/Loader';
import DataFieldHint from '../../components/DataFieldHint/DataFieldHint';
import {
  fetchInventoryGetOverviewTopLocations,
  fetchInventoryGetPlasmaByPhases,
  fetchInventoryGetVolumeIggTotals,
} from '../../redux/inventoryOverview';
import { numDecimalsCheck, pluralUnitCheck, useBackForwardCommands } from '../../utils';
import ViewInDevBanner from '../../components/ViewInDevBanner/ViewInDevBanner';
import tooltipHints from '../../tooltipHints';

const {
  total_plasma_volume: totalPlasmaVolumeHint,
  total_gamma_protein_estimate: totalGammaProteinEstimateHint,
} = tooltipHints;

function Inventory() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  useBackForwardCommands(history);

  const loading = useSelector(state => get(state, 'loading.loading', false));

  const phasesData = useSelector(state =>
    map(get(state, 'entities.phases.data', []), phase => ({
      phase: phase.phase,
      total_volume: numDecimalsCheck(phase.total_volume, 3, 'L'),
      total_igg: numDecimalsCheck(phase.total_igg, 2, 'g est. gamma'),
    }))
  );

  const totalsData = useSelector(state =>
    map(get(state, 'entities.totals.data', []), total => ({
      total_volume: numDecimalsCheck(total.total_volume, 3, 'L'),
      total_igg: numDecimalsCheck(total.total_igg, 2, 'g'),
    }))
  );

  const topLocationsLists = useSelector(state =>
    flatMap(get(state, 'entities.list', []), data => {
      const populate = (arr, center, id, stat, statUnit, subStat, subStatUnit) => {
        const array = [];
        if (arr) {
          arr.forEach(item => {
            let unit = `${item[stat]} ${statUnit}`;
            let subUnit = `${item[subStat]} ${subStatUnit}`;
            if (statUnit === 'L') {
              unit = numDecimalsCheck(item[stat], 3, statUnit);
            } else {
              unit = pluralUnitCheck(item[stat], statUnit);
            }
            if (subStatUnit === 'g est. gamma') {
              subUnit = numDecimalsCheck(item[subStat], 2, subStatUnit);
            } else {
              subUnit = pluralUnitCheck(item[subStat], subStatUnit);
            }
            array.push({
              title: item[center],
              url: `/collection/${item[id].toString()}/${item[center].toLowerCase()}`,
              stat: unit,
              subStat: subUnit,
            });
          });
        }
        return array;
      };

      if (data) {
        return [
          {
            Collected: populate(
              data[0] ? data[0].collected : [],
              'center_name',
              'location_id',
              'total_plasma_volume',
              'L',
              'total_est_igg',
              'g est. gamma'
            ),
          },
          {
            Boxed: populate(
              data[1] ? data[1].boxed : [],
              'center_name',
              'location_id',
              'total_box_count',
              'box',
              'total_donation_count',
              'donation'
            ),
          },
          {
            'Boxed with BOL': populate(
              data[2] ? data[2].boxed_with_bol : [],
              'center_name',
              'location_id',
              'total_bol_count',
              'BOL',
              'total_box_count',
              'box'
            ),
          },
        ];
      }
      return {};
    })
  );

  const date = new Date();
  const today = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

  useEffect(() => {
    dispatch(fetchInventoryGetOverviewTopLocations());
    dispatch(fetchInventoryGetPlasmaByPhases());
    dispatch(fetchInventoryGetVolumeIggTotals());
  }, []);

  // eslint-disable-next-line consistent-return
  const showTopFiveLists = () => {
    if (topLocationsLists[0]) {
      return topLocationsLists.map(list => {
        const key = Object.keys(list)[0];
        return (
          <Grid item xs={4} key={key}>
            <Typography className={classes.rankListTitle} variant="h6">
              {key}
            </Typography>
            <RankList list={list[key]} />
          </Grid>
        );
      });
    }
  };

  const getPhaseData = (phase, value) => {
    if (phasesData[0]) {
      for (let i = 0; i < 3; i += 1) {
        if (phasesData[i].phase === phase) {
          if (value === 'plasma') {
            return phasesData[i].total_volume;
          }
          return phasesData[i].total_igg;
        }
      }
    }
    return '-';
  };

  return (
    <Grid
      className={classes.inventory}
      container
      direction="row"
      spacing={3}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <ViewInDevBanner />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.inventoryTitles}>
          <div>
            <Typography variant="overline" className={classes.overviewSubtitle} noWrap>
              INVENTORY / OVERVIEW
            </Typography>
            <Typography className={classes.title} variant="h4" noWrap>
              Overview
            </Typography>
          </div>
          <div className={classes.viewDate}>
            <Typography variant="h6" noWrap>
              View date
            </Typography>
            <div className={classes.dateBox}>
              <Typography variant="h6" noWrap>
                {today}
              </Typography>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <Loader />
        ) : (
          <div className={classes.totals}>
            <div className={classes.totalVolume}>
              <DataFieldHint title={totalPlasmaVolumeHint}>
                <Typography variant="overline" className={classes.listItemTitle}>
                  TOTAL PLASMA VOLUME
                </Typography>
              </DataFieldHint>

              <Typography variant="h5" className={classes.listItemDetail}>
                {totalsData[0] ? totalsData[0].total_volume : <Loader />}
              </Typography>
            </div>
            <div className={classes.totalIgg}>
              <DataFieldHint title={totalGammaProteinEstimateHint}>
                <Typography variant="overline" className={classes.listItemTitle}>
                  TOTAL GAMMA PROTEIN ESTIMATE (PRE-DONATION)
                </Typography>
              </DataFieldHint>

              <Typography variant="h5" className={classes.listItemDetail}>
                {totalsData[0] ? totalsData[0].total_igg : <Loader />}
              </Typography>
            </div>
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.phasesTitle} variant="h4" noWrap>
          Plasma by Phases
        </Typography>
        {loading ? (
          <Loader />
        ) : (
          <div className={classes.panelsContainer}>
            <Link component={RouterLink} to={{ pathname: `/collection` }}>
              <div className={classes.panel}>
                <Typography variant="overline" className={classes.listItemTitle}>
                  In biolife donation centers
                </Typography>
                <Typography variant="h5" className={classes.listItemDetail}>
                  {getPhaseData('Collection', 'plasma')}
                </Typography>
                <Typography variant="subtitle2" className={classes.listItemDetailTwo}>
                  {getPhaseData('Collection', 'igg')}
                </Typography>
              </div>
            </Link>
            <Link component={RouterLink} to={{ pathname: `/transit` }}>
              <div className={classes.panel}>
                <Typography variant="overline" className={classes.listItemTitle}>
                  In Transit
                </Typography>
                <Typography variant="h5" className={classes.listItemDetail}>
                  {getPhaseData('Transit', 'plasma')}
                </Typography>
                <Typography variant="subtitle2" className={classes.listItemDetailTwo}>
                  {getPhaseData('Transit', 'igg')}
                </Typography>
              </div>
            </Link>
            <Link component={RouterLink} to={{ pathname: `/manufacturing` }}>
              <div className={classes.panel}>
                <Typography variant="overline" className={classes.listItemTitle}>
                  In manufacturing
                </Typography>
                <Typography variant="h5" className={classes.listItemDetail}>
                  {getPhaseData('Manufacturing', 'plasma')}
                </Typography>
                <Typography variant="subtitle2" className={classes.listItemDetailTwo}>
                  {getPhaseData('Manufacturing', 'igg')}
                </Typography>
              </div>
            </Link>
          </div>
        )}
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: '50px' }}>
        <Typography className={classes.title} variant="h5" noWrap>
          Top BioLife Donation Centers
        </Typography>
        <Grid container justifyContent="center" spacing={3} align="center">
          {loading ? <Loader /> : showTopFiveLists()}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Inventory;
