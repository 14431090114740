/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { map } from 'lodash';
import TabPanel from '../../../components/TabPanel/TabPanel';
import Table from '../../../components/Table/Table';
import TableSearch from '../../../components/TableSearch/TableSearch';
import useStyles from '../Quality.styles';
import {
  fetchComplianceGetLookbacksSubmitted,
  fetchComplianceGetDestroyedDonations,
} from '../../../redux/quality';
import {
  filterDateOptionsLogic,
  customFilterListOptionsRender,
  customFilterListOptionsUpdate,
  formatDate,
  numDecimalsCheck,
  withCommas,
  checkAndFormatIfDate,
  getFilterArray,
  customDateAlignRight,
  returnRowContainsStatus,
  sortDatesWithNullsLast,
  findFilter,
} from '../../../utils';
import {
  agingColumn,
  iggColumn,
  pdnColumn,
  tokenColumn,
  volumeColumn,
  pbnColumn,
} from '../../../components/Table/Columns';
import CustomRangeFilter from '../../../components/CustomRangeFilter/CustomRangeFilter';
import { TableLink } from '../../../components/Table/TableLink';

function AllLookbackDestroyedUnits({ timeFrame }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState('lookbacks');
  // Lookback units state / load
  const [lookbacksRetrieved, setLookbacksRetrieved] = useState(false);
  const [lookbackFilterData, setLookbackFilterData] = useState({});
  const [lookbackFiltersRetrieved, setLookbackFiltersRetrieved] = useState(false);
  const [lookbackPage, setLookbackPage] = useState(0);
  const [rowsPerLookbackPage, setRowsPerLookbackPage] = useState(50);
  const [filterOptionsLookbacks, setFilterOptionsLookbacks] = useState({});
  const [sortOptionsLookbacks, setSortOptionsLookbacks] = useState({ name: 'pbn', direction: 'desc' });
  const [searchTextLookbacks, setSearchTextLookbacks] = useState('');
  const loadingLookbacks = useSelector(state =>
    get(state, 'loading.FETCH_COMPLIANCE_GET_LOOKBACKS_SUBMITTED', false)
  );
  // Destroyed units state / load
  const [destroyedRetrieved, setDestroyedRetrieved] = useState(false);
  const [destroyedFilterData, setDestroyedFilterData] = useState({});
  const [destroyedFiltersRetrieved, setDestroyedFiltersRetrieved] = useState(false);
  const [destroyedPage, setDestroyedPage] = useState(0);
  const [rowsPerDestroyedPage, setRowsPerDestroyedPage] = useState(50);
  const [filterOptionsDestroyed, setFilterOptionsDestroyed] = useState({});
  const [sortOptionsDestroyed, setSortOptionsDestroyed] = useState({ name: 'pbn', direction: 'desc' });
  const [searchTextDestroyed, setSearchTextDestroyed] = useState('');
  const loadingDestroyed = useSelector(state =>
    get(state, 'loading.FETCH_COMPLIANCE_GET_DESTROYED_DONATIONS', false)
  );

  const tableLimit = 150;

  // LOOKBACK UNITS - Data
  const lookbackData = useSelector(state => {
    if (!get(state, 'entities.lookbackUnits.data', {})[0]) {
      return [];
    }
    return map(get(state, 'entities.lookbackUnits.data', {})[0].list, lb => ({
      total_count: Number(lb.total_count),
      total_queried_count: Number(lb.total_queried_count),
      pbn: lb.pbn || '-',
      pdn: lb.pdn || '-',
      location: lb.location || '-',
      status: lb.status || '-',
      token_id: lb.token_id || 'TBD',
      bleed_date: lb.bleed_date ? lb.bleed_date : 'N/A',
      center_number: lb.center_number || '-',
      volume: numDecimalsCheck(lb.volume, 3),
      est_igg: numDecimalsCheck(lb.est_igg, 2),
      lookback_report_type: lb.lookback_report_type || '-',
      lookback_reason: lb.lookback_reason || '-',
      description: lb.description || '-',
      lookback_confirmed: lb.lookback_confirmed || '-',
      measles_immune: lb.measles_immune === 'true' ? 'Yes' : 'No',
      aging: !Number.isNaN(lb.aging) ? `${withCommas(lb.aging)}` : '-',
      ctrlnum: lb.ctrlnum || '-',
      lookback_additionalinfo: lb.lookback_additionalinfo || '-',
      test_release_date: lb.test_release_date ? lb.test_release_date : 'N/A',
      test_result: lb.test_result ? lb.test_result : '-',
    }));
  });

  const lookbackFilters = useSelector(state => {
    if (lookbackFiltersRetrieved) {
      return lookbackFilterData;
    }
    if (!get(state, 'entities.lookbackUnits.data', {})[1]) {
      return [];
    }
    const allFilters = get(state, 'entities.lookbackUnits.data', {})[1].filters;
    if (allFilters) {
      const filtersObject = {
        lookback_descriptions: findFilter(allFilters, 'lookback_descriptions'),
        lookback_confirmed: findFilter(allFilters, 'lookback_confirmed'),
        lookback_reasons: findFilter(allFilters, 'lookback_reasons'),
        lookback_report_types: findFilter(allFilters, 'lookback_report_types'),
        min_bleed_date: formatDate(findFilter(allFilters, 'bleed_date'), 'min'),
        max_bleed_date: formatDate(findFilter(allFilters, 'bleed_date', 'max')),
        locations: findFilter(allFilters, 'locations'),
        measles_immune: findFilter(allFilters, 'measles_immune'),
        status: findFilter(allFilters, 'status'),
        min_test_release_date: formatDate(findFilter(allFilters, 'test_release_date', 'min')),
        max_test_release_date: formatDate(findFilter(allFilters, 'test_release_date', 'max')),
        test_result: findFilter(allFilters, 'test_result'),
      };
      setLookbackFilterData(filtersObject);
      setLookbackFiltersRetrieved(true);
      return filtersObject;
    }
    return [];
  });

  // DESTROYED UNITS - Data
  const destroyedData = useSelector(state => {
    if (!get(state, 'entities.destroyedUnits.data', {})[0]) {
      return [];
    }
    return map(get(state, 'entities.destroyedUnits.data', {})[0].list, dd => ({
      total_count: Number(dd.total_count),
      total_queried_count: Number(dd.total_queried_count),
      pbn: dd.pbn || '-',
      pdn: dd.pdn || '-',
      location: dd.location || '-',
      status: dd.status || '-',
      token_id: dd.token_id || 'TBD',
      center_number: dd.center_number || '-',
      volume: numDecimalsCheck(dd.volume, 3),
      est_igg: numDecimalsCheck(dd.est_igg, 2),
      bleed_date: dd.bleed_date ? dd.bleed_date : 'N/A',
      destroyed_reason: dd.destroyed_reason || '-',
      aging: !Number.isNaN(dd.aging) ? `${withCommas(dd.aging)}` : '-',
      lookback_submitted: dd.lookback_submitted ? dd.lookback_submitted : 'N/A',
      test_release_date: dd.test_release_date ? dd.test_release_date : 'N/A',
    }));
  });

  const destroyedFilters = useSelector(state => {
    if (destroyedFiltersRetrieved) {
      return destroyedFilterData;
    }
    if (!get(state, 'entities.destroyedUnits.data', {})[1]) {
      return [];
    }
    const allFilters = get(state, 'entities.destroyedUnits.data', {})[1].filters;
    if (allFilters) {
      const filtersObject = {
        locations: findFilter(allFilters, 'locations'),
        status: findFilter(allFilters, 'status'),
        destroyed_reasons: findFilter(allFilters, 'destroyed_reasons'),
        min_test_release_date: formatDate(findFilter(allFilters, 'test_release_date', 'min')),
        max_test_release_date: formatDate(findFilter(allFilters, 'test_release_date', 'max')),
        min_bleed_date: formatDate(findFilter(allFilters, 'bleed_date', 'min')),
        max_bleed_date: formatDate(findFilter(allFilters, 'bleed_date', 'max')),
      };
      setDestroyedFilterData(filtersObject);
      setDestroyedFiltersRetrieved(true);
      return filtersObject;
    }
    return [];
  });

  // LOOKBACK UNITS - Columns
  const lookbackColumns = [
    pbnColumn(pbn => {
      const getLocationId = () => {
        let id = 0;
        lookbackData.every(row => {
          if (row.pbn === pbn) {
            id = row.center_number;
            return false;
          }
          return true;
        });
        return id;
      };
      return TableLink(pbn, `/collection/${getLocationId()}/donation/${pbn}`);
    }),
    pdnColumn,
    {
      name: 'location',
      label: 'Location',
      options: {
        filter: true,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Location: ${v}`,
        },
        filterOptions: {
          logic: (location, filters) => returnRowContainsStatus(location, filters),
          names: lookbackFilters.locations,
        },
        sort: true,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Status: ${v}`,
        },
        filterOptions: {
          logic: (status, filters) => returnRowContainsStatus(status, filters),
          names: lookbackFilters.status,
        },
        sort: true,
      },
    },
    tokenColumn({
      className: classes.leftAlignedHeader,
    }),
    {
      name: 'bleed_date',
      label: 'Bleed Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customDateAlignRight,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => customFilterListOptionsRender(v, 'Bleed Date'),
          update: (filterList, filterPos, index) =>
            customFilterListOptionsUpdate(filterList, filterPos, index),
        },
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
        filterOptions: {
          names: [],
          logic(val, filters) {
            return filters.length ? filterDateOptionsLogic(val, filters) : false;
          },
          display: (filterList, onChange, index, column) => (
            <CustomRangeFilter
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
              label="Bleed Date"
              type="date"
              list={{ min_date: lookbackFilters.min_bleed_date, max_date: lookbackFilters.max_bleed_date }}
            />
          ),
        },
      },
    },
    {
      name: 'test_release_date',
      label: 'Test Release Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customDateAlignRight,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => customFilterListOptionsRender(v, 'Test Release Date'),
          update: (filterList, filterPos, index) =>
            customFilterListOptionsUpdate(filterList, filterPos, index),
        },
        filterOptions: {
          names: [],
          logic(val, filters) {
            return filters.length ? filterDateOptionsLogic(val, filters) : false;
          },
          display: (filterList, onChange, index, column) => (
            <CustomRangeFilter
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
              label="Test Release Date"
              type="date"
              list={{
                min_date: lookbackFilters.min_test_release_date,
                max_date: lookbackFilters.max_test_release_date,
              }}
            />
          ),
        },
      },
    },
    volumeColumn({
      className: classes.rightAlignedHeader,
    }),
    iggColumn({
      className: classes.rightAlignedHeader,
    }),
    {
      name: 'lookback_report_type',
      label: 'Lookback Report Type',
      options: {
        filter: true,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Report Type: ${v}`,
        },
        filterOptions: {
          logic: (reportType, filters) => returnRowContainsStatus(reportType, filters),
          names: lookbackFilters.lookback_report_types,
        },
        setCellHeaderProps: () => ({
          className: classes.leftAlignedHeader,
        }),
        sort: true,
      },
    },
    {
      name: 'ctrlnum',
      label: 'Control Number',
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({
          className: classes.leftAlignedHeader,
        }),
      },
    },
    {
      name: 'test_result',
      label: 'Test Result',
      options: {
        filter: true,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Test Result: ${v}`,
        },
        setCellHeaderProps: () => ({
          className: classes.leftAlignedHeader,
        }),
        filterOptions: {
          logic: (location, filters) => returnRowContainsStatus(location, filters),
          names: lookbackFilters.test_result,
        },
        sort: true,
      },
    },
    {
      name: 'lookback_reason',
      label: 'Lookback Reason',
      options: {
        filter: true,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Lookback Reason: ${v}`,
        },
        filterOptions: {
          logic: (reason, filters) => returnRowContainsStatus(reason, filters),
          names: lookbackFilters.lookback_reasons,
        },
        setCellHeaderProps: () => ({
          className: classes.leftAlignedHeader,
        }),
        sort: true,
      },
    },
    {
      name: 'description',
      label: 'Lookback Description',
      options: {
        filter: true,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Description: ${v}`,
        },
        filterOptions: {
          logic: (description, filters) => returnRowContainsStatus(description, filters),
          names: lookbackFilters.lookback_descriptions,
        },
        setCellHeaderProps: () => ({
          className: classes.leftAlignedHeader,
        }),
        sort: true,
      },
    },
    {
      name: 'lookback_additionalinfo',
      label: 'Lookback Additional Information',
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({
          className: classes.leftAlignedHeader,
        }),
      },
    },
    {
      name: 'lookback_confirmed',
      label: 'Lookback Notification Received? (Y/N)',
      options: {
        filter: true,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Confirmed: ${v}`,
        },
        filterOptions: {
          logic: (confirmed, filters) => returnRowContainsStatus(confirmed, filters),
          names: lookbackFilters.lookback_confirmed,
        },
        setCellHeaderProps: () => ({
          className: classes.leftAlignedHeader,
        }),
        sort: true,
      },
    },
    {
      name: 'measles_immune',
      label: 'Measles Hyperimmune',
      options: {
        filter: true,
        filterType: 'dropdown',
        customFilterListOptions: {
          render: v => `Measles Hyperimmune? (Y/N): ${v}`,
        },
        filterOptions: {
          logic: (measles, filters) => returnRowContainsStatus(measles, filters),
          names: ['Yes', 'No'],
        },
        setCellHeaderProps: () => ({
          className: classes.leftAlignedHeader,
        }),
        sort: true,
      },
    },
    agingColumn({
      className: classes.rightAlignedHeader,
    }),
  ];

  // DESTROYED UNITS - Columns
  const destroyedColumns = [
    pbnColumn(pbn => {
      const getLocationId = () => {
        let id = 0;
        destroyedData.every(row => {
          if (row.pbn === pbn) {
            id = row.center_number;
            return false;
          }
          return true;
        });
        return id;
      };
      return TableLink(pbn, `/collection/${getLocationId()}/donation/${pbn}`);
    }),
    pdnColumn,
    {
      name: 'location',
      label: 'Location',
      options: {
        filter: true,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Location: ${v}`,
        },
        filterOptions: {
          logic: (location, filters) => returnRowContainsStatus(location, filters),
          names: destroyedFilters.locations,
        },
        sort: true,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        filterType: 'multiselect',
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
        customFilterListOptions: {
          render: v => `Status: ${v}`,
        },
        filterOptions: {
          logic: (status, filters) => returnRowContainsStatus(status, filters),
          names: destroyedFilters.status,
        },
        sort: true,
      },
    },
    tokenColumn({
      className: classes.leftAlignedHeader,
    }),
    volumeColumn({
      className: classes.rightAlignedHeader,
    }),
    iggColumn({
      className: classes.rightAlignedHeader,
    }),
    {
      name: 'bleed_date',
      label: 'Bleed Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customDateAlignRight,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => customFilterListOptionsRender(v, 'Bleed Date'),
          update: (filterList, filterPos, index) =>
            customFilterListOptionsUpdate(filterList, filterPos, index),
        },
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
        filterOptions: {
          names: [],
          logic(val, filters) {
            return filters.length ? filterDateOptionsLogic(val, filters) : false;
          },
          display: (filterList, onChange, index, column) => (
            <CustomRangeFilter
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
              label="Bleed Date"
              type="date"
              list={{ min_date: destroyedFilters.min_bleed_date, max_date: destroyedFilters.max_bleed_date }}
            />
          ),
        },
      },
    },
    {
      name: 'test_release_date',
      label: 'Test Release Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customDateAlignRight,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => customFilterListOptionsRender(v, 'Test Release Date'),
          update: (filterList, filterPos, index) =>
            customFilterListOptionsUpdate(filterList, filterPos, index),
        },
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
        filterOptions: {
          names: [],
          logic(val, filters) {
            return filters.length ? filterDateOptionsLogic(val, filters) : false;
          },
          display: (filterList, onChange, index, column) => (
            <CustomRangeFilter
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
              label="Test Release Date"
              type="date"
              list={{
                min_date: destroyedFilters.min_test_release_date,
                max_date: destroyedFilters.max_test_release_date,
              }}
            />
          ),
        },
        // Custom sorting that sorts null/empty/NA values to the end
        sortCompare: sortDatesWithNullsLast,
      },
    },
    {
      name: 'destroyed_reason',
      label: 'Destroyed Reason',
      options: {
        filter: true,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Destroyed Reason: ${v}`,
        },
        filterOptions: {
          logic: (reason, filters) => returnRowContainsStatus(reason, filters),
          names: destroyedFilters.destroyed_reasons,
        },
        setCellHeaderProps: () => ({
          className: classes.leftAlignedHeader,
        }),
        sort: true,
      },
    },
    agingColumn({
      className: classes.rightAlignedHeader,
    }),
    {
      name: 'lookback_submitted',
      label: 'Lookback Submitted',
      options: {
        filter: true,
        filterType: 'dropdown',
        customFilterListOptions: {
          render: v => `Destroyed Reason: ${v}`,
        },
        filterOptions: {
          logic: (reason, filters) => returnRowContainsStatus(reason, filters),
          names: ['Submitted', 'Not Submitted'],
        },
        sort: true,
      },
    },
  ];

  // Initial fetch for Lookback and Destroyed units
  useEffect(() => {
    batch(() => {
      if (!lookbacksRetrieved) {
        dispatch(
          fetchComplianceGetLookbacksSubmitted(
            timeFrame,
            tableLimit,
            sortOptionsLookbacks.name,
            sortOptionsLookbacks.direction,
            lookbackFiltersRetrieved,
            checkAndFormatIfDate(searchTextLookbacks)
          )
        );
        setLookbacksRetrieved(true);
      }
      if (!destroyedRetrieved) {
        dispatch(
          fetchComplianceGetDestroyedDonations(
            timeFrame,
            tableLimit,
            sortOptionsDestroyed.name,
            sortOptionsDestroyed.direction,
            destroyedFiltersRetrieved,
            checkAndFormatIfDate(searchTextDestroyed)
          )
        );
        setDestroyedRetrieved(true);
      }
    });
  }, [dispatch]);

  // LOOKBACK UNITS - USEEFFECT FOR PAGINATION QUERIES - Changes to Rows, Sort, Search
  /* If the total lookbacks are greater than the current shown
    Fetch the lookbacks each time the user changes table status */
  const limitLookbacks = (lookbackPage + 3) * rowsPerLookbackPage;
  const lookbackDateColumns = ['bleed_date', 'test_release_date'];
  /* if filters are added, parse and add to query */
  const filterLookbackQuery = getFilterArray(lookbackColumns, lookbackDateColumns, filterOptionsLookbacks);

  // useEffect for lookback pagination -- query changes
  useEffect(() => {
    if (lookbacksRetrieved) {
      dispatch(
        fetchComplianceGetLookbacksSubmitted(
          timeFrame,
          limitLookbacks,
          sortOptionsLookbacks.name,
          sortOptionsLookbacks.direction,
          lookbackFiltersRetrieved,
          checkAndFormatIfDate(searchTextLookbacks === null ? '' : searchTextLookbacks),
          filterLookbackQuery.join('')
        )
      );
      setLookbackPage(0);
    }
  }, [timeFrame, filterOptionsLookbacks, sortOptionsLookbacks, searchTextLookbacks, dispatch]);

  // LOOKBACK CHANGES: ROWS PER PAGE
  useEffect(() => {
    if (lookbacksRetrieved) {
      setLookbackPage(0);
    }
  }, [rowsPerLookbackPage]);

  // LOOKBACK UNITS - USEEFFECT FOR PAGINATION QUERIES - Changes to Page Count
  // Sets the previous page count before current page count state change
  const previousLookbackPage = useRef(lookbackPage);
  useEffect(() => {
    // Check for whether there is a page count change
    // If current page count > previous page count, fetch for more data based on current page count
    if (lookbacksRetrieved) {
      if (lookbackPage > previousLookbackPage.current) {
        previousLookbackPage.current = lookbackPage;
        dispatch(
          fetchComplianceGetLookbacksSubmitted(
            timeFrame,
            limitLookbacks,
            sortOptionsLookbacks.name,
            sortOptionsLookbacks.direction,
            lookbackFiltersRetrieved,
            checkAndFormatIfDate(searchTextLookbacks === null ? '' : searchTextLookbacks),
            filterLookbackQuery.join('')
          )
        );
        // If current page count < previous page count don't fetch for additional data
      } else if (lookbackPage < previousLookbackPage.current) {
        previousLookbackPage.current = lookbackPage;
      }
    }
  }, [lookbackPage]);

  // DESTROYED UNITS - PAGINATION QUERIES
  /* If the total lookbacks are greater than the current shown
    Fetch the lookbacks each time the user changes table status */
  const limitDestroyed = (destroyedPage + 3) * rowsPerDestroyedPage;
  const destroyedDateColumns = ['bleed_date', 'test_release_date'];
  // Filter query for destroyed units
  const filterQueryDestroyed = getFilterArray(destroyedColumns, destroyedDateColumns, filterOptionsDestroyed);

  // DESTROYED CHANGES: SORT,  FILTER, SEARCH
  useEffect(() => {
    if (destroyedRetrieved) {
      dispatch(
        fetchComplianceGetDestroyedDonations(
          timeFrame,
          limitDestroyed,
          sortOptionsDestroyed.name,
          sortOptionsDestroyed.direction,
          destroyedFiltersRetrieved,
          checkAndFormatIfDate(searchTextDestroyed === null ? '' : searchTextDestroyed),
          filterQueryDestroyed.join('')
        )
      );
      setDestroyedPage(0);
    }
  }, [filterOptionsDestroyed, searchTextDestroyed, sortOptionsDestroyed, dispatch]);

  // DESTROYED CHANGES: ROWS PER PAGE
  useEffect(() => {
    if (destroyedRetrieved) {
      setDestroyedPage(0);
    }
  }, [rowsPerDestroyedPage]);

  // DESTROYED CHANGES: PAGE CHANGE
  const previousDestroyedPage = useRef(destroyedPage);
  useEffect(() => {
    // If current page count > previous page count, fetch for more data based on current page count
    if (destroyedPage > previousDestroyedPage.current) {
      previousDestroyedPage.current = destroyedPage;
      dispatch(
        fetchComplianceGetDestroyedDonations(
          timeFrame,
          limitDestroyed,
          sortOptionsDestroyed.name,
          sortOptionsDestroyed.direction,
          destroyedFiltersRetrieved,
          checkAndFormatIfDate(searchTextDestroyed === null ? '' : searchTextDestroyed),
          filterQueryDestroyed.join('')
        )
      );
      // If current page count < previous page count don't fetch for additional data
    } else if (destroyedPage < previousDestroyedPage.current) {
      previousDestroyedPage.current = destroyedPage;
    }
  }, [destroyedPage]);

  // Handle tab changes - Fetch data if not fetched already
  const handleChange = (e, value) => {
    if (value === 'lookbacks') {
      if (!lookbacksRetrieved) {
        setLookbacksRetrieved(true);
        dispatch(
          fetchComplianceGetLookbacksSubmitted(
            timeFrame,
            tableLimit,
            sortOptionsLookbacks.name,
            sortOptionsLookbacks.direction,
            lookbackFiltersRetrieved
          )
        );
      }
    }
    if (value === 'destroyed') {
      if (!destroyedRetrieved) {
        setDestroyedRetrieved(true);
        dispatch(
          fetchComplianceGetDestroyedDonations(
            timeFrame,
            tableLimit,
            sortOptionsDestroyed.name,
            sortOptionsDestroyed.direction,
            destroyedFiltersRetrieved
          )
        );
      }
    }
    setSelectedTab(value);
  };

  return (
    <Grid item xs={12}>
      <div className={classes.accordionDescription}>
        <Typography className={classes.title} variant="subtitle2">
          All lookback units and destroyed units, along with respective lookback reasons, control codes, and
          notification receipt confirmations from the warehouse sites. Data displayed is for the time interval
          selected above.
        </Typography>
      </div>
      <Tabs
        classes={{
          root: classes.navBar,
          flexContainer: classes.flexContainer,
          indicator: classes.sectionIndicator,
        }}
        value={selectedTab}
        onChange={handleChange}
      >
        <Tab className={classes.tabLink} value="lookbacks" label="Lookback Units" />
        <Tab className={classes.tabLink} value="destroyed" label="Destroyed Units" />
      </Tabs>
      <TabPanel value={selectedTab} index="lookbacks">
        <Table
          title="Lookback Units"
          columns={lookbackColumns}
          data={lookbackData}
          sortOptions={sortOptionsLookbacks}
          setSortOptions={setSortOptionsLookbacks}
          rowsPerPage={rowsPerLookbackPage}
          setRowsPerPage={setRowsPerLookbackPage}
          isLoading={loadingLookbacks}
          page={lookbackPage}
          setPage={setLookbackPage}
          setFilterOptions={setFilterOptionsLookbacks}
          options={{
            onSearchChange: text => setSearchTextLookbacks(text),
            customSearchRender: (_searchText, handleSearch, onHide) => (
              // handleSearch is mui datatables default function
              // we utilize it to handle searches that don't require the api
              // takes a parameter which is the search query
              <TableSearch
                options={{ searchPlaceholder: searchTextLookbacks }}
                setCustomSearchText={setSearchTextLookbacks}
                onSearch={handleSearch}
                onHide={() => {
                  onHide();
                  setSearchTextLookbacks('');
                }}
              />
            ),
          }}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index="destroyed">
        <Table
          title="Destroyed Units"
          columns={destroyedColumns}
          data={destroyedData}
          sortOptions={sortOptionsDestroyed}
          setSortOptions={setSortOptionsDestroyed}
          rowsPerPage={rowsPerDestroyedPage}
          setRowsPerPage={setRowsPerDestroyedPage}
          isLoading={loadingDestroyed}
          page={destroyedPage}
          setPage={setDestroyedPage}
          setFilterOptions={setFilterOptionsDestroyed}
          options={{
            onSearchChange: text => setSearchTextDestroyed(text),
            customSearchRender: (_searchText, handleSearch, onHide) => (
              // handleSearch is mui datatables default function
              // we utilize it to handle searches that don't require the api
              // takes a parameter which is the search query
              <TableSearch
                options={{ searchPlaceholder: searchTextDestroyed }}
                setCustomSearchText={setSearchTextDestroyed}
                onSearch={handleSearch}
                onHide={() => {
                  onHide();
                  setSearchTextDestroyed('');
                }}
              />
            ),
          }}
        />
      </TabPanel>
    </Grid>
  );
}

AllLookbackDestroyedUnits.propTypes = {
  timeFrame: PropTypes.string.isRequired,
};

export default AllLookbackDestroyedUnits;
