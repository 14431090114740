import React from 'react';
import PropTypes from 'prop-types';
import { Modal as MUIModal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import useStyles from './Modal.styles';

function Modal({ open, handleClose, children }) {
  const classes = useStyles();

  const resetValues = () => {
    handleClose();
  };

  return (
    <MUIModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          handleClose();
        }
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.modalRow1}>
            <IconButton aria-label="close" className={classes.buttonCloseModal} onClick={resetValues}>
              Close
              <CloseIcon />
            </IconButton>
          </div>
          <div>{children}</div>
        </div>
      </Fade>
    </MUIModal>
  );
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Modal;
