import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  boxDetailsContainer: {
    paddingTop: theme.spacing(3),
  },
  boxOverviewContainer: {
    backgroundColor: '#f1f1f1',
    margin: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  boxOverview: {
    margin: `0 auto ${theme.spacing(1)}px`,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(3),
    position: 'relative',
  },
  boxSubtitle: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
  boxTitle: {
    paddingLeft: theme.spacing(4),
  },
  horizontalList: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    paddingBottom: theme.spacing(2),
  },
  boxRowTitle: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.text.secondary,
    letterSpacing: '1.25px',
  },
  listItem: {
    paddingTop: '0',
  },
  listItemTitle: {
    letterSpacing: '1.25px',
  },
  listItemDetail: {
    fontWeight: '500',
    color: theme.palette.text.secondary,
  },
  modalDetailsTable: {
    padding: `0px ${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  rightAlignedHeader: {
    '& span': {
      '& button': {
        textAlign: 'right',
      },
    },
  },
  leftAlignedHeader: {
    '& span': {
      '& button': {
        textAlign: 'left',
      },
    },
  },
}));

export default useStyles;
