import React from 'react';
import PropTypes from 'prop-types';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import useStyles from './CustomeRangeFilter.styles';

function isValidDate(d) {
  return d instanceof Date && !Number.isNaN(d);
}

function isValidDateString(d) {
  return (
    moment(d, 'MM/DD/YYYY', true).isValid() ||
    moment(d, 'MM/D/YYYY', true).isValid() ||
    moment(d, 'M/D/YYYY', true).isValid()
  );
}

function CustomRangeFilter({ filterList, onChange, index, column, label, type, list }) {
  const classes = useStyles();
  let minDate = '-';
  let maxDate = '-';
  if (list) {
    minDate = list.min_date;
    maxDate = list.max_date;
  }

  const getInitialDate = i => {
    if (i === 0) {
      if (!filterList[index][0]) {
        return new Date(minDate).toUTCString();
      }
      return new Date(filterList[index][0]).toUTCString();
    }
    if (!filterList[index][1]) {
      return new Date(maxDate).toUTCString();
    }
    return new Date(filterList[index][1]).toUTCString();
  };

  const [selectedMinDate, setSelectedMinDate] = React.useState(getInitialDate(0));
  const [selectedMaxDate, setSelectedMaxDate] = React.useState(getInitialDate(1));

  const handleMinDateChange = date => {
    if (date && isValidDate(date)) {
      setSelectedMinDate(date);
      const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      const updatedFilter = filterList;
      updatedFilter[index][0] = formattedDate;
      onChange(updatedFilter[index], index, column);
    }
  };
  const handleMaxDateChange = date => {
    if (date && isValidDate(date)) {
      setSelectedMaxDate(date);
      const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      const updatedFilter = filterList;
      updatedFilter[index][1] = formattedDate;
      onChange(updatedFilter[index], index, column);
    }
  };
  return (
    <div>
      <FormLabel style={{ fontSize: '14px', fontWeight: '500' }}>{label}</FormLabel>
      {type === 'date' ? (
        <div className={classes.dropdownContainer}>
          <div className={classes.dateContainer}>
            {isValidDateString(minDate) ? (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label={`Min (${minDate})`}
                  value={selectedMinDate}
                  onChange={handleMinDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            ) : (
              <TextField label="Invalid Date" value="Invalid Date" disabled />
            )}
          </div>
          <div className={classes.dateContainer}>
            {isValidDateString(minDate) ? (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label={`Max (${maxDate})`}
                  value={selectedMaxDate}
                  onChange={handleMaxDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            ) : (
              <TextField label="Invalid Date" value="Invalid Date" disabled />
            )}
          </div>
        </div>
      ) : (
        <FormGroup row>
          <TextField
            label="min"
            value={filterList[index][0] || ''}
            onChange={event => {
              // eslint-disable-next-line no-param-reassign
              filterList[index][0] = event.target.value;
              onChange(filterList[index], index, column);
            }}
            style={{ width: '45%', marginRight: '5%' }}
          />
          <TextField
            label="max"
            value={filterList[index][1] || ''}
            onChange={event => {
              // eslint-disable-next-line no-param-reassign
              filterList[index][1] = event.target.value;
              onChange(filterList[index], index, column);
            }}
            style={{ width: '45%' }}
          />
        </FormGroup>
      )}
    </div>
  );
}

CustomRangeFilter.propTypes = {
  filterList: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  column: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  list: PropTypes.shape({
    min_date: PropTypes.string,
    max_date: PropTypes.string,
  }),
};

CustomRangeFilter.defaultProps = {
  list: {},
  type: 'textField',
};

export default CustomRangeFilter;
