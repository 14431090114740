import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useStyles from './Navigation.styles';

function Navigation() {
  const classes = useStyles();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(false);
  const url = () => {
    if (location.pathname === '/') {
      return 'inventory';
    }
    if (location.pathname.includes('inventory')) {
      return 'inventory';
    }
    if (location.pathname.includes('collection')) {
      return 'inventory';
    }
    if (location.pathname.includes('transit')) {
      return 'inventory';
    }
    if (location.pathname.includes('manufacturing')) {
      return 'inventory';
    }
    if (location.pathname.includes('reconciliation')) {
      return 'reconciliation';
    }
    if (location.pathname.includes('quality')) {
      return 'quality';
    }
    return '';
  };
  const [selected, setSelected] = useState('inventory');
  const handleToggle = event => {
    setAnchorEl(anchorEl ? false : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setSelected(url);
  }, [location]);

  const handleChange = (e, value) => {
    setSelected(value);
  };

  return (
    <>
      <Tabs
        classes={{
          root: classes.navBar,
          indicator: classes.navIndicator,
        }}
        value={selected}
        onChange={handleChange}
      >
        <Tab
          component="button"
          className={classes.navLink}
          classes={{
            wrapper: classes.iconLabelWrapper,
          }}
          aria-controls="customized-menu"
          aria-haspopup="true"
          style={{ minHeight: '1px' }}
          onClick={handleToggle}
          value="inventory"
          label="Inventory"
          icon={
            anchorEl ? (
              <ExpandLessOutlinedIcon style={{ marginBottom: '0px' }} />
            ) : (
              <ExpandMoreOutlinedIcon style={{ marginBottom: '0px' }} />
            )
          }
        />
        <Tab
          component={RouterLink}
          to="/quality"
          className={classes.navLink}
          value="quality"
          label="Quality &amp; Compliance"
        />
        <Tab
          component={RouterLink}
          to="/reconciliation"
          className={classes.navLink}
          value="reconciliation"
          label="Reconciliation"
        />
      </Tabs>
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.dropDownNav }}
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        id="menu-list-grow"
      >
        <MenuItem
          onClick={handleClose}
          component={RouterLink}
          className={classes.navLinkListItem}
          to="/"
          value="inventory"
        >
          OVERVIEW
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={RouterLink}
          className={classes.navLinkListItem}
          to="/collection"
          value="inventory"
        >
          COLLECTION
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={RouterLink}
          className={classes.navLinkListItem}
          to="/transit"
          value="inventory"
        >
          IN TRANSIT
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={RouterLink}
          className={classes.navLinkListItem}
          to="/manufacturing"
          value="inventory"
        >
          MANUFACTURING
        </MenuItem>
      </Menu>
    </>
  );
}

Navigation.propTypes = {};

export default Navigation;
