/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import map from 'lodash/map';
import get from 'lodash/get';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LogNavigation from '../../components/DonationInfo/LogNavigation/LogNavigation';
import SummaryOverview from '../../components/SummaryOverview/SummaryOverview';
import ViewInDevBanner from '../../components/ViewInDevBanner/ViewInDevBanner';
import DataFieldHint from '../../components/DataFieldHint/DataFieldHint';
import { fetchCollectionGetDonationOverview } from '../../redux/overview';
import { fetchCollectionGetLocationName } from '../../redux/locations';
import { fetchCollectionGetDonationDetails } from '../../redux/donationEvents';
import Loader from '../../components/Loader/Loader';
import { pluralUnitCheck, numDecimalsCheck } from '../../utils';
import useStyles from './Donation.styles';
import tooltipHints from '../../tooltipHints';

const { pbn: pbnHint, pdn: pdnHint } = tooltipHints;

function Donation() {
  const classes = useStyles();
  const { id, donation } = useParams();
  const dispatch = useDispatch();
  const loadingOverview = useSelector(state =>
    get(state, 'loading.FETCH_COLLECTION_GET_DONATION_OVERVIEW', true)
  );
  const loadingName = useSelector(state => get(state, 'loading.FETCH_COLLECTION_GET_LOCATION_NAME', true));

  const events = useSelector(state => Object.values(state.entities.events || {}));
  const overviewData = useSelector(state =>
    map(get(state, 'entities.overview.overview', {}), overview => ({
      overview: [
        { title: 'Volume', detail: numDecimalsCheck(overview['plasma-volume'], 3, 'L') },
        {
          title: 'Gamma Protein Estimate (pre-donation)',
          detail: numDecimalsCheck(overview['est-igg'], 2, 'g'),
        },
        { title: 'Master File Approved', detail: overview.masterfile ? 'Yes' : 'No' },
        { title: 'Flavor', detail: overview.flavor || '-' },
        {
          title: 'Aging',
          detail: pluralUnitCheck(overview.aging, 'day'),
        },
      ],
    }))
  );

  const locationName = useSelector(state => {
    if (
      state.entities &&
      state.entities.locationName &&
      state.entities.locationName[0] &&
      state.entities.locationName[0].locationname
    ) {
      return state.entities.locationName[0].locationname;
    }
    return '';
  });

  const pdn = useSelector(state => {
    if (
      state.entities &&
      state.entities.overview &&
      state.entities.overview.overview &&
      state.entities.overview.overview[0] &&
      state.entities.overview.overview[0].pdn
    ) {
      return state.entities.overview.overview[0].pdn;
    }
    return '-';
  });

  useEffect(() => {
    if (donation) {
      dispatch(fetchCollectionGetDonationOverview(donation));
      dispatch(fetchCollectionGetDonationDetails(donation));
    }
    if (id) {
      dispatch(fetchCollectionGetLocationName(id));
    }
  }, [donation, id]);

  return (
    <div className={classes.root}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <ViewInDevBanner />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.headerInfo}>
            {loadingName ? (
              <Loader />
            ) : (
              <Typography className={classes.placeTitle} variant="h4" component="h1" gutterBottom>
                {locationName}
              </Typography>
            )}
            <div className={classes.pbn}>
              <DataFieldHint title={pbnHint}>
                <Typography className={classes.pbnTitle} variant="h5" component="h1" align="center">
                  PBN: {donation}
                </Typography>
              </DataFieldHint>
            </div>
            <div className={classes.pdn}>
              <DataFieldHint title={pdnHint}>
                <Typography className={classes.pbnTitle} variant="h5" component="h1" align="center">
                  PDN: {pdn}
                </Typography>
              </DataFieldHint>
              {pdn !== '-' && (
                <div id="checkDonor" className={classes.donorStatus}>
                  <CheckCircleIcon className={classes.grayCheck} />
                  APPROVED DONOR
                </div>
              )}
            </div>
          </div>
        </Grid>
        <SummaryOverview data={overviewData[0] ? overviewData[0] : {}} loading={loadingOverview} />
        <Grid item xs={12}>
          <LogNavigation events={events} />
        </Grid>
      </Grid>
    </div>
  );
}

export default Donation;
