import React, { useState } from 'react';
import Grow from '@material-ui/core/Grow';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    main: {
      display: 'flex',
      flex: '1 0 auto',
    },
    searchIcon: {
      color: theme.palette.text.secondary,
      marginTop: '10px',
      marginRight: '8px',
    },
    searchText: {
      flex: '0.8 0',
    },
    clearIcon: {
      '&:hover': {
        color: theme.palette.error.main,
      },
    },
  }),
  { name: 'MUIDataTableSearch' }
);

function TableSearch({ options, setCustomSearchText, onSearch, onHide }) {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');

  const handleSearch = () => {
    setCustomSearchText(searchText);
    onSearch(searchText);
  };

  const handleTextChange = event => {
    setSearchText(event.target.value);
  };

  const onKeyDownEvent = event => {
    if (event.key === 'Escape') {
      setSearchText('');
      setCustomSearchText('');
      onSearch('');
      onHide();
    }
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const clearIconVisibility = options.searchAlwaysOpen ? 'hidden' : 'visible';

  return (
    <Grow appear in timeout={300}>
      <div className={classes.main}>
        <SearchIcon className={classes.searchIcon} />
        <TextField
          className={classes.searchText}
          autoFocus
          onKeyDown={onKeyDownEvent}
          onChange={handleTextChange}
          fullWidth
          placeholder={options.searchPlaceholder}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(options.searchProps ? options.searchProps : {})}
        />
        <Button className={classes.searchGo} variant="text" onClick={handleSearch}>
          SEARCH
        </Button>
        <IconButton
          className={classes.clearIcon}
          style={{ visibility: clearIconVisibility }}
          onClick={() => {
            setCustomSearchText('');
            onSearch('');
            onHide();
          }}
        >
          <ClearIcon />
        </IconButton>
      </div>
    </Grow>
  );
}

TableSearch.propTypes = {
  options: PropTypes.shape({
    searchProps: PropTypes.shape({}),
    searchPlaceholder: PropTypes.string,
    searchAlwaysOpen: PropTypes.bool,
  }),
  setCustomSearchText: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

TableSearch.defaultProps = {
  options: {
    searchProps: {},
    searchPlaceholder: '',
    searchAlwaysOpen: false,
  },
};

export default TableSearch;
