/* eslint-disable import/no-anonymous-default-export */
const { REACT_APP_ISSUER, REACT_APP_CLIENT_ID } = process.env;

export default {
  oidc: {
    clientId: REACT_APP_CLIENT_ID,
    issuer: REACT_APP_ISSUER,
    redirectUri: window.location.origin,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: false,
  },
};
