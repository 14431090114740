/* eslint-disable react/prop-types */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import FolderXIcon from './FolderXIcon';
import useStyles from './Error.styles';

function Error() {
  const classes = useStyles();

  return (
    <Grid container direction="row" spacing={3} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.title} noWrap>
          404 Error
        </Typography>
      </Grid>
      <div className={classes.messageContainer}>
        <Grid item xs={12} className={classes.message} align="center">
          <FolderXIcon />
          <Typography variant="h5" className={classes.messageTitle}>
            Something went wrong.
          </Typography>
          <Typography variant="subtitle1" className={classes.messageText}>
            There is an issue with the page you&apos;ve requested. First, try reloading the application or
            visit the{' '}
            <Link component={RouterLink} className={classes.link} to={{ pathname: `/` }}>
              Home Page
            </Link>
            . If the issue persists, please contact the QA team on{' '}
            <a
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
              href="https://mytakeda.sharepoint.com/sites/PlasmaChain/SitePages/Connect.aspx"
            >
              Sharepoint
            </a>
            .
          </Typography>
        </Grid>
      </div>
    </Grid>
  );
}

export default Error;
