import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';
import { apiMiddleware } from 'redux-api-middleware';
import {
  CollectionGetLocationDonationsMiddleware,
  CollectionGetLocationDonationsDatesMiddleware,
  ManufacturingGetLocationDonationsMiddleware,
  ManufacturingGetLocationDonationsDatesMiddleware,
} from './donations';
import {
  CollectionGetLocationBoxedDonationsMiddleware,
  CollectionGetBoxDonationsMiddleware,
} from './boxedDonations';
import { CollectionGetLocationBolDonationsMiddleware, CollectionGetBolBoxesMiddleware } from './BOLDonations';
import {
  CollectionGetlocationsMiddleware,
  CollectionGetlocationMiddleware,
  CollectionGetlocationNameMiddleware,
} from './locations';
import {
  ManufacturingGetPhaseOverviewMiddleware,
  ManufacturingGetLocationsMiddleware,
  ManufacturingGetLocationPhaseOverviewMiddleware,
  ManufacturingGetTeardownLotDonationsMiddleware,
} from './manufacturing';
import {
  CollectionGetPhaseOverviewMiddleware,
  CollectionGetlocationProcessesOverviewMiddleware,
  CollectionGetlocationQuarantineOverviewMiddleware,
  CollectionGetDonationOverviewMiddleware,
  ComplianceGetLookbackOverviewMiddleware,
  ComplianceGetTopReasonsMiddleware,
  ComplianceGetTopReasonsDonationsListMiddleware,
  ComplianceGetTopLocationsQuarantineMiddleware,
  ComplianceGetTopLocationsLookbackSubmittedMiddleware,
  ComplianceGetTopLocationsLookbackConfirmedMiddleware,
  ComplianceGetTopLocationsDestroyedMiddleware,
  ComplianceGetTopLocationsDonationsListMiddleware,
} from './overview';
import {
  ComplianceGetCrossDonationsMiddleware,
  ComplianceGetLookbacksSubmittedMiddleware,
  ComplianceGetDestroyedDonationsMiddleware,
} from './quality';
import {
  InventoryGetOverviewTopLocationsMiddleware,
  InventoryGetPlasmaByPhasesMiddleware,
  InventoryGetVolumeIggTotalsMiddleware,
} from './inventoryOverview';
import { CollectionGetDonationDetailsMiddleware } from './donationEvents';
import { CollectionGetDonationSearchhMiddleware } from './searchBar';
import {
  TransitGetShippedBOLMiddleware,
  TransitGetShippedBOLsCarrierConsigneeMiddleware,
  TransitGetShippedBoxesMiddleware,
} from './transitBOL';
import { ShipmentReconciliationMiddleware, ReconciliationGetBolNumberMiddleware } from './reconciliation';
import { DonationTransactionHashMiddleware, DonationTransactionDetailsMiddleware } from './donationToken';
import rootReducer from './rootReducer';

export const history = createBrowserHistory();

const composeEnhancers =
  (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const middleware = [
  routerMiddleware(history),
  apiMiddleware,
  CollectionGetLocationDonationsMiddleware,
  CollectionGetLocationDonationsDatesMiddleware,
  CollectionGetLocationBoxedDonationsMiddleware,
  CollectionGetLocationBolDonationsMiddleware,
  CollectionGetBoxDonationsMiddleware,
  CollectionGetBolBoxesMiddleware,
  CollectionGetDonationOverviewMiddleware,
  CollectionGetPhaseOverviewMiddleware,
  CollectionGetlocationsMiddleware,
  CollectionGetlocationNameMiddleware,
  ManufacturingGetPhaseOverviewMiddleware,
  ManufacturingGetLocationsMiddleware,
  ManufacturingGetLocationPhaseOverviewMiddleware,
  ManufacturingGetLocationDonationsMiddleware,
  ManufacturingGetLocationDonationsDatesMiddleware,
  ManufacturingGetTeardownLotDonationsMiddleware,
  CollectionGetlocationProcessesOverviewMiddleware,
  CollectionGetlocationQuarantineOverviewMiddleware,
  ComplianceGetLookbackOverviewMiddleware,
  ComplianceGetTopReasonsMiddleware,
  ComplianceGetTopReasonsDonationsListMiddleware,
  ComplianceGetTopLocationsQuarantineMiddleware,
  ComplianceGetTopLocationsLookbackSubmittedMiddleware,
  ComplianceGetTopLocationsLookbackConfirmedMiddleware,
  ComplianceGetTopLocationsDestroyedMiddleware,
  ComplianceGetTopLocationsDonationsListMiddleware,
  ComplianceGetCrossDonationsMiddleware,
  ComplianceGetLookbacksSubmittedMiddleware,
  ComplianceGetDestroyedDonationsMiddleware,
  CollectionGetlocationMiddleware,
  CollectionGetDonationDetailsMiddleware,
  CollectionGetDonationSearchhMiddleware,
  TransitGetShippedBOLMiddleware,
  TransitGetShippedBoxesMiddleware,
  TransitGetShippedBOLsCarrierConsigneeMiddleware,
  InventoryGetOverviewTopLocationsMiddleware,
  InventoryGetPlasmaByPhasesMiddleware,
  InventoryGetVolumeIggTotalsMiddleware,
  ShipmentReconciliationMiddleware,
  DonationTransactionHashMiddleware,
  DonationTransactionDetailsMiddleware,
  ReconciliationGetBolNumberMiddleware,
  createLogger(),
];

const store = preloadedState =>
  createStore(rootReducer(history), preloadedState, composeEnhancers(applyMiddleware(...middleware)));

export default store;
