import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import map from 'lodash/map';
import get from 'lodash/get';
import Table from '../Table/Table';
import useStyles from './ModalDetails.styles';
import Loader from '../Loader/Loader';
import { fetchCollectionGetBoxDonations } from '../../redux/boxedDonations';
import { fetchCollectionGetBolBoxes } from '../../redux/BOLDonations';
import {
  formatDate,
  numDecimalsCheck,
  options,
  sortNum,
  sortDate,
  withCommas,
  customAlignRight,
} from '../../utils';
import {
  agingColumn,
  donationsColumn,
  iggColumn,
  volumeColumn,
  transitionColumn,
  totalVelocityColumn,
  statusTimeColumn,
  itemNumberColumn,
  statusColumn,
  tokenColumn,
  pdnColumn,
  pbnColumn,
} from '../Table/Columns';
import { TableLink, TableModal } from '../Table/TableLink';

function ModalDetails({
  selectedId,
  setSelectedId,
  type,
  setSelectedType,
  overviewData,
  setSelectedOverview,
  currentLocation,
  openedAs,
  handleClose,
  findBolOverview,
  bolId,
  openedFrom,
}) {
  const classes = useStyles();
  const { id } = useParams();

  if (id) {
    sessionStorage.setItem('modalLocationID', id);
  }
  const dispatch = useDispatch();
  const loading = useSelector(state => get(state, 'loading.loading', false));
  const [previousBOL, setPreviousBOL] = useState('-1');

  const donationsInBox = useSelector(state => {
    if (get(state, 'entities.donationsInBox.data', {})[0] === 'no entries') {
      return [];
    }
    return map(get(state, 'entities.donationsInBox.data', {}), donation => ({
      pbn: donation.pbn || '-',
      pdn: donation.pdn || '-',
      status: donation.status || '-',
      flavor: donation.flavor || '-',
      item_number: donation.item_number || '-',
      bleed_date: formatDate(donation.bleed_date),
      token_id: donation.token || 'TBD',
      volume: numDecimalsCheck(donation.volume, 3),
      est_igg: numDecimalsCheck(donation.igg, 2),
      aging: withCommas(donation.aging),
      status_time: withCommas(donation['status-time']),
      transition_velocity: withCommas(donation['transition-velocity']),
      total_velocity: withCommas(donation['total-velocity']),
    }));
  });

  const boxesInBOL = useSelector(state => {
    if (get(state, 'entities.boxesInBOL.data', {})[0] === 'no entries') {
      return [];
    }
    return map(get(state, 'entities.boxesInBOL.data', {}), box => ({
      'box-number': box['box-number'] || '-',
      donations: withCommas(box.donations),
      volume: numDecimalsCheck(box.volume, 3),
      est_igg: numDecimalsCheck(box['est-igg'], 2),
      'earliest-bleed-date': formatDate(box['earliest-bleed-date']),
      'latest-bleed-date': formatDate(box['latest-bleed-date']),
      'min-age': withCommas(box['min-age']),
      'max-age': withCommas(box['max-age']),
      'date-boxed': formatDate(box['date-boxed']),
    }));
  });

  const handleBack = () => {
    const status = sessionStorage.getItem('ModalStatus');
    if ((openedAs === 'bol' && status === 'Open as BOL') || openedAs === 'box') {
      sessionStorage.setItem('ModalStatus', '-1');
      handleClose();
    } else {
      setSelectedOverview(findBolOverview(bolId));
      setSelectedType('bol');
      setSelectedId(bolId);
    }
  };

  const neutralizeBack = () => {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, '', window.location.href);
      handleBack();
    };
  };

  useEffect(() => {
    neutralizeBack();
  }, [openedAs]);

  useEffect(() => {
    const hasPrevBOL = previousBOL !== '-1';
    if (selectedId && type === 'bol') {
      setPreviousBOL(selectedId);
      dispatch(fetchCollectionGetBolBoxes(selectedId, currentLocation, openedFrom));
      sessionStorage.setItem('ModalStatus', 'Open as BOL');
    }
    if (hasPrevBOL && selectedId && type === 'box') {
      setPreviousBOL('-1');
      dispatch(fetchCollectionGetBoxDonations(selectedId, currentLocation, 'bol', openedFrom));
      sessionStorage.setItem('ModalStatus', 'Coming from BOL');
    }
    if (!hasPrevBOL && selectedId && type === 'box') {
      setPreviousBOL('-1');
      dispatch(fetchCollectionGetBoxDonations(selectedId, currentLocation, 'box', openedFrom));
      sessionStorage.setItem('ModalStatus', 'Opening as BOX');
    }
  }, [selectedId, type]);

  const handleRowClick = (rowId, rowType) => {
    setSelectedId(rowId);
    if (rowType === 'box') {
      const boxOverview = boxesInBOL.filter(box => box['box-number'] === rowId && box);
      setSelectedOverview(boxOverview[0]);
    }
    setSelectedType(rowType);
  };

  const getOverviewItems = () => {
    if (overviewData) {
      return Object.keys(overviewData).map(key => {
        if (key === 'id_origin') {
          if (id === undefined) {
            sessionStorage.setItem('modalLocationID', overviewData[key]);
          }
        }

        // eslint-disable-next-line no-shadow
        const getUnit = (key, value) => {
          if (value && parseInt(value, 10)) {
            if (key === 'volume') {
              return 'L';
            }
            if (key === 'est-igg') {
              return 'g';
            }
            if (key === 'boxes') {
              return value > 1 ? 'boxes' : 'box';
            }
            if (
              key === 'min-age' ||
              key === 'max-age' ||
              key === 'aging' ||
              key === 'status_time' ||
              key === 'total_time' ||
              key === 'transition_velocity' ||
              key === 'total_velocity'
            ) {
              return value > 1 ? 'days' : 'day';
            }
          }
          return '';
        };

        // eslint-disable-next-line no-shadow
        const getKeyName = key => {
          if (key === 'est-igg') {
            return 'GAMMA PROTEIN ESTIMATE (PRE-DONATION)';
          }
          return key.toUpperCase().replace(/-|_/g, ' ');
        };

        return (
          <ListItem className={classes.listItem} key={`row-item-${key}`}>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="overline" className={classes.listItemTitle}>
                  {getKeyName(key)}
                </Typography>
              }
              secondary={
                <Typography variant="subtitle2" className={classes.listItemDetail}>
                  {key === 'volume' || key === 'est-igg' ? withCommas(overviewData[key]) : overviewData[key]}{' '}
                  {getUnit(key, overviewData[key])}
                </Typography>
              }
            />
          </ListItem>
        );
      });
    }
    return '';
  };

  const donationsInBoxColumns = [
    pbnColumn(value =>
      TableLink(value, `/collection/${sessionStorage.getItem('modalLocationID')}/donation/${value}/`)
    ),
    pdnColumn,
    tokenColumn({
      className: classes.leftAlignedHeader,
    }),
    statusColumn(),
    {
      name: 'bleed_date',
      label: 'Bleed Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
        sortCompare: order => sortDate(order),
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
      },
    },
    {
      name: 'flavor',
      label: 'Flavor',
      options: {
        filter: true,
        filterType: 'multiselect',
        sort: true,
      },
    },
    volumeColumn({
      className: classes.rightAlignedHeader,
    }),
    iggColumn({
      className: classes.rightAlignedHeader,
    }),
    agingColumn({
      className: classes.rightAlignedHeader,
    }),
    statusTimeColumn({
      className: classes.rightAlignedHeader,
    }),
    itemNumberColumn({
      className: classes.leftAlignedHeader,
    }),
    transitionColumn({
      className: classes.rightAlignedHeader,
    }),
    totalVelocityColumn({
      className: classes.rightAlignedHeader,
    }),
  ];

  const boxesInBOLColumns = [
    {
      name: 'box-number',
      label: 'Box Number',
      options: {
        filter: true,
        sort: true,
        sortCompare: order => sortNum(order),
        viewColumns: false,
        customBodyRender: value => TableModal(value, () => handleRowClick(value, 'box')),
      },
    },
    donationsColumn({
      className: classes.rightAlignedHeader,
    }),
    volumeColumn({
      className: classes.rightAlignedHeader,
    }),
    iggColumn({
      className: classes.rightAlignedHeader,
    }),
    {
      name: 'earliest-bleed-date',
      label: 'Earliest Bleed Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
        sortCompare: order => sortDate(order),
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
      },
    },
    {
      name: 'latest-bleed-date',
      label: 'Latest Bleed Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
        sortCompare: order => sortDate(order),
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
      },
    },
    {
      name: 'max-age',
      label: 'Max Age (days)',
      options: options('Max Age', customAlignRight),
    },
    {
      name: 'min-age',
      label: 'Min Age (days)',
      options: options('Min Age', customAlignRight),
    },
    {
      name: 'date-boxed',
      label: 'Date Boxed',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
        sortCompare: order => sortDate(order),
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
      },
    },
  ];

  // eslint-disable-next-line consistent-return
  const showTable = () => {
    if (loading) {
      return <Loader />;
    }
    if (type === 'box') {
      return <Table columns={donationsInBoxColumns} data={donationsInBox} />;
    }
    if (type === 'bol') {
      return <Table columns={boxesInBOLColumns} data={boxesInBOL} />;
    }
  };

  return (
    <Grid className={classes.boxDetailsContainer}>
      <Typography variant="overline" className={classes.boxSubtitle}>
        {type === 'bol' ? 'BOL NUMBER' : 'BOX NUMBER'}
      </Typography>
      <Typography className={classes.boxTitle} variant="h4" noWrap>
        {selectedId}
      </Typography>
      <div className={classes.boxOverviewContainer}>
        <Grid className={classes.boxOverview}>
          <Grid item xs={12}>
            <List className={classes.horizontalList}>{getOverviewItems()}</List>
          </Grid>
        </Grid>
      </div>
      <Grid item xs={12} className={classes.modalDetailsTable}>
        {showTable()}
      </Grid>
    </Grid>
  );
}

ModalDetails.propTypes = {
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  overviewData: PropTypes.shape({}),
  type: PropTypes.string.isRequired,
  setSelectedId: PropTypes.func.isRequired,
  setSelectedType: PropTypes.func.isRequired,
  setSelectedOverview: PropTypes.func.isRequired,
  currentLocation: PropTypes.string.isRequired,
  openedAs: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  findBolOverview: PropTypes.func.isRequired,
  bolId: PropTypes.string.isRequired,
  openedFrom: PropTypes.string.isRequired,
};

ModalDetails.defaultProps = {
  overviewData: {},
};

export default ModalDetails;
