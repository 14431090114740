import { RSAA } from 'redux-api-middleware';

export const FETCH_INVENTORY_GET_OVERVIEW_TOP_LOCATIONS_REQUEST =
  'FETCH_INVENTORY_GET_OVERVIEW_TOP_LOCATIONS_REQUEST';
export const FETCH_INVENTORY_GET_OVERVIEW_TOP_LOCATIONS_SUCCESS =
  'FETCH_INVENTORY_GET_OVERVIEW_TOP_LOCATIONS_SUCCESS';
export const FETCH_INVENTORY_GET_OVERVIEW_TOP_LOCATIONS_FAILURE =
  'FETCH_INVENTORY_GET_OVERVIEW_TOP_LOCATIONS_FAILURE';

export const FETCH_INVENTORY_GET_PLASMA_BY_PHASES_REQUEST = 'FETCH_INVENTORY_GET_PLASMA_BY_PHASES_REQUEST';
export const FETCH_INVENTORY_GET_PLASMA_BY_PHASES_SUCCESS = 'FETCH_INVENTORY_GET_PLASMA_BY_PHASES_SUCCESS';
export const FETCH_INVENTORY_GET_PLASMA_BY_PHASES_FAILURE = 'FETCH_INVENTORY_GET_PLASMA_BY_PHASES_FAILURE';

export const FETCH_INVENTORY_GET_VOLUME_IGG_TOTALS_REQUEST = 'FETCH_INVENTORY_GET_VOLUME_IGG_TOTALS_REQUEST';
export const FETCH_INVENTORY_GET_VOLUME_IGG_TOTALS_SUCCESS = 'FETCH_INVENTORY_GET_VOLUME_IGG_TOTALS_SUCCESS';
export const FETCH_INVENTORY_GET_VOLUME_IGG_TOTALS_FAILURE = 'FETCH_INVENTORY_GET_VOLUME_IGG_TOTALS_FAILURE';

export const fetchInventoryGetOverviewTopLocations = () => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-inventory-get-overview-top-locations`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_INVENTORY_GET_OVERVIEW_TOP_LOCATIONS_REQUEST,
      {
        type: FETCH_INVENTORY_GET_OVERVIEW_TOP_LOCATIONS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { list: json } };
        },
      },
      {
        type: FETCH_INVENTORY_GET_OVERVIEW_TOP_LOCATIONS_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchInventoryGetVolumeIggTotals = () => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-inventory-get-volume-igg-totals`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_INVENTORY_GET_VOLUME_IGG_TOTALS_REQUEST,
      {
        type: FETCH_INVENTORY_GET_VOLUME_IGG_TOTALS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { totals: json } };
        },
      },
      {
        type: FETCH_INVENTORY_GET_VOLUME_IGG_TOTALS_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchInventoryGetPlasmaByPhases = () => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-inventory-get-plasma-by-phases`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_INVENTORY_GET_PLASMA_BY_PHASES_REQUEST,
      {
        type: FETCH_INVENTORY_GET_PLASMA_BY_PHASES_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { phases: json } };
        },
      },
      {
        type: FETCH_INVENTORY_GET_PLASMA_BY_PHASES_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const InventoryGetOverviewTopLocationsMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_INVENTORY_GET_OVERVIEW_TOP_LOCATIONS_SUCCESS:
      console.log('fetch inventory overview top locations success');
      break;
    case FETCH_INVENTORY_GET_OVERVIEW_TOP_LOCATIONS_FAILURE:
      console.log('fetch inventory overview top locations failure');
      break;
    default:
      break;
  }
};

export const InventoryGetPlasmaByPhasesMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_INVENTORY_GET_PLASMA_BY_PHASES_SUCCESS:
      console.log('fetch inventory plasma by phases success');
      break;
    case FETCH_INVENTORY_GET_PLASMA_BY_PHASES_FAILURE:
      console.log('fetch inventory plasma by phases failure');
      break;
    default:
      break;
  }
};

export const InventoryGetVolumeIggTotalsMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_INVENTORY_GET_VOLUME_IGG_TOTALS_SUCCESS:
      console.log('fetch inventory volume and igg totals success');
      break;
    case FETCH_INVENTORY_GET_VOLUME_IGG_TOTALS_FAILURE:
      console.log('fetch inventory volume and igg totals failure');
      break;
    default:
      break;
  }
};
