const gray = {
  200: '#f6f6fa',
  300: '#eaeaf2',
  400: '#c4c4cd',
  500: '#747480',
  600: '#2e2e38',
  700: '#1a1a24',
};

const blue = {
  500: '188CE5',
};

const red = {
  500: '#ee1100',
};

const primary = {
  lightest: gray[200],
  lighter: gray[300],
  light: gray[400],
  main: gray[500],
  dark: gray[600],
  darker: gray[700],
  darkest: '#000',
  contrastText: '#fff',
};

const error = {
  main: red[500], // red
};

const success = {
  main: '#2E7D32', // green
};

const background = {
  default: '#fff',
};

const text = {
  primary: gray[700],
  secondary: gray[500],
};

const palette = {
  background,
  text,
  primary,
  blue,
  red,
  gray,
  success,
  error,
};

const breakpoints = {
  values: {
    xl: 1920,
    lg: 1280,
    md: 960,
    sm: 600,
    xs: 0,
  },
};

const htmlFontSize = 16;
const fontSize = 14;

const typography = {
  htmlFontSize,
  fontSize,
  fontFamily: 'Roboto, sans-serif',
  button: {
    fontWeight: 'medium',
    fontSize: '14px',
    textTransform: 'none',
  },
  h1: {
    fontweight: 'bold',
    fontSize: '48px',
  },
  h4: {
    fontweight: 'bold',
    fontSize: '24px',
  },
  h5: {
    fontweight: 'bold',
    fontSize: '20px',
  },
  h6: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  body: {
    fontWeight: 'normal',
    fontSize: '16px',
  },
  body2: {
    fontWeight: 'normal',
    fontSize: '14px',
  },
  subtitle: {
    fontWeight: 'medium',
    fontSize: '16px',
  },
  subtitle2: {
    fontWeight: 'medium',
    fontSize: '14px',
  },
  caption: {
    fontWeight: 'normal',
    fontSize: '12px',
  },
  overline: {
    fontWeight: 'bold',
    fontSize: '10px',
  },
};

const overrides = {
  MUIDataTableHeadCell: {
    root: {
      '@media (min-width: 960px) and (max-width: 1280px)': {
        padding: '0.5rem',
      },
      verticalAlign: 'bottom',
    },
    fixedHeader: {
      '@media (min-width: 960px) and (max-width: 1280px)': {
        verticalAlign: 'bottom',
      },
    },
    contentWrapper: {
      '@media (min-width: 960px) and (max-width: 1280px)': {
        display: 'grid',
      },
    },
    toolButton: {
      fontWeight: 700,
      marginRight: '-8px',
    },
    sortAction: {
      margin: 0,
      padding: 0,
      width: 'fit-content',
    },
    sortLabelRoot: {
      width: 0,
    },
  },
  MuiTableSortLabel: {
    active: {
      position: 'absolute',
      bottom: '-12px',
      left: '50%',
      width: 0,
    },
  },
  MUIDataTableBodyCell: {
    root: {
      textAlign: 'left',
    },
  },
  MuiSvgIcon: {
    root: {
      '&.infoicon': {
        margin: '0.15em 0.5em -0.15em 0.5em',
        fontSize: '.888em',
      },
    },
  },
};

// eslint-disable-next-line
export default {
  palette,
  breakpoints,
  typography,
  overrides,
};
