import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import get from 'lodash/get';
import Loader from '../Loader/Loader';
import DataFieldHint from '../DataFieldHint/DataFieldHint';
import tooltipHints from '../../tooltipHints';
import useStyles from './ManufacturingOverview.styles';
import { getCountConversion, withCommas } from '../../utils';

const {
  lot_id: lotIDHint,
  description: descriptionHint,
  quantity: quantityHint,
  location: locationHint,
} = tooltipHints;

const getPrimaryHint = title => {
  let hint = '';
  switch (title.toUpperCase()) {
    case 'LOT ID':
      hint = lotIDHint;
      break;
    case 'DESCRIPTION':
      hint = descriptionHint;
      break;
    case 'QUANTITY':
      hint = quantityHint;
      break;
    case 'LOCATION':
      hint = locationHint;
      break;

    default:
      hint = 'field information';
  }
  return hint;
};

const getWidth = label => {
  switch (label) {
    case 'Donations':
      return 75;
    case 'Est. gamma(g)':
      return 87;
    case 'Boxes':
      return 70;
    case 'BOLs':
      return 65;
    default:
      return 90;
  }
};

const getSecondaryDetails = (items, classes) =>
  items.map(item => (
    <div style={{ flex: `0 0 ${getWidth(item.label)}px` }} className={classes.secondaryItem}>
      <Typography className={classes.secondaryItemLabel}>{item.label}</Typography>
      <Tooltip title={withCommas(item.detail)} className={classes.toolTip} placement="bottom" arrow>
        <Typography variant="h6" className={classes.secondaryItemDetail}>
          {getCountConversion(Number(item.detail))}
        </Typography>
      </Tooltip>
    </div>
  ));

const getItemClassName = (item, showCounts, classes) => {
  if (showCounts) {
    switch (item.title) {
      case 'Total Volume (L)':
      case 'Collected (L)':
      case 'Quarantine (L)':
      case 'Lookback Notification Received (L)':
      case 'Destroyed (L)':
      case 'Lookback Submitted (L)':
      case 'Received (L)':
      case 'Sorted (L)':
      case 'Released at MFG (L)':
        return classes.listSecondaryItem;
      case 'Boxed (L)':
      case 'Boxed with BOL (L)':
        return classes.listSecondaryItemLong;
      default:
        return classes.listItem;
    }
  }
  if (item.title === 'Gamma Protein Estimate (pre-donation)') {
    return classes.listItemLong;
  }
  return classes.listItem;
};
const getRowItems = (items, classes, showCounts) =>
  items.map(item => {
    if (item.title.toUpperCase() === 'DIVIDER') {
      return <Divider key="divider" orientation="vertical" flexItem />;
    }
    return (
      <ListItem className={getItemClassName(item, showCounts, classes)} key={`row-item-${item.title}`}>
        <ListItemText
          disableTypography
          primary={
            <DataFieldHint title={getPrimaryHint(item.title)}>
              <Typography variant="overline" className={classes.listItemTitle}>
                {item.title.toUpperCase()}
              </Typography>
            </DataFieldHint>
          }
          secondary={
            showCounts ? (
              <>
                <Typography variant="h5" className={classes.listItemDetail}>
                  {item.detail}
                </Typography>
                <List className={classes.horizontalAccordionList}>
                  {getSecondaryDetails(item.secondaryDetails, classes)}
                </List>
              </>
            ) : (
              <>
                <Typography variant="h5" className={classes.listItemDetail}>
                  {item.detail}
                </Typography>
                <Typography variant="subtitle2" className={classes.listItemDetailTwo}>
                  {item.detail_two}
                </Typography>
              </>
            )
          }
        />
      </ListItem>
    );
  });

const outputRows = (title, overview, classes, loading, showCounts) => {
  if (loading) {
    if (Object.keys(overview).length > 1) {
      return (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      );
    }
    return <Loader />;
  }
  if (Object.keys(overview).length > 1) {
    return Object.keys(overview).map((key, index) => {
      if (index >= 1) {
        return (
          <AccordionDetails key={key}>
            <Grid item xs={12} className={classes.summaryDetailsOverview}>
              <Typography variant="overline" className={classes.overviewRowTitle}>
                {title} LOT OVERVIEW
              </Typography>
              <List className={classes.horizontalAccordionList}>
                {getRowItems(overview[key], classes, showCounts)}
              </List>
            </Grid>
          </AccordionDetails>
        );
      }
      return (
        <AccordionSummary
          key={key}
          className={classes.summary}
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
        >
          <Grid item xs={12} className={classes.summaryAccOverview}>
            <Typography variant="overline" className={classes.overviewRowTitle}>
              {title} LOT OVERVIEW
            </Typography>
            <List className={classes.horizontalAccordionList}>
              {getRowItems(overview[key], classes, showCounts)}
            </List>
          </Grid>
        </AccordionSummary>
      );
    });
  }
  return Object.keys(overview).map((key, index) => (
    <Grid item xs={12} key={`visible-rows-${index === 0 ? 'one' : 'two'}`}>
      <Typography variant="overline" className={classes.overviewRowTitle}>
        {title} LOT OVERVIEW
      </Typography>
      <List className={classes.horizontalList}>{getRowItems(overview[key], classes, showCounts)}</List>
    </Grid>
  ));
};

function ManufacturingOverview({ title, data, showCounts }) {
  const classes = useStyles();
  const loading = useSelector(state => get(state, 'loading.loading', true));

  if (data && Object.keys(data).length > 1) {
    return (
      <div key="double-summary" className={classes.summaryOverviewContainer} data-testid="summary overview">
        <Accordion className={classes.accordion}>
          {outputRows(title, data, classes, loading, showCounts)}
        </Accordion>
      </div>
    );
  }
  return (
    <div key="single-summary" className={classes.summaryOverviewContainer} data-testid="summary overview">
      <Grid className={classes.summaryOverview}>{outputRows(title, data, classes, loading, showCounts)}</Grid>
    </div>
  );
}

ManufacturingOverview.propTypes = {
  data: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  showCounts: PropTypes.bool,
};

ManufacturingOverview.defaultProps = {
  showCounts: false,
};

export default ManufacturingOverview;
