import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 700,
    paddingBottom: theme.spacing(3),
  },
  overview: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  rightAlignedHeader: {
    '& span': {
      '& button': {
        textAlign: 'right',
      },
    },
  },
  leftAlignedHeader: {
    '& span': {
      '& button': {
        textAlign: 'left',
      },
    },
  },
}));

export default useStyles;
