import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    marginTop: theme.spacing(8),
    position: 'fixed',
    marginRight: '70px',
    margin: 'auto',
    width: '300px',
    right: '0px',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    borderBottom: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: '#e0e0e0',
  },
  button: {
    width: '280px',
    textAlign: 'start',
    justifyContent: 'flexSart',
  },
  icon: {
    marginRight: '10px',
    fontSize: '40px',
  },
  paper: {
    backgroundColor: '#fafafa',
  },
}));

export default useStyles;
