import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  box: {
    borderColor: 'f1f1f1',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  text1: {
    fontWeight: 'bold',
    marginTop: '10px',
    marginBottom: '10px',
    textAlign: 'center',
  },
  text2: {
    textAlign: 'center',
    marginBottom: '40px',
  },
  icon: {
    marginTop: '40px',
    marginLeft: '50%',
    marginRight: '50%',
  },
}));

export default useStyles;
