/* eslint-disable no-unused-vars */
import { RSAA } from 'redux-api-middleware';

export const FETCH_DONATION_GET_TRANSACTION_HASH_REQUEST = 'FETCH_DONATION_GET_TRANSACTION_HASH_REQUEST';
export const FETCH_DONATION_GET_TRANSACTION_HASH_SUCCESS = 'FETCH_DONATION_GET_TRANSACTION_HASH_SUCCESS';
export const FETCH_DONATION_GET_TRANSACTION_HASH_FAILURE = 'FETCH_DONATION_GET_TRANSACTION_HASH_FAILURE';

export const FETCH_DONATION_GET_TRANSACTION_DETAILS_REQUEST =
  'FETCH_DONATION_GET_TRANSACTION_DETAILS_REQUEST';
export const FETCH_DONATION_GET_TRANSACTION_DETAILS_SUCCESS =
  'FETCH_DONATION_GET_TRANSACTION_DETAILS_SUCCESS';
export const FETCH_DONATION_GET_TRANSACTION_DETAILS_FAILURE =
  'FETCH_DONATION_GET_TRANSACTION_DETAILS_FAILURE';

export const fetchDonationTransactionHash = token => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-token-get-transaction-hash/${token}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_DONATION_GET_TRANSACTION_HASH_REQUEST,
      {
        type: FETCH_DONATION_GET_TRANSACTION_HASH_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { transactionHash: json } };
        },
      },
      {
        type: FETCH_DONATION_GET_TRANSACTION_HASH_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const DonationTransactionHashMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_DONATION_GET_TRANSACTION_HASH_SUCCESS:
      console.log('fetch donation transaction hash success');
      break;
    case FETCH_DONATION_GET_TRANSACTION_HASH_FAILURE:
      console.log('fetch donation transaction hash failure');
      break;
    default:
      break;
  }
};

export const fetchDonationTransactionDetails = transactionHash => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-token-get-transaction-details/${transactionHash}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_DONATION_GET_TRANSACTION_DETAILS_REQUEST,
      {
        type: FETCH_DONATION_GET_TRANSACTION_DETAILS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { transactionDetails: json } };
        },
        meta: (action, state, res) => ({
          status: '',
        }),
      },
      {
        type: FETCH_DONATION_GET_TRANSACTION_DETAILS_FAILURE,
        meta: (action, state, res) => {
          if (res) {
            return {
              error: {
                transactionDetails: {
                  status: res.status,
                  statusText: res.statusText,
                },
              },
            };
          }
          return {
            error: {
              transactionDetails: {
                status: 'Network request failed',
              },
            },
          };
        },
      },
    ],
  },
});

export const DonationTransactionDetailsMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_DONATION_GET_TRANSACTION_DETAILS_SUCCESS:
      console.log('fetch donation transaction details success');
      break;
    case FETCH_DONATION_GET_TRANSACTION_DETAILS_FAILURE:
      console.log('fetch donation transaction details failure');
      break;
    default:
      break;
  }
};
