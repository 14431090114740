import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'; // SUCCESS
import ErrorIcon from '@material-ui/icons/Error'; // PENDING
import CancelIcon from '@material-ui/icons/Cancel'; // FAILED
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'; // Tooltip
import Tooltip from '@material-ui/core/Tooltip';
import { red, green } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Loader from '../../Loader/Loader';
import useStyles from './BlockchainDetails.styles';
import getDescription from './descriptions';
import { fetchDonationTransactionHash, fetchDonationTransactionDetails } from '../../../redux/donationToken';

function BlockchainDetails({ event, fields }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [count, setCount] = useState(0);
  const loadingBlockchainHash = useSelector(state =>
    get(state, 'loading.FETCH_DONATION_GET_TRANSACTION_HASH', true)
  );

  const loadingBlockchainEvents = useSelector(state =>
    get(state, 'loading.FETCH_DONATION_GET_TRANSACTION_DETAILS', true)
  );

  const tokenId = fields.token_id;
  const transactionHash = useSelector(state => get(state, 'entities.transactionHash.data', ''));
  const transactionDetails = useSelector(state => get(state, 'entities.transactionDetails.data', {}));
  const transactionDetailsStatus = useSelector(state => get(state, 'error.transactionDetails.status', ''));

  const fetchTransactionHash = () => (e, isExpanded) => {
    if (tokenId && isExpanded) {
      dispatch(fetchDonationTransactionHash(tokenId));
    }
  };

  useEffect(() => {
    if (transactionHash) {
      dispatch(fetchDonationTransactionDetails(transactionHash));
    }
  }, [transactionHash, dispatch]);

  useEffect(() => {
    if (transactionDetailsStatus && count !== 2) {
      dispatch(fetchDonationTransactionDetails(transactionHash));
      setCount(count + 1);
    }
  }, [transactionDetailsStatus, count]);

  const transaction = transactionDetails.blockNumber
    ? {
        event: transactionDetails.method || 'Mint',
        status: transactionDetails.status,
        block: transactionDetails.blockNumber,
        hash: transactionDetails.hash,
        timestamp: transactionDetails.timestamp,
        from: transactionDetails.to,
        address: transactionDetails.to,
        to: transactionDetails.from,
        tokenId,
        gas_price: transactionDetails.gasProvided,
      }
    : '';

  const allColumnNames = {
    hash: 'Transaction Hash',
    address: 'Contract Address',
    tokenId: 'Token ID',
  };

  const checkFields = value => {
    // Replace _ and capitalize first word
    const spaceValue = value.replace(/_/g, ' ');
    return spaceValue.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  };

  const getColumnLabel = name => (allColumnNames[name] ? allColumnNames[name] : checkFields(name));

  const getData = () => {
    if (transaction && Object.keys(transaction).length !== 0 && !transactionDetailsStatus) {
      const data = Object.entries(transaction).map(([field, value]) => ({ field, value }));
      return data.map(e => {
        e.field = getColumnLabel(e.field);
        return e;
      });
    }
    if (transactionDetailsStatus) {
      return [{ field: 'Status', value: transactionDetailsStatus }];
    }
    return [{ field: 'Status', value: 'PENDING' }];
  };

  const getEventName = () => {
    switch (event) {
      case 'Collected':
        return 'Minting Details';
      case 'Tested':
      case 'Boxed':
      case 'Boxed with BOL':
      case 'Shipped':
      case 'Received':
      case 'Sorted':
      case 'Released':
        return 'Metadata Update Details';
      default:
        return event;
    }
  };

  const getStatusFormat = status => {
    switch (status) {
      case 'success':
        return (
          <div className={classes.successContainer}>
            <CheckCircleIcon className={classes.statusIcon} style={{ color: green[500] }} />
            <Typography className={classes.success}>{status.toUpperCase()}</Typography>
          </div>
        );
      case 'PENDING':
        return (
          <div className={classes.pendingContainer}>
            <ErrorIcon className={classes.statusIcon} style={{ color: '#ffb300' }} />
            <Typography className={classes.pending}>{status}</Typography>
          </div>
        );
      case 'FAILED':
        return (
          <div className={classes.failedContainer}>
            <CancelIcon className={classes.statusIcon} style={{ color: red[500] }} />
            <Typography className={classes.failed}>{status}</Typography>
          </div>
        );
      default:
        return <Typography className={classes.value}>{status}</Typography>;
    }
  };

  if (!tokenId) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Accordion className={classes.accordion} onChange={fetchTransactionHash()}>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon style={{ color: red[500] }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.title} variant="h4" component="h1">
            Blockchain - {getEventName()}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div>
            {loadingBlockchainHash || loadingBlockchainEvents ? (
              <div>
                <Loader />
              </div>
            ) : (
              getData().map(item => (
                <div className={classes.fieldValue} key={item.field}>
                  <div className={classes.fieldTooltip}>
                    <Typography className={classes.field}>{item.field}</Typography>
                    <Tooltip title={getDescription(item.field)} className={classes.toolTip} arrow>
                      <InfoOutlinedIcon className={classes.iconInfo} />
                    </Tooltip>
                  </div>
                  {item.field !== 'Status' && <Typography className={classes.value}>{item.value}</Typography>}
                  {item.field === 'Status' && getStatusFormat(item.value)}
                </div>
              ))
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

BlockchainDetails.propTypes = {
  event: PropTypes.shape({}).isRequired,
  fields: PropTypes.shape({ token_id: PropTypes.number }),
};

BlockchainDetails.defaultProps = {
  fields: {
    token_id: 0,
  },
};

export default BlockchainDetails;
