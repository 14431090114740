import { makeStyles } from '@material-ui/core/styles';
// import { collectedColumn } from '../../components/Table/Columns';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 700,
    paddingBottom: theme.spacing(3),
  },
  subtitle: {
    paddingBottom: theme.spacing(2),
  },
  timeFrameDropdown: {
    width: '150px',
    height: '40px',
  },
  centerLoading: {
    width: '100%',
    marginLeft: '50%',
    marginRight: '50%',
  },
  accordion: {
    boxShadow: 'none',
    borderBottom: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: '#e0e0e0',
  },
  accordionSummary: {
    maxHeight: '60px',
  },
  accordionDescription: {
    fontWeight: 200,
    marginBottom: '30px',
  },
  topLists: {
    flexDirection: 'column',
  },
  titleAccordion: {
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  listLink: {
    color: '#1777CF',
    fontWeight: 600,
    paddingLeft: '3px',
    textAlign: 'left',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  rankListTitle: {
    fontWeight: 500,
    paddingBottom: theme.spacing(1),
  },
  rightAlignedHeader: {
    '& span': {
      '& button': {
        textAlign: 'right',
      },
    },
  },
  rightAlignedMarginHeader: {
    '& span': {
      '& button': {
        textAlign: 'right',
        marginRight: '16px',
        paddingRight: '0px',
      },
    },
  },
  leftAlignedHeader: {
    '& span': {
      '& button': {
        textAlign: 'left',
      },
    },
  },
  leftAlignedMarginHeader: {
    '& span': {
      '& button': {
        textAlign: 'left',
        marginLeft: '16px',
        paddingLeft: '0px',
      },
    },
  },
  crossDonationTable: {
    '& .MuiTableCell-root': {
      padding: '0',
    },
    '& .MuiTableCell-head': {
      paddingBottom: '12px',
    },
    '& .MuiTableCell-head:first-of-type': {
      paddingLeft: '16px',
    },
    '& .MuiTableCell-head:last-of-type': {
      paddingRight: '16px',
    },
    '& .MuiTableSortLabel-active': {
      left: '40%',
    },
  },
}));

export default useStyles;
