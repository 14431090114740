import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 700,
    paddingBottom: theme.spacing(3),
  },
  overview: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  overviewSubtitle: {
    color: theme.palette.text.secondary,
    letterSpacing: '1.25px',
  },
  transQtyTableContainer: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  timeframeDropdownLabel: {
    left: theme.spacing(2),
    top: '-5px',
  },
  rightAlignedHeader: {
    '& span': {
      '& button': {
        textAlign: 'right',
        padding: '0px 0px 0px 0px',
        margin: '0px 20px 0px 20px',
      },
    },
  },
  leftAlignedHeader: {
    '& span': {
      '& button': {
        textAlign: 'left',
        padding: '0px 0px 0px 0px',
        margin: '0px 20px 0px 20px',
      },
    },
  },
  leftAlignedNoMarginHeader: {
    '& span': {
      '& button': {
        textAlign: 'left',
        padding: '0px 0px 0px 0px',
        margin: '0px 20px 0px 0px',
      },
    },
  },
  reconTable: {
    '& .MuiTableCell-root': {
      padding: '0',
    },
    '& .MuiTableCell-head': {
      paddingBottom: '12px',
    },
    '& .MuiTableCell-head:first-of-type': {
      paddingLeft: '16px',
    },
    '& .MuiTableCell-head:last-of-type': {
      paddingRight: '16px',
    },
    '& .MuiTableSortLabel-active': {
      left: '30%',
    },
  },
}));

export default useStyles;
