import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '1280px',
    margin: `0 auto ${theme.spacing(1)}px`,
    paddingRight: theme.spacing(1),
    position: 'relative',
  },
  bar: {
    backgroundColor: theme.palette.primary.contrastText,
    color: '#212121',
    fontWeight: '900',
    width: '45%',
    boxShadow: 'none',
  },
  containerEventLog: {
    display: 'flex',
    width: '100%',
  },
  containerDetails: {
    width: '45%',
  },
  tab: {
    width: '100px',
    minWidth: '100px',
    fontWeight: '900',
  },
}));

export default useStyles;
