import { customAlignRight, customAlignLeftNoPadding, options, sortNum, sortQuantities } from '../../utils';

export const donationsColumn = headerProps => ({
  name: 'donations',
  label: 'Donations (units)',
  options: options('Donations', customAlignRight, headerProps),
});

export const volumeColumn = headerProps => ({
  name: 'volume',
  label: 'Volume (L)',
  options: options('Volume', customAlignRight, headerProps),
});

export const quantityRelatedColumn = (columnName, columnLabel, customBody, headerProps, customFilters) => ({
  name: columnName,
  label: columnLabel,
  options: {
    filter: !!customFilters,
    customFilterListOptions: {
      render: v => `${columnLabel}: ${v}`,
    },
    sort: true,
    customBodyRender: customBody,
    setCellHeaderProps: () => ({
      ...headerProps,
    }),
    filterOptions: customFilters,
    sortCompare: sortQuantities,
  },
});

export const reconBOLRelatedColumn = (columnName, columnLabel, customColor, headerProps, customFilters) => ({
  name: columnName,
  label: columnLabel,
  options: {
    filter: !!customFilters,
    customFilterListOptions: {
      render: v => `${columnLabel}: ${v}`,
    },
    sort: true,
    customBodyRender: customColor,
    setCellHeaderProps: () => ({
      ...headerProps,
    }),
    filterOptions: customFilters,
  },
});

export const collectedColumn = headerProps => ({
  name: 'collected',
  label: 'Collected (L)',
  options: options('Collected', customAlignRight, headerProps),
});

export const boxedColumn = headerProps => ({
  name: 'boxed',
  label: 'Boxed (L)',
  options: options('Boxed', customAlignRight, headerProps),
});

export const bolColumn = headerProps => ({
  name: 'bol',
  label: 'Boxed with BOL (L)',
  options: options('Boxed with BOL', customAlignRight, headerProps),
});

export const iggColumn = headerProps => ({
  name: 'est_igg',
  label: 'Gamma Protein Estimate (pre-donation) (g)',
  options: options('Est Igg', customAlignRight, headerProps),
});

export const agingColumn = headerProps => ({
  name: 'aging',
  label: 'Aging (days)',
  options: options('Age', customAlignRight, headerProps),
});

export const statusTimeColumn = headerProps => ({
  name: 'status_time',
  label: 'Status Time (days)',
  options: options('Status Time', customAlignRight, headerProps),
});

export const transitionColumn = headerProps => ({
  name: 'transition_velocity',
  label: 'Transition Velocity (days)',
  options: options('Transition Velocity', customAlignRight, headerProps),
});

export const totalVelocityColumn = headerProps => ({
  name: 'total_velocity',
  label: 'Total Velocity (days)',
  options: options('Total Velocity', customAlignRight, headerProps),
});

export const itemNumberColumn = headerProps => ({
  name: 'item_number',
  label: 'Item Number',
  options: options('Item Number', customAlignLeftNoPadding, headerProps, true, 'multiselect'),
});

export const statusColumn = headerProps => ({
  name: 'status',
  label: 'Status',
  options: options('Status', '', headerProps, true, 'multiselect'),
});

export const tokenColumn = headerProps => ({
  name: 'token_id',
  label: 'Token ID',
  options: options('Token ID', customAlignLeftNoPadding, headerProps, false),
});

export const pdnColumn = {
  name: 'pdn',
  label: 'PDN',
  options: {
    filter: false,
    sort: true,
  },
};

export const lookbackReportTypeColumn = {
  name: 'lookback_report_type',
  label: 'Lookback Report Type',
  options: {
    filter: true,
    sort: true,
  },
};

export const locationColumn = {
  name: 'location',
  label: 'Location',
  options: {
    filter: true,
    sort: true,
  },
};

export const lookbackReasonColumn = {
  name: 'lookback_reason',
  label: 'Lookback Reason',
  options: {
    filter: true,
    sort: true,
  },
};

export const destroyedReasonColumn = {
  name: 'destroyed_reason',
  label: 'Destroyed Reason',
  options: {
    filter: true,
    sort: true,
  },
};

export const lookbackDescriptionColumn = {
  name: 'description',
  label: 'Lookback Description',
  options: {
    filter: true,
    sort: true,
  },
};

export const lookbackConfirmedColumn = {
  name: 'lookback_confirmed',
  label: 'Lookback Notification Received? (Y/N)',
  options: {
    filter: true,
    sort: true,
  },
};

export const lookbackSubmittedColumn = {
  name: 'lookback_submitted',
  label: 'Lookback Submitted',
  options: {
    filter: true,
    sort: true,
  },
};

export const measlesHyperimmuneColumn = {
  name: 'measles_hyperimmune',
  label: 'Measles Hyperimmune',
  options: {
    filter: true,
    sort: true,
  },
};

export const pbnColumn = customBodyRender => ({
  name: 'pbn',
  label: 'PBN',
  options: {
    filter: false,
    sort: true,
    sortCompare: order => sortNum(order),
    viewColumns: false,
    customBodyRender,
  },
});

export const basePBNColumn = (customBodyRender, setCellHeaderProps) => ({
  name: 'base_pbn',
  label: 'PTR/PDI PBN',
  options: {
    filter: false,
    sort: true,
    sortCompare: order => sortNum(order),
    viewColumns: false,
    customBodyRender,
    setCellHeaderProps,
  },
});
