import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  navBar: {
    backgroundColor: theme.palette.primary.contrastText,
    borderBottom: `solid 1px ${theme.palette.primary.main}`,
    color: '#000',
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  navIndicator: {
    height: '5px',
  },
  navLink: {
    textTransform: 'uppercase',
    fontWeight: '500',
  },
  iconLabelWrapper: {
    flexDirection: 'row-reverse',
  },
  navLinkList: {
    marginTop: '5px',
    transformOrigin: 'center top',
  },
  navLinkListItem: {
    textTransform: 'uppercase',
    paddingBottom: theme.spacing(2),
    fontSize: '13.5px',
    fontWeight: '500',
    letterSpacing: '.5px',
  },
  dropDownNav: {
    border: `1px solid ${theme.palette.primary.lightest}`,
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
  },
}));

export default useStyles;
