import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './Loader.styles';

function Loader() {
  const classes = useStyles();
  return (
    <div className={classes.centerLoading}>
      <CircularProgress />
    </div>
  );
}

export default Loader;
