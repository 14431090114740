import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 700,
    paddingBottom: theme.spacing(3),
  },
  backButton: {
    width: '100%',
    display: 'flex',
    marginTop: '15px',
    height: '30px',
    alignItems: 'center',
    marginBottom: '15px',
  },
  backText: {
    marginLeft: '10px',
  },
  overviewSubtitle: {
    color: theme.palette.text.secondary,
    letterSpacing: '1.25px',
  },
  overview: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  iconImg: {
    marginBottom: '20px',
    // position: 'absolute',
  },
  stepperContainer: {
    // marginTop: '150px',
    // height: '300px',
  },
  root: {
    backgroundColor: 'transparent',
    marginTop: '0px',
    // height: '0px',
    // height: '200px',
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      // marginTop: '50px',
      textAlign: 'center',
    },
    '& .MuiStepLabel-label': {
      // For fontsize
      // color: "#777777",
      // fontSize: '20px',
      marginTop: '0px',
    },
    '& .MuiStepLabel-root': {
      // color: "#777777",
      // fontSize: '20px',
      marginBottom: '30px',
    },
    '& .MuiStepLabel-iconContainer': {
      // ICONS
      marginTop: '120px',
      position: 'absolute',
      // color: 'green',
      textAlign: 'center',
      // margin-bottom: 20px; */
    },
    '& .MuiStepLabel-labelContainer': {
      marginTop: '0px',
      // position: 'absolute',
      // color: 'green',
      // textAlign: 'center',
      // margin-bottom: 20px; */
    },
    '& .MuiStepConnector-root': {
      // Connectors
      // color: "green",
      // color: "#777777",
      // borderColor: 'green',
      marginTop: '120px',
    },
    '& .MuiStepConnector-lineHorizontal': {
      // Connector color and width
      borderTopStyle: 'solid',
      // borderTopWidth: "2px",
      // borderColor: "green",
    },
    // "& .MuiStepConnector-completed": { //Connectors
    //   borderColor: 'green',
    //   color: 'green',
    // },
    '& .MuiStepLabel-active': {
      color: '#777777',
      // fontSize: '16px',
      // paddingBottom: '5px',
    },

    // "& .MuiTypography-root": {
    //   backgroundColor: 'pink',
    //   paddingBottom: '20px',
    // },
    '& .MuiStepLabel-completed': {
      // color: "#777777"
    },
    '& .MuiButtonBase-root': {
      minWidth: '110px',
    },
    '& .Mui-disabled .MuiStepLabel-label': {
      // color: "#777777"
    },

    '& .MuiStepIcon-root.MuiStepIcon-completed': {
      // Green check completed
      color: 'green',
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      // Green check
      width: '30px',
      height: '30px',
    },
    '& .MuiStepIcon-root': {
      // Green check
      // color: 'green',
    },
    // "& .Mui-completed": {
    //   color: 'green',
    // },
  },
  triangleContainer: {
    minWidth: '110px',
    // backgroundColor:'yellow',
  },
  triangleUp: {
    marginTop: '60px',
    marginBottom: '-80px',
    marginLeft: '25%',
    marginRight: '25%',
    // paddingRight: '10px',
    width: '0',
    height: '0',
    borderLeft: '25px solid transparent',
    borderRight: '25px solid transparent',
    borderBottom: '25px solid #f1f1f1',
    // position: 'absolute',
  },
  greenIcon: {
    color: 'green',
  },
  avatar: {
    width: '1.5em',
    height: '1.5em',
    backgroundColor: '#A5C3E0',
  },
  lineVertical: {
    padding: '8px 0px',
    marginLeft: 14,
  },
}));

export default useStyles;
