import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import map from 'lodash/map';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import { fetchCollectionGetDonationSearch } from '../../redux/searchBar';
import Table from '../Table/Table';
import useStyles from './Search.styles';
import Loader from '../Loader/Loader';
import isInputValid from './Validation';
import {
  formatDate,
  numDecimalsCheck,
  customAlignRight,
  options,
  sortDate,
  customFilterListOptionsRender,
  customFilterListOptionsUpdate,
  filterDateOptionsLogic,
  getMinMaxDates,
} from '../../utils';
import CustomRangeFilter from '../CustomRangeFilter/CustomRangeFilter';
import { TableLink } from '../Table/TableLink';
import { pbnColumn } from '../Table/Columns';

function Search({ handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [retrieved, setRetrieved] = useState(false);
  const [input, setInput] = useState('');
  const [valid, setValid] = useState(true);
  const loading = useSelector(state => get(state, 'loading.FETCH_COLLECTION_GET_DONATION_SEARCH', false));
  const data = useSelector(state => {
    if (get(state, 'entities.donationsSearched.data', {})[0] === 'No results found') {
      return 'No results found';
    }
    return map(get(state, 'entities.donationsSearched.data', []), donation => ({
      pbn: donation.pbn || '-',
      pdn: donation.pdn || '-',
      status: donation.status || '-',
      token_id: donation.token_id || '-',
      plasma_volume: numDecimalsCheck(donation.plasma_volume, 3),
      est_igg: numDecimalsCheck(donation.est_igg, 2),
      item_number: donation.item_number || '-',
      bol_number: donation.bol_number === -1 ? '-' : donation.bol_number,
      flavor: donation.flavor || '-',
      center_name: donation.center_name || '-',
      center_num: donation.center_num || '-',
      bleed_date: formatDate(donation.bleed_date),
      lookback_reason: donation.lookback_reason || '-',
      destroyed: donation.destroyed ? 'Yes' : 'No',
      destroyed_reason: donation.destroyed_reason || '-',
      quarantine: donation.quarantine ? 'Yes' : 'No',
      quarantine_in_date: formatDate(donation.quarantine_in_date),
      quarantine_out_date: formatDate(donation.quarantine_out_date),
    }));
  });

  const allColumnNames = [
    { name: 'pdn', label: 'PDN', index: 1 },
    { name: 'status', label: 'Status', index: 2 },
    { name: 'token_id', label: 'Token ID', index: 3 },
    { name: 'plasma_volume', label: 'Volume (L)', index: 4 },
    { name: 'est_igg', label: 'Gamma Protein Estimate (pre-donation) (g)', index: 5 },
    { name: 'item_number', label: 'Item Number', index: 5.1 },
    { name: 'bol_number', label: 'BOL Number', index: 5.2 },
    { name: 'flavor', label: 'Flavor', index: 6 },
    { name: 'center_name', label: 'Center Name', index: 7 },
    { name: 'bleed_date', label: 'Bleed Date (MM/DD/YYYY)', index: 8 },
    { name: 'lookback_reason', label: 'Lookback Reason', index: 9 },
    { name: 'destroyed', label: 'Destroyed', index: 10 },
    { name: 'destroyed_reason', label: 'Destroyed Reason', index: 11 },
    { name: 'quarantine', label: 'Quarantine', index: 12 },
    { name: 'quarantine_in_date', label: 'Quarantine In Date (MM/DD/YYYY)', index: 13 },
    { name: 'quarantine_out_date', label: 'Quarantine Out Date (MM/DD/YYYY)', index: 14 },
  ];

  const trimInput = term => term.trim().split(' ').join('_');

  const keyPress = e => {
    if (e.keyCode === 13 && input) {
      setValid(true);
      if (!isInputValid(input)) {
        setValid(false);
        setRetrieved(true);
      } else {
        dispatch(fetchCollectionGetDonationSearch(trimInput(input)));
        if (!loading) setRetrieved(true);
      }
    }
  };

  const getLocationId = pbn => {
    let result = 1;
    data.forEach(e => {
      if (e.pbn === pbn) {
        result = e.center_num;
      }
    });
    return result;
  };

  const pbn = pbnColumn((value, tableMeta) =>
    TableLink(
      <div
        role="none"
        onClick={() => {
          handleClose();
        }}
        onKeyDown={() => {
          handleClose();
        }}
      >
        {tableMeta.rowIndex === 0}
        {value}
      </div>,
      {
        pathname: `/collection/${getLocationId(value)}/donation/${value}`,
      }
    )
  );

  const getColumnLabel = name => {
    let result = 'field';
    allColumnNames.forEach(element => {
      if (element.name === name) {
        result = element.label;
      }
    });
    return result;
  };

  const getColumnIndex = name => {
    let result = 0;
    allColumnNames.forEach(element => {
      if (element.name === name) {
        result = element.index;
      }
    });
    return result;
  };

  const getFilter = element => {
    if (
      element === 'token_id' ||
      element === 'bol_number' ||
      element === 'center_name' ||
      element === 'pdn'
    ) {
      return false;
    }
    return true;
  };

  const getFilterType = element => {
    if (element === 'status' || element === 'flavor') return 'multiselect';
    if (element === 'plasma_volume' || element === 'est_igg') {
      return 'checkbox';
    }
    return 'dropdown';
  };

  const getColumns = () => {
    const columns = [pbn];
    if (data[0]) {
      const keys = Object.keys(data[0]).filter(e => e !== 'center_num');
      keys.forEach(element => {
        if (element !== 'pbn') {
          if (element === 'bol_number' || element === 'est_igg' || element === 'plasma_volume') {
            const name = element.replace('_', ' ');
            columns.push({
              name: element,
              label: getColumnLabel(element),
              index: getColumnIndex(element),
              options: options(
                name.replace(/(^\w|\s\w)/g, m => m.toUpperCase()),
                customAlignRight
              ),
            });
          } else if (
            element === 'destroyed_date' ||
            element === 'quarantine_in_date' ||
            element === 'quarantine_out_date'
          ) {
            columns.push({
              name: element,
              label: getColumnLabel(element),
              index: getColumnIndex(element),
              options: {
                filter: getFilter(element),
                filterType: getFilterType(element),
                filterOptions: {},
                customBodyRender: customAlignRight,
                sort: true,
                sortCompare: order => sortDate(order),
              },
            });
          } else if (element === 'bleed_date') {
            columns.push({
              name: element,
              label: getColumnLabel(element),
              index: getColumnIndex(element),
              options: {
                filter: true,
                customBodyRender: customAlignRight,
                filterType: 'custom',
                customFilterListOptions: {
                  render: v => customFilterListOptionsRender(v, 'Bleed Date'),
                  update: (filterList, filterPos, index) =>
                    customFilterListOptionsUpdate(filterList, filterPos, index),
                },
                filterOptions: {
                  names: [],
                  logic(val, filters) {
                    return filters.length ? filterDateOptionsLogic(val, filters) : false;
                  },
                  // eslint-disable-next-line react/no-unstable-nested-components
                  display: (filterList, onChange, index, column) => (
                    <CustomRangeFilter
                      filterList={filterList}
                      onChange={onChange}
                      index={index}
                      column={column}
                      label="Bleed Date"
                      type="date"
                      list={getMinMaxDates(data, 'bleed_date')}
                    />
                  ),
                },
              },
            });
          } else {
            columns.push({
              name: element,
              label: getColumnLabel(element),
              index: getColumnIndex(element),
              options: {
                filter: getFilter(element),
                filterType: getFilterType(element),
                filterOptions: {},
                sort: true,
              },
            });
          }
        }
      });
    }
    columns.sort((a, b) => a.index - b.index);
    return columns;
  };

  const displayResults = () => {
    if (loading) {
      return <Loader />;
    }
    if (valid && data !== 'No results found' && data && data[0]) {
      return <Table columns={getColumns()} title="Plasma Donations List" data={data} />;
    }
    if ((!valid || data === 'No results found') && retrieved) {
      return <div className={classes.errorMessage}>No results found</div>;
    }
    return '';
  };

  return (
    <Grid
      className={classes.search}
      container
      direction="row"
      spacing={3}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <div className={classes.root}>
          <div className={classes.container}>
            <Typography className={classes.modalTitle} variant="h4" component="h1" gutterBottom>
              Search for Donations, Centers, and Lookback
            </Typography>
            <TextField
              className={classes.searchBar}
              id="outlined-full-width"
              InputLabelProps={{ shrink: true }}
              label="Search PBNs, PDNs, Bill of Lading IDs, Donation Status or Center Name"
              type="search"
              fullWidth
              alt="searchBar"
              margin="normal"
              variant="outlined"
              onChange={e => {
                setInput(e.target.value);
                setRetrieved(false);
              }}
              onKeyDown={keyPress}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.root}>
          <div className={classes.container}>{displayResults()}</div>
        </div>
      </Grid>
    </Grid>
  );
}

Search.propTypes = {
  handleClose: PropTypes.func,
};

Search.defaultProps = {
  handleClose: () => {},
};

export default Search;
