import React from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from './ViewInDevBanner.styles';

function ViewInDevBanner() {
  const classes = useStyles();

  return (
    <div className={classes.messageBanner}>
      <Typography className={classes.title} variant="h6">
        View in Development
      </Typography>
      <Typography className={classes.subtitle} variant="h6">
        Certain data fields or columns in this view may be in progress.
      </Typography>
    </div>
  );
}

ViewInDevBanner.propTypes = {};

export default ViewInDevBanner;
