import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  messageBanner: {
    backgroundColor: '#f1f1f1',
    border: `solid 1px #f1f1f1`,
    borderRadius: `2px`,
    color: '#000',
    padding: theme.spacing(2),
    display: `flex`,
    flexDirection: `column`,
  },
  title: {
    fontWeight: 700,
    marginBottom: `5px`,
  },
  subtitle: {
    fontWeight: 200,
    fontSize: `13px`,
    fontStyle: `italic`,
  },
}));

export default useStyles;
