import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import map from 'lodash/map';
import get from 'lodash/get';
import Table from '../../components/Table/Table';
import {
  fetchManufacturingGetPhaseOverview,
  fetchManufacturingGetLocations,
} from '../../redux/manufacturing';
import { options, numDecimalsCheck, withCommas, useBackForwardCommands, customAlignRight } from '../../utils';
import useStyles from './Manufacturing.styles';
import Loader from '../../components/Loader/Loader';
import SummaryOverview from '../../components/SummaryOverview/SummaryOverview';
import { donationsColumn, iggColumn, volumeColumn } from '../../components/Table/Columns';
import { TableLink } from '../../components/Table/TableLink';
import ViewInDevBanner from '../../components/ViewInDevBanner/ViewInDevBanner';

function Manufacturing() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  useBackForwardCommands(history);

  const manufacturingLocationsLoading = useSelector(state =>
    get(state, 'loading.FETCH_MANUFACTURING_GET_LOCATIONS', true)
  );
  const manufacturingOverviewLoading = useSelector(state =>
    get(state, 'loading.FETCH_MANUFACTURING_GET_PHASE_OVERVIEW', true)
  );

  useEffect(() => {
    dispatch(fetchManufacturingGetLocations());
    dispatch(fetchManufacturingGetPhaseOverview());
  }, []);

  const locationData = useSelector(state =>
    map(get(state, 'entities.manufacturingLocations.data', []), location => ({
      locations: location.locations || '-',
      locationid: location.locationid || '-',
      district: location.district || '-',
      region: location.region || '-',
      donations: withCommas(location.donations) || '-',
      volume: numDecimalsCheck(location.volume, 3),
      est_igg: numDecimalsCheck(location.est_igg, 2),
      received_volume: numDecimalsCheck(location.received_volume, 3),
      sorted_volume: numDecimalsCheck(location.sorted_volume, 3),
      released_volume: numDecimalsCheck(location.released_volume, 3),
      state: location.state || '-',
    }))
  );

  const overviewData = useSelector(state =>
    map(get(state, 'entities.manufacturingOverview.overview', []), overview => ({
      'View by Processes': [
        {
          title: 'Total Volume (L)',
          detail: numDecimalsCheck(overview.volume_manufacturing, 3),
          secondaryDetails: [
            {
              label: 'Donations',
              detail: overview.total_donations_manufacturing,
            },
            {
              label: 'Est. gamma(g)',
              detail: overview.total_igg_manufacturing,
            },
          ],
        },
        { title: 'Divider' },
        {
          title: 'Received (L)',
          detail: numDecimalsCheck(overview.received_volume, 3),
          secondaryDetails: [
            {
              label: 'Donations',
              detail: overview.received_donations,
            },
            {
              label: 'Est. gamma(g)',
              detail: overview.received_igg,
            },
          ],
        },
        {
          title: 'Sorted (L)',
          detail: numDecimalsCheck(overview.sorted_volume, 3),
          secondaryDetails: [
            {
              label: 'Donations',
              detail: overview.sorted_donations,
            },
            {
              label: 'Est. gamma(g)',
              detail: overview.sorted_igg,
            },
          ],
        },
        {
          title: 'Released at MFG (L)',
          detail: numDecimalsCheck(overview.released_volume, 3),
          secondaryDetails: [
            {
              label: 'Donations',
              detail: overview.released_donations,
            },
            {
              label: 'Est. gamma(g)',
              detail: overview.released_igg,
            },
          ],
        },
      ],
    }))
  );

  const overviews = {
    ...overviewData[0],
    // ...overviewDetails[0], Remove comment when details required for main
  };

  const findId = name => {
    const info = locationData.filter(obj => obj.locations === name);
    return info[0].locationid;
  };

  const columns = [
    {
      name: 'locations',
      label: 'Locations',
      options: {
        filter: false,
        sort: true,
        viewColumns: false,
        customBodyRender: value =>
          TableLink(value, {
            pathname: `/manufacturing/location/${findId(value)}/${(value || '').toLowerCase()}`,
          }),
      },
    },
    donationsColumn({
      className: classes.rightAlignedHeader,
    }),
    volumeColumn({
      className: classes.rightAlignedHeader,
    }),
    {
      name: 'received_volume',
      label: 'Received (L)',
      options: options('Received', customAlignRight, { className: classes.shortRightTableHeader }),
    },
    {
      name: 'sorted_volume',
      label: 'Sorted (L)',
      options: options('Sorted', customAlignRight, { className: classes.shortRightTableHeader }),
    },
    {
      name: 'released_volume',
      label: 'Released',
      options: options('Released', customAlignRight, { className: classes.shortRightTableHeader }),
    },
    iggColumn({
      className: classes.rightAlignedHeader,
    }),
  ];

  return (
    <Grid
      className={classes.manufacturing}
      container
      direction="row"
      spacing={3}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <ViewInDevBanner />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="overline" className={classes.overviewSubtitle}>
          INVENTORY / MANUFACTURING
        </Typography>
        <Typography className={classes.title} variant="h4" noWrap>
          Manufacturing
        </Typography>
      </Grid>
      <div className={classes.overview}>
        {manufacturingOverviewLoading ? (
          <Loader />
        ) : (
          <SummaryOverview data={overviews} showCounts loading={manufacturingOverviewLoading} />
        )}
      </div>
      <Grid item xs={12}>
        {manufacturingLocationsLoading ? (
          <Loader />
        ) : (
          <Table columns={columns} title="Plant Warehouse List" data={locationData} />
        )}
      </Grid>
    </Grid>
  );
}

export default Manufacturing;
