import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import map from 'lodash/map';
import get from 'lodash/get';
import Table from '../../components/Table/Table';
import { fetchCollectionGetPhaseOverview } from '../../redux/overview';
import { fetchCollectionGetLocations } from '../../redux/locations';
import {
  numDecimalsCheck,
  sortNum,
  sortNumberFilter,
  sortStringFilter,
  withCommas,
  useBackForwardCommands,
  customAlignRight,
} from '../../utils';
import useStyles from './Collection.styles';
import Loader from '../../components/Loader/Loader';
import SummaryOverview from '../../components/SummaryOverview/SummaryOverview';
import {
  donationsColumn,
  volumeColumn,
  collectedColumn,
  boxedColumn,
  bolColumn,
  iggColumn,
} from '../../components/Table/Columns';
import { TableLink } from '../../components/Table/TableLink';
import ViewInDevBanner from '../../components/ViewInDevBanner/ViewInDevBanner';

function Collection() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  useBackForwardCommands(history);

  const loading = useSelector(state => get(state, 'loading.FETCH_COLLECTION_GET_LOCATIONS', true));
  const overviewLoading = useSelector(state =>
    get(state, 'loading.FETCH_COLLECTION_GET_PHASE_OVERVIEW', true)
  );

  const overviewData = useSelector(state =>
    map(get(state, 'entities.overview.overview', {}), overview => ({
      'View by Processes': [
        {
          title: 'Total Volume (L)',
          detail: numDecimalsCheck(overview.total_volume, 3),
          secondaryDetails: [
            {
              label: 'Donations',
              detail: overview.total_donations,
            },
            {
              label: 'Est. gamma(g)',
              detail: overview.total_igg,
            },
          ],
        },
        { title: 'Divider' },
        {
          title: 'Collected (L)',
          detail: numDecimalsCheck(overview.collected_volume, 3),
          secondaryDetails: [
            {
              label: 'Donations',
              detail: overview.collected_donations,
            },
            {
              label: 'Est. gamma(g)',
              detail: overview.collected_igg,
            },
          ],
        },
        {
          title: 'Boxed (L)',
          detail: numDecimalsCheck(overview.boxed_volume, 3),
          secondaryDetails: [
            {
              label: 'Donations',
              detail: overview.boxed_donations,
            },
            {
              label: 'Boxes',
              detail: overview.boxes,
            },
            {
              label: 'Est. gamma(g)',
              detail: overview.boxed_igg,
            },
          ],
        },
        {
          title: 'Boxed with BOL (L)',
          detail: numDecimalsCheck(overview.bol_volume, 3),
          secondaryDetails: [
            {
              label: 'Donations',
              detail: overview.bol_donations,
            },
            {
              label: 'BOLs',
              detail: overview.bols,
            },
            {
              label: 'Est. gamma(g)',
              detail: overview.bol_igg,
            },
          ],
        },
      ],
    }))
  );

  useEffect(() => {
    dispatch(fetchCollectionGetPhaseOverview());
    dispatch(fetchCollectionGetLocations());
  }, []);

  const locationData = useSelector(state =>
    map(get(state, 'entities.locations.data', []), location => ({
      boxed: numDecimalsCheck(location.boxed, 3),
      bol: numDecimalsCheck(location.bol, 3),
      collected: numDecimalsCheck(location.collected, 3),
      donations: withCommas(location.donations) || '-',
      masterfile: location.master_file_approved ? 'Yes' : 'No',
      est_igg: numDecimalsCheck(location.est_igg, 2),
      item_number: location.item_number || '-',
      locationid: location.locationid || '-',
      locations: location.locations || '-',
      state: location.state || '-',
      volume: numDecimalsCheck(location.volume, 3),
      region: location.region || '-',
      district: location.district || '-',
    }))
  );

  const districts = sortNumberFilter(locationData, 'district', 'asc');
  const states = sortStringFilter(locationData, 'state', 'asc');

  const findId = name => {
    const info = locationData.filter(obj => obj.locations === name);
    return info[0].locationid;
  };

  const columns = [
    {
      name: 'locations',
      label: 'Locations',
      options: {
        filter: false,
        sort: true,
        viewColumns: false,
        customBodyRender: value =>
          TableLink(value, {
            pathname: `/collection/${findId(value)}/${(value || '').toLowerCase()}`,
          }),
      },
    },
    donationsColumn({
      className: classes.rightAlignedHeader,
    }),
    volumeColumn({
      className: classes.rightAlignedHeader,
    }),
    collectedColumn({
      className: classes.rightAlignedHeader,
    }),
    boxedColumn({
      className: classes.rightAlignedHeader,
    }),
    bolColumn({
      className: classes.rightAlignedHeader,
    }),
    iggColumn({
      className: classes.rightAlignedHeader,
    }),
    {
      name: 'masterfile',
      label: 'Master File Approved',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        filterOptions: { names: states },
        filter: true,
        sort: true,
      },
    },
    {
      name: 'district',
      label: 'District',
      options: {
        filterOptions: { names: districts },
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
        sortCompare: order => sortNum(order),
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
      },
    },
    {
      name: 'region',
      label: 'Region',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const overviews = {
    ...overviewData[0],
    // ...overviewDetails[0], Remove comment when details required for main
  };

  return (
    <Grid
      className={classes.collection}
      container
      direction="row"
      spacing={3}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <ViewInDevBanner />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="overline" className={classes.overviewSubtitle}>
          INVENTORY / COLLECTION
        </Typography>
        <Typography className={classes.title} variant="h4" noWrap>
          BioLife Donation Centers
        </Typography>
      </Grid>
      <div className={classes.overview}>
        <SummaryOverview data={overviews} showCounts loading={overviewLoading} />
      </div>
      <Grid item xs={12}>
        {loading ? (
          <Loader />
        ) : (
          <Table columns={columns} title="BioLife Donation Center List" data={locationData} />
        )}
      </Grid>
    </Grid>
  );
}

export default Collection;
