import { RSAA } from 'redux-api-middleware';

export const FETCH_COLLECTION_GET_PHASE_OVERVIEW_REQUEST = 'FETCH_COLLECTION_GET_PHASE_OVERVIEW_REQUEST';
export const FETCH_COLLECTION_GET_PHASE_OVERVIEW_SUCCESS = 'FETCH_COLLECTION_GET_PHASE_OVERVIEW_SUCCESS';
export const FETCH_COLLECTION_GET_PHASE_OVERVIEW_FAILURE = 'FETCH_COLLECTION_GET_PHASE_OVERVIEW_FAILURE';

export const FETCH_COLLECTION_GET_LOCATION_PROCESSES_OVERVIEW_REQUEST =
  'FETCH_COLLECTION_GET_LOCATION_PROCESSES_OVERVIEW_REQUEST';
export const FETCH_COLLECTION_GET_LOCATION_PROCESSES_OVERVIEW_SUCCESS =
  'FETCH_COLLECTION_GET_LOCATION_PROCESSES_OVERVIEW_SUCCESS';
export const FETCH_COLLECTION_GET_LOCATION_PROCESSES_OVERVIEW_FAILURE =
  'FETCH_COLLECTION_GET_LOCATION_PROCESSES_OVERVIEW_FAILURE';

export const FETCH_COLLECTION_GET_LOCATION_QUARANTINE_OVERVIEW_REQUEST =
  'FETCH_COLLECTION_GET_LOCATION_QUARANTINE_OVERVIEW_REQUEST';
export const FETCH_COLLECTION_GET_LOCATION_QUARANTINE_OVERVIEW_SUCCESS =
  'FETCH_COLLECTION_GET_LOCATION_QUARANTINE_OVERVIEW_SUCCESS';
export const FETCH_COLLECTION_GET_LOCATION_QUARANTINE_OVERVIEW_FAILURE =
  'FETCH_COLLECTION_GET_LOCATION_QUARANTINE_OVERVIEW_FAILURE';

export const FETCH_COLLECTION_GET_DONATION_OVERVIEW_REQUEST =
  'FETCH_COLLECTION_GET_DONATION_OVERVIEW_REQUEST';
export const FETCH_COLLECTION_GET_DONATION_OVERVIEW_SUCCESS =
  'FETCH_COLLECTION_GET_DONATION_OVERVIEW_SUCCESS';
export const FETCH_COLLECTION_GET_DONATION_OVERVIEW_FAILURE =
  'FETCH_COLLECTION_GET_DONATION_OVERVIEW_FAILURE';

export const FETCH_COMPLIANCE_GET_LOOKBACK_OVERVIEW_REQUEST =
  'FETCH_COMPLIANCE_GET_LOOKBACK_OVERVIEW_REQUEST';
export const FETCH_COMPLIANCE_GET_LOOKBACK_OVERVIEW_SUCCESS =
  'FETCH_COMPLIANCE_GET_LOOKBACK_OVERVIEW_SUCCESS';
export const FETCH_COMPLIANCE_GET_LOOKBACK_OVERVIEW_FAILURE =
  'FETCH_COMPLIANCE_GET_LOOKBACK_OVERVIEW_FAILURE';

export const FETCH_COMPLIANCE_GET_TOP_REASONS_REQUEST = 'FETCH_COMPLIANCE_GET_TOP_REASONS_REQUEST';
export const FETCH_COMPLIANCE_GET_TOP_REASONS_SUCCESS = 'FETCH_COMPLIANCE_GET_TOP_REASONS_SUCCESS';
export const FETCH_COMPLIANCE_GET_TOP_REASONS_FAILURE = 'FETCH_COMPLIANCE_GET_TOP_REASONS_FAILURE';

export const FETCH_COMPLIANCE_GET_TOP_REASONS_DONATIONS_LIST_REQUEST =
  'FETCH_COMPLIANCE_GET_TOP_REASONS_DONATIONS_LIST_REQUEST';
export const FETCH_COMPLIANCE_GET_TOP_REASONS_DONATIONS_LIST_SUCCESS =
  'FETCH_COMPLIANCE_GET_TOP_REASONS_DONATIONS_LIST_SUCCESS';
export const FETCH_COMPLIANCE_GET_TOP_REASONS_DONATIONS_LIST_FAILURE =
  'FETCH_COMPLIANCE_GET_TOP_REASONS_DONATIONS_LIST_FAILURE';

export const FETCH_COMPLIANCE_GET_TOP_LOCATIONS_QUARANTINE_REQUEST =
  'FETCH_COMPLIANCE_GET_TOP_LOCATIONS_QUARANTINE_REQUEST';
export const FETCH_COMPLIANCE_GET_TOP_LOCATIONS_QUARANTINE_SUCCESS =
  'FETCH_COMPLIANCE_GET_TOP_LOCATIONS_QUARANTINE_SUCCESS';
export const FETCH_COMPLIANCE_GET_TOP_LOCATIONS_QUARANTINE_FAILURE =
  'FETCH_COMPLIANCE_GET_TOP_LOCATIONS_QUARANTINE_FAILURE';

export const FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_SUBMITTED_REQUEST =
  'FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_SUBMITTED_REQUEST';
export const FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_SUBMITTED_SUCCESS =
  'FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_SUBMITTED_SUCCESS';
export const FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_SUBMITTED_FAILURE =
  'FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_SUBMITTED_FAILURE';

export const FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_CONFIRMED_REQUEST =
  'FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_CONFIRMED_REQUEST';
export const FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_CONFIRMED_SUCCESS =
  'FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_CONFIRMED_SUCCESS';
export const FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_CONFIRMED_FAILURE =
  'FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_CONFIRMED_FAILURE';

export const FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DESTROYED_REQUEST =
  'FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DESTROYED_REQUEST';
export const FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DESTROYED_SUCCESS =
  'FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DESTROYED_SUCCESS';
export const FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DESTROYED_FAILURE =
  'FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DESTROYED_FAILURE';

export const FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DONATIONS_LIST_REQUEST =
  'FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DONATIONS_LIST_REQUEST';
export const FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DONATIONS_LIST_SUCCESS =
  'FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DONATIONS_LIST_SUCCESS';
export const FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DONATIONS_LIST_FAILURE =
  'FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DONATIONS_LIST_FAILURE';

export const fetchCollectionGetPhaseOverview = () => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-collection-get-phase-overview`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COLLECTION_GET_PHASE_OVERVIEW_REQUEST,
      {
        type: FETCH_COLLECTION_GET_PHASE_OVERVIEW_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { overview: json } };
        },
      },
      {
        type: FETCH_COLLECTION_GET_PHASE_OVERVIEW_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchCollectionGetLocationProcessesOverview = id => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-collection-get-location-processes-overview/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COLLECTION_GET_LOCATION_PROCESSES_OVERVIEW_REQUEST,
      {
        type: FETCH_COLLECTION_GET_LOCATION_PROCESSES_OVERVIEW_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { overviewProcesses: json } };
        },
      },
      {
        type: FETCH_COLLECTION_GET_LOCATION_PROCESSES_OVERVIEW_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchCollectionGetLocationQuarantineOverview = id => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-collection-get-location-quarantine-overview/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COLLECTION_GET_LOCATION_QUARANTINE_OVERVIEW_REQUEST,
      {
        type: FETCH_COLLECTION_GET_LOCATION_QUARANTINE_OVERVIEW_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { overviewQuarantine: json } };
        },
      },
      {
        type: FETCH_COLLECTION_GET_LOCATION_QUARANTINE_OVERVIEW_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchCollectionGetDonationOverview = pbn => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-collection-get-donation-overview/${pbn}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COLLECTION_GET_DONATION_OVERVIEW_REQUEST,
      {
        type: FETCH_COLLECTION_GET_DONATION_OVERVIEW_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { overview: json } };
        },
      },
      {
        type: FETCH_COLLECTION_GET_DONATION_OVERVIEW_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const CollectionGetPhaseOverviewMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COLLECTION_GET_PHASE_OVERVIEW_SUCCESS:
      console.log('fetch collection overview success');
      break;
    case FETCH_COLLECTION_GET_PHASE_OVERVIEW_FAILURE:
      console.log('fetch collection overview failure');
      break;
    default:
      break;
  }
};

export const CollectionGetlocationProcessesOverviewMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COLLECTION_GET_LOCATION_PROCESSES_OVERVIEW_SUCCESS:
      console.log('fetch collection location processes overview success');
      break;
    case FETCH_COLLECTION_GET_LOCATION_PROCESSES_OVERVIEW_FAILURE:
      console.log('fetch collection location processes overview failure');
      break;
    default:
      break;
  }
};

export const CollectionGetlocationQuarantineOverviewMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COLLECTION_GET_LOCATION_QUARANTINE_OVERVIEW_SUCCESS:
      console.log('fetch collection location quarantine overview success');
      break;
    case FETCH_COLLECTION_GET_LOCATION_QUARANTINE_OVERVIEW_FAILURE:
      console.log('fetch collection location quarantine overview failure');
      break;
    default:
      break;
  }
};

export const CollectionGetDonationOverviewMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COLLECTION_GET_DONATION_OVERVIEW_SUCCESS:
      console.log('fetch donation overview success');
      break;
    case FETCH_COLLECTION_GET_DONATION_OVERVIEW_FAILURE:
      console.log('fetch donation overview failure');
      break;
    default:
      break;
  }
};

// endpoints for quality and compliance page
export const fetchComplianceGetLookbackOverview = timeFrame => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-compliance-get-lookback-overview/${timeFrame}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COMPLIANCE_GET_LOOKBACK_OVERVIEW_REQUEST,
      {
        type: FETCH_COMPLIANCE_GET_LOOKBACK_OVERVIEW_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { overview: json } };
        },
      },
      {
        type: FETCH_COMPLIANCE_GET_LOOKBACK_OVERVIEW_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const ComplianceGetLookbackOverviewMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COMPLIANCE_GET_LOOKBACK_OVERVIEW_SUCCESS:
      console.log('fetch compliance lookback overview success');
      break;
    case FETCH_COMPLIANCE_GET_LOOKBACK_OVERVIEW_FAILURE:
      console.log('fetch compliance lookback overview failure');
      break;
    default:
      break;
  }
};

export const fetchComplianceGetTopReasons = timeframe => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-compliance-get-top-lookback-reasons/${timeframe}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COMPLIANCE_GET_TOP_REASONS_REQUEST,
      {
        type: FETCH_COMPLIANCE_GET_TOP_REASONS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { topReasons: json } };
        },
      },
      {
        type: FETCH_COMPLIANCE_GET_TOP_REASONS_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const ComplianceGetTopReasonsMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COMPLIANCE_GET_TOP_REASONS_SUCCESS:
      console.log('fetch compliance and quality top reasons success');
      break;
    case FETCH_COMPLIANCE_GET_TOP_REASONS_FAILURE:
      console.log('fetch compliance and quality top reasons failure');
      break;
    default:
      break;
  }
};

export const fetchComplianceGetTopReasonsDonationsList = (
  days,
  type,
  reason,
  limit,
  offset,
  sortBy,
  orderBy,
  search,
  filterQuery = ''
) => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-compliance-get-top-reasons-donations-list/?type=${type}&reason=${reason}&days=${days}&limit=${limit}&offset=${offset}&sortby=${sortBy}&orderby=${orderBy}&search=${search}${filterQuery}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COMPLIANCE_GET_TOP_REASONS_DONATIONS_LIST_REQUEST,
      {
        type: FETCH_COMPLIANCE_GET_TOP_REASONS_DONATIONS_LIST_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { topReasonsDonationsList: json } };
        },
      },
      {
        type: FETCH_COMPLIANCE_GET_TOP_REASONS_DONATIONS_LIST_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const ComplianceGetTopReasonsDonationsListMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COMPLIANCE_GET_TOP_REASONS_DONATIONS_LIST_SUCCESS:
      console.log('fetch compliance and quality top reasons donations list success');
      break;
    case FETCH_COMPLIANCE_GET_TOP_REASONS_DONATIONS_LIST_FAILURE:
      console.log('fetch compliance and quality top reasons donations list failure');
      break;
    default:
      break;
  }
};

export const fetchComplianceGetTopLocationsQuarantine = timeframe => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-compliance-get-top-locations/${timeframe}?type=quarantine`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COMPLIANCE_GET_TOP_LOCATIONS_QUARANTINE_REQUEST,
      {
        type: FETCH_COMPLIANCE_GET_TOP_LOCATIONS_QUARANTINE_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { topLocationsQuarantine: json } };
        },
      },
      {
        type: FETCH_COMPLIANCE_GET_TOP_LOCATIONS_QUARANTINE_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const ComplianceGetTopLocationsQuarantineMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COMPLIANCE_GET_TOP_LOCATIONS_QUARANTINE_SUCCESS:
      console.log('fetch compliance top locations quarantine success');
      break;
    case FETCH_COMPLIANCE_GET_TOP_LOCATIONS_QUARANTINE_FAILURE:
      console.log('fetch compliance top locations quarantine failure');
      break;
    default:
      break;
  }
};

export const fetchComplianceGetTopLocationsLookbackSubmitted = timeframe => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-compliance-get-top-locations/${timeframe}?type=lookback_submitted`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_SUBMITTED_REQUEST,
      {
        type: FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_SUBMITTED_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { topLocationsLookbackSubmitted: json } };
        },
      },
      {
        type: FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_SUBMITTED_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const ComplianceGetTopLocationsLookbackSubmittedMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_SUBMITTED_SUCCESS:
      console.log('fetch compliance top locations lookback submitted success');
      break;
    case FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_SUBMITTED_FAILURE:
      console.log('fetch compliance top locations lookback submitted failure');
      break;
    default:
      break;
  }
};

export const fetchComplianceGetTopLocationsLookbackConfirmed = timeframe => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-compliance-get-top-locations/${timeframe}?type=lookback_confirmed`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_CONFIRMED_REQUEST,
      {
        type: FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_CONFIRMED_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { topLocationsLookbackConfirmed: json } };
        },
      },
      {
        type: FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_CONFIRMED_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const ComplianceGetTopLocationsLookbackConfirmedMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_CONFIRMED_SUCCESS:
      console.log('fetch compliance top locations lookback confirmed success');
      break;
    case FETCH_COMPLIANCE_GET_TOP_LOCATIONS_LOOKBACK_CONFIRMED_FAILURE:
      console.log('fetch compliance top locations lookback confirmed failure');
      break;
    default:
      break;
  }
};

export const fetchComplianceGetTopLocationsDestroyed = timeframe => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-compliance-get-top-locations/${timeframe}?type=destroyed`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DESTROYED_REQUEST,
      {
        type: FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DESTROYED_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { topLocationsDestroyed: json } };
        },
      },
      {
        type: FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DESTROYED_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const ComplianceGetTopLocationsDestroyedMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DESTROYED_SUCCESS:
      console.log('fetch compliance top locations destroyed success');
      break;
    case FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DESTROYED_FAILURE:
      console.log('fetch compliance top locations destroyed failure');
      break;
    default:
      break;
  }
};

export const fetchComplianceGetTopLocationsDonationsList = (
  locationId,
  days,
  topType,
  limit,
  offset,
  sortBy,
  orderBy,
  search,
  filterQuery = ''
) => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-compliance-get-top-locations-donations-list?locationid=${locationId}&toptype=${topType}&days=${days}&limit=${limit}&offset=${offset}&sortby=${sortBy}&orderby=${orderBy}&search=${search}${filterQuery}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DONATIONS_LIST_REQUEST,
      {
        type: FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DONATIONS_LIST_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { topQualityCenter: json } };
        },
      },
      {
        type: FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DONATIONS_LIST_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const ComplianceGetTopLocationsDonationsListMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DONATIONS_LIST_SUCCESS:
      console.log('fetch inventory qualilty top center success');
      break;
    case FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DONATIONS_LIST_FAILURE:
      console.log('fetch inventory qualilty top center failure');
      break;
    default:
      break;
  }
};
