/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import map from 'lodash/map';
import get from 'lodash/get';
import TabPanel from '../../components/TabPanel/TabPanel';
import Table from '../../components/Table/Table';
import Modal from '../../components/Modal/Modal';
import TableSearch from '../../components/TableSearch/TableSearch';
import ModalDetails from '../../components/ModalDetails/ModalDetails';
import ViewInDevBanner from '../../components/ViewInDevBanner/ViewInDevBanner';
import useStyles from './CollectionLocation.styles';
import {
  fetchCollectionGetLocationDonations,
  fetchCollectionGetLocationDonationsDates,
} from '../../redux/donations';
import { fetchCollectionGetLocationBoxedDonations } from '../../redux/boxedDonations';
import { fetchCollectionGetLocationBolDonations } from '../../redux/BOLDonations';
import { fetchTransitGetShippedBOLsCarrierConsignee } from '../../redux/transitBOL';
import {
  formatDate,
  formatStatus,
  options,
  filterDateOptionsLogic,
  customFilterListOptionsRender,
  customFilterListOptionsUpdate,
  numDecimalsCheck,
  returnRowContainsStatus,
  sortNum,
  sortDate,
  withCommas,
  customAlignRight,
  checkAndFormatIfDate,
  formatLocationName,
  getFilterArray,
} from '../../utils';
import Loader from '../../components/Loader/Loader';
import LocationOverview from '../../components/LocationOverview/LocationOverview';
import CustomRangeFilter from '../../components/CustomRangeFilter/CustomRangeFilter';
import {
  donationsColumn,
  volumeColumn,
  iggColumn,
  agingColumn,
  statusTimeColumn,
  transitionColumn,
  totalVelocityColumn,
  itemNumberColumn,
  tokenColumn,
  pdnColumn,
  pbnColumn,
} from '../../components/Table/Columns';
import { TableLink, TableModal } from '../../components/Table/TableLink';

function CollectionLocation() {
  const { id, location } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState('donations');
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [openModalAs, setModalOpenAs] = useState('');
  const [selectedOverview, setSelectedOverview] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [sortOptions, setSortOptions] = useState({ name: 'aging', direction: 'asc' });
  const [filterOptions, setFilterOptions] = useState({});
  const [boxedRetrieved, setBoxedRetrieved] = useState(false);
  const [bolRetrieved, setBolRetrieved] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [bolId, setBolId] = useState('-1');

  const locationDonationsLoading = useSelector(state => {
    const loadingArray = [
      'FETCH_COLLECTION_GET_LOCATION_DONATIONS',
      'FETCH_COLLECTION_GET_LOCATION_BOXED_DONATIONS',
      'FETCH_COLLECTION_GET_LOCATION_BOL_DONATIONS',
    ];

    const even = element => state.loading[element] === true;

    return Object.keys(state.loading).length !== 0 ? loadingArray.some(even) : true;
  });

  const boxedData = useSelector(state => {
    if (get(state, 'entities.boxed.data', {})[0] === 'no entries') {
      return [];
    }
    return map(get(state, 'entities.boxed.data', {}), box => ({
      'box-number': box['box-number'] || '-',
      donations: box.donations,
      volume: numDecimalsCheck(box.volume, 3),
      'est-igg': numDecimalsCheck(box['est-igg'], 2),
      'earliest-bleed-date': formatDate(box['earliest-bleed-date']),
      'latest-bleed-date': formatDate(box['latest-bleed-date']),
      'min-age': withCommas(box['min-age']),
      'max-age': withCommas(box['max-age']),
      'date-boxed': formatDate(box['date-boxed']),
    }));
  });

  const bolData = useSelector(state => {
    if (get(state, 'entities.bol.data', {})[0] === 'no entries') {
      return [];
    }
    return map(get(state, 'entities.bol.data', {}), bol => ({
      'bol-number': bol['bol-number'] || '-',
      boxes: bol.boxes,
      volume: numDecimalsCheck(bol.volume, 3),
      'est-igg': numDecimalsCheck(bol['est-igg'], 2),
      item_number: bol.item_number || '-',
      carrier: bol.carrier || '-',
      'earliest-bleed-date': formatDate(bol['earliest-bleed-date']),
      'latest-bleed-date': formatDate(bol['latest-bleed-date']),
      'min-box-number': bol['min-box-number'] || '-',
      'max-box-number': bol['max-box-number'] || '-',
    }));
  });

  const allData = useSelector(state =>
    map(get(state, 'entities.donations.data', {}), donation => ({
      total_queried_count: Number(donation.total_queried_count),
      pbn: donation.pbn || '-',
      pdn: donation.pdn || '-',
      status: formatStatus(donation.status),
      token_id: donation.token || '-',
      volume: numDecimalsCheck(donation.volume, 3),
      est_igg: numDecimalsCheck(donation.est_igg, 2),
      aging: withCommas(donation.aging),
      status_time: withCommas(donation.status_time),
      transition_velocity: withCommas(donation.transition_velocity),
      total_velocity: withCommas(donation.total_velocity),
      bleed_date: formatDate(donation.bleed_date),
      flavor: donation.flavor || '-',
      item_number: donation.item_number || '-',
    }))
  );

  const bleedDates = useSelector(state =>
    map(get(state, 'entities.dates.data', {}), bleedDate => ({
      min_date: formatDate(bleedDate.min_date),
      max_date: formatDate(bleedDate.max_date),
    }))
  );

  const carriersConsignees = useSelector(state =>
    map(get(state, 'entities.carriersConsignees.data', []), cc => ({
      carrier: cc.carrier || '-',
    }))
  );

  // sort objects for all three tables

  const sortBoxed = {
    name: 'max-age',
    direction: 'desc',
  };

  const sortBOL = {
    name: 'earliest-bleed-date',
    direction: 'asc',
  };

  const revivalBack = () => {
    window.onpopstate = () => undefined;
    window.history.back();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedId('');
    setSelectedType('');
    revivalBack();
  };

  // donations boxed_bol boxed
  const handleChange = (e, value) => {
    if (id && value === 'boxed') {
      if (!boxedRetrieved) {
        setBoxedRetrieved(true);
        dispatch(fetchCollectionGetLocationBoxedDonations(id));
      }
    }
    if (id && value === 'boxed_bol') {
      if (!bolRetrieved) {
        setBolRetrieved(true);
        dispatch(fetchCollectionGetLocationBolDonations(id));
        dispatch(fetchTransitGetShippedBOLsCarrierConsignee());
      }
    }
    setSelectedTab(value);
  };

  const allColumns = [
    pbnColumn(value => TableLink(value, `/collection/${id}/donation/${value}/`)),
    pdnColumn,
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Status: ${v.replace(/[0-9]|\./g, '')}`,
        },
        filterOptions: {
          logic: (status, filters) => returnRowContainsStatus(status, filters),
          names: ['Collected', 'Boxed', 'Boxed with BOL'],
        },
        sort: true,
      },
    },
    tokenColumn(),
    volumeColumn({
      className: classes.rightAlignedHeader,
    }),
    iggColumn({
      className: classes.rightAlignedHeader,
    }),
    {
      name: 'bleed_date',
      label: 'Bleed Date (MM/DD/YYYY)',
      // options: options('Bleed Date', 'alignRight'),
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => customFilterListOptionsRender(v, 'Bleed Date'),
          update: (filterList, filterPos, index) =>
            customFilterListOptionsUpdate(filterList, filterPos, index),
        },
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
        filterOptions: {
          names: [],
          logic(val, filters) {
            return filters.length ? filterDateOptionsLogic(val, filters) : false;
          },
          display: (filterList, onChange, index, column) => (
            <CustomRangeFilter
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
              label="Bleed Date"
              type="date"
              list={bleedDates[0]}
            />
          ),
        },
      },
    },
    {
      name: 'flavor',
      label: 'Flavor',
      options: {
        filter: true,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Flavor: ${v}`,
        },
        filterOptions: {
          logic: (flavor, filters) => returnRowContainsStatus(flavor, filters),
          names: ['Source', 'Void', 'No Take', 'Physical', 'Source-Designated', 'Low Volume', 'Salvaged'],
        },
        sort: true,
      },
    },
    itemNumberColumn({
      className: classes.leftAlignedHeader,
    }),
    agingColumn({
      className: classes.rightAlignedHeader,
    }),
    statusTimeColumn({
      className: classes.rightAlignedHeader,
    }),
    transitionColumn({
      className: classes.rightAlignedHeader,
    }),
    totalVelocityColumn({
      className: classes.rightAlignedHeader,
    }),
  ];

  useEffect(() => {
    if (id) {
      dispatch(
        fetchCollectionGetLocationDonations(
          id,
          300,
          0,
          sortOptions.name,
          sortOptions.direction,
          checkAndFormatIfDate(searchText)
        )
      );
      dispatch(fetchCollectionGetLocationDonationsDates(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    /* If the total donations are greater than the current shown
       Fetch the donations each time the user changes table status */
    const limit = (page + 3) * rowsPerPage;
    const offset = 0; // page * rowsPerPage;
    const donationCount = allData && allData[0] ? allData[0].total_queried_count : 300;

    const dateColumns = ['bleed_date'];

    /* if filters are added, parse and add to query */
    const filterQuery = getFilterArray(allColumns, dateColumns, filterOptions);

    if (id && donationCount > 300) {
      dispatch(
        fetchCollectionGetLocationDonations(
          id,
          limit,
          offset,
          sortOptions.name,
          sortOptions.direction,
          checkAndFormatIfDate(searchText === null ? '' : searchText),
          filterQuery.join('')
        )
      );
    }
  }, [id, page, rowsPerPage, sortOptions, filterOptions, searchText, dispatch]);

  const findBolOverview = rowId => {
    const bolOverview = bolData.filter(bol => bol['bol-number'] === rowId && bol);
    return bolOverview[0];
  };

  const handleRowClick = (rowId, type) => {
    setModalOpenAs(type);
    setSelectedId(rowId);
    if (type === 'bol') {
      setBolId(rowId);
    } else {
      setBolId('-1');
    }
    if (type === 'box') {
      const boxOverview = boxedData.filter(box => box['box-number'] === rowId && box);
      setSelectedOverview(boxOverview[0]);
    }
    if (type === 'bol') {
      setSelectedOverview(findBolOverview(rowId));
    }
    setSelectedType(type);
    setOpenModal(true);
  };

  // donations boxed_bol boxed
  const boxedColumns = [
    {
      name: 'box-number',
      label: 'Box Number',
      options: {
        sort: true,
        sortCompare: order => sortNum(order),
        viewColumns: false,
        customBodyRender: value => TableModal(value, () => handleRowClick(value, 'box')),
      },
    },
    donationsColumn({
      className: classes.rightAlignedHeader,
    }),
    volumeColumn({
      className: classes.rightAlignedHeader,
    }),
    iggColumn({
      className: classes.rightAlignedHeader,
    }),
    {
      name: 'earliest-bleed-date',
      label: 'Earliest Bleed Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
        sortCompare: order => sortDate(order),
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
      },
    },
    {
      name: 'latest-bleed-date',
      label: 'Latest Bleed Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
        sortCompare: order => sortDate(order),
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
      },
    },
    {
      name: 'max-age',
      label: 'Max Age (days)',
      options: options('Max Age', customAlignRight, {
        className: classes.rightAlignedHeader,
      }),
    },
    {
      name: 'min-age',
      label: 'Min Age (days)',
      options: options('Min Age', customAlignRight, {
        className: classes.rightAlignedHeader,
      }),
    },
    {
      name: 'date-boxed',
      label: 'Date Boxed',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
        sortCompare: order => sortDate(order),
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
      },
    },
  ];

  const bolColumns = [
    {
      name: 'bol-number',
      label: 'BOL Number',
      options: {
        sort: true,
        sortCompare: order => sortNum(order),
        viewColumns: false,
        setCellHeaderProps: () => ({
          className: classes.leftAlignedHeader,
        }),
        customBodyRender: value => TableModal(value, () => handleRowClick(value, 'bol')),
      },
    },
    {
      name: 'boxes',
      label: 'Boxes (boxes)',
      options: options('Boxes', customAlignRight, {
        className: classes.rightAlignedHeader,
      }),
    },
    volumeColumn({
      className: classes.rightAlignedHeader,
    }),
    iggColumn({
      className: classes.rightAlignedHeader,
    }),
    itemNumberColumn({
      className: classes.leftAlignedHeader,
    }),
    {
      name: 'carrier',
      label: 'Carrier Number',
      options: {
        filter: true,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Carrier Number: ${v}`,
        },
        setCellHeaderProps: () => ({
          className: classes.leftAlignedHeader,
        }),
        filterOptions: {
          logic: (carrier, filters) => returnRowContainsStatus(carrier, filters),
          names: carriersConsignees[0] ? carriersConsignees[0].carrier : '',
        },
        sort: true,
      },
    },
    {
      name: 'earliest-bleed-date',
      label: 'Earliest Bleed Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
        sortCompare: order => sortDate(order),
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
      },
    },
    {
      name: 'latest-bleed-date',
      label: 'Latest Bleed Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
        sortCompare: order => sortDate(order),
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
      },
    },
    {
      name: 'min-box-number',
      label: 'Min Box #',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
        sortCompare: order => sortNum(order),
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
      },
    },
    {
      name: 'max-box-number',
      label: 'Max Box #',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
        sortCompare: order => sortNum(order),
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
      },
    },
  ];

  return (
    <Grid
      className={classes.location}
      container
      direction="row"
      spacing={3}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <ViewInDevBanner />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.title} variant="h4" noWrap>
          {location ? formatLocationName(location) : ''}
        </Typography>
      </Grid>
      <div className={classes.overview}>
        <LocationOverview id={id} center={location ? formatLocationName(location) : ''} />
      </div>
      <Grid item xs={12}>
        <Tabs
          classes={{
            root: classes.navBar,
            flexContainer: classes.flexContainer,
            indicator: classes.sectionIndicator,
          }}
          value={selectedTab}
          onChange={handleChange}
        >
          <Tab className={classes.tabLink} value="donations" label="All Donations" />
          <Tab className={classes.tabLink} value="boxed" label="Boxed" />
          <Tab className={classes.tabLink} value="boxed_bol" label="Boxed With BOL" />
        </Tabs>
        <TabPanel value={selectedTab} index="donations">
          <Table
            columns={allColumns}
            title="Plasma Donations List: All Donations"
            data={allData}
            sortOptions={sortOptions}
            setSortOptions={setSortOptions}
            isLoading={locationDonationsLoading}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            setFilterOptions={setFilterOptions}
            options={{
              onSearchChange: text => setSearchText(text),
              customSearchRender: (_searchText, handleSearch, onHide) => (
                // handleSearch is mui datatables default function
                // we utilize it to handle searches that don't require the api
                // takes a parameter which is the search query
                <TableSearch
                  options={{ searchPlaceholder: searchText }}
                  setCustomSearchText={setSearchText}
                  onSearch={handleSearch}
                  onHide={() => {
                    onHide();
                    setSearchText('');
                  }}
                />
              ),
            }}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index="boxed">
          {locationDonationsLoading ? (
            <Loader />
          ) : (
            <Table
              columns={boxedColumns}
              title="Plasma Donations: Boxed"
              data={boxedData}
              sortOptions={sortBoxed}
            />
          )}
        </TabPanel>
        <TabPanel value={selectedTab} index="boxed_bol">
          {locationDonationsLoading ? (
            <Loader />
          ) : (
            <Table
              columns={bolColumns}
              title="Plasma Donations: BOL Donations"
              data={bolData}
              sortOptions={sortBOL}
            />
          )}
        </TabPanel>
      </Grid>
      {selectedId && (
        <Modal open={openModal} handleClose={handleCloseModal}>
          <ModalDetails
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            setSelectedType={setSelectedType}
            type={selectedType}
            overviewData={selectedOverview}
            setSelectedOverview={setSelectedOverview}
            currentLocation={id}
            openedAs={openModalAs}
            handleClose={handleCloseModal}
            findBolOverview={findBolOverview}
            bolId={bolId}
            openedFrom="location"
          />
        </Modal>
      )}
    </Grid>
  );
}

export default CollectionLocation;
