import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  rankContainer: {
    padding: theme.spacing(2),
    border: '1px solid #0000001f',
    borderRadius: '10px',
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: '0 !important',
  },
  listItem: {
    paddingLeft: '0 !important',
    fontWeight: 600,
    minHeight: '70px !important',
  },
  listLink: {
    color: '#1777CF',
    fontWeight: 600,
    paddingLeft: '3px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  listStats: {
    textAlign: 'right',
  },
}));

export default useStyles;
