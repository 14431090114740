import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  centerLoading: {
    width: '100%',
    marginLeft: '50%',
    marginRight: '50%',
  },
}));

export default useStyles;
