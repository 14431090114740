import { RSAA } from 'redux-api-middleware';

export const FETCH_COLLECTION_GET_LOCATION_BOL_DONATIONS_REQUEST =
  'FETCH_COLLECTION_GET_LOCATION_BOL_DONATIONS_REQUEST';
export const FETCH_COLLECTION_GET_LOCATION_BOL_DONATIONS_SUCCESS =
  'FETCH_COLLECTION_GET_LOCATION_BOL_DONATIONS_SUCCESS';
export const FETCH_COLLECTION_GET_LOCATION_BOL_DONATIONS_FAILURE =
  'FETCH_COLLECTION_GET_LOCATION_BOL_DONATIONS_FAILURE';

export const FETCH_COLLECTION_GET_BOL_BOXES_REQUEST = 'FETCH_COLLECTION_GET_BOL_BOXES_REQUEST';
export const FETCH_COLLECTION_GET_BOL_BOXES_SUCCESS = 'FETCH_COLLECTION_GET_BOL_BOXES_SUCCESS';
export const FETCH_COLLECTION_GET_BOL_BOXES_FAILURE = 'FETCH_COLLECTION_GET_BOL_BOXES_FAILURE';

export const fetchCollectionGetLocationBolDonations = id => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-collection-get-location-bol-donations/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COLLECTION_GET_LOCATION_BOL_DONATIONS_REQUEST,
      {
        type: FETCH_COLLECTION_GET_LOCATION_BOL_DONATIONS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          if (!json.data[0]) {
            return { entities: { data: { bol: ['no entries'] } } };
          }
          return { entities: { bol: json } };
        },
      },
      {
        type: FETCH_COLLECTION_GET_LOCATION_BOL_DONATIONS_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

const getUrlExtension = (locationid, openedFrom) => {
  if (openedFrom === 'location') {
    return `&locationid=${locationid}`;
  }
  return '';
};

export const fetchCollectionGetBolBoxes = (BOLNumber, locationid, openedFrom) => ({
  [RSAA]: {
    endpoint: `${
      process.env.REACT_APP_API_URL
    }/plasmachain-collection-get-bol-boxes?bolid=${BOLNumber}${getUrlExtension(locationid, openedFrom)}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COLLECTION_GET_BOL_BOXES_REQUEST,
      {
        type: FETCH_COLLECTION_GET_BOL_BOXES_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          if (!json.data[0]) {
            return { entities: { boxesInBOL: { data: ['no entries'] } } };
          }
          return { entities: { boxesInBOL: json } };
        },
      },
      {
        type: FETCH_COLLECTION_GET_BOL_BOXES_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const CollectionGetLocationBolDonationsMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COLLECTION_GET_LOCATION_BOL_DONATIONS_SUCCESS:
      console.log('fetch bol list success');
      break;
    case FETCH_COLLECTION_GET_LOCATION_BOL_DONATIONS_FAILURE:
      console.log('fetch bol list failure');
      break;
    default:
      break;
  }
};

export const CollectionGetBolBoxesMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COLLECTION_GET_BOL_BOXES_SUCCESS:
      console.log('fetch boxes in bol success');
      break;
    case FETCH_COLLECTION_GET_BOL_BOXES_FAILURE:
      console.log('fetch boxes in bol failure');
      break;
    default:
      break;
  }
};
