import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from '../Loader/Loader';
import DataFieldHint from '../DataFieldHint/DataFieldHint';
import tooltipHints from '../../tooltipHints';
import useStyles from './SummaryOverview.styles';
import { getCountConversion, withCommas } from '../../utils';

const {
  total_volume: totalVolumeHint,
  collected: collectedHint,
  boxed: boxedHint,
  received: receivedHint,
  sorted: sortedHint,
  boxed_with_bol: boxedWithBOLHint,
  released_at_mfg: releasedAtMFGHint,
  lot_id: lotIDHint,
  description: descriptionHint,
  quantity: quantityHint,
  location: locationHint,
  quarantine: quarantineHint,
  lookback_confirmed: lookbackConfirmedHint,
  lookback_submitted: lookbackSubmittedHint,
  destroyed: destroyedHint,
  warehouse: warehouseHints,
  volume: volumeHint,
  gamma_protein_estimate_pre_donation: preDonationHint,
  master_file_approved: masterFileApprovedHint,
  flavor: flavorHint,
  aging: agingHint,
} = tooltipHints;

const {
  total_donation_volume: totalDominationVolumeHint,
  released_at_mfg: warehouseRealeasedAtMFGHint,
  received: warehouseReceivedHint,
  sorted: warehouseSortedHint,
  teardown_pools: teardownPoolsHint,
} = warehouseHints;

const getWidth = label => {
  switch (label) {
    case 'Donations':
      return 75;
    case 'Est. gamma(g)':
      return 87;
    case 'Boxes':
      return 70;
    case 'BOLs':
      return 65;
    default:
      return 90;
  }
};

const getSecondaryDetails = (items, classes) =>
  items.map(item => (
    <div key={item.label} style={{ flex: `0 0 ${getWidth(item.label)}px` }} className={classes.secondaryItem}>
      <Typography className={classes.secondaryItemLabel}>{item.label}</Typography>
      <Tooltip title={withCommas(item.detail)} className={classes.toolTip} placement="bottom" arrow>
        <Typography variant="h6" className={classes.secondaryItemDetail}>
          {getCountConversion(Number(item.detail))}
        </Typography>
      </Tooltip>
    </div>
  ));

const getItemClassName = (item, showCounts, classes) => {
  if (showCounts) {
    switch (item.title) {
      case 'Total Volume (L)':
      case 'Collected (L)':
      case 'Quarantine (L)':
      case 'Lookback Notification Received (L)':
      case 'Destroyed (L)':
      case 'Lookback Submitted (L)':
      case 'Received (L)':
      case 'Sorted (L)':
      case 'Released at MFG (L)':
        return classes.listSecondaryItem;
      case 'Boxed (L)':
      case 'Boxed with BOL (L)':
        return classes.listSecondaryItemLong;
      default:
        return classes.listItem;
    }
  }
  return classes.listItem;
};
const getPrimaryHint = (title, warehouse = null) => {
  let hint = '';
  switch (title.toUpperCase()) {
    case 'TOTAL VOLUME (L)':
      hint = totalVolumeHint;
      break;
    case 'COLLECTED (L)':
      hint = collectedHint;
      break;
    case 'BOXED (L)':
      hint = boxedHint;
      break;
    case 'BOXED WITH BOL (L)':
      hint = boxedWithBOLHint;
      break;
    case 'RELEASED AT MFG (L)':
      hint = releasedAtMFGHint;
      break;
    case 'RECEIVED (L)':
      hint = receivedHint;
      break;
    case 'SORTED (L)':
      hint = sortedHint;
      break;
    case 'RELEASED AT MFG':
      hint =
        warehouse === null
          ? releasedAtMFGHint
          : warehouseRealeasedAtMFGHint.replace('[warehouse name]', warehouse);
      break;
    case 'RECEIVED':
      hint = warehouse === null ? receivedHint : warehouseReceivedHint.replace('[warehouse name]', warehouse);
      break;
    case 'SORTED':
      hint = warehouse === null ? sortedHint : warehouseSortedHint.replace('[warehouse name]', warehouse);
      break;
    case 'TEARDOWN POOLS':
      hint = teardownPoolsHint.replace('[warehouse name]', warehouse);
      break;
    case 'TOTAL DONATION VOLUME':
      hint = totalDominationVolumeHint.replace('[warehouse name]', warehouse);
      break;
    case 'LOT ID':
      hint = lotIDHint;
      break;
    case 'DESCRIPTION':
      hint = descriptionHint;
      break;
    case 'QUANTITY':
      hint = quantityHint;
      break;
    case 'LOCATION':
      hint = locationHint;
      break;
    case 'QUARANTINE (L)':
      hint = quarantineHint;
      break;
    case 'LOOKBACK NOTIFICATION RECEIVED (L)':
      hint = lookbackConfirmedHint;
      break;
    case 'LOOKBACK SUBMITTED (L)':
      hint = lookbackSubmittedHint;
      break;
    case 'DESTROYED (L)':
      hint = destroyedHint;
      break;
    case 'VOLUME':
      hint = volumeHint;
      break;

    case 'GAMMA PROTEIN ESTIMATE (PRE-DONATION)':
      hint = preDonationHint;
      break;
    case 'MASTER FILE APPROVED':
      hint = masterFileApprovedHint;
      break;
    case 'FLAVOR':
      hint = flavorHint;
      break;
    case 'AGING':
      hint = agingHint;
      break;

    default:
      hint = 'field information';
  }
  return hint;
};
const getRowItems = (items, classes, showCounts, showDataInDev, warehouse = '') =>
  items.map((item, index) => {
    if (item.title.toUpperCase() === 'DIVIDER') {
      // eslint-disable-next-line react/no-array-index-key
      return <Divider key={`divider-${index}`} orientation="vertical" flexItem />;
    }
    return (
      <ListItem className={getItemClassName(item, showCounts, classes)} key={`row-item-${item.title}`}>
        <ListItemText
          disableTypography
          primary={
            <DataFieldHint title={getPrimaryHint(item.title, warehouse)}>
              <Typography variant="overline" className={classes.listItemTitle}>
                {item.title.toUpperCase()}
              </Typography>
            </DataFieldHint>
          }
          secondary={
            showCounts ? (
              <>
                <Typography variant="h5" className={classes.listItemDetail}>
                  {item.detail}
                </Typography>
                <List className={classes.horizontalAccordionList}>
                  {getSecondaryDetails(item.secondaryDetails, classes)}
                </List>
              </>
            ) : (
              <>
                <Typography variant="h5" className={classes.listItemDetail}>
                  {item.detail}
                </Typography>
                <Typography variant="subtitle2" className={classes.listItemDetailTwo}>
                  {item.detail_two}
                </Typography>
              </>
            )
          }
        />
      </ListItem>
    );
  });

const outputRows = (overview, classes, loading, showCounts, showDataInDev, warehouse = '') => {
  if (loading) {
    if (Object.keys(overview).length > 1) {
      return (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      );
    }
    return <Loader />;
  }
  if (Object.keys(overview).length > 1) {
    return Object.keys(overview).map((key, index) => {
      if (index >= 1) {
        return (
          <AccordionDetails key={key}>
            <Grid item xs={12} className={classes.summaryDetailsOverview}>
              <Typography variant="overline" className={classes.overviewRowTitle}>
                {key.toUpperCase()}
              </Typography>
              <List className={classes.horizontalAccordionList}>
                {getRowItems(overview[key], classes, showCounts, showDataInDev)}
              </List>
            </Grid>
          </AccordionDetails>
        );
      }
      return (
        <AccordionSummary
          key={key}
          className={classes.summary}
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
        >
          <Grid item xs={12} className={classes.summaryAccOverview}>
            <Typography variant="overline" className={classes.overviewRowTitle}>
              {key.toUpperCase()}
            </Typography>
            <List className={classes.horizontalAccordionList}>
              {getRowItems(overview[key], classes, showCounts)}
            </List>
          </Grid>
        </AccordionSummary>
      );
    });
  }
  return Object.keys(overview).map((key, index) => (
    <Grid item xs={12} key={`visible-rows-${index === 0 ? 'one' : 'two'}`}>
      <Typography variant="overline" className={classes.overviewRowTitle}>
        {key.toUpperCase()}
      </Typography>
      {showDataInDev ? (
        <Typography variant="overline" className={classes.overviewRowTitle}>
          * Data in Development
        </Typography>
      ) : (
        ''
      )}
      <List className={classes.horizontalList}>
        {getRowItems(overview[key], classes, showCounts, showDataInDev, warehouse)}
      </List>
    </Grid>
  ));
};

function SummaryOverview({ data, showCounts, showDataInDev, loading, warehouse }) {
  const classes = useStyles();
  if (data && Object.keys(data).length > 1) {
    return (
      <div key="double-summary" className={classes.summaryOverviewContainer} data-testid="summary overview">
        <Accordion className={classes.accordion}>
          {outputRows(data, classes, loading, showCounts, showDataInDev, warehouse)}
        </Accordion>
      </div>
    );
  }
  return (
    <div key="single-summary" className={classes.summaryOverviewContainer} data-testid="summary overview">
      <Grid className={classes.summaryOverview}>
        {outputRows(data, classes, loading, showCounts, showDataInDev, warehouse)}
      </Grid>
    </div>
  );
}

SummaryOverview.propTypes = {
  data: PropTypes.shape({}).isRequired,
  showCounts: PropTypes.bool,
  showDataInDev: PropTypes.bool,
  loading: PropTypes.bool,
  warehouse: PropTypes.string,
};

SummaryOverview.defaultProps = {
  showDataInDev: false,
  showCounts: false,
  loading: true,
  warehouse: 'N/A',
};

export default SummaryOverview;
