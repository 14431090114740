import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(12),
    paddingRight: theme.spacing(1),
  },
  container: {
    maxWidth: '1280px',
    margin: `0 auto ${theme.spacing(1)}px`,
    position: 'relative',
  },
  modalTitle: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1.5),
    color: 'f9f9f9',
    fontWeight: '500',
  },
  searchBar: {
    marginBottom: theme.spacing(4),
    position: 'relative',
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    padding: '10px',
  },
  centerLoading: {
    width: '100%',
    marginLeft: '50%',
    marginRight: '50%',
  },
}));

export default useStyles;
