import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import assign from 'lodash/assign';
import loading from './loadingReducer';

/**
 * Updates an entity cache in response to any action with response.entities.
 * This property is generated by `normalizr`.
 *
 * @param {object} state - Current partial state for this reducer.
 * @param {object} action - Redux action object.
 * @return {object} - New state object.
 */
// eslint-disable-next-line default-param-last
const entities = (state = {}, action) => {
  const { payload } = action;

  if (payload && payload.entities) {
    return assign({}, state, action.payload.entities);
  }

  return state;
};

// eslint-disable-next-line default-param-last
const error = (state = {}, action) => {
  const { meta } = action;

  if (meta && meta.error) {
    return assign({}, state, meta.error);
  }

  return state;
};

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    loading,
    entities,
    error,
  });

export default rootReducer;
