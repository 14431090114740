import { RSAA } from 'redux-api-middleware';

export const FETCH_MANUFACTURING_GET_PHASE_OVERVIEW_REQUEST =
  'FETCH_MANUFACTURING_GET_PHASE_OVERVIEW_REQUEST';
export const FETCH_MANUFACTURING_GET_PHASE_OVERVIEW_SUCCESS =
  'FETCH_MANUFACTURING_GET_PHASE_OVERVIEW_SUCCESS';
export const FETCH_MANUFACTURING_GET_PHASE_OVERVIEW_FAILURE =
  'FETCH_MANUFACTURING_GET_PHASE_OVERVIEW_FAILURE';

export const FETCH_MANUFACTURING_GET_LOCATIONS_REQUEST = 'FETCH_MANUFACTURING_GET_LOCATIONS_REQUEST';
export const FETCH_MANUFACTURING_GET_LOCATIONS_SUCCESS = 'FETCH_MANUFACTURING_GET_LOCATIONS_SUCCESS';
export const FETCH_MANUFACTURING_GET_LOCATIONS_FAILURE = 'FETCH_MANUFACTURING_GET_LOCATIONS_FAILURE';

export const FETCH_MANUFACTURING_GET_LOCATION_PHASE_OVERVIEW_REQUEST =
  'FETCH_MANUFACTURING_GET_LOCATION_PHASE_OVERVIEW_REQUEST';
export const FETCH_MANUFACTURING_GET_LOCATION_PHASE_OVERVIEW_SUCCESS =
  'FETCH_MANUFACTURING_GET_LOCATION_PHASE_OVERVIEW_SUCCESS';
export const FETCH_MANUFACTURING_GET_LOCATION_PHASE_OVERVIEW_FAILURE =
  'FETCH_MANUFACTURING_GET_LOCATION_PHASE_OVERVIEW_FAILURE';

export const FETCH_MANUFACTURING_GET_TEARDOWN_LOTS_REQUEST = 'FETCH_MANUFACTURING_GET_TEARDOWN_LOTS_REQUEST';
export const FETCH_MANUFACTURING_GET_TEARDOWN_LOTS_SUCCESS = 'FETCH_MANUFACTURING_GET_TEARDOWN_LOTS_SUCCESS';
export const FETCH_MANUFACTURING_GET_TEARDOWN_LOTS_FAILURE = 'FETCH_MANUFACTURING_GET_TEARDOWN_LOTS_FAILURE';

export const FETCH_MANUFACTURING_GET_TEARDOWN_LOT_DONATIONS_REQUEST =
  'FETCH_MANUFACTURING_GET_TEARDOWN_LOT_DONATIONS_REQUEST';
export const FETCH_MANUFACTURING_GET_TEARDOWN_LOT_DONATIONS_SUCCESS =
  'FETCH_MANUFACTURING_GET_TEARDOWN_LOT_DONATIONS_SUCCESS';
export const FETCH_MANUFACTURING_GET_TEARDOWN_LOT_DONATIONS_FAILURE =
  'FETCH_MANUFACTURING_GET_TEARDOWN_LOT_DONATIONS_FAILURE';

export const fetchManufacturingGetTeardownLotDonations = lotid => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-manufacturing-get-teardown-lot-donations/${lotid}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_MANUFACTURING_GET_TEARDOWN_LOT_DONATIONS_REQUEST,
      {
        type: FETCH_MANUFACTURING_GET_TEARDOWN_LOT_DONATIONS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { lotDonations: json } };
        },
      },
      {
        type: FETCH_MANUFACTURING_GET_TEARDOWN_LOT_DONATIONS_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchManufacturingGetLocationPhaseOverview = id => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-manufacturing-get-location-phase-overview/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_MANUFACTURING_GET_LOCATION_PHASE_OVERVIEW_REQUEST,
      {
        type: FETCH_MANUFACTURING_GET_LOCATION_PHASE_OVERVIEW_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { overview: json } };
        },
      },
      {
        type: FETCH_MANUFACTURING_GET_LOCATION_PHASE_OVERVIEW_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchManufacturingGetTeardownLots = lot => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-manufacturing-get-teardown-lots/${lot}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_MANUFACTURING_GET_TEARDOWN_LOTS_REQUEST,
      {
        type: FETCH_MANUFACTURING_GET_TEARDOWN_LOTS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { lots: json } };
        },
      },
      {
        type: FETCH_MANUFACTURING_GET_TEARDOWN_LOTS_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchManufacturingGetPhaseOverview = () => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-manufacturing-get-phase-overview`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_MANUFACTURING_GET_PHASE_OVERVIEW_REQUEST,
      {
        type: FETCH_MANUFACTURING_GET_PHASE_OVERVIEW_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { manufacturingOverview: json } };
        },
      },
      {
        type: FETCH_MANUFACTURING_GET_PHASE_OVERVIEW_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const ManufacturingGetTeardownLotDonationsMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_MANUFACTURING_GET_TEARDOWN_LOT_DONATIONS_SUCCESS:
      console.log('fetch manufacturing get teardown lot donations success');
      break;
    case FETCH_MANUFACTURING_GET_TEARDOWN_LOT_DONATIONS_FAILURE:
      console.log('fetch manufacturing get teardown lot donations failure');
      break;
    default:
      break;
  }
};

export const ManufacturingGetLocationPhaseOverviewMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_MANUFACTURING_GET_LOCATION_PHASE_OVERVIEW_SUCCESS:
      console.log('fetch manufacturing get location phase overview success');
      break;
    case FETCH_MANUFACTURING_GET_LOCATION_PHASE_OVERVIEW_FAILURE:
      console.log('fetch manufacturing get location phase overview failure');
      break;
    default:
      break;
  }
};

export const ManufacturingGetPhaseOverviewMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_MANUFACTURING_GET_PHASE_OVERVIEW_SUCCESS:
      console.log('fetch manufacturing get phase overview success');
      break;
    case FETCH_MANUFACTURING_GET_PHASE_OVERVIEW_FAILURE:
      console.log('fetch manufacturing get phase overview failure');
      break;
    default:
      break;
  }
};

export const fetchManufacturingGetLocations = () => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-manufacturing-get-locations`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_MANUFACTURING_GET_LOCATIONS_REQUEST,
      {
        type: FETCH_MANUFACTURING_GET_LOCATIONS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { manufacturingLocations: json } };
        },
      },
      {
        type: FETCH_MANUFACTURING_GET_LOCATIONS_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const ManufacturingGetLocationsMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_MANUFACTURING_GET_LOCATIONS_SUCCESS:
      console.log('fetch manufacturing get locations success');
      break;
    case FETCH_MANUFACTURING_GET_LOCATIONS_FAILURE:
      console.log('fetch manufacturing get locations failure');
      break;
    default:
      break;
  }
};
