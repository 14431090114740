import { RSAA } from 'redux-api-middleware';

export const FETCH_COLLECTION_GET_LOCATIONS_REQUEST = 'FETCH_COLLECTION_GET_LOCATIONS_REQUEST';
export const FETCH_COLLECTION_GET_LOCATIONS_SUCCESS = 'FETCH_COLLECTION_GET_LOCATIONS_SUCCESS';
export const FETCH_COLLECTION_GET_LOCATIONS_FAILURE = 'FETCH_COLLECTION_GET_LOCATIONS_FAILURE';

export const FETCH_COLLECTION_GET_LOCATION_REQUEST = 'FETCH_COLLECTION_GET_LOCATION_REQUEST';
export const FETCH_COLLECTION_GET_LOCATION_SUCCESS = 'FETCH_COLLECTION_GET_LOCATION_SUCCESS';
export const FETCH_COLLECTION_GET_LOCATION_FAILURE = 'FETCH_COLLECTION_GET_LOCATION_FAILURE';

export const FETCH_COLLECTION_GET_LOCATION_NAME_REQUEST = 'FETCH_COLLECTION_GET_LOCATION_NAME_REQUEST';
export const FETCH_COLLECTION_GET_LOCATION_NAME_SUCCESS = 'FETCH_COLLECTION_GET_LOCATION_NAME_SUCCESS';
export const FETCH_COLLECTION_GET_LOCATION_NAME_FAILURE = 'FETCH_COLLECTION_GET_LOCATION_NAME_FAILURE';

export const fetchCollectionGetLocations = () => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-collection-get-locations`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COLLECTION_GET_LOCATIONS_REQUEST,
      {
        type: FETCH_COLLECTION_GET_LOCATIONS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { locations: json } };
        },
      },
      {
        type: FETCH_COLLECTION_GET_LOCATIONS_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchCollectionGetLocation = id => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-collection-get-location/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COLLECTION_GET_LOCATION_REQUEST,
      {
        type: FETCH_COLLECTION_GET_LOCATION_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          console.log('location details: ', json);
          return { entities: { location: json } };
        },
      },
      {
        type: FETCH_COLLECTION_GET_LOCATION_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const fetchCollectionGetLocationName = id => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-collection-get-location-name/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COLLECTION_GET_LOCATION_NAME_REQUEST,
      {
        type: FETCH_COLLECTION_GET_LOCATION_NAME_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { locationName: json.location } };
        },
      },
      {
        type: FETCH_COLLECTION_GET_LOCATION_NAME_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const CollectionGetlocationsMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COLLECTION_GET_LOCATIONS_SUCCESS:
      console.log('fetch collection locations success');
      break;
    case FETCH_COLLECTION_GET_LOCATIONS_FAILURE:
      console.log('fetch collection locations failure');
      break;
    default:
      break;
  }
};

export const CollectionGetlocationMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COLLECTION_GET_LOCATION_SUCCESS:
      console.log('fetch location details success');
      break;
    case FETCH_COLLECTION_GET_LOCATION_FAILURE:
      console.log('fetch location details failure');
      break;
    default:
      break;
  }
};

export const CollectionGetlocationNameMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COLLECTION_GET_LOCATION_NAME_SUCCESS:
      console.log('fetch location name success');
      break;
    case FETCH_COLLECTION_GET_LOCATION_NAME_FAILURE:
      console.log('fetch location name failure');
      break;
    default:
      break;
  }
};
