/* eslint-disable no-param-reassign */
import React from 'react';
import moment from 'moment';
import { write, utils as xlsxUtils } from 'xlsx';
import useKeyboardShortcut from './use-keyboard-shortcut';
import CustomRangeFilter from './components/CustomRangeFilter/CustomRangeFilter';

const SPACED_DATE_FORMAT = 'MM/DD/YYYY';

/**
 * Takes a date (ex. 2021-04-14T00:00:00.000Z) and returns as a string
 * in the following format: MM/DD/YYYY (ex. 4/13/2021)
 */

const validateDate = dateString =>
  dateString &&
  dateString !== '-' &&
  dateString !== undefined &&
  dateString !== 'N/A' &&
  dateString !== null &&
  dateString !== 'null';

export const formatDate = date =>
  date && date !== '' && date !== null
    ? new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' })
    : 'N/A';

/**
 * Takes the status of a donation
 */

export const formatStatus = status => {
  if (status === 'BOXED_BOL') {
    return 'Boxed with BOL';
  }
  if (status) {
    return status
      .split(' ')
      .map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
      .join(' ');
  }
  return '-';
};

export const customFilterListOptionsRender = (v, name) => {
  if (v[0] && v[1]) {
    return `Min ${name}: ${v[0]}, Max ${name}: ${v[1]}`;
  }
  if (v[0]) {
    return `Min ${name}: ${v[0]}`;
  }
  if (v[1]) {
    return `Max ${name}: ${v[1]}`;
  }
  return [];
};

export const customFilterListOptionsUpdate = (filterList, filterPos, index) => {
  if (filterPos === 0) {
    filterList[index].splice(filterPos, 1, '');
  } else if (filterPos === 1) {
    filterList[index].splice(filterPos, 1);
  } else if (filterPos === -1) {
    filterList[index] = [];
  }
  return filterList;
};

export const filterOptionsLogic = (val, filters) => {
  const value = parseFloat(val.replace(',', ''));
  if (filters[0] && filters[1]) {
    return value < filters[0] || value > filters[1];
  }
  if (filters[0]) {
    return value < filters[0];
  }
  if (filters[1]) {
    return value > filters[1];
  }
  return false;
};

export const filterDateOptionsLogic = (val, filters) => {
  const value = moment.utc(val).format('MM/DD/YYYY');
  const minDate = moment.utc(filters[0]).format('MM/DD/YYYY');
  const maxDate = moment.utc(Date.parse(filters[1]) + 86400000).format('MM/DD/YYYY');

  if (filters[0] && filters[1]) {
    return !(new Date(value) >= new Date(minDate) && new Date(value) <= new Date(maxDate));
  }
  if (filters[0]) {
    return !(new Date(value) >= new Date(minDate));
  }
  if (filters[1]) {
    return !(new Date(value) <= new Date(maxDate));
  }
  return false;
};

/**
 * Sorts dates in string type asc or desc
 */

export const sortDate = order => (row1, row2) => {
  const val1 = Date.parse(row1.data);
  const val2 = Date.parse(row2.data, 10);
  return (val1 - val2) * (order === 'asc' ? 1 : -1);
};

export const sortDateFilter = (dates, key, order = 'asc') => {
  const dateArray = ['-'];
  dates.forEach(date => {
    if (!dateArray.includes(date[`${key}`])) {
      dateArray.push(date[`${key}`]);
    }
  });

  dateArray.sort((date1, date2) =>
    order === 'asc' ? new Date(date1) - new Date(date2) : new Date(date2) - new Date(date1)
  );
  return dateArray;
};

export const sortNumberFilter = (numbers, key, order = 'asc') => {
  const sequence = [];
  numbers.forEach(number => {
    const targetNumber = String(number[`${key}`]);
    if (!sequence.includes(targetNumber)) {
      sequence.push(String(targetNumber));
    }
  });

  sequence.sort((number1, number2) => (order === 'asc' ? number1 - number2 : number2 - number1));
  return sequence;
};

export const sortStringFilter = (strings, key, order = 'asc') => {
  const sequence = [];
  strings.forEach(number => {
    const targetString = String(number[`${key}`]);
    if (!sequence.includes(targetString)) {
      sequence.push(String(targetString));
    }
  });

  sequence.sort((string1, string2) => {
    if (order === 'asc') return string1.localeCompare(string2);
    return string2.localeCompare(string1);
  });
  return sequence;
};

// Custom right render for number and date columns
export const customAlignRight = dataCell => (
  <div
    style={{
      textAlign: 'right',
    }}
  >
    {dataCell}
  </div>
);

// Custom left render for reconciliation_status
export const customAlignLeft = dataCell => (
  <div
    style={{
      textAlign: 'left',
      padding: '16px',
    }}
  >
    {dataCell}
  </div>
);

export const customAlignLeftNoPadding = dataCell => (
  <div
    style={{
      textAlign: 'left',
    }}
  >
    {dataCell}
  </div>
);

export const customLeftCrossAlignRight = dataCell => (
  <div
    style={{
      textAlign: 'right',
      backgroundColor: '#EAEAF2',
      padding: '16px',
      whiteSpace: 'noWrap',
    }}
  >
    {dataCell}
  </div>
);

export const customLeftDateCrossAlignRight = dataCell => (
  <div
    style={{
      textAlign: 'right',
      backgroundColor: '#EAEAF2',
      padding: '16px',
      whiteSpace: 'noWrap',
    }}
  >
    {validateDate(dataCell) ? moment(new Date(dataCell)).utc().format(SPACED_DATE_FORMAT) : 'N/A'}
  </div>
);

export const customRightCrossAlignRight = dataCell => (
  <div
    style={{
      textAlign: 'right',
      backgroundColor: '#F6F6FA',
      padding: '16px',
      whiteSpace: 'noWrap',
    }}
  >
    {dataCell}
  </div>
);

export const customRightDateCrossAlignRight = dataCell => (
  <div
    style={{
      textAlign: 'right',
      backgroundColor: '#F6F6FA',
      padding: '16px',
      whiteSpace: 'noWrap',
    }}
  >
    {validateDate(dataCell) ? moment(new Date(dataCell)).utc().format(SPACED_DATE_FORMAT) : 'N/A'}
  </div>
);

export const customTest = headerCell => (
  <div
    style={{
      backgroundColor: '#C4C4CD',
      padding: '16px',
      whiteSpace: 'noWrap',
    }}
  >
    {headerCell}
  </div>
);

// Uses Gray 300
export const customCrossLeftGray = dataCell => (
  <div
    style={{
      backgroundColor: '#EAEAF2',
      padding: '16px',
      whiteSpace: 'noWrap',
    }}
  >
    {dataCell}
  </div>
);

// Uses Gray 200
export const customCrossRightGray = dataCell => (
  <div
    style={{
      backgroundColor: '#F6F6FA',
      padding: '16px',
      whiteSpace: 'noWrap',
    }}
  >
    {dataCell}
  </div>
);

// Uses Gray 300
export const customDarkGray = dataCell => (
  <div
    style={{
      backgroundColor: '#EAEAF2',
      padding: '16px',
      whiteSpace: 'noWrap',
    }}
  >
    {dataCell}
  </div>
);

// Uses Gray 200
export const customLightGray = dataCell => (
  <div
    style={{
      backgroundColor: '#F6F6FA',
      padding: '16px',
      whiteSpace: 'noWrap',
    }}
  >
    {dataCell}
  </div>
);

// Uses Gray 300
export const customRightAlignDarkGray = dataCell => (
  <div
    style={{
      textAlign: 'right',
      backgroundColor: '#EAEAF2',
      padding: '16px',
      whiteSpace: 'noWrap',
    }}
  >
    {dataCell}
  </div>
);

export const customLeftAlignDarkGray = dataCell => (
  <div
    style={{
      textAlign: 'left',
      backgroundColor: '#EAEAF2',
      padding: '16px',
      whiteSpace: 'noWrap',
    }}
  >
    {dataCell}
  </div>
);

// Uses Gray 200
export const customRightAlignLightGray = dataCell => (
  <div
    style={{
      textAlign: 'right',
      backgroundColor: '#F6F6FA',
      padding: '16px',
      whiteSpace: 'noWrap',
    }}
  >
    {dataCell}
  </div>
);

// Uses Gray 200
export const customRightAlignWhite = dataCell => (
  <div
    style={{
      backgroundColor: '#FFFFFF',
      textAlign: 'right',
      padding: '16px',
      whiteSpace: 'noWrap',
    }}
  >
    {dataCell}
  </div>
);

export const customLeftAlignWhite = dataCell => (
  <div
    style={{
      backgroundColor: '#FFFFFF',
      textAlign: 'left',
      padding: '16px',
      whiteSpace: 'noWrap',
    }}
  >
    {dataCell}
  </div>
);

// Custom right render for number and date columns
export const customDateAlignRight = dataCell => (
  <div
    style={{
      textAlign: 'right',
    }}
  >
    {validateDate(dataCell) ? moment(new Date(dataCell)).utc().format(SPACED_DATE_FORMAT) : 'N/A'}
  </div>
);

/**
 * Customized options for advanced filtering
 * @param {string} name - column name to be configured.
 * @param {string} alignment - set to 'alignRight' if that's the case, default aligns to the left
 * @param {Object} headerProps - column header properties
 * @param {boolean} filter - true or false for filtering
 * @param {string} filterType - filterType
 */
export const options = (name, alignment, headerProps, filter, filterType) => {
  const addFilter = filter === false ? false : filter || true;

  const customFilterDetails =
    addFilter && !filterType
      ? {
          filterType: 'custom',
          customFilterListOptions: {
            render: v => customFilterListOptionsRender(v, name),
            update: (filterList, filterPos, index) =>
              customFilterListOptionsUpdate(filterList, filterPos, index),
          },
          filterOptions: {
            names: [],
            logic(val, filters) {
              return filters.length ? filterOptionsLogic(val, filters) : false;
            },
            display: (filterList, onChange, index, column) => (
              <CustomRangeFilter
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
                label={name}
              />
            ),
          },
        }
      : {};

  return {
    filter: addFilter,
    customBodyRender: alignment,
    filterType,
    setCellHeaderProps: () => ({
      ...headerProps,
    }),
    ...customFilterDetails,
  };
};

export const getMinMaxDates = (data, column) => {
  const allDates = [];
  data.forEach(element => {
    if (!allDates.includes(element[column])) {
      allDates.push(element[column]);
    }
  });
  let minDate = allDates[0];
  let maxDate = allDates[0];
  allDates.forEach(item => {
    const currentDate = Date.parse(item);
    if (currentDate > Date.parse(maxDate)) {
      maxDate = item;
    }
    if (currentDate < Date.parse(minDate)) {
      minDate = item;
    }
  });
  return { minDate, maxDate };
};

/**
 * Customized options for advanced filtering
 * @param {string} name - column name to be configured.
 * @param {string} alignment - set to 'alignRight' if that's the case, default aligns to the left
 */
export const dropdownOptions = (data, key, name, alignment) => ({
  filter: true,
  customBodyRender: alignment === 'alignRight' && customAlignRight,
  filterType: 'custom',
  customFilterListOptions: {
    render: v => customFilterListOptionsRender(v, name),
    update: (filterList, filterPos, index) => customFilterListOptionsUpdate(filterList, filterPos, index),
  },
  filterOptions: {
    names: [],
    logic(val, filters) {
      return filters.length ? filterOptionsLogic(val, filters) : false;
    },
    display: (filterList, onChange, index, column) => (
      <CustomRangeFilter
        filterList={filterList}
        onChange={onChange}
        index={index}
        column={column}
        label={name}
        names={getMinMaxDates(data, key)}
      />
    ),
  },
});

/**
 * Takes a status name and status filters list
 * Removes numbers and dots to compare to value and returns
 * false if status is in filter, true if it is not
 */
export const returnRowContainsStatus = (value, filters) => {
  if (filters.length === 0) {
    return false;
  }
  return !filters.includes(value);
};

/**
 * Takes a status name and status filters list
 * Returns wether the value contains data or it's blank
 */
export const returnRowIsBlank = (value, filters) => {
  let result = true;
  if (filters.includes('Blank')) {
    result = value === '-' || value === null || value === undefined;
  }
  if (filters.includes('Exist')) {
    result = value !== '-' && value !== null && value !== undefined;
  }
  return !result;
};

/**
 * Takes a number and a unit name,
 * it returns the expression number and value in plural or singular
 * if the number is 1
 */

export const pluralUnitCheck = (number, unit) => {
  if (!Number.isNaN(number) && number && unit) {
    switch (unit) {
      case 'day':
        return number === '1' ? `${number} day` : `${number} days`;
      case 'box':
        return number === '1' ? `${number} box` : `${number} boxes`;
      case 'unit':
        return number === '1' ? `${number} unit` : `${number} units`;
      case 'donation':
        return number === '1' ? `${number} donation` : `${number} donations`;
      case 'BOL':
        return number === '1' ? `${number} BOL` : `${number} BOLs`;
      default:
        return '-';
    }
  }
  return '-';
};

/**
 * Add commas to numbers
 */

export const withCommas = num => {
  if (num && !Number.isNaN(num)) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '-';
};

/**
 * Formats number with fixed decimals and unit name
 */

export const numDecimalsCheck = (number, decimals, unit, noComma) => {
  if (number) {
    if (!Number.isNaN(number)) {
      let converted = parseFloat(number).toFixed(decimals);
      converted = parseInt(converted, 10);
      if (unit) {
        return noComma ? `${converted} ${unit}` : `${withCommas(converted)} ${unit}`;
      }
      return noComma ? converted : `${withCommas(converted)}`;
    }
  }
  return '-';
};

/**
 * Formats a list of numbers with fixed decimals and/or unit name
 */
export const formatListOfNumbersNumDecimalCheck = (string, decimals, unit, noComma) => {
  const nums = string.split(';');
  if (nums.length > 1) {
    let restring = '';
    // eslint-disable-next-line no-return-assign
    nums.forEach((num, index) => {
      restring += `${numDecimalsCheck(num, decimals, unit, noComma)}; `;
      if (index === nums.length - 1) restring += `${numDecimalsCheck(num, decimals, unit, noComma)}`;
    });
    return restring;
  }
  return numDecimalsCheck(nums[0], decimals, unit).toString();
};

/**
 * Formats a number to a fixed short display with thousands (K), millions (M) or Billions (B)
 */
export const getCountConversion = converted => {
  if (converted < 1000) {
    return converted;
  }
  if (converted < 1000000) {
    return `${numDecimalsCheck(converted / 1000, 1)}K`;
  }
  if (converted < 1000000000) {
    return `${numDecimalsCheck(converted / 1000000, 1)}M`;
  }
  if (converted >= 1000000000) {
    return `${numDecimalsCheck(converted / 1000000000, 1)}B`;
  }
  return 0;
};

/**
 * Sorts numbers asc or desc
 */

export const sortNum = order => (row1, row2) => {
  const val1 = parseInt(row1.data, 10);
  const val2 = parseInt(row2.data, 10);
  return (val1 - val2) * (order === 'asc' ? 1 : -1);
};

/**
 * Applies Back/Forward commands in pages
 */

export const useBackForwardCommands = history => {
  useKeyboardShortcut(['Alt', 'ArrowRight'], () => history.goForward(), { overrideSystem: false });
  useKeyboardShortcut(['Meta', 'ArrowRight'], () => history.goForward(), { overrideSystem: false });
  useKeyboardShortcut(['Alt', 'ArrowLeft'], () => history.goBack(), { overrideSystem: false });
  useKeyboardShortcut(['Meta', 'ArrowLeft'], () => history.goBack(), { overrideSystem: false });
};

// Format date to display

export const checkAndFormatIfDate = string => {
  // check if in 'MM/dd/YYYY' format
  if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(string)) {
    const date = string.split('/');
    // convert to 'YYYY-MM-dd' format for backend
    return `${date[2].length === 1 ? `0${date[2]}` : date[2]}-${
      date[0].length === 1 ? `0${date[0]}` : date[0]
    }-${date[1].length === 1 ? `0${date[1]}` : date[1]}`;
  }
  return string;
};

// Format location name

export const formatLocationName = name => {
  if (name.length > 3) {
    return name[0].toUpperCase() + name.substring(1);
  }
  return name.toUpperCase();
};

// Filter creator for pagination endpoints

export const getFilterArray = (columns, dateNames, filterOptions) => {
  const filterQuery = [];
  const customOptions = ['Reconciled', 'Non-Reconciled', 'RxC', 'BPL'];
  if (filterOptions) {
    const keys = Object.keys(filterOptions);
    keys.forEach((key, index) => {
      const filters = filterOptions[key];
      if (filters && filters.length) {
        if (columns[index] && columns[index].options && columns[index].options.filterType === 'custom') {
          if (customOptions.includes(filters[0])) {
            filterQuery.push(`&${columns[index].name}=${filters[0]}`);
          } else {
            // If it's a range, format in range type
            const min = filters[0] === '' || !filters[0] ? 'min' : filters[0];
            const max = filters[1] === '' || !filters[1] ? 'max' : filters[1];
            filterQuery.push(`&${columns[index].name}-range=${min}-${max}`);
          }
        } else if (dateNames.includes(columns[index].name)) {
          // Date columns cases
          filterQuery.push(`&${columns[index].name}=${checkAndFormatIfDate(filters[0])}`);
        } else {
          // Single value cases, just format with status = filter
          let parsedFilters = filters.map(word => (word === 'Boxed with BOL' ? 'boxed_bol' : word));
          parsedFilters = parsedFilters.map(word => (word === 'Yes' || word === 'yes' ? 'true' : word));
          parsedFilters = parsedFilters.map(word => (word === 'No' || word === 'no' ? 'false' : word));
          filterQuery.push(`&${columns[index].name}=${parsedFilters}`);
        }
      }
    });
  }
  return filterQuery;
};

export const findFilter = (filtersObj, key, minOrMax) => {
  for (let i = 0; i < filtersObj.length; i += 1) {
    if (filtersObj[i].field_name === key) {
      if (minOrMax === 'min') {
        return filtersObj[i].min_value || '';
      }
      if (minOrMax === 'max') {
        return filtersObj[i].max_value || '';
      }
      return filtersObj[i].values || ['no options'];
    }
  }
  return null;
};

// Custom date sort function to push null values to the end
export const sortDatesWithNullsLast = order => (obj1, obj2) => {
  const date1 = obj1.data;
  const date2 = obj2.data;

  // If values are N/A, sort after everything else
  if (date1 === 'N/A') {
    return 1;
  }

  if (date2 === 'N/A') {
    return -1;
  }

  // If values are the same, return 0
  if (date1 === date2) {
    return 0;
  }

  // If neither values are N/A or the same, convert them to date objects and compare for sorting
  let a = new Date(date1);
  a = new Date(a.getUTCFullYear(), a.getUTCMonth(), a.getUTCDate());
  let b = new Date(date2);
  b = new Date(b.getUTCFullYear(), b.getUTCMonth(), b.getUTCDate());

  // If asc, oldest dates sort first
  if (order === 'asc') {
    return a < b ? -1 : 1;
  }
  // If desc, newest dates sort first
  return a < b ? 1 : -1;
};

/**
 * Formats table data for an .xlsx spreadsheet
 * @param {Object} spreadsheetColumns - Object containing the column names of the speradsheet
 * @param {Object} spreadsheetData - Object containing the data for the spreasheet columns
 */
export const formatToXLST = (spreadsheetColumns, spreadsheetData) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const json = spreadsheetData.reduce((result, val) => {
    const temp = {};
    val.data.forEach((v, idx) => {
      // eslint-disable-next-line react/prop-types
      temp[spreadsheetColumns[idx].label] = v;
    });
    result.push(temp);
    return result;
  }, []);

  const ws = xlsxUtils.json_to_sheet(json);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
  const spreasheetData = new Blob([excelBuffer], { type: fileType });

  return spreasheetData;
};

// Format Reconciliation ship in-out quantity data to include commas and use parentheses for negative quantities
export const formatQuantities = num => {
  if (num && !Number.isNaN(num)) {
    // Format number with comma format
    let formattedNum = withCommas(num);

    // If number is negative, replace the negative sign with parentheses
    if (num < 0) {
      formattedNum = `(${formattedNum.replace('-', '')})`;
      return formattedNum;
    }
    // If number is positive return value
    if (num > 0) {
      return formattedNum;
    }
    // If number is zero
    return num;
  }
  return '-';
};

// Sort Reconciliation ship in-out quantity data (strips out parantheses and commas and converts data to number for sorting)
export const sortQuantities = order => (row1, row2) => {
  let val1 = row1.data.replace(/\([^\d]*([\d.]+)\)/g, '-$1').replace(',', '');
  val1 = parseInt(val1, 10);

  let val2 = row2.data.replace(/\([^\d]*([\d.]+)\)/g, '-$1').replace(',', '');
  val2 = parseInt(val2, 10);

  return (val1 - val2) * (order === 'asc' ? 1 : -1);
};
