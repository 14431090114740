import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dropdownContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  form: {
    width: '100%',
  },
  dateContainer: {
    width: '95%',
    marginRight: '5%',
  },
}));

export default useStyles;
