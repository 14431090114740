/* eslint-disable react/prop-types */
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { red } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { formatDate, numDecimalsCheck, pluralUnitCheck } from '../../../utils';
import Loader from '../../Loader/Loader';
import useStyles from './EventDetails.styles.js';

function EventDetails({ event, fields }) {
  const classes = useStyles();
  const loading = useSelector(state => get(state, 'loading.FETCH_COLLECTION_GET_DONATION_DETAILS', true));

  // Add in alphabetical order
  const allColumnNames = {
    aeroid: 'Aero ID',
    alpha1: 'Alpha 1',
    alpha2: 'Alpha 2',
    alt1: 'Alt 1',
    alt2: 'Alt 2',
    alternateid: 'Alternate ID',
    atya: 'Atya',
    batchnumber: 'Batch Number',
    beta: 'Beta',
    bleeddate: 'Bleed Date',
    bleedstat: 'Bleed Status',
    bol_earliest_bleed_date: 'BOL Earliest Bleed Date',
    bol_est_igg: 'BOL Estimated Gamma',
    bol_latest_bleed_date: 'BOL Latest Bleed date',
    bol_number: 'BOL Number',
    bol_volume: 'BOL Volume',
    carrier: 'Carrier Number',
    centrecode: 'Center Code',
    center_alias: 'Center Name',
    confirmed: 'Confirmed',
    ctrnum: 'Center Number',
    drugscreen: 'Drug Screen',
    dttimereleased: 'DateTime Released',
    est_igg: 'Gamma Protein Estimate (pre-donation) (g)',
    filerecord: 'File Record',
    fully_tested: 'Fully Tested? (Y/N)',
    hcv_screen2: 'Hcv Screen 2',
    insrt_dtm: 'Insert DateTime',
    instrumentid: 'Instrument ID',
    labcd: 'Lab CD',
    lastmodified: 'Last Modified',
    number: 'BOL Number',
    pdn: 'PDN',
    pdtlastupdated: 'PDT Last Updated',
    resultdttime: 'Result DateTime',
    rpr: 'RPR',
    spe: 'SPE',
    status_timestamp: 'Timestamp',
    sts: 'STS',
    tetanuscatg: 'Tetanus CATG',
    timesid: 'Times ID',
    token_id: 'Token ID',
    tp: 'TP',
    tppa: 'TPPA',
  };

  const getFormat = (field, value) => {
    if (typeof value === 'boolean') return value.toString();
    if (!value || value === 'null') {
      return '-';
    }
    switch (field) {
      case 'bleeddate':
      case 'bol_earliest_bleed_date':
      case 'bol_latest_bleed_date':
      case 'date_boxed':
      case 'dttimereleased':
      case 'earliest_bleed_date':
      case 'insrt_dtm':
      case 'latest_bleed_date':
      case 'lastmodified':
      case 'received_date':
      case 'released':
      case 'released_date':
      case 'resultdttime':
      case 'shipping_date':
      case 'sorted_date':
      case 'status_timestamp':
        return formatDate(value);
      case 'pdtlastupdated':
        return JSON.stringify(value);
      case 'bol_est_igg':
      case 'box_est_igg':
        return numDecimalsCheck(value, 2, 'g');
      case 'bol_volume':
      case 'box_volume':
        return numDecimalsCheck(value, 3, 'L');
      case 'min_age':
      case 'max_age':
      case 'status_time':
      case 'total_time':
      case 'transition_velocity':
      case 'total_velocity':
        return pluralUnitCheck(value, 'day');
      default:
        return value;
    }
  };

  const checkFields = value => {
    // Replace _ and capitalize first word
    const spaceValue = value.replace(/_/g, ' ');
    return spaceValue.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  };

  const getData = () => {
    if (fields === undefined) {
      return [];
    }
    // eslint-disable-next-line camelcase
    const {
      // plasma_volume,
      // est_igg,
      // measles_immune,
      // flavor,
      // destroyed,
      // destroyed_date,
      // destroyed_reason,
      // pbn,
      // bol_date_boxed,
      ...remainingFields
    } = fields;

    const data = Object.entries(remainingFields).map(([field, value]) => ({ field, value }));
    return data.map(e => {
      e.value = getFormat(e.field, e.value);
      // Check if field has specific formatting, otherwise auto-format
      e.field = allColumnNames[e.field] ? allColumnNames[e.field] : checkFields(e.field);
      return e;
    });
  };

  return (
    <div key="details-section" className={classes.root}>
      {loading ? (
        <Loader />
      ) : (
        <Accordion className={classes.accordion}>
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon style={{ color: red[500] }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.title} variant="h4" component="h1">
              {event === 'Bol' ? 'Boxed with BOL' : event} Donation Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <div>
              {getData().map(item => (
                <div key={`${item.field}-${item.value}`}>
                  {item.field !== 'Status' && (
                    <div className={classes.fieldValue} key={item.field}>
                      <Typography className={classes.field}>{item.field}</Typography>
                      <Typography className={classes.value}>
                        {event === 'Tested' && item.field === 'Timestamp' ? '-' : item.value}
                      </Typography>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
}

EventDetails.propTypes = {
  event: PropTypes.string.isRequired,
  fields: PropTypes.shape({}).isRequired,
};

export default EventDetails;
