import { RSAA } from 'redux-api-middleware';

// Resolution message for All Lookback Units
export const FETCH_COMPLIANCE_GET_LOOKBACKS_SUBMITTED_REQUEST =
  'FETCH_COMPLIANCE_GET_LOOKBACKS_SUBMITTED_REQUEST';
export const FETCH_COMPLIANCE_GET_LOOKBACKS_SUBMITTED_SUCCESS =
  'FETCH_COMPLIANCE_GET_LOOKBACKS_SUBMITTED_SUCCESS';
export const FETCH_COMPLIANCE_GET_LOOKBACKS_SUBMITTED_FAILURE =
  'FETCH_COMPLIANCE_GET_LOOKBACKS_SUBMITTED_FAILURE';

export const FETCH_COMPLIANCE_GET_DESTROYED_DONATIONS_REQUEST =
  'FETCH_COMPLIANCE_GET_DESTROYED_DONATIONS_REQUEST';
export const FETCH_COMPLIANCE_GET_DESTROYED_DONATIONS_SUCCESS =
  'FETCH_COMPLIANCE_GET_DESTROYED_DONATIONS_SUCCESS';
export const FETCH_COMPLIANCE_GET_DESTROYED_DONATIONS_FAILURE =
  'FETCH_COMPLIANCE_GET_DESTROYED_DONATIONS_FAILURE';

export const FETCH_COMPLIANCE_GET_CROSS_DONATIONS_REQUEST = 'FETCH_COMPLIANCE_GET_CROSS_DONATIONS_REQUEST';
export const FETCH_COMPLIANCE_GET_CROSS_DONATIONS_SUCCESS = 'FETCH_COMPLIANCE_GET_CROSS_DONATIONS_SUCCESS';
export const FETCH_COMPLIANCE_GET_CROSS_DONATIONS_FAILURE = 'FETCH_COMPLIANCE_GET_CROSS_DONATIONS_FAILURE';

// Fetch for All Destroyed Units
export const fetchComplianceGetDestroyedDonations = (
  timeFrame,
  limit,
  sortBy,
  orderBy,
  filtersRetrieved,
  search,
  filterQuery = ''
) => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-compliance-get-destroyed-donations/${timeFrame}?limit=${limit}&sortBy=${sortBy}&orderBy=${orderBy}&filtersRetrieved=${filtersRetrieved}&search=${search}${filterQuery}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COMPLIANCE_GET_DESTROYED_DONATIONS_REQUEST,
      {
        type: FETCH_COMPLIANCE_GET_DESTROYED_DONATIONS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          if (!json.data[0]) {
            return { entities: { data: { destroyedUnits: ['no entries'] } } };
          }
          return { entities: { destroyedUnits: json } };
        },
      },
      {
        type: FETCH_COMPLIANCE_GET_DESTROYED_DONATIONS_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

// Fetch Middleware for All destroyed Units
export const ComplianceGetDestroyedDonationsMiddleware = () => next => action => {
  next(action);
  switch (action.type) {
    case FETCH_COMPLIANCE_GET_DESTROYED_DONATIONS_SUCCESS:
      console.log('fetch all destroyed units success');
      break;
    case FETCH_COMPLIANCE_GET_DESTROYED_DONATIONS_FAILURE:
      console.log('fetch all destroyed units failure');
      break;
    default:
      break;
  }
};

// Fetch for All Lookback Units
export const fetchComplianceGetLookbacksSubmitted = (
  timeFrame,
  limit,
  sortBy,
  orderBy,
  filtersRetrieved,
  search,
  filterQuery = ''
) => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-compliance-get-lookbacks-submitted/${timeFrame}?limit=${limit}&sortBy=${sortBy}&orderBy=${orderBy}&filtersRetrieved=${filtersRetrieved}&search=${search}${filterQuery}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COMPLIANCE_GET_LOOKBACKS_SUBMITTED_REQUEST,
      {
        type: FETCH_COMPLIANCE_GET_LOOKBACKS_SUBMITTED_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          if (!json.data[0]) {
            return { entities: { data: { lookbackUnits: ['no entries'] } } };
          }
          return { entities: { lookbackUnits: json } };
        },
      },
      {
        type: FETCH_COMPLIANCE_GET_LOOKBACKS_SUBMITTED_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

// Fetch Middleware for All Lookback Units
export const ComplianceGetLookbacksSubmittedMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COMPLIANCE_GET_LOOKBACKS_SUBMITTED_SUCCESS:
      console.log('fetch all lookback units success');
      break;
    case FETCH_COMPLIANCE_GET_LOOKBACKS_SUBMITTED_FAILURE:
      console.log('fetch all lookback units failure');
      break;
    default:
      break;
  }
};

// Fetch for Cross Donations
export const fetchComplianceGetCrossDonations = (
  timeFrame,
  limit,
  offset,
  sortBy,
  orderBy,
  filtersRetrieved,
  search,
  filterQuery = ''
) => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-compliance-get-cross-donations/${timeFrame}?limit=${limit}&offset=${offset}&sortby=${sortBy}&orderby=${orderBy}&filtersRetrieved=${filtersRetrieved}&search=${search}${filterQuery}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COMPLIANCE_GET_CROSS_DONATIONS_REQUEST,
      {
        type: FETCH_COMPLIANCE_GET_CROSS_DONATIONS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          if (!json.data[0]) {
            return { entities: { data: { crossDonations: ['no entries'] } } };
          }
          return { entities: { crossDonations: json } };
        },
      },
      {
        type: FETCH_COMPLIANCE_GET_CROSS_DONATIONS_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

// Fetch Middleware for Cross Donations
export const ComplianceGetCrossDonationsMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COMPLIANCE_GET_CROSS_DONATIONS_SUCCESS:
      console.log('fetch cross donations success');
      break;
    case FETCH_COMPLIANCE_GET_CROSS_DONATIONS_FAILURE:
      console.log('fetch cross donations failure');
      break;
    default:
      break;
  }
};
