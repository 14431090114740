import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import RankList from '../../../components/RankList/RankList';
import { TableModal } from '../../../components/Table/TableLink';
import useStyles from '../Quality.styles';
import Loader from '../../../components/Loader/Loader';
import {
  fetchComplianceGetTopLocationsDestroyed,
  fetchComplianceGetTopLocationsLookbackConfirmed,
  fetchComplianceGetTopLocationsLookbackSubmitted,
  fetchComplianceGetTopLocationsQuarantine,
} from '../../../redux/overview';

function TopBioCenters({ handleLocationClick, timeFrame }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const loading = useSelector(state =>
    get(state, 'loading.FETCH_COMPLIANCE_GET_TOP_LOCATIONS_QUARANTINE', false)
  );

  useEffect(() => {
    dispatch(fetchComplianceGetTopLocationsQuarantine(timeFrame));
    dispatch(fetchComplianceGetTopLocationsLookbackSubmitted(timeFrame));
    dispatch(fetchComplianceGetTopLocationsLookbackConfirmed(timeFrame));
    dispatch(fetchComplianceGetTopLocationsDestroyed(timeFrame));
  }, [timeFrame]);

  const topLocationsLists = useSelector(state => {
    const quarantineLocations = get(state, 'entities.topLocationsQuarantine', []);
    const submittedLocations = get(state, 'entities.topLocationsLookbackSubmitted', []);
    const confirmedLocations = get(state, 'entities.topLocationsLookbackConfirmed', []);
    const destroyedLocations = get(state, 'entities.topLocationsDestroyed', []);
    const data = [];
    data.push(quarantineLocations.data);
    data.push(submittedLocations.data);
    data.push(confirmedLocations.data);
    data.push(destroyedLocations.data);

    // helper
    const populate = (arr, centername, centerid, stat, statUnit, topCenterType) => {
      const array = [];
      if (arr)
        arr.forEach(item => {
          array.push({
            title: item[centername],
            stat: `${item[stat]} ${statUnit}`,
            showLink: () =>
              TableModal(item[centername], () =>
                handleLocationClick(item[centerid], topCenterType, item[centername])
              ),
          });
        });
      return array;
    };

    if (data[0]) {
      const topLocationCards = [
        {
          // eslint-disable-next-line prettier/prettier
          Quarantine: populate(
            data[0],
            'centeralias',
            'centernum',
            'total_volume',
            'L',
            'Quarantine',
          ),
        },
        {
          // eslint-disable-next-line prettier/prettier
          'Lookback Submitted': populate(
            data[1],
            'centeralias',
            'centernum',
            'total_volume',
            'L',
            'Lookback Submitted'
          ),
        },
        {
          // eslint-disable-next-line prettier/prettier
          Destroyed: populate(
            data[3],
            'centeralias',
            'centernum',
            'total_volume',
            'L',
            'Destroyed',
          ),
        },
      ];
      topLocationCards.splice(1, 0, {
        'Lookback Notification Received': populate(
          data[2],
          'centeralias',
          'centernum',
          'total_volume',
          'L',
          'Lookback Notification Received'
        ),
      });
      return topLocationCards;
    }
    return {};
  });

  const showTopFiveQualityLists = () => {
    if (topLocationsLists[0]) {
      return topLocationsLists.map(list => {
        const key = Object.keys(list)[0];
        return (
          <Grid item xs={3} key={key} align="left">
            <Typography className={classes.rankListTitle} variant="h6">
              {key}
            </Typography>
            <RankList list={list[key]} usePopUp />
          </Grid>
        );
      });
    }
    return null;
  };

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.accordionDescription}>
          <Typography className={classes.title} variant="subtitle2">
            The top BioLife donation centers with donations flagged in lookback notifications. Data displayed
            is for the time interval selected above.
          </Typography>
        </div>
      </Grid>
      <Grid container spacing={3} align="left">
        {loading ? <Loader /> : showTopFiveQualityLists()}
      </Grid>
    </>
  );
}

TopBioCenters.propTypes = {
  timeFrame: PropTypes.string.isRequired,
  handleLocationClick: PropTypes.func.isRequired,
};
export default TopBioCenters;
