import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import { map } from 'lodash';
import Table from '../../../components/Table/Table';
import TableSearch from '../../../components/TableSearch/TableSearch';
import useStyles from '../Quality.styles';
import CustomRangeFilter from '../../../components/CustomRangeFilter/CustomRangeFilter';
import { fetchComplianceGetCrossDonations } from '../../../redux/quality';
import {
  filterDateOptionsLogic,
  customFilterListOptionsRender,
  customFilterListOptionsUpdate,
  formatDate,
  getFilterArray,
  checkAndFormatIfDate,
  customCrossLeftGray,
  customCrossRightGray,
  customLeftDateCrossAlignRight,
  customRightDateCrossAlignRight,
  returnRowContainsStatus,
  findFilter,
  sortNum,
} from '../../../utils';
import { TableLink } from '../../../components/Table/TableLink';

function CrossDonations({ timeFrame }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const crossLoading = useSelector(state =>
    get(state, 'loading.FETCH_COMPLIANCE_GET_CROSS_DONATIONS', false)
  );
  const [sortOptions, setSortOptions] = useState({ name: 'bleed_date', direction: 'desc' });
  const [filterOptions, setFilterOptions] = useState({});
  const [filterData, setFilterData] = useState({});
  const [filtersRetrieved, setFiltersRetrieved] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [crossRetrieved, setCrossRetrieved] = useState(false);

  const crossData = useSelector(state => {
    if (!get(state, 'entities.crossDonations.data', {})[0]) {
      return [];
    }
    return map(get(state, 'entities.crossDonations.data', {})[0].list, cd => ({
      total_queried_count: Number(cd.total_queried_count),
      base_pbn: cd.base_pbn || '-',
      lookback_report_type: cd.lookback_report_type || '-',
      base_location: cd.base_location || '-',
      base_centernum: cd.base_centernum || '-',
      base_bleed_date: cd.base_bleed_date ? cd.base_bleed_date : 'N/A',
      test_release_date: cd.test_release_date ? cd.test_release_date : 'N/A',
      pbn: cd.pbn || '-',
      pdn: cd.pdn || '-',
      location: cd.location || '-',
      centernum: cd.centernum || '-',
      bleed_date: cd.bleed_date ? cd.bleed_date : 'N/A',
    }));
  });

  const crossFilters = useSelector(state => {
    if (filtersRetrieved) {
      return filterData;
    }
    if (!get(state, 'entities.crossDonations.data', {})[1]) {
      return [];
    }
    const allFilters = get(state, 'entities.crossDonations.data', {})[1].filters;
    if (allFilters) {
      const filtersObject = {
        base_locations: findFilter(allFilters, 'base_locations'),
        cross_locations: findFilter(allFilters, 'cross_locations'),
        lookback_report_types: findFilter(allFilters, 'lookback_report_types'),
        min_base_bleed_date: formatDate(findFilter(allFilters, 'base_bleed_date', 'min')),
        max_base_bleed_date: formatDate(findFilter(allFilters, 'base_bleed_date', 'max')),
        min_cross_bleed_date: formatDate(findFilter(allFilters, 'cross_bleed_date', 'min')),
        max_cross_bleed_date: formatDate(findFilter(allFilters, 'cross_bleed_date', 'max')),
        min_test_release_date: formatDate(findFilter(allFilters, 'test_release_date', 'min')),
        max_test_release_date: formatDate(findFilter(allFilters, 'test_release_date', 'max')),
      };
      setFilterData(filtersObject);
      setFiltersRetrieved(true);
      return filtersObject;
    }
    return [];
  });

  /* eslint-disable */
  const crossColumns = [
    {
      name: 'base_pbn',
      label: 'PTR/PDI PBN',
      options: {
        filter: false,
        sort: true,
        sortCompare: order => sortNum(order),
        viewColumns: false,
        customBodyRender: ptr => {
          const getLocationId = () => {
            let id = 0;
            crossData.every(row => {
              if (row.ptr === ptr) {
                id = row.base_centernum;
                return false;
              }
              return true;
            });
            return id;
          };
          return <div style={{
            backgroundColor: '#EAEAF2',
            padding: '16px',
          }}>{TableLink(ptr, `/collection/${getLocationId()}/donation/${ptr}`)}</div>;
        },
        setCellHeaderProps: () => ({
          className: classes.leftAlignedHeader,
        }),
      },
    },
    {
      name: 'base_location',
      label: 'PTR/PDI Donation Location',
      options: {
        filter: true,
        customBodyRender: customCrossLeftGray,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `PTR/PDI Location: ${v}`,
        },
        filterOptions: {
          logic: (location, filters) => returnRowContainsStatus(location, filters),
          names: crossFilters.base_locations,
        },
        setCellHeaderProps: () => ({
          className: classes.leftAlignedMarginHeader,
        }),
        sort: true,
      },
    }, 
    {
      name: 'base_bleed_date',
      label: 'PTR/PDI Donation Bleed Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customLeftDateCrossAlignRight,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => customFilterListOptionsRender(v, 'Bleed Date'),
          update: (filterList, filterPos, index) =>
            customFilterListOptionsUpdate(filterList, filterPos, index),
        },
        setCellHeaderProps: () => ({
          className: classes.rightAlignedMarginHeader,
        }),
        filterOptions: {
          names: [],
          logic(val, filters) {
            return filters.length ? filterDateOptionsLogic(val, filters) : false;
          },
          display: (filterList, onChange, index, column) => (
            <CustomRangeFilter
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
              label="PTR/PDI Bleed Date"
              type="date"
              list={{min_date: crossFilters.min_base_bleed_date, max_date: crossFilters.max_base_bleed_date}}
            />
          ),
        },
      },
    },
    {
      name: 'test_release_date',
      label: 'PTR/PDI Test Release Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customLeftDateCrossAlignRight,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => customFilterListOptionsRender(v, 'Bleed Date'),
          update: (filterList, filterPos, index) =>
            customFilterListOptionsUpdate(filterList, filterPos, index),
        },
        setCellHeaderProps: () => ({
          className: classes.rightAlignedMarginHeader,
        }),
        filterOptions: {
          names: [],
          logic(val, filters) {
            return filters.length ? filterDateOptionsLogic(val, filters) : false;
          },
          display: (filterList, onChange, index, column) => (
            <CustomRangeFilter
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
              label="Release Test Date"
              type="date"
              list={{min_date: crossFilters.min_test_release_date, max_date: crossFilters.max_test_release_date}}
            />
          ),
        },
      },
    },
    {
      name: 'lookback_report_type',
      label: 'PTR/PDI Lookback Report Type',
      options: {
        filter: true,
        customBodyRender: customCrossLeftGray,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Lookback Report Type: ${v}`,
        },
        filterOptions: {
          logic: (types, filters) => returnRowContainsStatus(types, filters),
          names: crossFilters.lookback_report_types,
        },
        setCellHeaderProps: () => ({
          className: classes.leftAlignedMarginHeader,
        }),
        sort: true,
      },
    },
    {
      name: 'pdn',
      label: 'PTR/PDI Donation PDN',
      options: {
        filter: false,
        customBodyRender: customCrossLeftGray,
        sort: true,
        setCellHeaderProps: () => ({
          className: classes.leftAlignedMarginHeader,
        }),
      },
    },
    {
      name: 'pbn',
      label: 'Cross-Donation PBN',
      options: {
        filter: false,
        sort: true,
        sortCompare: order => sortNum(order),
        viewColumns: false,
        customBodyRender: pbn => {
          const getLocationId = () => {
            let id = 0;
            crossData.every(row => {
              if (row.pbn === pbn) {
                id = row.centernum;
                return false;
              }
              return true;
            });
            return id;
          };
          return <div style={{
            backgroundColor: '#F6F6FA',
            padding: '16px',
          }}>{TableLink(pbn, `/collection/${getLocationId()}/donation/${pbn}`)}</div>;
        },
        setCellHeaderProps: () => ({
          className: classes.leftAlignedMarginHeader,
        }),
      },
    },
    {
      name: 'location',
      label: 'Cross-Donation Location',
      options: {
        filter: true,
        customBodyRender: customCrossRightGray,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Cross Location: ${v}`,
        },
        filterOptions: {
          logic: (location, filters) => returnRowContainsStatus(location, filters),
          names: crossFilters.cross_locations,
        },
        sort: true,
        setCellHeaderProps: () => ({
          className: classes.leftAlignedMarginHeader,
        }),
      },
    },
    {
      name: 'bleed_date',
      label: 'Cross-Donation Bleed Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customRightDateCrossAlignRight,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => customFilterListOptionsRender(v, 'Bleed Date'),
          update: (filterList, filterPos, index) =>
            customFilterListOptionsUpdate(filterList, filterPos, index),
        },
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
        filterOptions: {
          names: [],
          logic(val, filters) {
            return filters.length ? filterDateOptionsLogic(val, filters) : false;
          },
          display: (filterList, onChange, index, column) => (
            <CustomRangeFilter
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
              label="Bleed Date"
              type="date"
              list={{min_date: crossFilters.min_cross_bleed_date, max_date: crossFilters.max_cross_bleed_date}}
            />
          ),
        },
      },
    },
  ];

  // INITIAL FETCH
  useEffect(() => {
    if (!crossRetrieved){
      dispatch(fetchComplianceGetCrossDonations(timeFrame, 150, 0, sortOptions.name, sortOptions.direction, filtersRetrieved));
      setCrossRetrieved(true);
    }
  }, [dispatch]);

  const limit = (page + 3) * rowsPerPage;
  const crossDateColumns = ['bleed_date', 'test_relase_date', 'base_bleed_date'];
  const filterQuery = getFilterArray(crossColumns, crossDateColumns, filterOptions);
  const offset = 0;

  // FOR QUERY CHANGES: SORT, FILTERS AND SEARCH
  useEffect(() => {
    if (crossRetrieved) {
      dispatch(
        fetchComplianceGetCrossDonations(
          timeFrame,
          limit,
          offset,
          sortOptions.name,
          sortOptions.direction,
          filtersRetrieved,
          checkAndFormatIfDate(searchText === null ? '' : searchText),
          filterQuery.join('')
        )
      );
      setPage(0);
    }
  }, [timeFrame, sortOptions, filterOptions, searchText, dispatch]);

  // ROWS PER PAGE CHANGE
  useEffect(() => {
    if (crossRetrieved) {
      setPage(0);
    }
  }, [rowsPerPage]);

  // FOR PAGE CHANGES
  const previousPage = useRef(page);
  useEffect(() => {
    if (crossRetrieved){
      if (previousPage.current !== page) {
        if (page > previousPage.current) {
          previousPage.current = page;
          dispatch(
            fetchComplianceGetCrossDonations(
              timeFrame,
              limit,
              offset,
              sortOptions.name,
              sortOptions.direction,
              filtersRetrieved,
              checkAndFormatIfDate(searchText === null ? '' : searchText),
              filterQuery.join('')
            )
          );
        } else if (page < previousPage.current) {
          previousPage.current = page;
        }
      }
    }
  }, [page]);

  return (
    <Grid item xs={12}>
      <div className={classes.accordionDescription}>
        <Typography className={classes.title} variant="subtitle2">
          Cross-sectional list of units containing unsuitable traits or post-donation information, and donations at different BioLife centers from the same donor. Data displayed is for the time interval selected above.
        </Typography>
      </div>
      <Table
        title="PTR/PDI & Cross-Donations"
        additionalClasses={classes.crossDonationTable}
        columns={crossColumns}
        data={crossData}
        sortOptions={sortOptions}
        setSortOptions={setSortOptions}
        isLoading={crossLoading}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        setFilterOptions={setFilterOptions}
        options={{
          onSearchChange: text => setSearchText(text),
          customSearchRender: (_searchText, handleSearch, onHide) => (
            // handleSearch is mui datatables default function
            // we utilize it to handle searches that don't require the api
            // takes a parameter which is the search query
            <TableSearch
              options={{ searchPlaceholder: searchText }}
              setCustomSearchText={setSearchText}
              onSearch={handleSearch}
              onHide={() => {
                onHide();
                setSearchText('');
              }}
            />
          ),
        }}
      />
    </Grid>
  );
}

CrossDonations.propTypes = {
  timeFrame: PropTypes.string.isRequired,
};
export default CrossDonations;
