import { RSAA } from 'redux-api-middleware';

export const FETCH_COLLECTION_GET_DONATION_DETAILS_REQUEST = 'FETCH_COLLECTION_GET_DONATION_DETAILS_REQUEST';
export const FETCH_COLLECTION_GET_DONATION_DETAILS_SUCCESS = 'FETCH_COLLECTION_GET_DONATION_DETAILS_SUCCESS';
export const FETCH_COLLECTION_GET_DONATION_DETAILS_FAILURE = 'FETCH_COLLECTION_GET_DONATION_DETAILS_FAILURE';

export const fetchCollectionGetDonationDetails = pbn => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_API_URL}/plasmachain-collection-get-donation-details/${pbn}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Origin: '*',
      Authorization: sessionStorage.getItem('OktaToken'),
    },
    method: 'GET',
    types: [
      FETCH_COLLECTION_GET_DONATION_DETAILS_REQUEST,
      {
        type: FETCH_COLLECTION_GET_DONATION_DETAILS_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          return { entities: { events: json } };
        },
      },
      {
        type: FETCH_COLLECTION_GET_DONATION_DETAILS_FAILURE,
        meta: action => ({ method: action[RSAA].method }),
      },
    ],
  },
});

export const CollectionGetDonationDetailsMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_COLLECTION_GET_DONATION_DETAILS_SUCCESS:
      console.log('fetch donation events success');
      break;
    case FETCH_COLLECTION_GET_DONATION_DETAILS_FAILURE:
      console.log('fetch donation events failure');
      break;
    default:
      break;
  }
};
