import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  summaryOverviewContainer: {
    backgroundColor: '#f1f1f1',
    width: '100vw',
    minWidth: '100vw',
    minHeight: '100px',
    marginBottom: theme.spacing(3),
  },
  summaryAccOverviewContainer: {
    backgroundColor: '#f1f1f1',
    width: '100vw',
    minWidth: '100vw',
    minHeight: '100px',
    marginBottom: theme.spacing(3),
  },
  summaryOverview: {
    maxWidth: '1280px',
    margin: `0 auto ${theme.spacing(1)}px`,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(3),
    position: 'relative',
  },
  summaryDetailsOverview: {
    maxWidth: '1280px',
    margin: `0 auto ${theme.spacing(1)}px`,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    position: 'relative',
  },
  summaryAccOverview: {
    maxWidth: '1280px',
    margin: `0 auto ${theme.spacing(1)}px`,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    position: 'relative',
  },
  horizontalAccordionList: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    maxWidth: '1000px',
  },
  horizontalList: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    paddingBottom: theme.spacing(2),
    maxWidth: '1200px',
  },
  overviewRowTitle: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.text.secondary,
    letterSpacing: '1.25px',
  },
  listItem: {
    paddingTop: '0',
  },
  listItemLong: {
    paddingTop: '0',
    flex: '0 0 20px',
  },
  listSecondaryItem: {
    paddingTop: '0',
    minWidth: '190px',
  },
  listSecondaryItemLong: {
    paddingTop: '0',
    minWidth: '250px',
  },
  listItemTitle: {
    letterSpacing: '1.25px',
  },
  listItemDetail: {
    color: theme.palette.text.primary,
    fontWeight: '700',
  },
  listItemDetailTwo: {
    fontWeight: '400',
    color: theme.palette.text.secondary,
  },
  secondaryItem: {
    paddingTop: '10px',
  },
  secondaryItemLabel: {
    fontWeight: '400',
    fontSize: '13px',
  },
  secondaryItemDetail: {
    fontWeight: '500',
  },
  toolTip: {
    backgroundcolor: theme.palette.common.black,
  },
  toggleButton: {
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(20),
  },
  rowHidden: {
    height: '0',
    overflow: 'hidden',
    transition: 'all .5s ease',
    webkitTransition: 'all .5s ease',
    mozTransition: 'all .5s ease',
  },
  show: {
    height: '120px',
    paddingBottom: theme.spacing(2),
  },
  accordion: {
    backgroundColor: '#f1f1f1',
    boxShadow: 'none',
  },
  loaderContainer: {
    paddingTop: theme.spacing(4),
  },
}));

export default useStyles;
