/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import map from 'lodash/map';
import get from 'lodash/get';
import moment from 'moment';
import Table from '../Table/Table';
import TableSearch from '../TableSearch/TableSearch';
import useStyles from './QualityModalDetails.styles';
import Loader from '../Loader/Loader';
import {
  fetchComplianceGetTopLocationsDonationsList,
  fetchComplianceGetTopReasonsDonationsList,
} from '../../redux/overview';
import {
  formatDate,
  numDecimalsCheck,
  withCommas,
  customFilterListOptionsRender,
  customFilterListOptionsUpdate,
  filterDateOptionsLogic,
  getMinMaxDates,
  customAlignRight,
  sortDate,
  getFilterArray,
  checkAndFormatIfDate,
} from '../../utils';
import { TableLink } from '../Table/TableLink';
import CustomRangeFilter from '../CustomRangeFilter/CustomRangeFilter';
import {
  agingColumn,
  iggColumn,
  pbnColumn,
  pdnColumn,
  statusColumn,
  tokenColumn,
  volumeColumn,
} from '../Table/Columns';

function QualityModalDetails({
  reasonOrLoc,
  selectedLocId,
  modalOverview,
  selectedAttribute,
  selectedValue,
  reasonDescription,
  timeFrame,
  handleClose,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const loadingLocations = useSelector(state =>
    get(state, 'loading.FETCH_COMPLIANCE_GET_TOP_LOCATIONS_DONATIONS_LIST', false)
  );
  const loadingReasons = useSelector(state =>
    get(state, 'loading.FETCH_COMPLIANCE_GET_TOP_REASONS_DONATIONS_LIST', false)
  );
  const SPACED_DATE_FORMAT = 'MM/DD/YYYY';
  const getHyphenatedDate = dateString => moment(dateString, 'MM/DD/YYYY').format(SPACED_DATE_FORMAT);
  const [sortOptions, setSortOptions] = useState({ name: 'bleed_date', direction: 'desc' });
  const [filterOptions, setFilterOptions] = useState({});
  const [searchText, setSearchText] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);

  const neutralizeBack = callback => {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, '', window.location.href);
      callback();
    };
  };

  useEffect(() => {
    neutralizeBack(handleClose);
  }, [selectedLocId]);

  const validateLookback = (attr, lookback) => {
    if (lookback && attr === 'lookback') {
      return lookback;
    }
    return '-';
  };

  const reasonDonations = useSelector(state =>
    map(get(state, 'entities.topReasonsDonationsList.data', {}), donation => ({
      total_queried_count: Number(donation.total_queried_count),
      pbn: donation.pbn || '-',
      pdn: donation.pdn || '-',
      status: donation.status.replace('_', ' '),
      token_id: donation.token_id || 'TBD',
      center_id: donation.center_number || '-',
      center_name: donation.center_alias || '-',
      bleed_date: getHyphenatedDate(formatDate(donation.bleed_date)),
      volume: numDecimalsCheck(donation.volume, 3),
      est_igg: numDecimalsCheck(donation.est_igg, 2),
      aging: !Number.isNaN(donation.aging) ? `${withCommas(donation.aging)}` : '-',
      lookback_report_type: validateLookback(selectedAttribute, donation.lookback_report_type),
      description: validateLookback(selectedAttribute, donation.description),
      lookback_submitted: validateLookback(selectedAttribute, donation.lookback_submitted),
      lookback_confirmed: validateLookback(selectedAttribute, donation.lookback_confirmed),
    }))
  );

  const reasonColumns = [
    pbnColumn(pbn => {
      const getLocationId = () => {
        let id = 0;
        reasonDonations.every(row => {
          if (row.pbn === pbn) {
            id = row.center_id;
            return false;
          }
          return true;
        });
        return id;
      };
      return TableLink(pbn, `/collection/${getLocationId()}/donation/${pbn}`);
    }),
    pdnColumn,
    {
      name: 'center_name',
      label: 'Center Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'bleed_date',
      label: 'Bleed Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => customFilterListOptionsRender(v, 'Bleed Date'),
          update: (filterList, filterPos, index) =>
            customFilterListOptionsUpdate(filterList, filterPos, index),
        },
        filterOptions: {
          names: [],
          logic(val, filters) {
            return filters.length ? filterDateOptionsLogic(val, filters) : false;
          },
          display: (filterList, onChange, index, column) => (
            <CustomRangeFilter
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
              label="Bleed Date"
              type="date"
              list={getMinMaxDates(reasonDonations, 'bleed_date')}
            />
          ),
        },
      },
    },
    statusColumn(),
    tokenColumn({
      className: classes.leftAlignedHeader,
    }),
    volumeColumn({
      className: classes.rightAlignedHeader,
    }),
    iggColumn({
      className: classes.rightAlignedHeader,
    }),
    agingColumn({
      className: classes.rightAlignedHeader,
    }),
    {
      name: 'lookback_report_type',
      label: 'Lookback Report Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: 'Lookback Description',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'lookback_submitted',
      label: 'Lookback Submitted',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'lookback_confirmed',
      label: 'Lookback Confirmed',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const reasonLookbackColumns = [
    pbnColumn(pbn => {
      const getLocationId = () => {
        let id = 0;
        reasonDonations.every(row => {
          if (row.pbn === pbn) {
            id = row.center_id;
            return false;
          }
          return true;
        });
        return id;
      };
      return TableLink(pbn, `/collection/${getLocationId()}/donation/${pbn}`);
    }),
    pdnColumn,
    {
      name: 'center_name',
      label: 'Center Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'bleed_date',
      label: 'Bleed Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => customFilterListOptionsRender(v, 'Bleed Date'),
          update: (filterList, filterPos, index) =>
            customFilterListOptionsUpdate(filterList, filterPos, index),
        },
        filterOptions: {
          names: [],
          logic(val, filters) {
            return filters.length ? filterDateOptionsLogic(val, filters) : false;
          },
          display: (filterList, onChange, index, column) => (
            <CustomRangeFilter
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
              label="Bleed Date"
              type="date"
              list={getMinMaxDates(reasonDonations, 'bleed_date')}
            />
          ),
        },
      },
    },
    statusColumn(),
    tokenColumn({
      className: classes.leftAlignedHeader,
    }),
    volumeColumn({
      className: classes.rightAlignedHeader,
    }),
    iggColumn({
      className: classes.rightAlignedHeader,
    }),
    agingColumn({
      className: classes.rightAlignedHeader,
    }),
    // getExtraColumns(),
  ];

  const getColumns = () => {
    if (selectedAttribute === 'lookback') {
      return reasonLookbackColumns;
    }
    return reasonColumns;
  };

  const locationDonations = useSelector(state => {
    if (!get(state, 'entities.topQualityCenter.data', {})[0]) {
      return [];
    }
    return map(get(state, 'entities.topQualityCenter.data', {})[0].list, donation => ({
      total_queried_count: Number(donation.total_queried_count),
      pbn: donation.pbn || '-',
      pdn: donation.pdn || '-',
      status: donation.status || '-',
      token_id: donation.token_id || 'TBD',
      bleed_date: getHyphenatedDate(formatDate(donation.bleed_date)),
      volume: numDecimalsCheck(donation.plasma_volume, 3),
      est_igg: numDecimalsCheck(donation.est_igg, 2),
      lookback_report_type: donation.lookback_report_type || '-',
      description: donation.description || '-',
      measles_hyperimmune: donation.measles_immune ? 'Yes' : 'No',
      aging: !Number.isNaN(donation.aging) ? `${withCommas(donation.aging)}` : '-',
      lookback_reason: donation.reason_code,
    }));
  });

  const locationColumns = [
    pbnColumn(value => TableLink(value, `/collection/${selectedLocId}/donation/${value}`)),
    pdnColumn,
    statusColumn(),
    tokenColumn({
      className: classes.leftAlignedHeader,
    }),
    {
      name: 'bleed_date',
      label: 'Bleed Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
        sortCompare: order => sortDate(order),
        filterType: 'custom',
        customFilterListOptions: {
          render: v => customFilterListOptionsRender(v, 'Bleed Date'),
          update: (filterList, filterPos, index) =>
            customFilterListOptionsUpdate(filterList, filterPos, index),
        },
        filterOptions: {
          names: [],
          logic(val, filters) {
            return filters.length ? filterDateOptionsLogic(val, filters) : false;
          },
          display: (filterList, onChange, index, column) => (
            <CustomRangeFilter
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
              label="Bleed Date"
              type="date"
              list={getMinMaxDates(locationDonations, 'bleed_date')}
            />
          ),
        },
      },
    },
    volumeColumn({
      className: classes.rightAlignedHeader,
    }),
    iggColumn({
      className: classes.rightAlignedHeader,
    }),
    {
      name: 'lookback_report_type',
      label: 'Lookback Report Type',
    },
    {
      name: 'lookback_reason',
      label: 'Lookback Reason',
    },
    {
      name: 'description',
      label: 'Lookback Description',
    },
    {
      name: 'measles_hyperimmune',
      label: 'Measles Hyperimmune',
      options: {
        filter: true,
        sort: true,
      },
    },
    agingColumn({
      className: classes.rightAlignedHeader,
    }),
  ];

  // INITIAL FETCH FOR BOTH CASES, ONLY ONE IS CALLED
  useEffect(() => {
    if (reasonOrLoc === 'location' && selectedLocId && timeFrame && selectedAttribute) {
      dispatch(
        fetchComplianceGetTopLocationsDonationsList(
          selectedLocId,
          timeFrame,
          selectedAttribute.toLowerCase().split(' ').join('_'),
          100,
          0,
          sortOptions.name,
          sortOptions.direction,
          '',
          ''
        )
      );
    } else if (reasonOrLoc === 'reason' && selectedAttribute && selectedValue && timeFrame) {
      dispatch(
        fetchComplianceGetTopReasonsDonationsList(
          timeFrame,
          selectedAttribute,
          selectedValue,
          100,
          0,
          sortOptions.name,
          sortOptions.direction,
          '',
          ''
        )
      );
    }
  }, [reasonOrLoc, timeFrame, selectedLocId, selectedAttribute, selectedValue]);

  const limit = (page + 3) * rowsPerPage;
  const offset = 0;
  const reasonFilterQuery = getFilterArray(getColumns(), [], filterOptions);
  const locationFilterQuery = getFilterArray(locationColumns, [], filterOptions);

  useEffect(() => {
    if (reasonOrLoc === 'reason' && selectedAttribute && selectedValue && timeFrame) {
      dispatch(
        fetchComplianceGetTopReasonsDonationsList(
          timeFrame,
          selectedAttribute,
          selectedValue,
          limit,
          offset,
          sortOptions.name,
          sortOptions.direction,
          checkAndFormatIfDate(searchText === null ? '' : searchText),
          reasonFilterQuery.join('')
        )
      );
    }
    if (reasonOrLoc === 'location' && selectedAttribute && selectedValue && timeFrame) {
      dispatch(
        fetchComplianceGetTopLocationsDonationsList(
          selectedLocId,
          timeFrame,
          selectedAttribute.toLowerCase().split(' ').join('_'),
          limit,
          offset,
          sortOptions.name,
          sortOptions.direction,
          checkAndFormatIfDate(searchText === null ? '' : searchText),
          locationFilterQuery.join('')
        )
      );
    }
  }, [page, rowsPerPage, sortOptions, filterOptions, searchText, dispatch]);

  const formatSummary = (key, value) => {
    switch (key) {
      case 'donations':
        return `${withCommas(value)} units`;
      case 'volume':
        return numDecimalsCheck(value, 3, 'L');
      case 'est_igg':
        return numDecimalsCheck(value, 2, 'g');
      default:
        return '-';
    }
  };

  const getLocOverviewItems = useSelector(state => {
    if (!get(state, 'entities.topQualityCenter.data', {})[1]) {
      return [];
    }
    const overviewData = get(state, 'entities.topQualityCenter.data', {})[1].summary[0];
    if (overviewData) {
      return Object.keys(overviewData).map(key => (
        <ListItem className={classes.listItem} key={`row-item-${key}`}>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="overline" className={classes.listItemTitle}>
                {key === 'est_igg'
                  ? 'Gamma Protein Estimate (pre-donation)'
                  : key.toUpperCase().replace(/-|_/g, ' ')}
              </Typography>
            }
            secondary={
              <Typography variant="subtitle2" className={classes.listItemDetail}>
                {formatSummary(key, overviewData[key])}
              </Typography>
            }
          />
        </ListItem>
      ));
    }
    return [];
  });

  const getReasonOverviewItems = overviewData => {
    if (overviewData) {
      return Object.keys(overviewData).map(key => (
        <ListItem className={classes.listItem} key={`row-item-${key}`}>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="overline" className={classes.listItemTitle}>
                {key === 'est_igg'
                  ? 'Gamma Protein Estimate (pre-donation)'
                  : key.toUpperCase().replace(/-|_/g, ' ')}
              </Typography>
            }
            secondary={
              <Typography variant="subtitle2" className={classes.listItemDetail}>
                {formatSummary(key, overviewData[key])}
              </Typography>
            }
          />
        </ListItem>
      ));
    }
    return '';
  };

  const getAttribute = attribute => {
    if (attribute === 'lookback') return 'Lookback Reason';
    if (attribute === 'destroyed') return 'Destroyed Reason';
    return attribute;
  };

  return (
    <Grid className={classes.boxDetailsContainer}>
      <Typography variant="overline" className={classes.boxSubtitle}>
        {reasonOrLoc === 'location' ? 'LOCATION' : 'REASON'}
      </Typography>
      <Typography className={classes.boxTitle} variant="h4" noWrap>
        {reasonDescription || selectedValue} | {getAttribute(selectedAttribute)}
      </Typography>
      <div className={classes.boxOverviewContainer}>
        <Grid className={classes.boxOverview}>
          <Grid item xs={12}>
            {loadingReasons || loadingLocations ? (
              <Loader />
            ) : (
              (reasonOrLoc === 'location' && (
                <List className={classes.horizontalList}>{getLocOverviewItems}</List>
              )) || <List className={classes.horizontalList}>{getReasonOverviewItems(modalOverview)}</List>
            )}
          </Grid>
        </Grid>
      </div>
      <Grid item xs={12} className={classes.modalDetailsTable}>
        {(reasonOrLoc === 'location' && (
          <Table
            columns={locationColumns}
            data={locationDonations}
            sortOptions={{ name: 'bleed_date', direction: 'desc' }}
            isLoading={loadingReasons}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            setFilterOptions={setFilterOptions}
            options={{
              onSearchChange: text => setSearchText(text),
              customSearchRender: (_searchText, handleSearch, onHide) => (
                // handleSearch is mui datatables default function
                // we utilize it to handle searches that don't require the api
                // takes a parameter which is the search query
                <TableSearch
                  options={{ searchPlaceholder: searchText }}
                  setCustomSearchText={setSearchText}
                  onSearch={handleSearch}
                  onHide={() => {
                    onHide();
                    setSearchText('');
                  }}
                />
              ),
            }}
          />
        )) || (
          <Table
            columns={getColumns()}
            data={reasonDonations}
            sortOptions={{ name: 'bleed_date', direction: 'desc' }}
            setSortOptions={setSortOptions}
            isLoading={loadingReasons}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            setFilterOptions={setFilterOptions}
            options={{
              onSearchChange: text => setSearchText(text),
              customSearchRender: (_searchText, handleSearch, onHide) => (
                // handleSearch is mui datatables default function
                // we utilize it to handle searches that don't require the api
                // takes a parameter which is the search query
                <TableSearch
                  options={{ searchPlaceholder: searchText }}
                  setCustomSearchText={setSearchText}
                  onSearch={handleSearch}
                  onHide={() => {
                    onHide();
                    setSearchText('');
                  }}
                />
              ),
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}

QualityModalDetails.propTypes = {
  reasonOrLoc: PropTypes.string.isRequired,
  selectedLocId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  modalOverview: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  selectedAttribute: PropTypes.string.isRequired,
  selectedValue: PropTypes.string.isRequired,
  reasonDescription: PropTypes.string,
  timeFrame: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

QualityModalDetails.defaultProps = {
  selectedLocId: '',
  modalOverview: '',
  reasonDescription: '',
};

export default QualityModalDetails;
