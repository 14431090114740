import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { Button } from '@material-ui/core';

export function TableLink(value, to) {
  return (
    <Link
      component={RouterLink}
      style={{
        color: '#1777CF',
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
      to={to}
    >
      {value}
    </Link>
  );
}

export function TableModal(value, onclick) {
  return (
    <Button
      component={Link}
      style={{
        color: '#1777CF',
        '&:hover': {
          textDecoration: 'underline !important',
        },
        padding: '0 8px',
        minWidth: '0px',
        textAlign: 'left',
      }}
      onClick={onclick}
    >
      {value}
    </Button>
  );
}
