import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import map from 'lodash/map';
import get from 'lodash/get';
import TabPanel from '../../components/TabPanel/TabPanel';
import Table from '../../components/Table/Table';
import Modal from '../../components/Modal/Modal';
import ModalDetails from '../../components/ModalDetails/ModalDetails';
import useStyles from './Transit.styles';
import {
  fetchTransitGetShippedBOL,
  fetchTransitGetShippedBOLsCarrierConsignee,
  fetchTransitGetShippedBoxes,
} from '../../redux/transitBOL';
import {
  formatDate,
  customAlignRight,
  options,
  numDecimalsCheck,
  sortNum,
  sortNumberFilter,
  sortDate,
  sortDateFilter,
  useBackForwardCommands,
  returnRowContainsStatus,
  withCommas,
} from '../../utils';
import { iggColumn, itemNumberColumn, volumeColumn, donationsColumn } from '../../components/Table/Columns';
import { TableModal } from '../../components/Table/TableLink';
import Loader from '../../components/Loader/Loader';
import ViewInDevBanner from '../../components/ViewInDevBanner/ViewInDevBanner';

function Transit() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  useBackForwardCommands(history);
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedOverview, setSelectedOverview] = useState('');
  const [openModalAs, setModalOpenAs] = useState('');
  const [bolId, setBolId] = useState('-1');
  const loadingBols = useSelector(state => get(state, 'loading.FETCH_TRANSIT_GET_SHIPPED_BOL', true));
  const loadingBoxes = useSelector(state => get(state, 'loading.FETCH_TRANSIT_GET_SHIPPED_BOXES', true));
  const [selectedTab, setSelectedTab] = useState('bols');
  const [boxedRetrieved, setBoxedRetrieved] = useState(false);
  const bolSortDefault = { name: 'shipping_date', direction: 'desc' };

  const bolData = useSelector(state =>
    map(get(state, 'entities.shipped.data', []), bol => ({
      total_queried_count: Number(bol.total_queried_count),
      'bol-number': bol['bol-number'] || '-',
      id_origin: bol.id_origin || '-',
      item_number: bol.item_number || '-',
      origin: bol.origin || '-',
      shipping_date: formatDate(bol.shipping_date),
      carrier: bol.carrier || '-',
      vin: bol.VIN || '-',
      box_count: bol.boxes,
      bol_volume: numDecimalsCheck(bol.volume, 3),
      est_igg: numDecimalsCheck(bol.est_igg, 2),
      bol_earliest_bleed_date: formatDate(bol.earliest_bleed_date),
      bol_latest_bleed_date: formatDate(bol.latest_bleed_date),
      min_box_number: bol.min_box_number || '-',
      max_box_number: bol.max_box_number || '-',
    }))
  );

  const earliestBOLDates = sortDateFilter(bolData, 'bol_earliest_bleed_date', 'desc');
  const latestBOLDates = sortDateFilter(bolData, 'bol_latest_bleed_date', 'desc');
  const itemNumbers = sortNumberFilter(bolData, 'item_number', 'asc');

  if (itemNumberColumn && itemNumberColumn.options) {
    itemNumberColumn.options.filterOptions = { names: itemNumbers };
  }

  const boxedData = useSelector(state => {
    if (get(state, 'entities.shippedBoxes.data', {})[0] === 'no entries') {
      return [];
    }
    return map(get(state, 'entities.shippedBoxes.data', {}), box => ({
      total_queried_count: Number(box.total_queried_count),
      box_number: box.box_number || '-',
      donations: box.donations,
      volume: numDecimalsCheck(box.volume, 3),
      est_igg: numDecimalsCheck(box.est_igg, 2),
      earliest_bleed_date: formatDate(box.earliest_bleed_date),
      latest_bleed_date: formatDate(box.latest_bleed_date),
      min_age: withCommas(box.min_age),
      max_age: withCommas(box.max_age),
      date_boxed: formatDate(box.date_boxed),
    }));
  });

  const findBolOverview = rowId => {
    const bolOverview = bolData.filter(bol => bol['bol-number'] === rowId && bol);
    return bolOverview[0];
  };

  const carriersConsignees = useSelector(state =>
    map(get(state, 'entities.carriersConsignees.data', []), cc => ({
      carrier: cc.carrier || '-',
    }))
  );

  useEffect(() => {
    dispatch(fetchTransitGetShippedBOL(300, bolSortDefault.name, bolSortDefault.direction));
    dispatch(fetchTransitGetShippedBOLsCarrierConsignee());
  }, []);

  const revivalBack = () => {
    window.onpopstate = () => undefined;
    window.history.back();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedId('');
    setSelectedType('');
    revivalBack();
  };

  const handleRowClick = (rowId, type) => {
    setModalOpenAs(type);
    setSelectedId(rowId);
    if (type === 'bol') {
      setBolId(rowId);
    } else {
      setBolId('-1');
    }
    if (type === 'box') {
      const boxOverview = boxedData.filter(box => box.box_number === rowId && box);
      setSelectedOverview(boxOverview[0]);
    }
    if (type === 'bol') {
      setSelectedOverview(findBolOverview(rowId));
    }
    setSelectedType(type);
    setOpenModal(true);
  };

  const bolColumns = [
    {
      name: 'bol-number',
      label: 'BOL Number',
      options: {
        filter: true,
        sort: true,
        sortCompare: order => sortNum(order),
        viewColumns: false,
        customBodyRender: value => TableModal(value, () => handleRowClick(value, 'bol')),
      },
    },
    itemNumberColumn({
      className: classes.leftAlignedHeader,
    }),
    {
      name: 'origin',
      label: 'Origin',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'shipping_date',
      label: 'Shipping Date',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
        sortCompare: order => sortDate(order),
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
      },
    },
    {
      name: 'carrier',
      label: 'Carrier Number',
      options: {
        filter: true,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: v => `Carrier Number: ${v}`,
        },
        filterOptions: {
          logic: (carrier, filters) => returnRowContainsStatus(carrier, filters),
          names: carriersConsignees[0] ? carriersConsignees[0].carrier : '',
        },
        sort: true,
      },
    },
    {
      name: 'vin',
      label: 'VIN',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
      },
    },
    {
      name: 'box_count',
      label: 'Boxes',
      options: options('Boxes', customAlignRight, {
        className: classes.rightAlignedHeader,
      }),
    },
    volumeColumn({
      className: classes.rightAlignedHeader,
    }),
    iggColumn({
      className: classes.rightAlignedHeader,
    }),
    {
      name: 'bol_earliest_bleed_date',
      label: 'Earliest Bleed Date (MM/DD/YYYY)',
      options: {
        filter: true,
        filterOptions: { names: earliestBOLDates },
        customBodyRender: customAlignRight,
        sort: true,
        sortCompare: order => sortDate(order),
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
      },
    },
    {
      name: 'bol_latest_bleed_date',
      label: 'Latest Bleed Date (MM/DD/YYYY)',
      options: {
        filter: true,
        filterOptions: { names: latestBOLDates },
        customBodyRender: customAlignRight,
        sort: true,
        sortCompare: order => sortDate(order),
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
      },
    },
    {
      name: 'min_box_number',
      label: 'Min Box #',
      options: options('Min Box', customAlignRight, {
        className: classes.rightAlignedHeader,
      }),
    },
    {
      name: 'max_box_number',
      label: 'Max Box #',
      options: options('Max Box', customAlignRight, {
        className: classes.rightAlignedHeader,
      }),
    },
  ];

  const sortShipping = {
    name: 'shipping_date',
    direction: 'desc',
  };

  const sortBoxed = {
    name: 'date_boxed',
    direction: 'desc',
  };

  const handleChange = (e, value) => {
    if (value === 'boxes') {
      if (!boxedRetrieved) {
        setBoxedRetrieved(true);
        dispatch(fetchTransitGetShippedBoxes(300, sortBoxed.name, sortBoxed.direction));
      }
    }
    setSelectedTab(value);
  };

  const boxedColumns = [
    {
      name: 'box_number',
      label: 'Box Number',
      options: {
        sort: true,
        sortCompare: order => sortNum(order),
        viewColumns: false,
        customBodyRender: value => TableModal(value, () => handleRowClick(value, 'box')),
      },
    },
    donationsColumn({
      className: classes.rightAlignedHeader,
    }),
    volumeColumn({
      className: classes.rightAlignedHeader,
    }),
    iggColumn({
      className: classes.rightAlignedHeader,
    }),
    {
      name: 'earliest_bleed_date',
      label: 'Earliest Bleed Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
        sortCompare: order => sortDate(order),
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
      },
    },
    {
      name: 'latest_bleed_date',
      label: 'Latest Bleed Date (MM/DD/YYYY)',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
        sortCompare: order => sortDate(order),
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
      },
    },
    {
      name: 'max_age',
      label: 'Max Age (days)',
      options: options('Max Age', customAlignRight, {
        className: classes.rightAlignedHeader,
      }),
    },
    {
      name: 'min_age',
      label: 'Min Age (days)',
      options: options('Min Age', customAlignRight, {
        className: classes.rightAlignedHeader,
      }),
    },
    {
      name: 'date_boxed',
      label: 'Date Boxed',
      options: {
        filter: true,
        customBodyRender: customAlignRight,
        sort: true,
        sortCompare: order => sortDate(order),
        setCellHeaderProps: () => ({
          className: classes.rightAlignedHeader,
        }),
      },
    },
  ];

  return (
    <Grid
      className={classes.location}
      container
      direction="row"
      spacing={3}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <ViewInDevBanner />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="overline" className={classes.overviewSubtitle}>
          INVENTORY / IN TRANSIT
        </Typography>
        <Typography className={classes.title} variant="h4" noWrap>
          In Transit
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          classes={{
            root: classes.navBar,
            flexContainer: classes.flexContainer,
            indicator: classes.sectionIndicator,
          }}
          value={selectedTab}
          onChange={handleChange}
        >
          <Tab className={classes.tabLink} value="bols" label="BOL View" />
          <Tab className={classes.tabLink} value="boxes" label="Boxes View" />
        </Tabs>
        <TabPanel value={selectedTab} index="bols">
          {loadingBols ? (
            <Loader />
          ) : (
            <Table
              columns={bolColumns}
              title="Bill of Lading"
              data={bolData}
              sortOptions={sortShipping}
              isLoading={loadingBols}
            />
          )}
        </TabPanel>
        <TabPanel value={selectedTab} index="boxes">
          {loadingBoxes ? (
            <Loader />
          ) : (
            <Table
              columns={boxedColumns}
              title="Boxes View"
              data={boxedData}
              sortOptions={sortBoxed}
              isLoading={loadingBoxes}
            />
          )}
        </TabPanel>
      </Grid>
      {selectedId && (
        <Modal open={openModal} handleClose={handleCloseModal}>
          <ModalDetails
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            setSelectedType={setSelectedType}
            type={selectedType}
            overviewData={selectedOverview}
            setSelectedOverview={setSelectedOverview}
            currentLocation="none"
            openedAs={openModalAs}
            handleClose={handleCloseModal}
            findBolOverview={findBolOverview}
            bolId={bolId}
            openedFrom="transit"
          />
        </Modal>
      )}
    </Grid>
  );
}

export default Transit;
