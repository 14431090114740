import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import map from 'lodash/map';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useStyles from './ManufacturingLocationPool.styles';
import ManufacturingOverview from '../../components/ManufacturingOverview/ManufacturingOverview';
import TabPanel from '../../components/TabPanel/TabPanel';
import InputOutput from '../../components/ManufacturingPanel/InputOutput/InputOutput';
import ViewInDevBanner from '../../components/ViewInDevBanner/ViewInDevBanner';
import {
  fetchManufacturingGetTeardownLots,
  fetchManufacturingGetTeardownLotDonations,
} from '../../redux/manufacturing';
import imgFill from '../../images/filling.png';
import imgProd from '../../images/finalProduct.png';
import imgFrac from '../../images/fractionation.png';
import imgInsp from '../../images/inspection.png';
import imgPurif from '../../images/purification.png';
import imgTear from '../../images/teardown.png';
import { numDecimalsCheck, withCommas } from '../../utils';

function ManufacturingLocationPool() {
  const { lotid } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState('inputOutput');
  const [selectedLot, setSelectedLot] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Teardown', 'Fractionation', 'Purification', 'Filling', 'Inspection', 'Finished Product'];
  const icons = [imgTear, imgFrac, imgPurif, imgFill, imgInsp, imgProd];
  const [activeLabel, setActiveLabel] = useState('Teardown');
  const [allInputs, setAllInputs] = useState([]);
  const dummyObj = [{ lot: lotid }];

  const lotParentData = useSelector(state => get(state, 'entities.lots.data', []));

  let totalDonations = 0;
  let totalGamma = 0;
  let totalVolume = 0;

  const lotDonations = useSelector(state =>
    map(get(state, 'entities.lotDonations.data', []), donation => {
      totalDonations += 1;
      totalGamma += Number(donation.collected_est_igg);
      totalVolume += Number(donation.collected_plasma_volume);
      return {
        pbn: donation.pbn || '-',
        status: donation.current_status || '-',
        token_id: donation.collected_token_id || '-',
        volume: donation.collected_plasma_volume || '-',
        est_igg: numDecimalsCheck(donation.collected_est_igg, 2),
        aging: withCommas(donation.aging),
        masterfile: donation.masterfile ? 'true' : 'false',
      };
    })
  );

  const [lotData, setLotData] = useState(lotParentData || dummyObj);

  useEffect(() => {
    if (lotParentData) {
      setLotData(lotParentData);
    }
  }, [lotParentData]); // listen only to currentChannelName changes

  const handleTabChange = (e, value) => {
    setSelectedTab(value);
  };

  useEffect(() => {
    if (lotid) {
      dispatch(fetchManufacturingGetTeardownLots(lotid));
      dispatch(fetchManufacturingGetTeardownLotDonations(lotid));
    }
  }, []);

  const findNestedObj = (entireObj, keyToFind, valToFind) => {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
      if (nestedValue && nestedValue[keyToFind] === valToFind) {
        foundObj = nestedValue;
      }
      return nestedValue;
    });
    return foundObj;
  };

  const getPlantName = name => {
    switch (name) {
      case 'CV1':
        return 'Covington';
      case 'LA1':
        return 'Los Angeles';
      case 'VN1':
        return 'Vienna';
      default:
        return 'Unknown';
    }
  };

  const overviewData = lotData[0]
    ? [
        {
          overview: [
            { title: 'Lot ID', detail: lotData[0].lot ? lotData[0].lot : '-' },
            {
              title: 'Description',
              detail: lotData[0].description ? lotData[0].description : '-',
            },
            {
              title: 'Quantity',
              detail: `${lotData[0].quantity_declared ? lotData[0].quantity_declared : '-'} ${
                lotData[0].uom ? lotData[0].uom : '-'
              }`,
            },
            {
              title: 'Location',
              detail: lotData[0].branch_plant ? getPlantName(lotData[0].branch_plant) : '-',
            },
          ],
        },
      ]
    : [
        {
          overview: [
            { title: 'Lot ID', detail: lotid },
            { title: 'Description', detail: '-' },
            { title: 'Quantity', detail: '-' },
            { title: 'Location', detail: '-' },
          ],
        },
      ];

  const handleStep = (step, lot) => {
    const newAllInputs = allInputs;
    newAllInputs[activeStep] = lotData;
    setActiveLabel(steps[step]);
    setActiveStep(step);
    setSelectedLot(lot);
    if (activeStep < step) {
      const newLot = [findNestedObj(lotData, 'lot', lot)];
      newAllInputs[step] = newLot;
      setLotData([findNestedObj(lotData, 'lot', lot)]);
    } else {
      setLotData(newAllInputs[step]);
    }
    setAllInputs(newAllInputs);
  };

  // Loop through response and count number of children
  // count the number of children levels in lotData
  let maxCount = 0;
  let count = 0;

  const getDepth = ({ child }) => 1 + (child ? Math.max(...child.map(getDepth)) : 0);

  lotData.forEach(lot => {
    if (lot) {
      count = getDepth(lot);
      if (count > maxCount) {
        maxCount = count;
      }
      count = 0;
    }
  });

  // turn integer into array of booleans
  const completed = new Array(6).fill(false);
  completed[0] = true;
  completed.forEach((step, index) => {
    if (index + 1 <= maxCount + activeStep) {
      completed[index] = 'true';
    }
  });

  const isActive = index => index === activeStep;

  const getPrevPageName = () => {
    if (location && location.state && location.state.prevPath) {
      const { prevPath } = location.state;
      if (prevPath.includes('manufacturing')) {
        return 'Plasma Pool List';
      }
      return 'Donation Details Page';
    }
    return 'Plasma Pool List';
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <Grid
      className={classes.location}
      container
      direction="row"
      spacing={3}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <ViewInDevBanner />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.backButton}>
          <Button onClick={goBack}>
            <ArrowBackIcon color="error" />
            <Typography className={classes.backText} variant="h6" noWrap>
              Back to {getPrevPageName()}
            </Typography>
          </Button>
        </div>
        <Typography variant="overline" className={classes.overviewSubtitle}>
          INVENTORY / MANUFACTURING
        </Typography>
        <br />
      </Grid>
      <Box className={classes.stepperContainer} sx={{ width: '100%' }}>
        <Stepper className={classes.root} activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step className={classes.root} key={label} completed={completed[index]}>
              <StepButton>
                <img className={classes.iconImg} alt="example" src={icons[index]} />
                {completed[index] ? (
                  <StepLabel active={isActive(index)} completed className={classes.root}>
                    {label}
                  </StepLabel>
                ) : (
                  <StepLabel className={classes.root}>{label}</StepLabel>
                )}
                {isActive(index) && (
                  <div className={classes.triangleContainer}>
                    <div className={classes.triangleUp} />
                  </div>
                )}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
      <ManufacturingOverview title={activeLabel} data={overviewData[0] ? overviewData[0] : {}} />
      <Grid item xs={12}>
        <Tabs
          classes={{
            root: classes.navBar,
            flexContainer: classes.flexContainer,
            indicator: classes.sectionIndicator,
          }}
          value={selectedTab}
          onChange={handleTabChange}
        >
          <Tab className={classes.tabLink} value="inputOutput" label="Input &amp; Output" />
          <Tab className={classes.tabLink} value="eventLog" label="Blockchain Event Log" />
        </Tabs>
        <TabPanel value={selectedTab} index="inputOutput">
          <InputOutput
            selectedLot={selectedLot || lotid}
            lotData={lotData.length !== 0 ? lotData : dummyObj}
            activeStep={activeStep}
            handleStep={handleStep}
            lotDonations={lotDonations}
            lotTotals={{ donations: totalDonations, volume: totalVolume, gamma: totalGamma }}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index="eventLog">
          Event Log Here.
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default ManufacturingLocationPool;
